import React, { useState, useEffect,useCallback,useRef } from "react";
import './UserLandingTest.css';
import clogo from '../../images/Naidus directory_Symbol.svg';
import profile from '../../images/sagarsir.svg';
import profileshital from '../../images/shitalnaidu.svg';

import { MdOutlineArrowDropDown, MdLocationOn, MdNotifications, MdWaterDrop } from 'react-icons/md';
import { Link } from "react-router-dom";
import { CgProfile } from 'react-icons/cg'
import { Box } from '@material-ui/core';
import { Slider } from '@material-ui/core';
import AddNewsPopup from "./AddNewsPopup";
import eventlogo from '../../images/eventlogo.svg';
import eventimg1 from '../../images/event1.png';
import eventimg2 from '../../images/event2.png';
import eventimg3 from '../../images/event3.png';
import eventimg4 from '../../images/eventimg4jpg.jpg';
import { BiLike, } from 'react-icons/bi';
import { FaRegComments, FaUserClock, FaFilter, FaInfoCircle,FaSearch, FaRegHandshake, FaEdit, FaBookmark } from 'react-icons/fa';

import { AiOutlineDislike, AiFillLike, AiOutlineHeart,AiOutlineFilePdf ,AiFillHeart,AiOutlineClose,AiFillDislike} from 'react-icons/ai'
import { HiOutlineLogout } from 'react-icons/hi'
import { BsBookmark } from 'react-icons/bs'
import { RiCupFill, RiSendPlaneFill, RiTimeLine } from 'react-icons/ri';
import imgupload from '../../images/imageupload.svg'
import blogimg from '../../images/blog.png'
import kycimg from '../../images/kyc.png'
import AddBlogPopup from "./AddBlogPopup";
import AddKycPopup from "./AddKycPopup";
import flag from '../../images/india.png';
import subcast from '../../images/subcast.svg';
import refferal from '../../images/refferalsvg.svg';
import cups from '../../images/cups.svg';
import from from '../../images/from.svg';
import radius from '../../images/radius.svg';
import conprof from '../../images/coneect.svg';
import hotelpic from '../../images/u1165.png'
import bussslider from '../../images/bussslider.png'
import bussslider1 from '../../images/bussslider1.png'

import contactinfo from '../../images/contactinfo.png';
import connect from '../../images/connect.png';
import ContactInfoPopup from "./ContactInfoPopup";
import NotificationPopup from "./NotificationPopup";
import { MdAddBox, MdWarning,MdOutlineClose } from 'react-icons/md';
import SliderPage from "./SliderPage";
import BloodRequirePopup from "./BloodRequirePopup";
import MessagePopup from "./MessagePopup";
import { Badge, Button, Tab, Nav, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { MdClose } from 'react-icons/md'
import { event } from "jquery";
import { ImCross, ImMobile } from 'react-icons/im'
import { AiOutlineCalendar } from 'react-icons/ai'
import { TbCheckbox } from 'react-icons/tb'
import { FaWhatsapp, FaArrowRight, FaMapPin } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import ReferInviteCommMemberPopup from "../ViewProfile/ReferInviteCommMemberPopup";
import Gallery from "react-photo-gallery";
import Carousel1, { Modal, ModalGateway } from "react-images";
import { photos } from "./photos";
// import Carousel1 from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-grid-carousel'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import SignUpMatrimony from "../SignUpMatrimony/SignUpMatrimony";
import { BsThreeDots } from 'react-icons/bs';
import './Blockpopup.css';
import { MdArrowDropDown } from 'react-icons/md'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment, { duration } from 'moment'
import csc from "country-state-city";
import Select from "react-select";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import MySubscription from "./DataBussnes";
import SimpleImageSlider from "react-simple-image-slider";
import MyJobPosting from "./MyJobPosting";
import MyJobPostingMain from "./MyJobPostingMain";
import MySubscriptionMatrimony from "./MySubscriptionMatrimony";
import SignUpBusiness from "../SignUpBusiness/SignUpBusiness";
import JobAllSeePop from "./JobAllSeePop";
import ReactPaginate from 'react-paginate';
import Avatar from '@mui/material/Avatar';
import SliderPageNew from "./SliderPageNew";
import MessagePopupDirectory from "./MessegePopupDirectory";
import SponserBusiness from "./SponserBusiness";
import ViewProfCommunityDirectPop from "./ViewProfCommunityDirectPop.js";
import SubscribePreMiemPop from "./SubscribePremiemPop";
import SubscribePremiem from "./SubscribePremiemPop";
import BusinessFirstPage from "../SignUpBusiness/BusinessFirstPage";
import AddJobPop from "./AddJobPop";
import ContactViewPop from "./ContactViewPop";
import {configData} from "../../Config"
import ReactCountryFlag from "react-country-flag"
import { CounryflagJson, CountryflagJson } from './CountryflagJson';



//for like button
const { useReducer } = React;
const initialState = {
  likes: 21,
  dislikes: 5
}



function valuetext(value) {
  return `${value}`;
}

const BusinessListingMain = () => {

  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
  console.log("authdata11", authlocalstoragedata)

  const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
  const [uerCurrentCity, setuerCurrentCity] = useState(authlocalstoragedata?.currentCity);

  //for session
  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')

  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);

//for new session for userlogin
 //for updaating authdata value
 
 const [updatedData, setupdatedData] = useState({});


  useEffect(() => {
    loadUpdatedData();
   }, []);

   const loadUpdatedData = async () => {
     debugger;
     const result = await axios.get(`${configData.ApiUrl}/getUpdatedData?userId=${uerAuthId}`);
     setupdatedData(result.data.data);

     console.log("updatedadataforuserlanding",result.data.data)

 //console.log("updatedadataforuserlanding+++++++",updatedData)
   };
   const [updatedDataMatrimonyid, setupdatedDataMatrimonyid] = useState(updatedData.matrimonyId);
   console.log("updatedadataforuserlanding+++++++",updatedData  )


  const [loadProfile, setloadProfile] = useState([]);

  useEffect(() => {
    loadProfilePic();
  }, [loadProfile]);

  const loadProfilePic = async () => {

    debugger;
    const result = await axios.get(`${configData.ApiUrl}/user/getProfilePicture?id=${uerAuthId}`);
    setloadProfile(result.data.data);
    console.log("profpic", result.data.data);
    const rootElement1 = document.getElementById('profpicdisablesave1');

    if(rootElement1){
      rootElement1.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
    if(rootElement1){
      return () => {
        rootElement1.removeEventListener('contextmenu', handleContextMenuimgdisable);
      };
    }
    const rootElement4 = document.getElementById('profpicdisablesave4');

    if(rootElement4){
      rootElement4.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
    if(rootElement4){
    return () => {
      rootElement4.removeEventListener('contextmenu', handleContextMenuimgdisable);
    };
  }
    
  };

  const avtarProf = authlocalstoragedata?.name2.substr(0, 2)

  //for paginatin
  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 5


  //for gallary
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    debugger
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 464 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const sliderImageUrl = [
    //First image url

    {
      url:
        "https://picsum.photos/800/600?random=1"
    },
    {
      url:
        "https://picsum.photos/800/600?random=2"
    },
    {
      url:
        "https://source.unsplash.com/Dm-qxdynoEc/800x799"
    },

    {
      url:
        "https://picsum.photos/800/600?random=2"
    },
    {
      url:
        "https://picsum.photos/800/600?random=2"
    },
    //Second image url

  ];

  const images = [
     { url: bussslider1 },
     { url: bussslider },
    // { url: bussslider1 },
    // { url: bussslider1 },
    // { url: bussslider },
    // { url: bussslider1 },
    // { url: bussslider },
  ];

  const [profdivhide, setprofdivhide] = useState(false);


  //for like functionality
  const appReducer = (state, action) => {
    switch (action.type) {
      case 'HANDLE_LIKE':
        return {
          ...state,
          likes: state.likes + action.payload
        }
      case 'HANDLE_DISLIKE':
        return {
          ...state,
          dislikes: state.dislikes + action.payload
        }
      default:
        return state
    }
  }


  const handleClickLike = () => {
    if (status === 'like') {
      setStatus(null)
      dispatch({
        type: 'HANDLE_LIKE',
        payload: -1,
      })
    } else {
      setStatus('like')
      if (status === 'dislike') {
        dispatch({
          type: 'HANDLE_DISLIKE',
          payload: -1,
        })
      }
      dispatch({
        type: 'HANDLE_LIKE',
        payload: 1,
      })
    }
  }

  const handleClickDislike = () => {
    if (status === 'dislike') {
      setStatus(null)
      dispatch({
        type: 'HANDLE_DISLIKE',
        payload: -1,
      })
    } else {
      setStatus('dislike')
      if (status === 'like') {
        dispatch({
          type: 'HANDLE_LIKE',
          payload: -1,
        })
      }
      dispatch({
        type: 'HANDLE_DISLIKE',
        payload: 1,
      })
    }
  }
  const [state, dispatch] = useReducer(appReducer, initialState)
  const { likes, dislikes } = state
  const [status, setStatus] = useState(null)


  const [isOpenMessagePop, setIsOpenMessagePop] = useState(false);
  const togglePopupMessage = (userId) => {
    debugger;
    if (!styleForMessgblock || !blockPop) {
      setIsOpenMessagePop(!isOpenMessagePop);

    }
    setstyleForMessgid(userId)
    //loadMesseges(userId)

  }

  //for community popup
  const [isOpenMessagePopDirect, setIsOpenMessagePopDirect] = useState(false);
  const [idforpop, setidforpop] = useState(0);
  const [styleForMessgBlockstatusComm, setstyleForMessgBlockstatusComm] = useState(null);

  const togglePopupMessageDirect = (id,blockStatus) => {
    setIsOpenMessagePopDirect(!isOpenMessagePopDirect);
    setidforpop(id);
    setstyleForMessgBlockstatusComm(blockStatus);
  }
  const [isOpenMessagePopViewDirect, setisOpenMessagePopViewDirect] = useState(false);
  const [idforcommpop, setidforcommpop] = useState(0);
  const [styleForMessgBlockstatusCommView, setstyleForMessgBlockstatusCommView] = useState(null);

  
  const toggleviewProfDiPop = (id,blockStatus) => {
    setisOpenMessagePopViewDirect(!isOpenMessagePopViewDirect);
    setidforcommpop(id);
    setstyleForMessgBlockstatusCommView(blockStatus)
  }

  

  

  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const togglePopupNotify = () => {
    setIsOpenNotification(!isOpenNotification);
  }

  const [isOpenContactPopup, setisOpenContactPopup] = useState(false);
  const togglePopupContact = () => {
    setisOpenContactPopup(!isOpenContactPopup);
  }

  const [selected, setSelected] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {

    setIsOpen(!isOpen);


  }

  const [isOpenBlog, setIsOpenBlog] = useState(false);
  const togglePopupBlog = () => {
    setIsOpenBlog(!isOpenBlog);
  }
  const [isOpenkyc, setIsOpenkyc] = useState(false);
  const togglePopupKyc = () => {
    setIsOpenkyc(!isOpenkyc);
  }
  const [isOpentopjobPop, setisOpentopjobPop] = useState(false);
  const togglePopuptopJobPop = () => {
    setisOpentopjobPop(!isOpentopjobPop);
  }
  

  const [connectedStatus, setConnectedStatus] = useState(0);

  const handleChange = event => {
    debugger;
    setSelected(event.target.value);

    const { name, value, type, checked } = event.currentTarget;


    setcmntValue(preState => ({
      ...preState,
      [name]: type === 'checkbox' ? checked : value
    }))

    // setConnectedStatus(preState =>({
    //   ...preState,
    //   [name]:type ==='checkbox'?checked: value
    // }))

    if (type == "checkbox") {
      //loadMembers(event.currentTarget.checked);
      //setConnectedStatus(1);
      loadMembers(checked);

    }



  }

  const [sliderval, setSliderVal] = useState()

  const getValue = (e, val) => {
    setSliderVal(val);
  }

  const mark = [
    {
      value: 25,
      label: "25 Km",
    },
    {
      value: 50,
      label: "50 Km"
    },
    {
      value: 75,
      label: "75 Km"
    },
    {
      value: 100,
      label: "100 Km"
    }


  ]



  const [news, setNews] = useState(false);
  const [communityDirect, setcommunityDirect] = useState(false);
  const [matrimony, setMatrimony] = useState(false);
  const [communityMeet, setcommunityMeet] = useState(false);
  const [businessDirect, setbusinessDirect] = useState(true);

  const showNews = () => {
    setNews(true);
    setcommunityDirect(false);
    setMatrimony(false);
    setbusinessDirect(false);
    setcommunityMeet(false);



  }

  const showCommunityDirect = () => {
    setNews(false);
    setcommunityDirect(true);
    setMatrimony(false);
    setbusinessDirect(false);
    setcommunityMeet(false);

  }
  const showMatrimony = () => {
    setNews(false);
    setcommunityDirect(false);
    setMatrimony(true);
    setbusinessDirect(false);
    setcommunityMeet(false);

  }
  const showBusinessDirect = () => {
    setNews(false);
    setcommunityDirect(false);
    setMatrimony(false);
    setbusinessDirect(true);
    setcommunityMeet(false);
  }
  const showCommunityMeet = () => {
    setNews(false);
    setcommunityDirect(false);
    setMatrimony(false);
    setbusinessDirect(false);
    setcommunityMeet(true);
  }


  const [isOpenBloodPopup, setisOpenBloodPopup] = useState(false);
  const togglePopupBlood = () => {
    setisOpenBloodPopup(!isOpenBloodPopup);
  }


  // const [comment ,setComment] = useState(false);
  // const commentClick = () => {
  //   setComment(true);
  // }
  //logout
  

  

  let history = useHistory();

  const logoutclick = async() => {
    debugger
    const result = await axios.get(configData.ApiUrl+'/logout');
    localStorage.clear()

    history.push("/Login");
  }

  //for disable backbtn
  window.history.pushState(null, null, window.location.href);
window.onpopstate = function () {
    window.history.go(1);
};

  //for bloodreq slider



  // const [bloodReqSlider, setbloodReqSlider] = useState([]);

  // useEffect(() => {
  //   loadBloodReqSlider();
  // }, []);

  // const loadBloodReqSlider = async () => {

  //   const result = await axios.get(`/bloodReq/getCommunityBloodRequirementsForMain?id=${uerAuthId}`);
  //   setbloodReqSlider(result.data.data);
  //   console.log("bldreqslider", result.data.data)
  // };

  const [loadPost, setloadPost] = useState([]);

  // useEffect(() => {
  //   loadPostList();
  // }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      loadPostList();
    }, 5100); // Poll every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  const loadPostList = async () => {


    const result = await axios.get(`${configData.ApiUrl}/CommunityPost/getListOfPosts?id=${uerAuthId}`);
    setloadPost(result.data.data);
    console.log("postlist", result.data.data)
    debugger;
    //for pagination
    setTotalPages(Math.ceil(result.data.length / itemsPerPage));

    const rootElement5 = document.getElementById('profpicdisablesave5');

    if(rootElement5){
      rootElement5.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
    if(rootElement5){
      return () => {
        rootElement5.removeEventListener('contextmenu', handleContextMenuimgdisable);
      };
    }
    const rootElement6 = document.getElementById('profpicdisablesave6');

    if(rootElement6){
      rootElement6.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
    if(rootElement6){
      return () => {
        rootElement6.removeEventListener('contextmenu', handleContextMenuimgdisable);
      };
    }
    const rootElement7 = document.getElementById('profpicdisablesave7');

    if(rootElement7){
      rootElement7.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
    if(rootElement7){
      return () => {
        rootElement7.removeEventListener('contextmenu', handleContextMenuimgdisable);
      };
    }
    const rootElement19 = document.getElementById('profpicdisablesave19');

    if(rootElement19){
      rootElement19.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
    if(rootElement19){
      return () => {
        rootElement19.removeEventListener('contextmenu', handleContextMenuimgdisable);
      };
    }
  };
  //for pagination
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const subset = loadPost.slice(startIndex, endIndex);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  //for addlikes
  const [postid, setPostid] = useState();

  // for(var i=0;i<loadPost.length;i++)
  // {
  //   debugger
  //   loadPost[i].postId=postid ;
  //   console.log(postid)
  // }

  const addLikes = async (id) => {

    const response = await axios.post(configData.ApiUrl+'/CommunityPost/like?postId=' + id + '&userId=' + uerAuthId)
    if (response.status == 200 && response.data.code) {
      loadPostList();

    }
    else {
      alert("Something Went Wrong..!")
    }



  }


  //for getlikesbyid
  const [LikeDiv, setLikeDiv] = useState(false);
  function Likepoupopen(isLike) {
    if (isLike) {
      setLikeDiv(true);
    }
    else {
      setLikeDiv(false);
    }
  }

  
  
  const { id } = useParams();

  const [likeData, setlikeData] = useState([]);

  useEffect(() => {
    //loadlikes();
  }, []);

  const loadLikesInfo = async (id) => {
    console.log(id)
    const result = await axios.get(`${configData.ApiUrl}/CommunityPost/getListOfLikesById?id=${id}`);
    setlikeData(result.data.data);
    //  debugger;
    console.log("likesdt", result.data.data);

    Likepoupopen(true);
  };

  //for contactinfopopup
  const [contactInfoDiv, setcontactInfoDiv] = useState(false);
  const [commanView, setcommanView] = useState({
    fullName: "",
    emailId: "",
    countryCode: "",
    mobileNumber: "",
    connectionStatus: "",
    mobileVisibility: ""
  })
  var info = "";
  const contactInfopoupopen = (id, isContInfo) => {
    if (id > 0) {
      info = commDirectory.filter((obj) =>
        id == obj.id)[0];

      //setcommanView({...commanView :info} );
      commanView.fullName = info.fullName;


      commanView.emailId = info.emailId;
      commanView.mobileVisibility = info.mobileVisibility;
      if (commanView.mobileVisibility == 0) {
        commanView.mobileNumber = info.mobileNumber.substr(0, 2) + 'xxxxxx'
          + info.mobileNumber.substr(8, 9);
      }
      else {
        commanView.mobileNumber = info.mobileNumber
      }
      commanView.connectionStatus = info.connectionStatus
    }

    console.log("conm View =", commanView);

    if (isContInfo) {
      setcontactInfoDiv(true);
    }
    else {
      setcontactInfoDiv(false);
    }
  }

  //for cofeediv
  const [coffeediv, setcoffeediv] = useState(false);
  const [coffeeView, setcoffeeView] = useState({
    hostId: "",
    hostName: "",
    date: "",
    city: "",
    venue: "",
    time: "",
    guests: [],
    attendeesCount: "",
    googleMapsLink: "",
    attendStatus: "",
    id: "",
    profilePicturePath:""
  })
  var info = "";
  const coffeeInfopoupopen = (id, isCoffeeInfo) => {
    if (id > 0) {
      info = coffeeMeets.filter((obj) =>
        id == obj.id)[0];

      //setcommanView({...commanView :info} );
      coffeeView.hostName = info.hostName;
      coffeeView.date = info.date;
      coffeeView.city = info.city;
      coffeeView.venue = info.venue;
      coffeeView.time = info.time;
      coffeeView.guests = info.guests;
      coffeeView.attendeesCount = info.attendeesCount;
      coffeeView.googleMapsLink = info.googleMapsLink;
      coffeeView.attendStatus = info.attendStatus;
      coffeeView.id = info.id;
coffeeView.profilePicturePath = info.profilePicturePath
   }
    console.log("conm View =", coffeeView);

    if (isCoffeeInfo) {
      setcoffeediv(true);
    }
    else {
      setcoffeediv(false);
    }
  }

  //for comments
  const [comment, setComment] = useState(false);
  const [cmntEventId, setCmntEventId] = useState(0);

  function Commentpoupopen(commentopen) {
    if (commentopen) {
      setComment(true);
    }
    else {
      setComment(false);
    }
  }

  const [commentData, setcommentData] = useState([]);
  const [cmntValue, setcmntValue] = useState({
    txtCmnt: ""
  });




  useEffect(() => {
    //loadlikes();
  }, []);

  const commentInfo = async (id) => {
    console.log(id)
    const result = await axios.get(`${configData.ApiUrl}/CommunityPost/getListOfCommentsById?id=${id}`);
    setcommentData(result.data.data.sort((a, b) => b.id - a.id));
    //  debugger;
    console.log("commentdt", result.data.data.sort((a, b) => b.id - a.id));
    setCmntEventId(id);

    Commentpoupopen(true);
    setcmntValue(preState => ({
      ...preState,
      "txtCmnt": ""
    }))
  };
  const [commentValiderr, setcommentValiderr] = useState(false)

  const handleKeypress = e => {
    //it triggers by pressing the enter key
    debugger
    if (e.key === "Enter") {
      saveComent(id);

    }
  };

  const saveComent = async (id) => {
    if (cmntValue.txtCmnt == "") {
      setcommentValiderr(true)
      return false;
    }
    else {
      setcommentValiderr(false)
      const response = await axios.post(configData.ApiUrl+'/CommunityPost/comment?postId=' + id + '&userId=' + uerAuthId + '&comment=' + cmntValue.txtCmnt)
      setcmntValue(preState => ({
        ...preState,
        "txtCmnt": ""
      }))
      debugger;
      loadPostList();
      commentInfo(id);


    }


  }

  const deleteComment = (id) => {

    const response = axios.delete(`${configData.ApiUrl}/CommunityPost/deleteComment?commentId=${id}`)
    response.then(() => {
      toast.success("Comment deleted Successfully!");
      loadPostList();

      commentInfo(id);


    })
    response.catch((err) => {
      alert("Getting error in featching data")
    });


  }

  //for community directory
  const [commDirectory, setcommDirectory] = useState([]);

  // useEffect(() => {
  //   loadCommDirectory();
  // }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      loadCommDirectory();
    }, 3000); // Poll every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  const loadCommDirectory = async () => {
    debugger
    const result = await axios.get(`${configData.ApiUrl}/subscriber/getAllSubscribers?id=${uerAuthId}`);
    setcommDirectory(result.data.data);
    console.log("comdirectory",result.data.data)
    const rootElement8 = document.getElementById('profpicdisablesave8');
    const rootElement9 = document.getElementById('profpicdisablesave9');
    
    if(rootElement8){
      rootElement8.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
    if(rootElement8){
      return () => {
        rootElement8.removeEventListener('contextmenu', handleContextMenuimgdisable);
      };
    }
    if(rootElement9){
      rootElement9.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
    if(rootElement9){
      return () => {
        rootElement9.removeEventListener('contextmenu', handleContextMenuimgdisable);
      };
    }
    const rootElement20 = document.getElementById('profpicdisablesave20');
    if(rootElement20){
      rootElement20.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
    if(rootElement20){
      return () => {
        rootElement20.removeEventListener('contextmenu', handleContextMenuimgdisable);
      };
    }
  };


  function handleContextMenuimgdisable(e) {
    debugger
    e.preventDefault(); // prevents the default right-click menu from appearing
  }

  //bookmark
  //for community directory


  const addCommDirectoryBookmark = async (id) => {

    const response = await axios.post(configData.ApiUrl+'/subscriber/bookMark?hostId=' + uerAuthId + '&userId=' + id)
    if (response.status == 200 && response.data.code) {
      //toast.success("Bookmarked Successfully!")
      loadCommDirectory();

    }
    else {
      alert("Something Went Wrong..!")
    }
  }
  //forconnection reqsent in commDirectory
  const [commDirectoryconReq, setcommDirectoryconReq] = useState(false);

  const addCommDirectoryConnect = async (id) => {
    debugger
    const response = await axios.post(configData.ApiUrl+'/connection/sendConnection?senderId=' + uerAuthId + '&recieverId=' + id)
    if (response.status == 200 && response.data.code) {
      //setcommDirectoryconReq(true)
      loadCommDirectory();

    }
    else {
      setcommDirectoryconReq(false)
      alert("Something Went Wrong..!")
    }
  }

  //for  getall connection of 
  const [connection, setconnection] = useState([]);

  // useEffect(() => {
  //   loadConnection();
  // }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      loadConnection();
    }, 1500); // Poll every 5 seconds

    return () => clearInterval(intervalId);
  }, []);


  const loadConnection = async () => {

    const result = await axios.get(`${configData.ApiUrl}/connection/getListRecievedConnections?id=${uerAuthId}`);
    setconnection(result.data.data);
    console.log("connections", result.data.data)
    const rootElement3 = document.getElementById('profpicdisablesave3');

    if(rootElement3){
      rootElement3.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
  };
  //for accept connection in my connection
  const acceptConnection = async (senderId) => {
    debugger
    const response = await axios.post(configData.ApiUrl+'/connection/acceptConnection?senderId=' + senderId + '&recieverId=' + uerAuthId)
    if (response.status == 200 && response.data.code) {
      toast.success("Successfully Accepted Connection Request")

      loadConnection();

    }
    else {
      alert("Something Went Wrong..!")
    }
  }
  //for Reject connection in my connection
  const rejectConnection = async (senderId) => {
    debugger
    const response = await axios.post(configData.ApiUrl+'/connection/rejectConnection?senderId=' + senderId + '&recieverId=' + uerAuthId)
    if (response.status == 200 && response.data.code) {
      toast.success("Successfully Rejected Connection Request")
      loadConnection();

    }
    else {
      alert("Something Went Wrong..!")
    }
  }


  //for load list of coffee meets
  const [coffeeMeets, setcoffeeMeets] = useState([]);

  // useEffect(() => {
  //   loadCoffeeMeets();
  // }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      loadCoffeeMeets();
    }, 5000); // Poll every 5 seconds

    return () => clearInterval(intervalId);
  }, []);


  const loadCoffeeMeets = async () => {

    const result = await axios.get(`${configData.ApiUrl}/coffeeMeet/getListOfUpcomingCoffeeMeets?hostId=${uerAuthId}`);
    setcoffeeMeets(result.data.data);
    console.log("coffeemeet", result.data.data);
    const rootElement18 = document.getElementById('profpicdisablesave18');

    if(rootElement18){
      rootElement18.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
    if(rootElement18){
      return () => {
        rootElement18.removeEventListener('contextmenu', handleContextMenuimgdisable);
      };
    }
    
  };

  //for my coffee meet search functionality
  //const [searchVal, setSearchVal] = useState("");
  const handleSearchChange = (e) => {
    debugger
    if (e.currentTarget.value === "") {
      loadCoffeeMeets();
      return;
    }
    const filterBySearch = coffeeMeets.filter((item) => {
      if (item != null && item.hostName != null) {
        if (item?.hostName?.toLowerCase().includes(e.currentTarget.value)) { return item; }
      }
      // if ( item != null && item.profession != null){
      //   if (item?.profession?.toLowerCase()
      //       .includes(e.currentTarget.value)) { return item; }}
    })
    setcoffeeMeets(filterBySearch);
  }

  //second filter
  //for filter
  const [pendingDIv, setpendingDIv] = useState(false);
  const [acceptedDiv, setacceptedDiv] = useState(false);
  const [allDiv, setallDiv] = useState(false);


  const [filter, setfilter] = useState({
    filterstatus: ""
  });

  const handleChangeFilter = (e) => {
    debugger
    const { name, value, type, checked } = e.currentTarget;


    setfilter(preState => ({
      ...preState,
      [name]: type === 'checkbox' ? checked : value
    }));

    if (e.target.value == "Pending") {
      //setisfilterdata( e.target.value )
      //setAadharDIv(true);
      setpendingDIv(true);
      setacceptedDiv(false);
      setallDiv(false);

    }
    else if (e.target.value == "accepted") {
      //setAadharDIv(false);
      setacceptedDiv(true);
      setpendingDIv(false);
      setallDiv(false);
    }

    else {
      setallDiv(true);
      setpendingDIv(false);
      setacceptedDiv(false)
    }


  }


  const [countmenu, setData] = useState([]);

  // useEffect(() => {
  //   loadUsers();
  // }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      loadUsers();
    }, 1000); // Poll every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  const loadUsers = async () => {
    const result = await axios.get(`${configData.ApiUrl}/subscriber/getCountForInvitations?id=${uerAuthId}`);

    setData(result.data.data);
    console.log("getCountForInvitations", result.data.data)

  };


  const [isOpenMessagePop1, setIsOpenMessagePop1] = useState(false);
  const togglePopupMessage1 = () => {
    setIsOpenMessagePop1(!isOpenMessagePop1);
  }

  //for spampost
  const addSpam = async (id) => {

    const response = await axios.post(configData.ApiUrl+'/CommunityPost/spam?postId=' + id + '&userId=' + uerAuthId)
    if (response.status == 200 && response.data.code) {
      loadPostList();

    }
    else {
      alert("Something Went Wrong..!")
    }

  }

  const redirectTorefferal = () => {

    history.push("/ViewProfile");

  }


  //for members in my location

  const [members, setMembers] = useState([]);

  useEffect(() => {
    loadMembers();
  }, []);


  const loadMembers = async (checkedval) => {
    console.log("checkedval", checkedval)
    if (checkedval == true) {

      const result = await axios.get(configData.ApiUrl+'/user/getUsersNearMe?id=' + uerAuthId + '&connectedStatus=' + 1 + '&currentCity=' + uerCurrentCity);

      setMembers(result.data.data);
      console.log("getmembersinlocation", result.data.data)
      const rootElement2 = document.getElementById('profpicdisablesave2');

    if(rootElement2){
      rootElement2.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
    if(rootElement2){
      return () => {
        rootElement2.removeEventListener('contextmenu', handleContextMenuimgdisable);
      };
    }
    }
    else {

      const result = await axios.get(configData.ApiUrl+'/user/getUsersNearMe?id=' + uerAuthId + '&connectedStatus=' + 0 + '&currentCity=' + uerCurrentCity);

      setMembers(result.data.data);
      console.log("getmembersinlocation", result.data.data);
      const rootElement2 = document.getElementById('profpicdisablesave2');

    if(rootElement2){
      rootElement2.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
    if(rootElement2){
      return () => {
        rootElement2.removeEventListener('contextmenu', handleContextMenuimgdisable);
      };
    }
    }



  };

  const [isOpenSignMatriPopup, setisOpenSignMatriPopup] = useState(false);
  const togglePopupSignUpmatri = () => {
    setisOpenSignMatriPopup(!isOpenSignMatriPopup);
  }

  const [isOpenSignBusinessPopup, setisOpenSignBusinessPopup] = useState(false);
  const togglePopupSignUpBusiness = () => {
    setisOpenSignBusinessPopup(!isOpenSignBusinessPopup);
  }

  
  ///for mesenger
  const [userlist, setuserlist] = useState([]);

  // useEffect(() => {
  //   loadUserList();
  // }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      loadUserList();
    }, 5000); // Poll every 5 seconds

    return () => clearInterval(intervalId);
  }, []);


  const loadUserList = async () => {
    const result = await axios.get(`${configData.ApiUrl}/messenger/getUsersListMessenger?hostId=${uerAuthId}`);

    setuserlist(result.data.data);
    console.log("getuserlist", result.data.data);
    const rootElement17 = document.getElementById('profpicdisablesave17');

    if(rootElement17){
      rootElement17.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
    if(rootElement17){
      return () => {
        rootElement17.removeEventListener('contextmenu', handleContextMenuimgdisable);
      };
    }
  };

  //for search messg
  const handleSearchChangeMessage = (e) => {
    debugger
    if (e.currentTarget.value === "") {
      loadUserList();
      return;
    }
    const filterBySearch = userlist.filter((item) => {
      if (item != null && item.userName != null) {
        if (item?.userName?.toLowerCase().includes(e.currentTarget.value)) { return item; }
      }
       if ( item != null && item.lastMessage != null){
         if (item?.lastMessage?.toLowerCase()
             .includes(e.currentTarget.value)) { return item; }}
    })
    setuserlist(filterBySearch);
  }


  const [styleForMessg, setstyleForMessg] = useState(true);
  const [styleForMessgid, setstyleForMessgid] = useState(0);



  const [styleForMessgBlockstatus, setstyleForMessgBlockstatus] = useState("");

  const omMouseHover = (userId,blockStatus) => {

    setstyleForMessg(true);
    setstyleForMessgid(userId);
    setstyleForMessgBlockstatus(blockStatus)

  }
  const omMouseLeve = (userId) => {

    setstyleForMessg(false);

  }

  //for three dot for bpck unblock
  const [styleForMessgblock, setstyleForMessgblock] = useState(true);
  const [style, setStyle] = useState({ display: 'none' });

  const omMouseHoverblock = (userId) => {

    setIsOpenMessagePop(false)
    setstyleForMessgid(userId)

    //setStyle({display: 'block',border: '1px solid gray',  padding: 6, borderRadius:50});
    setstyleForMessgblock(true)
  }
  const omMouseLeveBlock = (userId) => {

    setstyleForMessgblock(false)

  }


  const addblockUnblock = async (userId) => {
debugger
    const response = await axios.post(configData.ApiUrl+'/messenger/block-unblock?hostId=' + uerAuthId + '&userId=' + userId)
    if (response.status == 200 && response.data.code) {
      toast.success("Updated Block Status Successfully!");
      loadUserList();
      blockunblockpoupopen(false)
      setblockPop(false)
    }
    else {
      alert("Something Went Wrong..!")
    }
  }

  //for block popup
  const [blockPop, setblockPop] = useState(false);

  function blockunblockpoupopen(blockpopopen) {
    if (blockpopopen) {
      setblockPop(true);
    }
    else {
      setblockPop(false);
    }
  }

  const attendStatusCofeeMeet = async (id) => {



    debugger;
    const response = await axios.post(configData.ApiUrl+'/coffeeMeet/editGuestStatus?guestId=' + uerAuthId + '&coffeeMeetId=' + id)
    if (response.status == 200 && response.data.code) {
      toast.success("Update Status  Successfully!")
      loadCoffeeMeets();

    }
    else {
      alert("Something Went Wrong..!")
    }
  }

  //for matrimony
  const [matrimonyDiv, setmatrimonyDiv] = useState(true);
  const [matrimonyEditDiv, setmatrimonyEditDiv] = useState(false);



  const onNext = (isback) => {
    debugger;
    if (isback) {
      setmatrimonyDiv(true);
      setmatrimonyEditDiv(false)
    }
    else {
      setmatrimonyDiv(false);
      setmatrimonyEditDiv(true)
    }
  }

  const [startDate, setStartDate] = useState(new Date());

  const handleChangedate = (e) => {
    setStartDate(e)
    //  setBlog(preState => ({
    //   ...preState,
    //   "scheduledDate": (moment(e).format("DD MMM yyyy")),

    // }))
  }
  const [formData, setFormData] = useState({

    reqCountryId: "",
    reqCountry: "",
    reqStateId: "",
    reqState: "",
    reqCityId: "",
    reqCity: "",


  });

  const countries = csc.getAllCountries();
  ////console.log(csc.getAllCountries())

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country
  }));

  const updatedStates = (countryId) => csc.getStatesOfCountry(countryId)
    .map((state) => ({ label: state.name, value: state.name, ...state }));

  const updatedCities = (stateId) =>
    csc
      .getCitiesOfState(stateId)
      .map((city) => ({ label: city.name, value: city.name, ...city }));




  //for business directory

  const columns = [
    {
      name: "Job Id",
      selector: "id",
      sortable: true,
      width: "100px",
      cell: (d) => (
        <Link to={`/ViewJobPosting/${d.id}`} className="dlink">
          {d.id}
        </Link>
      ),

    },

    {
      name: "Date",
      selector: "createdDate",
      sortable: true,

    },
    {
      name: "Position",
      selector: "role",
      sortable: true,
      width: "100px"
    },

    {
      name: "Location",
      selector: "city",
      sortable: true,
      className: "texttransformcapitalize"

    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (d) => [
        d.status == "Open" ? <span className="" style={{ color: '#70B603' }}>Open</span>
          : <span className="" style={{ color: '#FA114F' }}>Closed</span>

      ]

    },


  ];

  //  const data = [
  //     {
  //         jobId:"1002",
  //         date:"1 May 2023",
  //         position: "Sales Executive",
  //         location: "Pune",
  //          status: "Open",


  //       },
  //       {
  //         jobId:"1003",
  //         date:"1 May 2023",
  //         position: "Sales Executive",
  //         location: "Pune",
  //          status: "Closed",

  //         },

  //             ]



  const [data, setDataJob] = useState([]);

  const tableData = {
    columns,
    data,

  };

  useEffect(() => {
    loadJobList();
  }, []);

  const loadJobList = async () => {
    debugger
    const result = await axios.get(`${configData.ApiUrl}/buisness/getListJobPostings?buisnessId=${authlocalstoragedata?.buisnessId}`);
    setDataJob(result.data.data);
    console.log("getalljobpost", result.data.data)


  };


  const redirecttoViewProf = () => {
    history.push("/ViewProfile");
  }
  const redirectToReferal = () => {
    history.push("/RefferalMain");
  }
  const redirectToContact = () => {
    history.push("/ContactMain");
  }
  const redirectToContactRecive = () => {
    history.push("/ViewContactRecive");
  }
  const redirectToCoffeemeetInvite = () => {
    history.push("/ViewCoffeeMeetinvitatin");
  }
  const redirectMybussProfilemain = () => {
    history.push("/MybussProfileMain");

  }
  const redirectToAccSetting = () => {
    history.push("/AccountMain");
  }
  const redirectToBloodReq = () => {
    history.push("/BloodReqMain");
  }

  ///for matrimony all profile
  const [matrimonyAllProf, setmatrimonyAllProf] = useState([]);

  useEffect(() => {
    loadMatAllProf();
  }, []);


  const loadMatAllProf = async () => {
    const result = await axios.get(`${configData.ApiUrl}/matrimony/getMatrimonyProfiles?matrimonyId=${authlocalstoragedata?.matrimonyId}`);

    setmatrimonyAllProf(result.data.data);
    console.log("getAllMatProflist", result.data.data)
    const rootElement10 = document.getElementById('profpicdisablesave10');

     if(rootElement10){
       rootElement10.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement10){
       return () => {
         rootElement10.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
  };

  ///for shortlisted  profile
  const [matrimonyShortProf, setmatrimonyShortProf] = useState([]);

  useEffect(() => {
    loadMatShortProf();
  }, []);


  const loadMatShortProf = async () => {
    const result = await axios.get(`${configData.ApiUrl}/matrimony/getMatrimonyShortlistedProfiles?matrimonyId=${authlocalstoragedata?.matrimonyId}`);

    setmatrimonyShortProf(result.data.data);
    console.log("getShortMatProflist", result.data.data);
    const rootElement16 = document.getElementById('profpicdisablesave16');

     if(rootElement16){
       rootElement16.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement16){
       return () => {
         rootElement16.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
  };
  ///for Myprofile  profile
  const [matrimonyMyProf, setmatrimonyMyProf] = useState([]);

  useEffect(() => {
    loadMatMyProf();
  }, []);


  const loadMatMyProf = async () => {
    const result = await axios.get(`${configData.ApiUrl}/matrimony/getMatrimonyMyProfile?matrimonyId=${authlocalstoragedata?.matrimonyId}`);

    setmatrimonyMyProf(result.data.data);
    console.log("getMyMatProflist", result.data.data);
    const rootElement11 = document.getElementById('profpicdisablesave11');

     if(rootElement11){
       rootElement11.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement11){
       return () => {
         rootElement11.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }

     const rootElement12 = document.getElementById('profpicdisablesave12');

     if(rootElement12){
       rootElement12.addEventListener('contextmenu', handleContextMenuimgdisable);
     }

     if(rootElement12){
       return () => {
         rootElement12.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
     const rootElement13 = document.getElementById('profpicdisablesave13');

     if(rootElement13){
       rootElement13.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement13){
       return () => {
         rootElement13.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
     const rootElement14 = document.getElementById('profpicdisablesave14');

     if(rootElement14){
       rootElement14.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement14){
       return () => {
         rootElement14.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
     const rootElement15 = document.getElementById('profpicdisablesave15');

     if(rootElement15){
       rootElement15.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement15){
       return () => {
         rootElement15.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
  };

   ///for viewprofile  profile
   const [matrimonyViewProf, setmatrimonyViewProf] = useState([]);

   useEffect(() => {
    loadMatViewProf();
   }, []);
 
 
   const loadMatViewProf = async () => {
     const result = await axios.get(`${configData.ApiUrl}/matrimony/getMatrimonyMyProfile?matrimonyId=${authlocalstoragedata?.matrimonyId}`);
 
     setmatrimonyViewProf(result.data.data);
     console.log("getViewMatProflist", result.data.data)
 
   };

  //for Matrimony directory


  const addShortList = async (id) => {

    const response = await axios.post(configData.ApiUrl+'/matrimony/shortlist?hostMatrimonyId=' + authlocalstoragedata?.matrimonyId + '&userMatrimonyId=' + id)
    if (response.status == 200 && response.data.code) {
      //toast.success("Bookmarked Successfully!")
      //loadMatAllProf();
      const result = await axios.get(`${configData.ApiUrl}/matrimony/getMatrimonyProfiles?matrimonyId=${authlocalstoragedata?.matrimonyId}`);

    setmatrimonyAllProf(result.data.data);
    console.log("getAllMatProflist", result.data.data)
    const rootElement10 = document.getElementById('profpicdisablesave10');

     if(rootElement10){
       rootElement10.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement10){
       return () => {
         rootElement10.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
    }
    else {
      alert("Something Went Wrong..!")
    }
  }


  ///for business listing  profile
  const [allbusinessList, setallbusinessList] = useState([]);

  useEffect(() => {
    loadBusinessList();
  }, []);


  const loadBusinessList = async () => {
    const result = await axios.get(`${configData.ApiUrl}/buisness/getBuisnessProfiles?buisnessId=${authlocalstoragedata?.buisnessId}`);

    setallbusinessList(result.data.data);
    console.log("getallbusiness", result.data.data)

  };

  ///for business myprofile
  const [myProfilebusinessList, setmyProfilebusinessList] = useState([]);

  useEffect(() => {
    loadBusinessMyProfile();
  }, []);


  const loadBusinessMyProfile = async () => {
    const result = await axios.get(`${configData.ApiUrl}/buisness/getBuisnessProfilesById?buisnessId=${authlocalstoragedata?.buisnessId}`);

    setmyProfilebusinessList(result.data.data);
    console.log("getmyprofbussness", result.data.data)

  };
console.log("mybussprofbyid",myProfilebusinessList)
  //for job psting fr userlanding pg
  const [jobPostingList, setjobPostingList] = useState([]);

  useEffect(() => {
    loadJobPostingList();
  }, []);


  const loadJobPostingList = async () => {
    const result = await axios.get(`${configData.ApiUrl}/buisness/getListJobOpenings?buisnessId=${authlocalstoragedata?.buisnessId}`);

    setjobPostingList(result.data.data);
    console.log("jobPostingList", result.data.data)

  };

  //for opening job popup
  const [isOpenJobPop, setisOpenJobPop] = useState(false);
  const togglePopupJob = () => {
    setisOpenJobPop(!isOpenJobPop);
  }

  //for directory filter

  const [bloodGroupDIv, setbloodGroupDIv] = useState(false);
  const [countryDiv, setcountryDiv] = useState(false);
  const [stateDiv, setstateDiv] = useState(false);
  const [cityDiv, setcityDiv] = useState(false);
  const [professionDiv, setprofessionDiv] = useState(false);


  const [filterDirect, setfilterDirect] = useState({
    name: ""
  });

  const [filterdiData, setfilterdiData] = useState({
    city: "",
    country: "",
    state: "",
    bloodGroup: "",
    profession: ""
  })

  const handleChangeDirectFilter = (e) => {
    debugger
    const { name, value, type, checked } = e.currentTarget;


    setfilterdiData(preState => ({
      ...preState,
      [name]: type === 'checkbox' ? checked : value
    }));

    setfilterDirect(preState => ({
      ...preState,
      "name" : value
    }));
    

    if (e.target.value == "country") {

      setcountryDiv(true);
      setstateDiv(false)
      setcityDiv(false);
      setbloodGroupDIv(false);
      setprofessionDiv(false);
      //loadCommDirectory(e.target.value)

    }
    else if (e.target.value == "state") {
      //setAadharDIv(false);
      setcountryDiv(false);
      setstateDiv(true)
      setcityDiv(false);
      setbloodGroupDIv(false);
      setprofessionDiv(false);
      //loadCommDirectory(e.target.value)


    }
    else if (e.target.value == "city") {
      //setAadharDIv(false);
      setcountryDiv(false);
      setstateDiv(false)
      setcityDiv(true);
      setbloodGroupDIv(false);
      setprofessionDiv(false);
      //loadCommDirectory(e.target.value)


    }
    else if (e.target.value == "blood group") {
      //setAadharDIv(false);
      setcountryDiv(false);
      setstateDiv(false)
      setcityDiv(false);
      setbloodGroupDIv(true);
      setprofessionDiv(false);
      //loadCommDirectory(e.target.value)

    }
    else if (e.target.value == "profession") {
      //setAadharDIv(false);
      setcountryDiv(false);
      setstateDiv(false)
      setcityDiv(false);
      setbloodGroupDIv(false);
      setprofessionDiv(true);
      //loadCommDirectory(e.target.value)

    }

    else {
      setcountryDiv(false);
      setstateDiv(false)
      setcityDiv(false);
      setbloodGroupDIv(false);
      setprofessionDiv(false);
      //loadCommDirectory1
      if (e.target.value == "All") {
        loadCommDirectory1(e.target.value)

      }
      else if (e.target.value == "bookmark profile") {
        loadCommDirectory1(e.target.value)

       
      }

    }


  }


  const [filterCountryName, setfilterCountryName] = useState("")

  const handleChangeforFilterDirect = (e) => {
    debugger
    const { name, value, type, checked } = e.currentTarget;


    setfilterCountryName(e.target.value)

  }

  const filterCountry = (e) => {
    loadCommDirectory1('country')
  }

  const [filterStateName, setfilterStateName] = useState("")

  const handleChangeforFilterState = (e) => {
    debugger
    const { name, value, type, checked } = e.currentTarget;

    setfilterStateName(e.target.value)

  }

  const filterState = (e) => {
    loadCommDirectory1('state')
  }

  const [filterCityName, setfilterCityName] = useState("")

  const handleChangeforFilterCity = (e) => {
    debugger
    const { name, value, type, checked } = e.currentTarget;

    setfilterCityName(e.target.value);

  }

  const filterCity = (e) => {
    loadCommDirectory1('city')
  }

  const [filterBlooodName, setfilterBloodName] = useState("")

  const handleChangeforFilterBlood = (e) => {
    debugger
    const { name, value, type, checked } = e.currentTarget;

    setfilterBloodName(e.target.value);

  }

  const filterBlood = (e) => {
    loadCommDirectory1('blood group')
  }
  const [filterProfessionName, setfilterProfessionName] = useState("")

  const handleChangeforFilterProfession = (e) => {
    debugger
    const { name, value, type, checked } = e.currentTarget;

    setfilterProfessionName(e.target.value);

  }

  const filterProfession = (e) => {
    loadCommDirectory1('profession')
  }


  const loadCommDirectory1 = async (directFilter) => {
    debugger;

    if (directFilter == 'All') {
      debugger
      const result =  await axios.get(`${configData.ApiUrl}/subscriber/getAllSubscribers?id=${uerAuthId}`);
      setcommDirectory(result.data.data);
      console.log("comdirectory", result.data.data)
      const rootElement8 = document.getElementById('profpicdisablesave8');
  const rootElement9 = document.getElementById('profpicdisablesave9');
  
   if(rootElement8){
     rootElement8.addEventListener('contextmenu', handleContextMenuimgdisable);
   }
   if(rootElement8){
     return () => {
       rootElement8.removeEventListener('contextmenu', handleContextMenuimgdisable);
     };
   }
   if(rootElement9){
     rootElement9.addEventListener('contextmenu', handleContextMenuimgdisable);
   }
   if(rootElement9){
     return () => {
       rootElement9.removeEventListener('contextmenu', handleContextMenuimgdisable);
     };
   }
   const rootElement20 = document.getElementById('profpicdisablesave20');
   if(rootElement20){
     rootElement20.addEventListener('contextmenu', handleContextMenuimgdisable);
   }
   if(rootElement20){
     return () => {
       rootElement20.removeEventListener('contextmenu', handleContextMenuimgdisable);
     };
   }
    }
    else if(directFilter=="bookmark profile"){
      const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + directFilter);
      setcommDirectory(result.data.data);
      console.log("comdirectory", result.data.data)
      const rootElement8 = document.getElementById('profpicdisablesave8');
  const rootElement9 = document.getElementById('profpicdisablesave9');
  
   if(rootElement8){
     rootElement8.addEventListener('contextmenu', handleContextMenuimgdisable);
   }
   if(rootElement8){
     return () => {
       rootElement8.removeEventListener('contextmenu', handleContextMenuimgdisable);
     };
   }
   if(rootElement9){
     rootElement9.addEventListener('contextmenu', handleContextMenuimgdisable);
   }
   if(rootElement9){
     return () => {
       rootElement9.removeEventListener('contextmenu', handleContextMenuimgdisable);
     };
   }
   const rootElement20 = document.getElementById('profpicdisablesave20');
   if(rootElement20){
     rootElement20.addEventListener('contextmenu', handleContextMenuimgdisable);
   }
   if(rootElement20){
     return () => {
       rootElement20.removeEventListener('contextmenu', handleContextMenuimgdisable);
     };
   }
    }
    else if (directFilter == 'country') {
      debugger

      const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + filterDirect.name + '&country=' + filterCountryName);
      setcommDirectory(result.data.data);
      console.log("comdirectory", result.data.data)
      const rootElement8 = document.getElementById('profpicdisablesave8');
  const rootElement9 = document.getElementById('profpicdisablesave9');
  
   if(rootElement8){
     rootElement8.addEventListener('contextmenu', handleContextMenuimgdisable);
   }
   if(rootElement8){
     return () => {
       rootElement8.removeEventListener('contextmenu', handleContextMenuimgdisable);
     };
   }
   if(rootElement9){
     rootElement9.addEventListener('contextmenu', handleContextMenuimgdisable);
   }
   if(rootElement9){
     return () => {
       rootElement9.removeEventListener('contextmenu', handleContextMenuimgdisable);
     };
   }
   const rootElement20 = document.getElementById('profpicdisablesave20');
   if(rootElement20){
     rootElement20.addEventListener('contextmenu', handleContextMenuimgdisable);
   }
   if(rootElement20){
     return () => {
       rootElement20.removeEventListener('contextmenu', handleContextMenuimgdisable);
     };
   }
    }
    else if (filterdiData.filterDirect == 'state') {
      debugger

      const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + filterDirect.name + '&state=' + filterStateName);
      setcommDirectory(result.data.data);
      console.log("comdirectory", result.data.data)
      const rootElement8 = document.getElementById('profpicdisablesave8');
  const rootElement9 = document.getElementById('profpicdisablesave9');
  
   if(rootElement8){
     rootElement8.addEventListener('contextmenu', handleContextMenuimgdisable);
   }
   if(rootElement8){
     return () => {
       rootElement8.removeEventListener('contextmenu', handleContextMenuimgdisable);
     };
   }
   if(rootElement9){
     rootElement9.addEventListener('contextmenu', handleContextMenuimgdisable);
   }
   if(rootElement9){
     return () => {
       rootElement9.removeEventListener('contextmenu', handleContextMenuimgdisable);
     };
   }
   const rootElement20 = document.getElementById('profpicdisablesave20');
   if(rootElement20){
     rootElement20.addEventListener('contextmenu', handleContextMenuimgdisable);
   }
   if(rootElement20){
     return () => {
       rootElement20.removeEventListener('contextmenu', handleContextMenuimgdisable);
     };
   }
    }
    else if (filterdiData.filterDirect == 'city') {
      debugger

      const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + filterDirect.name + '&city=' + filterCityName);
      setcommDirectory(result.data.data);
      console.log("comdirectory", result.data.data)
      const rootElement8 = document.getElementById('profpicdisablesave8');
      const rootElement9 = document.getElementById('profpicdisablesave9');
      
       if(rootElement8){
         rootElement8.addEventListener('contextmenu', handleContextMenuimgdisable);
       }
       if(rootElement8){
         return () => {
           rootElement8.removeEventListener('contextmenu', handleContextMenuimgdisable);
         };
       }
       if(rootElement9){
         rootElement9.addEventListener('contextmenu', handleContextMenuimgdisable);
       }
       if(rootElement9){
         return () => {
           rootElement9.removeEventListener('contextmenu', handleContextMenuimgdisable);
         };
       }
       const rootElement20 = document.getElementById('profpicdisablesave20');
       if(rootElement20){
         rootElement20.addEventListener('contextmenu', handleContextMenuimgdisable);
       }
       if(rootElement20){
         return () => {
           rootElement20.removeEventListener('contextmenu', handleContextMenuimgdisable);
         };
       }
    }
    else if (filterdiData.filterDirect == 'blood group') {
      debugger
      const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + filterDirect.name + '&bloodGroup=' + filterBlooodName);
      setcommDirectory(result.data.data);
      console.log("comdirectory", result.data.data)
      const rootElement8 = document.getElementById('profpicdisablesave8');
    const rootElement9 = document.getElementById('profpicdisablesave9');
    
     if(rootElement8){
       rootElement8.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement8){
       return () => {
         rootElement8.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
     if(rootElement9){
       rootElement9.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement9){
       return () => {
         rootElement9.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
     const rootElement20 = document.getElementById('profpicdisablesave20');
     if(rootElement20){
       rootElement20.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement20){
       return () => {
         rootElement20.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
    }
    else if (filterdiData.filterDirect == 'profession') {
      debugger
      const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + filterDirect.name + '&profession=' + filterProfessionName);
      setcommDirectory(result.data.data);
      console.log("comdirectory", result.data.data)
      const rootElement8 = document.getElementById('profpicdisablesave8');
  const rootElement9 = document.getElementById('profpicdisablesave9');
  
   if(rootElement8){
     rootElement8.addEventListener('contextmenu', handleContextMenuimgdisable);
   }
   if(rootElement8){
     return () => {
       rootElement8.removeEventListener('contextmenu', handleContextMenuimgdisable);
     };
   }
   if(rootElement9){
     rootElement9.addEventListener('contextmenu', handleContextMenuimgdisable);
   }
   if(rootElement9){
     return () => {
       rootElement9.removeEventListener('contextmenu', handleContextMenuimgdisable);
     };
   }
   const rootElement20 = document.getElementById('profpicdisablesave20');
   if(rootElement20){
     rootElement20.addEventListener('contextmenu', handleContextMenuimgdisable);
   }
   if(rootElement20){
     return () => {
       rootElement20.removeEventListener('contextmenu', handleContextMenuimgdisable);
     };
   }
    }
    else {
      debugger
      const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + filterDirect.name);
      setcommDirectory(result.data.data);
      console.log("comdirectory", result.data.data)
      const rootElement8 = document.getElementById('profpicdisablesave8');
    const rootElement9 = document.getElementById('profpicdisablesave9');
    
     if(rootElement8){
       rootElement8.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement8){
       return () => {
         rootElement8.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
     if(rootElement9){
       rootElement9.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement9){
       return () => {
         rootElement9.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
     const rootElement20 = document.getElementById('profpicdisablesave20');
     if(rootElement20){
       rootElement20.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement20){
       return () => {
         rootElement20.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
    }

  };

  //   const loadCommDirectory = async (abc) => {
  //    debugger
  //    const result = await axios.get('/subscriber/getAllSubscribers?id='+uerAuthId+'&filter='+abc);
  //    setcommDirectory(result.data.data);
  //    console.log("comdirectory",result.data.data)
  //  };

  const handleSearchForDirectChange = (e) => {
    debugger
    if (e.currentTarget.value === "") {
      loadCommDirectory();
      return;
    }
    const filterBySearch = commDirectory.filter((item) => {
      if (item != null && item.fullName != null) {
        if (item?.fullName?.toLowerCase().includes(e.currentTarget.value)) { return item; }
      }
       if ( item != null && item.profession != null){
         if (item?.profession?.toLowerCase()
             .includes(e.currentTarget.value)) { return item; }
            }
      
        if ( item != null && item.subCaste != null){
           if (item?.subCaste?.toLowerCase()
              .includes(e.currentTarget.value)) { return item; }
              }
          if ( item != null && item.currentCity != null){
            if (item?.currentCity?.toLowerCase()
               .includes(e.currentTarget.value)) { return item; }
              }
    })
    setcommDirectory(filterBySearch);
  }
//disable download and rightclick on image
// const noRightClick = document.getElementById("profpicdisablesave");
// if(noRightClick){
//   noRightClick.addEventListener("contextmenu", (e) => {e.preventDefault()});

// }
//disable download and rightclick on image
// useEffect(() => {
//   function handleContextMenu(e) {
//     e.preventDefault(); // prevents the default right-click menu from appearing
//   }
//   // add the event listener to the component's root element
//   const rootElement = document.getElementById('profpicdisablesave');
//   rootElement.addEventListener('contextmenu', handleContextMenu);
//   // remove the event listener when the component is unmounted

//   return () => {
//     rootElement.removeEventListener('contextmenu', handleContextMenu);
//   };
// }, []);


  //for nidudirectory
  const [isOpenNaiduDiPopup, setisOpenNaiduDiPopup] = useState(false);
  
  const togglePopupNaiduDi = () => {
    setisOpenNaiduDiPopup(!isOpenNaiduDiPopup);
  }
  //for businessdirectory
  const [isOpenbusinessDiPopup, setisOpenbusinessDiPopup] = useState(false);
  
  const togglePopupbussDi = () => {
    setisOpenbusinessDiPopup(!isOpenbusinessDiPopup);
  }

  const [isOpenJobPostContactPopup, setisOpenJobPostContactPopup] = useState(false);
  const toggleJobPostPopupContact = () => {
    setisOpenJobPostContactPopup(!isOpenJobPostContactPopup);
  }

  const [viewContactJobpostDiv, setviewContactJobpost] = useState(false);
  function ContactJobPostpoupopen(iscontactJobPost) {
    if (iscontactJobPost) {
      setviewContactJobpost(true);
    }
    else {
      setviewContactJobpost(false);
    }
  }

  const viewContactJobPost = async (id) => {

    debugger;
    const response = await axios.post(configData.ApiUrl+'/CommunityPost/viewJobContactDetails?userId=' + uerAuthId + '&postId=' + id)
    if (response.status == 200 && response.data.code) {
      // toast.success("Update Status  Successfully!")
      setviewContactJobpost(true);
    loadPostList();
    }
    else {
      alert("Something Went Wrong..!")
    }
  }

  const viewContactJobPostPdf = async (id) => {

    debugger;
    const response = await axios.post(configData.ApiUrl+'/CommunityPost/viewJobContactDetails?userId=' + uerAuthId + '&postId=' + id)
    if (response.status == 200 && response.data.code) {
      // toast.success("Update Status  Successfully!")
     // setviewContactJobpost(true);
    loadPostList();
    }
    else {
      alert("Something Went Wrong..!")
    }
  }

  //for bussness filter


  const handleSearchChangeBusiness = (e) => {
    debugger
    if (e.currentTarget.value === "") {
      loadBusinessList();
      return;
    }
    const filterBySearch = allbusinessList.filter((item) => {
      if (item != null && item.buisnessName != null) {
        if (item?.buisnessName?.toLowerCase().includes(e.currentTarget.value)) { return item; }
      }
       if ( item != null && item.city != null){
         if (item?.city?.toLowerCase()
             .includes(e.currentTarget.value)) { return item; }}
    })
    setallbusinessList(filterBySearch);
  }





const filterLoadBusiness = async (value)=>{
  debugger
  const result = await axios.get(configData.ApiUrl+'/buisness/getBuisnessProfiles?buisnessId=' + authlocalstoragedata.buisnessId + '&businessCategory=' + value );
  setmyProfilebusinessList(result.data.data);
    console.log("bussdirectfilter", result.data.data)
}

  
  const handleChangeBussFilter = (e) => {
    debugger
    const { name, value, type, checked } = e.currentTarget;
   
    filterLoadBusiness( e.target.value)
    
    
  }


  //for matrimony shortlist search
  const handleSearchForShortMatrimony = (e) => {
    debugger
    if (e.currentTarget.value === "") {
      loadMatShortProf();
      return;
    }
    const filterBySearch = matrimonyShortProf.filter((item) => {
      if (item != null && item.caste != null) {
        if (item?.caste?.toLowerCase().includes(e.currentTarget.value)) { return item; }
      }
       if ( item != null && item.livesIn != null){
         if (item?.livesIn?.toLowerCase()
             .includes(e.currentTarget.value)) { return item; }
            }
      
        if ( item != null && item.from != null){
           if (item?.from?.toLowerCase()
              .includes(e.currentTarget.value)) { return item; }
              }
          if ( item != null && item.motherTongue != null){
            if (item?.motherTongue?.toLowerCase()
               .includes(e.currentTarget.value)) { return item; }
              }
          if ( item != null && item.eduQualification != null){
                if (item?.eduQualification?.toLowerCase()
                   .includes(e.currentTarget.value)) { return item; }
                  }
          if ( item != null && item.working != null){
                    if (item?.working?.toLowerCase()
                       .includes(e.currentTarget.value)) { return item; }
                      }
            if ( item != null && item.name != null){
                        if (item?.name?.toLowerCase()
                           .includes(e.currentTarget.value)) { return item; }
                          }  
    })
    setmatrimonyShortProf(filterBySearch);
  }
  
  //for read more blog text
 
 const [showFullDescription, setShowFullDescription] = useState(false);
  
 // Function to toggle description display
 const toggleDescription = () => {
   setShowFullDescription(!showFullDescription);
 };
 //for read more kyc text
 
 const [showFullDescriptionkyc, setShowFullDescriptionkyc] = useState(false);
  
 // Function to toggle description display
 const toggleDescriptionkyc = () => {
  setShowFullDescriptionkyc(!showFullDescriptionkyc);
 };
  //for randm color
 const colors = ["#ff8427", "#3498db", "#2ecc71", "#e74c3c", "#9b59b6", "#f1c40f", "#e67e22"];
 const getRandomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)];
};
const [bgColor, setBgColor] = useState(""); // Initialize state for background color

  useEffect(() => {
    // Set random color only once during the initial render
    setBgColor(getRandomColor());
  }, []);

//for close popup when click on outside
const popupcommentRef = useRef(null);
const handleClickOutsideCommentdiv = (event) => {
  if (popupcommentRef.current && !popupcommentRef.current.contains(event.target)) {
    setComment(false);
  }
};
useEffect(() => {
  document.addEventListener('mousedown', handleClickOutsideCommentdiv);
  return () => {
    document.removeEventListener('mousedown', handleClickOutsideCommentdiv);
  };
}, []);
  return (
    <div className="container-fluid containerfluidpadding0">
      <div className="container-fluid  userlandtop-div">
        <div className="row">
          <div className="col-lg-1 col-sm-12 col-md-3">
            <img src={clogo} className='clogo' id='profpicdisablesave'/>

          </div>
          <div className="col-lg-2 col-sm-12 col-md-6 textleft">
            <span className="com-name">Naidu Directory</span>

          </div>
          <div className="col-lg-6 col-sm-12 col-md-12 "></div>
          <div className="col-1 displyinline  ">
            <MdNotifications size='1.8em' className="notifiicon" onClick={togglePopupNotify} />
            {isOpenNotification && <NotificationPopup
              handleClose={togglePopupNotify}
            />
            }
            <p className="notifi-text">12</p>
            {/* <RiCupFill className="cupicon-top" size='1.7em' />
            <p className="notifi-text1">2</p> */}

          </div>
          <div className="col-lg-2 col-sm-12 col-md-12 textcenter">

            <div className=" margintop1 textright">
              <div class="dropdown drpdiv">
                <button type="button" class="drpbtn-menu dropdown-toggle" data-toggle="dropdown">
                  <div className="row proftop-div textcenter">
                    <div className="col-lg-1 col-md-2 col-sm-12 margintop1">
                      {loadProfile == "" || loadProfile == null ?
                        (<Avatar className="" style={{marginTop:"4px", width: "32px", height: "30px",fontSize:"15px",backgroundColor: bgColor,color:"#ffffff" }}>{avtarProf}</Avatar>)
                        : (<img src={loadProfile} id='profpicdisablesave1' className='prof-img-right topprofsmimg' />)
                      }

                    </div>
                    <div className="col-lg-8 col-sm-12 col-md-9 margintop1 textcenter" style={{paddingTop:"4px"}}>
                      <span className="proftop-name textShowondottopprofname capitalizetxxxt">{authlocalstoragedata?.name2}</span>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-12 margintop1">
                      {/* <i class="fa fa-caret-down" aria-hidden="true"></i> */}
                      <MdArrowDropDown size='1.9em' style={{marginTop:"6px"}}/>
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu drpdn-meni-item" style={{ cursor: "pointer" }}>
                  <a class="dropdown-item drp-item" onClick={redirecttoViewProf} style={{ cursor: "pointer" }}>View Profile</a>
                  <a class="dropdown-item drp-item" onClick={redirectToContact}>View Contacts</a>
                  <a class="dropdown-item drp-item" onClick={redirectToBloodReq}>Blood Requirement Request</a>
                  <a class="dropdown-item drp-item" >View Subscriptions</a>
                  <a class="dropdown-item drp-item" onClick={redirectToContactRecive}>View Connect requests</a>
                  <a class="dropdown-item drp-item" onClick={redirectToReferal}>Referrals</a>
                  <a class="dropdown-item drp-item" onClick={redirectToAccSetting}>Change Password</a>
                  <a class="dropdown-item drp-item" onClick={redirectToAccSetting}>My Account</a>
                  <a class="dropdown-item drp-item" onClick={logoutclick}>Sign Out <HiOutlineLogout size='1.4em' className="marginleft2" /></a>
                </div>
              </div>
            </div>


          </div>


        </div>
      </div>

      <div className="container-fluid   borderbotomcontiner">
        <div className="row ">
          <div className="col-lg-2 col-sm-12 col-md-4 displyinline margintpmins1">

            <div className="margintop3 ">
              <div className="">
                <span className=" menu-name capitalizetxxxt" >{authlocalstoragedata?.name2}</span>
                <div className="margintpmins2" style={{ marginTop: "-5px" }}><span className="imgspn-menu"><FaUserClock color="#FF8427" size="0.9em" className="prof-veryfiimg" /></span>
                  <span className={updatedData.verification == "Pending" ? "hide" : "menu-veryfyprof"}>Verified profile</span>
                  <span className={updatedData.verification == "Pending" ? "menu-veryfyprof" : "hide"}>Verification Pending  </span>

                </div>

              </div>

              {/* <div className="margintpmins2 "><span className=" menuname-subtext">CID # 0000001</span></div>  */}
            </div>

          </div>
          <div className="col-lg-2 col-sm-12 col-md-4 textright">
            <span className={news ? "textdecorunder menu-name" : "menu-name"} onClick={showNews} name="news">News & Updates</span>
          </div>
          <div className="col-lg-2 col-sm-12 col-md-4 textright">
            <span className={communityDirect ? "textdecorunder menu-name" : "menu-name"} onClick={showCommunityDirect} name="cdirect">Community Directory</span>
          </div>
          <div className="col-lg-2 col-sm-12 col-md-4 textcenter">
            <span className={matrimony ? "textdecorunder menu-name" : "menu-name"} onClick={showMatrimony} name="matrimony">Matrimony</span>
          </div>
          <div className="col-lg-2 col-sm-12 col-md-12 textcenter">
            <span className={businessDirect ? "textdecorunder menu-name" : "menu-name"} onClick={showBusinessDirect} name="bdirect">Business Directory</span>
          </div>
          <div className="col-lg-2 col-sm-12 col-md-4 textcenter">
            <span className={communityMeet ? "textdecorunder menu-name" : "menu-name"} onClick={showCommunityMeet} name="communitym">Community Meetups</span>
          </div>

        </div>
      </div>
      <div className="row margintop1">
      <div className="col-lg-3 col-sm-12 col-md-12 leftdivuser heightforAll3Div" id="radiusdivchek">
          {/* <div className="profdiv">
            <div className="row">
              <div className="col-1">
                <CgProfile color="#214B0D" size='1.0em'/>
              </div>
              <div className="col-4  textcenter">
                <span className="marginleftmins4 profpercent ">Profile 50% - </span>
              </div>
              <div className="col-6 textleft marginleftmins1">
                <span className="colpletprof-spn">Complete your profile now</span>
              </div>
           </div>
           <div className="row">
              <div className="col-1">
                <MdEmail color="#214B0D" size='1.0em'/>
              </div>
              <div className="col-6 ">
                <span className="profpercent ">Email - Verification Pending -</span>
              </div>
              <div className="col-4  marginleftmins">
                <span className="colpletprof-spn">Verify now</span>
              </div>
           </div>
            </div> */}

          <div className="row margintop1">

            <div className="borderbottom-radius">
              <span className="radius-heding" > Members In My Location</span>

              <div className="margintop1 "></div>
              <div className="radius-div" style={{ marginLeft: "-20px" }} >
                <div className="row">
                  <div className="col-lg-4"></div>
                  <div className="col-lg-2 col-sm-4 col-md-4">
                    <span className="radius-heding">{members && members.length}</span></div>

                </div>
                <div className="row margintop1"></div>


                <div style={{ marginLeft: "-10px" }}>

                  <Carousel cols={4} rows={2} gap={10} loop autoplay={1200} >
                    {/* <Carousel.Item>
        <img width="100%" src="https://source.unsplash.com/Dm-qxdynoEc/800x799" />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src="https://source.unsplash.com/Dm-qxdynoEc/800x799" />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src={eventimg1} />
      </Carousel.Item> */}

{members.map((image, index) => (
                      // <Tippy content={imageUrl.name} style={{fontSize:"11px"}}>

<Carousel.Item onClick={openLightbox}>
                      {image.profilePhotoPath == "" || image.profilePhotoPath == null ?
                        (<Avatar className="" style={{ width: "57px", height: "56px",backgroundColor: bgColor,color:"#ffffff",textTransform:"capitalize" }}>{image.name?.substring(0, 2)}</Avatar>)
                        : (<img src={image.profilePhotoPath} id='profpicdisablesave2' title={image.name} className='prof-img-right topprofsmimg eventlogoimg1' style={{ cursor: "pointer",borderRadius:"50%",width: "57px", height: "56px" }} />)
                      }
                        {/* <img width="100%" src={image.profilePhotoPath} id='profpicdisablesave2' title={image.name} style={{ cursor: "pointer" }} /> */}
                      </Carousel.Item>
                      // </Tippy>
                    ))
                    }
                    {
                      members && members.length == 0 && (
                        <Carousel.Item >
                          <div style={{ width: "100%", height: "20px", color: "gray", marginLeft: "90px", paddingTop: '10px' }}>0 Members</div>
                        </Carousel.Item>
                      )
                    }
                    <ModalGateway >
                      {viewerIsOpen ? (

                        <Modal onClose={closeLightbox}>
                          <Carousel1
                            currentIndex={currentImage}
                            views={
                              //members.map(x => ({
                              //   ...x,
                              //   srcset: x.srcSet,
                              //   caption: x.title
                              // }))
                              sliderImageUrl
                            }
                          />
                        </Modal>
                      ) : null}
                    </ModalGateway>
                  </Carousel>

                </div>


                <div className="margintop1" style={{ marginLeft: "20px", marginTop: "10px" }}>
                  {/* <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" value=""/>
    <span className="showconnectmemrs">Show Connected Members Only</span>
    </label> */}
                  <label class="chechboxxcustom">Show Connected Members Only
                    <input type="checkbox" class=" form-check-input " name='connectedStatus' onChange={handleChange} value="" />
                    <span class="checkmark1"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="row margintop1">
            <div className="borderbottom-radius">
              <span className="radius-heding"> My Community Networks ({countmenu.count1})</span>

              <div className="margintop1"></div>
              <div className="radius-div">
                <div className="row ">
                  <div className="col-lg-5 col-sm-12 col-md-6">
                    <span className="radius-secobd-text marginleftmins4">Members Joined</span>
                  </div>
                  <div className="col-lg-1 col-sm-12 col-md-5">
                    <span className="radius-secobd-text marginleftmins6">{countmenu.count2}</span>
                  </div>
                  <div className="col-lg-5 col-sm-12 col-md-1">
                    <span className="radius-second-linktext whitenowrap marginleftmins6" onClick={togglePopupMessage1} style={{cursor:"pointer"}}>Invite New Member</span>
                  </div>
                  {isOpenMessagePop1 && <ReferInviteCommMemberPopup

                    handleClose={togglePopupMessage1}
                  />}
                </div>

                <div className="row margintop1 ">
                  <div className="col-lg-5 col-sm-12 col-md-12">
                    <span className="radius-secobd-text marginleftmins4">Invitations Sent</span>
                  </div>
                  <div className="col-lg-1 col-sm-12 col-md-12">
                    <span className="radius-secobd-text marginleftmins6">{countmenu.count3}</span>
                  </div>
                  <div className="col-5">
                    {/* <span className="radius-second-linktext whitenowrap marginleftmins6" onClick={redirectTorefferal}>Sent Invitations</span>  */}
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div className="row margintop1">
            <div className="borderbottom-radius">
              <span className="radius-heding"> My Community Platforms</span>

              <div className="margintop1"></div>
              <div className="radius-div">
                <div className="">
                  {/* <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" checked value=""/>
    <span className="radius-secobd-text">Community Listing & Directory </span>
    </label> */}
                  {/* <label class="chechboxxcustom">Community Listing & Directory
                    <input type="checkbox" checked={authlocalstoragedata?.directory == true ? true : false} class=" form-check-input " value="" />
                    <span class="checkmark1"></span>
                  </label> */}
                   <label class="chechboxxcustom" style={{ cursor: "pointer" }}>Naidu Directory -Free 
                    <input type="checkbox" checked={authlocalstoragedata?.directory == true ? true : false} class=" form-check-input " value="" />
                    <span class="checkmark1"></span>
                  </label> 
                </div>
                <div className=" margintop1" style={{ whiteSpace: "nowrap" }}>
                  {/* <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" checked value=""/>
    <span className="radius-secobd-text">Community Meetups</span>
    </label> */}
                  {/* <label class="chechboxxcustom">Community Meetups
                    <input type="checkbox" class="form-check-input" checked={authlocalstoragedata?.directory == true ? true : false} value="" />
                    <span class="checkmark1"></span>
                  </label> */}
                  <div className="row">
                    <div className="col-lg-6 col-sm-12 col-md-12" style={{ marginLeft: "-15px" }}>
                      <label class="chechboxxcustom" style={{ cursor: "pointer" }}>Naidu Directory -Premium 
                        <input type="checkbox" class=" form-check-input " value="" />
                        <span class="checkmark1"></span>
                      </label>
                    </div>
                    <div className="col-lg-3 col-sm-12 col-md-12" style={{ marginTop: "0px" }}>
                      <FaInfoCircle color='#214B0D' size='0.9em' style={{ marginLeft: "54px" }} /> <span className="radius-second-linktext" onClick={togglePopupNaiduDi} style={{cursor:"pointer"}}>Sign Up</span>

                    </div>
                    {isOpenNaiduDiPopup && <SubscribePremiem
                    handleClose={togglePopupNaiduDi}
                  />
                  }
                  </div>
                </div>
                <div className=" margintop1">
                  {/* <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" checked value=""/>
    <span className="radius-secobd-text">Business Directory</span>
    </label> */}
                  <label class="chechboxxcustom" style={{ cursor: "pointer" }}>Business Directory
                    <input type="checkbox" class=" form-check-input " checked value="" />
                    <span class="checkmark1"></span>
                  </label>
                </div>
                <div className=" margintop1" style={{ whiteSpace: "nowrap" }}>
                  {/* <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" value=""/>
    <span className="radius-secobd-text">Community Matrimony</span>  <span className="radius-second-linktext">Sign Up</span>
    </label> */}
                  <div className="row">
                    <div className="col-lg-7 col-sm-12 col-md-12" style={{ marginLeft: "-15px" }}>
                      <label class="chechboxxcustom" style={{ cursor: "pointer" }}>Community Matrimony
                        <input type="checkbox" class=" form-check-input " value="" checked={authlocalstoragedata?.matrimony == true ? true : false}/>
                        <span class="checkmark1"></span>
                      </label>
                    </div>
                    
                    <div className={authlocalstoragedata.matrimony ==false?"col-lg-3 col-sm-12 col-md-12":"hide"} style={{ marginTop: "0px" }}>
                      <span className="radius-second-linktext" style={{ marginLeft: "5px" ,cursor:"pointer"}} onClick={togglePopupSignUpmatri}>Sign Up</span>

                    </div>

                  </div>
                  {isOpenSignMatriPopup && <SignUpMatrimony
                    handleClose={togglePopupSignUpmatri}
                  />
                  }

                </div>
                <div className=" margintop1" style={{ whiteSpace: "nowrap" }}>
                  {/* <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" value=""/>
    <span className="radius-secobd-text">Business Listing</span> <FaInfoCircle color='#214B0D' size='0.9em'/> <span className="radius-second-linktext">Sign Up</span>
    </label> */}
                  <div className="row">
                    <div className="col-lg-6 col-sm-12 col-md-12" style={{ marginLeft: "-15px" }}>
                      <label class="chechboxxcustom" style={{ cursor: "pointer" }}>Business Listing
                        <input type="checkbox" class=" form-check-input " value="" checked={authlocalstoragedata?.buisnessDirectory == true ? true : false} />
                        <span class="checkmark1"></span>
                      </label>
                    </div>
                    <div className={authlocalstoragedata.buisnessDirectory ==false?"col-lg-3 col-sm-12 col-md-12":"hide"} style={{ marginTop: "0px" }}>
                      <FaInfoCircle color='#214B0D' size='0.9em' style={{ marginLeft: "-12px" }} /> <span className="radius-second-linktext" onClick={togglePopupbussDi} style={{cursor:"pointer"}}>Sign Up</span>

                    </div>
                  </div>
                  {isOpenbusinessDiPopup && <BusinessFirstPage
                    handleClose={togglePopupbussDi}
                  />
                  }
                  

                </div>


              </div>
            </div>
          </div>
          <div className="row margintop1">
            <div className="borderbottom-radius">
              <span className="radius-heding"> My Coffee Meet Ups</span>   <RiCupFill color="#B5924C" size='1.3em' />
              <div className="margintop1 "></div>
              <div className="radius-div">
                {
                  coffeeMeets && coffeeMeets.length > 0 ? (
                    <span className="radius-fourth-subspn">You have {coffeeMeets.length} Upcoming Cofee Meet Ups</span>
                  ) : (
                    <span className="radius-fourth-subspn">You have 0 Upcoming Cofee Meet Ups</span>

                  )
                }
                
                <div className="row margintop2">
                  <div class="form-group col-lg-6 col-md-6 col-sm-6 has-search margintop1">
                    <span class="fa fa-search form-control-feedback"></span>
                    <input type="text" class="form-control" placeholder="Search by Organizer" onChange={handleSearchChange} style={{ fontSize: '11px' }} />

                  </div>

                  <div className="col-lg-6 col-sm-12 col-md-12 margintop1 ">
                    <select id="inputState" name='filterstatus' onChange={handleChangeFilter}
                      className=" form-control" style={{ height: "34px", fontSize: "11px" }}>
                      <option selected value="">Date</option>
                      <option value="Pending">Pending Response</option>
                      <option value="accepted">Accepted Invitations</option>
                    </select>
                  </div>

                </div>

                {
                  coffeeMeets.map((getmenu, index) => (
                    <div>
                      <div className={pendingDIv && getmenu.attendStatus == 'Pending' ? "" : "hide"}>
                        <div className="radius-fourth-card1 margintop3">
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Date/Place</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-text">{getmenu.date}</span>  <span className="marginleft5 radius-fourth-card1-text">{getmenu.time}</span><span className="marginleft5 radius-fourth-card1-text">{getmenu.city}</span>
                            </div>
                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Organizer</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-text">{getmenu.hostName}</span>
                            </div>
                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Guests:- {getmenu.guests?.length}</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              {
                                getmenu.guests?.map((gname, index) => (
                                  <span className="radius-fourth-card1-text">{gname.name}</span>
                                ))
                              }

                            </div>
                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Venue</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-text">{getmenu.venue}</span>
                            </div>
                          </div>
                          <div className="marginleft1 whitenowrap">
                            <span className={getmenu.attendStatus == "Pending" ? "hide" : "radius-fourth-subspn "}>You have accepted this invitation</span>
                            <span className={getmenu.attendStatus == "Pending" ? "radius-fourth-subspn" : "hide  "}>You did not respond to this invitation</span>
                            <span className="radius-fourth-linktext marginleft1" onClick={() => coffeeInfopoupopen(getmenu.id, true,getmenu.attendStatus)}>View Invitation</span>

                          </div>



                        </div>
                      </div>

                      <div className={acceptedDiv && getmenu.attendStatus == 'accepted' ? "" : "hide"}>
                        <div className="radius-fourth-card1 margintop3">
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Date/Place</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-text">{getmenu.date}</span>  <span className="marginleft5 radius-fourth-card1-text">{getmenu.time}</span><span className="marginleft5 radius-fourth-card1-text">{getmenu.city}</span>
                            </div>
                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Organizer</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-text">{getmenu.hostName}</span>
                            </div>
                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Guests:- {getmenu.guests?.length}</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              {
                                getmenu.guests?.map((gname, index) => (
                                  <span className="radius-fourth-card1-text">{gname.name}</span>
                                ))
                              }

                            </div>
                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Venue</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-text">{getmenu.venue}</span>
                            </div>
                          </div>
                          <div className="marginleft1 whitenowrap">
                            <span className={getmenu.attendStatus == "Pending" ? "hide" : "radius-fourth-subspn "}>You have accepted this invitation</span>
                            <span className={getmenu.attendStatus == "Pending" ? "radius-fourth-subspn" : "hide  "}>You did not respond to this invitation</span>
                            <span className="radius-fourth-linktext marginleft1" onClick={() => coffeeInfopoupopen(getmenu.id, true,getmenu.attendStatus)}>View Invitation</span>

                          </div>



                        </div>
                      </div>
                      <div className={pendingDIv == false && acceptedDiv == false ? '' : 'hide'}>
                        <div className="radius-fourth-card1 margintop3">
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Date/Place</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-text">{getmenu.date}</span>  <span className="marginleft5 radius-fourth-card1-text">{getmenu.time}</span><span className="marginleft5 radius-fourth-card1-text">{getmenu.city}</span>
                            </div>
                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Organizer</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-text">{getmenu.hostName}</span>
                            </div>
                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Guests:- {getmenu.guests?.length}</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              {
                                getmenu.guests?.map((gname, index) => (
                                  <span className="radius-fourth-card1-text">{gname.name}</span>
                                ))
                              }

                            </div>
                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Venue</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-text">{getmenu.venue}</span>
                            </div>
                          </div>
                          <div className="marginleft1 whitenowrap">
                            <span className={getmenu.attendStatus == "Pending" ? "hide" : "radius-fourth-subspn "}>You have accepted this invitation</span>
                            <span className={getmenu.attendStatus == "Pending" ? "radius-fourth-subspn" : "hide  "}>You did not respond to this invitation</span>
                            <span className="radius-fourth-linktext marginleft1" onClick={() => coffeeInfopoupopen(getmenu.id, true,getmenu.attendStatus)}>View Invitation</span>

<div className={coffeediv ? "row" : "hide row"}>
        <div className="popup-boxdate">
          <div className="boxdate" style={{ width: '52%', marginLeft: "400px", marginTop: "120px" }}>
            <div className='bordernonemessg' style={{ marginLeft: "-16px", marginRight: "-16px" }}>
              <div className=" row" style={{ borderBottom: "1px", borderColor: "lightgray" }} >
                <div className=" col-11" style={{ textAlign: "left", fontSize: "16px" }}><span className="event-sub-detail">View Invitation</span></div>
                <div className='col-1'>
                  <span className="" onClick={() => coffeeInfopoupopen(0, false,coffeeView.attendStatus)}><MdClose size='1.5em' /></span>
                </div>
                <br />
              </div>
              <div className='row' style={{ marginTop: "0px" }}></div>
            </div>

            <div style={{ overflowX: "hidden" }}>


              <div className="cardiv-coffee margintop border1px">
                <div className="row margintop3">
                  <div className="col-8">
                    <span className="event-sub-detail">{coffeeView.date} </span><span className="event-sub-detail" style={{ marginLeft: '12px' }}>{coffeeView.city}</span>
                  </div>
                  <div className="col-3" style={{ float: "right" }}>
                    <button className={coffeeView.attendStatus == 'Pending' ? "attendbtn" : "hide"} style={{ marginTop: '6px', position: "absolute", width: "180px" }} onClick={() => attendStatusCofeeMeet(coffeeView.id)}>Attend</button>
                    {/* <button className={getmenu.attendStatus=='Pending'?"hide ":"attendbtn"} style={{marginTop:'6px',position:"absolute"}} onClick={()=>attendStatusCofeeMeet(getmenu.id)}>Attend</button> */}
                    <div class={coffeeView.attendStatus == 'Pending' ? "hide" : "dropdown"}>
                      <button class="btn  dropdown-toggle attendbtn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width: "180px" }} onClick={() => attendStatusCofeeMeet(coffeeView.id)}>
                        You are Attending
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" style={{ fontSize: '14px', }}>Withdraw Attendance</a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row margintop2">
                  <div className="">
                    <span className="event-sub-detail">Venue:- </span>
                    <span className="event-sub-detail1" style={{ margin: "5px", fontSize: '13px' }}>{coffeeView.venue}</span>
                    {/* <span className="bluetextforlink" style={{margin:"7px",fontSize:'10px'}}>Check on Google Maps</span> */}
                    {/* <Link to={`/${coffeeView.googleMapsLink}`}><span className="bluetextforlink" style={{ margin: "7px", fontSize: '10px' }}>Check on Google Maps</span></Link> */}
                    < a href={`/${coffeeView.googleMapsLink}`} target='_blank'><span className="bluetextforlink" style={{ margin: "7px", fontSize: '10px' }}>Check on Google Maps</span></a>
                  </div>
                </div>
                <div className="row margintop3">
                  <div className="">
                    <span className="event-sub-detail1" style={{ margin: "5px", fontSize: '13px' }}>{coffeeView.guests.length} Members are attending this Coffee Meet Up</span>
                  </div>
                </div>
                <div className="row margintop">

                  {coffeeView.guests.map((geuest, index) => (
                    <div className="col-2 ">
                      <img src={geuest.profilePicture} id='profpicdisablesave18' className=' ' style={{ width: "100px", height: "100px", borderRadius: "50%" }} />
                      <div className="margintop1"><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '0px' }}>{geuest.name}</span></div>
                    </div>
                  ))
                  }



                </div>
                <div className="row margintop3"></div>
              </div>



            </div>


          </div>
        </div>
      </div>
                          </div>



                        </div>
                      </div>
                    </div>
                  ))
                }
                {/* <div className="radius-fourth-card1 margintop3">
          <div className="row margintop1">
            <div className="col-4">
              <span className="radius-fourth-card1-lefttext">Date/Place</span>
            </div>
            <div className="col-8">
              <span className="radius-fourth-card1-text">12 Oct 2022</span>  <span className="marginleft5 radius-fourth-card1-text">6:00 PM </span><span className="marginleft5 radius-fourth-card1-text">Pune</span>
            </div>
            </div>
            <div className="row margintop1">
            <div className="col-4">
              <span className="radius-fourth-card1-lefttext">Organizer</span>
            </div>
            <div className="col-8">
              <span className="radius-fourth-card1-text">Shankar Naidu</span>  
            </div>
            </div>
            <div className="row margintop1">
            <div className="col-4">
              <span className="radius-fourth-card1-lefttext">Guests:- 3</span>
            </div>
            <div className="col-8">
              <span className="radius-fourth-card1-text">Sagar Naidu, Vishal Naidu +1</span>  
            </div>
            </div>
            <div className="row margintop1">
            <div className="col-4">
              <span className="radius-fourth-card1-lefttext">Venue</span>
            </div>
            <div className="col-8">
              <span className="radius-fourth-card1-text">Mariott , SB Road</span>  
            </div>
            </div>
            <div className="marginleft1 whitenowrap">
            <span className="radius-fourth-subspn ">You have not respode to this invitation</span>  <span className="radius-fourth-linktext ">View Invitation</span>

            </div>

    </div> */}
                <div className="textright"><span className="radius-fourth-linktext" onClick={redirectToCoffeemeetInvite} style={{cursor:"pointer"}}>Check all invites</span></div>

              </div>
            </div>
          </div>
          <div className="row margintop1">
            <div className="borderbottom-radius">
              <span className="radius-heding"> My Connection Requests</span>   <FaRegHandshake color="#B5924C" size='1.3em' />
              <div className="margintop1 "></div>
              <div className="radius-div">
                {
                  connection && connection.length > 0 ? (
                    <span className="radius-fourth-subspn">You have {connection.length} new connection requests</span>
                  )
                    :
                    (
                      <span className="radius-fourth-subspn">You have 0 new connection requests</span>
                    )

                }
                {
                  connection.map((getmenu, index) => (
                    <div className="radius-fourth-card1 margintop3">
                      <div className="row margintop1">
                        <div className="col-lg-4 col-sm-12 col-md-12 margintop1">
                          <img src={getmenu.profilePicturePath} id='profpicdisablesave3' className='topprofsmimg margintop1' />
                          <div className=""><span className="radius-fourth-linktext">View Profile</span></div>
                        </div>
                        <div className="col-lg-8 col-sm-12 col-md-12">
                          <div className=""><span className="radius-fourth-card1-lefttext ">{getmenu.senderName}</span> </div>
                          <div className=""><span className="radius-fourth-card1-text">{getmenu.subcast}</span> </div>
                          <div className=""><span className="radius-fourth-card1-text">{getmenu.profession}</span></div>
                          <div className=""><span className="radius-fourth-card1-text">{getmenu.homeCity}, {getmenu.homeState}</span> </div>
                          <div className=""><span className="radius-fourth-card1-text">{getmenu.currentCity}, {getmenu.currentState}</span> </div>

                        </div>
                      </div>
                      <div className="marginleft1 whitenowrap">
                        <span className="radius-fourth-linktext ">Responded to this invitation</span><span className="radius-fourth-subspn" style={{ color: "#30D158", marginLeft: "9px", cursor: "pointer" }} onClick={() => acceptConnection(getmenu.senderId)}>Accept</span>
                        <span className="radius-fourth-subspn" style={{ color: "#D30F45", marginLeft: "9px", cursor: "pointer" }} onClick={() => rejectConnection(getmenu.senderId)}>Reject</span>

                      </div>



                    </div>
                  ))
                }

                {/* <div className="radius-fourth-card1 margintop3">
          <div className="row margintop1">
            <div className="col-4 margintop1">
            <img src={conprof}  className='margintop1'/>
            <div className=""><span className="radius-fourth-linktext">View Profile</span></div>
            </div>
            <div className="col-8">
              <div className=""><span className="radius-fourth-card1-lefttext ">Sagar Naidu</span> </div>
              <div className=""><span className="radius-fourth-card1-text">Balaji Naidu</span> </div> 
              <div className=""><span className="radius-fourth-card1-text">Information Technology</span></div> 
              <div className=""><span className="radius-fourth-card1-text">Latur, Maharashtra</span> </div> 
              <div className=""><span className="radius-fourth-card1-text">Pune, Maharashtra</span> </div> 
 
            </div>
            </div>
            <div className="marginleft1 whitenowrap">
             <span className="radius-fourth-linktext ">Responde to this invitation</span>

            </div>



        </div> */}

                <div className="textright"><span className="radius-fourth-linktext" onClick={redirectToContactRecive} style={{cursor:"pointer"}}>Check all Requests</span></div>

              </div>
            </div>
          </div>


          <div className="row margintop"></div>
          <div className="row margintop"></div>
          <div className="row margintop"></div>


        </div>


        <div className={news ? "col-lg-6 col-sm-12 col-md-12  newsfeeddiv heightforAll3Div" : "hide"}>
          <div className="row margintop4 eventdivopen-popup">
            <div className="col-lg-1 col-sm-12 col-md-2 ">
              {loadProfile == "" || loadProfile == null ?
                (<Avatar className="" style={{ width: "60px", height: "58px", marginTop: "4px",backgroundColor: bgColor,color:"#ffffff" }}>{avtarProf}</Avatar>)
                : (<img src={loadProfile} id='profpicdisablesave4' className='prof-img-right topprofMdimg' style={{ marginTop: "4px" }} />)
              }
              {/* <img src={loadProfile}  className='sagarsirimg1'/> */}
            </div>
            <div className="col-lg-11 col-sm-12 col-md-10 margintop1 form-group ">
              <input type='text' className="userland-pop-textbox form-control"
                placeholder="Share an update (Your post will be approved shortly. All the content needs to be community oriented)"
                onClick={togglePopup} />
            </div>
            {isOpen && <AddNewsPopup

              handleClose={togglePopup}
            />}

            <div className="row " id="sharediv1" style={{whiteSpace:"nowrap"}}>
              <div className="col-lg-2 col-sm-12 col-md-12 marginleftmins5 textleft">
                {/* <input type="radio" id="radio1" className='radiobtnnews' value='event' onClick={togglePopup} 
                      checked={selected === 'event'}
                     onChange={handleChange}
                     name="example4"/>   */}
                {/* <label for="defaultRadio1" className='marginleft5 radiobtn-text1'>Event</label> */}
                <label class="radiodivcust radiobtn-text1">Event
                  <input type="radio" id="radio1" className='' value='event' onClick={togglePopup}
                    checked={selected === 'event'}
                    onChange={handleChange}
                    name="example4" />
                  <span class="checkmark"></span>
                </label>
                {isOpen && <AddNewsPopup

                  handleClose={togglePopup}
                />}
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 marginleftmins5 textleft">
                {/* <input type="radio" id="radio1" className='radiobtnnews' name="example4" onClick={togglePopupBlog} 
                    onChange={handleChange}
                    checked={selected === 'blog'}
                   value='blog'
                    />  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text1'>Blog</label> */}

                <label class="radiodivcust radiobtn-text1">Blog
                  <input type="radio" id="radio1" className='radiobtnnews' name="example4" onClick={togglePopupBlog}
                    onChange={handleChange}
                    checked={selected === 'blog'}
                    value='blog'
                  />
                  <span class="checkmark"></span>
                </label>

                {isOpenBlog && <AddBlogPopup

                  handleClose={togglePopupBlog}
                />}
              </div>
              {/* <div className="col-2  textleft"> */}
              {/* <input type="radio" id="radio1" className='radiobtnnews' 
                    onChange={handleChange}
                    checked={selected === 'recipie'}
                    value='recipie'
                    name="example4"/>  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text1'>Recipie</label> */}

              {/* <label class="radiodivcust radiobtn-text1">Recipie
                     <input type="radio" id="radio1" className='radiobtnnews' 
                    onChange={handleChange}
                    checked={selected === 'recipie'}
                    value='recipie'
                    name="example4"/>
                      <span class="checkmark"></span>
           </label> */}
              {/* </div> */}
              {/* <div className="col-2 whitenowrap  textleft"> */}
              {/* <input type="radio" id="radio1" className='radiobtnnews'
                    onChange={handleChange}
                    checked={selected === 'vlog'}
                    value='vlog'
                    name="example4"/>  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text1'>Vlog</label> */}
              {/* <label class="radiodivcust radiobtn-text1">Vlog
                     <input type="radio" id="radio1" className='radiobtnnews'
                    onChange={handleChange}
                    checked={selected === 'vlog'}
                    value='vlog'
                    name="example4"/>  
                      <span class="checkmark"></span>
           </label> */}
              {/* </div> */}
              <div className="col-lg-3 col-sm-12 col-md-12 whitenowrap marginleftmins5 textleft">
                {/* <input type="radio" id="radio1" className='radiobtnnews'
                    onClick={togglePopupKyc}
                    onChange={handleChange}
                    checked={selected === 'kyc'}
                    value='kyc'
                    name="example4"/>  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text1'>KYC(Know your community)</label> */}
                <label class="radiodivcust radiobtn-text1">KYC(Know your community)
                  <input type="radio" id="radio1" className='radiobtnnews'
                    onClick={togglePopupKyc}
                    onChange={handleChange}
                    checked={selected === 'kyc'}
                    value='kyc'
                    name="example4" />
                  <span class="checkmark"></span>
                </label>
                {isOpenkyc && <AddKycPopup

                  handleClose={togglePopupKyc}
                />}
              </div>

              <div className="col-lg-5 col-sm-12 col-md-12 whitenowrap marginleft5  textleft">
                {/* <input type="radio" id="radio1" className='radiobtnnews'
                    onClick={togglePopupKyc}
                    onChange={handleChange}
                    checked={selected === 'kyc'}
                    value='kyc'
                    name="example4"/>  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text1'>KYC(Know your community)</label> */}
                <label class="radiodivcust radiobtn-text1 marginleft5" style={{marginLeft:"48px"}}>Looking for job post
                  <input type="radio" id="radio1" className='radiobtnnews'
                    onClick={togglePopuptopJobPop}
                    onChange={handleChange}
                    checked={selected === 'job'}
                    value='job'
                    name="example4" />
                  <span class="checkmark"></span>
                </label>
                {isOpentopjobPop && <AddJobPop

                  handleClose={togglePopuptopJobPop}
                />}
              </div>
            </div>

          </div>
          {
            loadPost.map((getmenu, index) => (
              <div>
                <div className={getmenu.event ? "cardiv-event" : "hide"}>
                  <div className="row margintop">
                    <div className="col-lg-1 col-sm-12 col-md-12">
                      {/* <img src={getmenu.event?.profilePic} id='profpicdisablesave5' className='eventlogoimg' /> */}
                      {getmenu.event?.profilePic == "" || getmenu.event?.profilePic == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px",marginTop:"6px", height: "42px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.event?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.event?.profilePic} id='profpicdisablesave5' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
                    </div>
                    <div className="col-lg-1 col-sm-12 col-md-12 textright" style={{marginTop:"4px"}}><span className="evntxt ">Event</span></div>
                    <div className="col-lg-5 col-sm-12" style={{ whiteSpace: "nowrap",marginTop:"4px" }}><span className="marginleftmins5 event-type-text" key={getmenu.event?.id}>{getmenu.event?.eventType}</span><span className="marginleft2 event-location">{getmenu.event?.city}</span></div>
                    <div className="col-lg-6 col-sm-12 col-md-12 event-date-div"><span className="event-date">{getmenu.event?.createdDate} at {getmenu.event?.createdTime}</span><span className="event-publisher ">Published by {getmenu.event?.hostName}</span></div>
                  </div>
                  {/* <div className="margintop3 row">
           <section id="image-grid">
            <div>
            <img src={eventimg1}  className='img1'/>
         </div>
            <div>
            <img src={eventimg2}  className='img2'/>
          </div>
            <div>
          </div>
          <div>
            <img src={eventimg3}  className='img3'/>  

          </div>
          <div>
          </div>


          </section> 


          </div> */}

<div className={getmenu.event?.imagesCount == 4 ? "" : "hide"}>
                    <div className="row margintop3">
                      {
                        getmenu.event?.images?.map((gname, index) => (
                          <div className="col-6">
                            <img src={gname} className='' style={{ width: "326px", height: "100%" }} />

                          </div>
                        ))
                      }
                      {/* <div className="col-6">
            <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div> */}

                    </div>
                  </div>
                  <div className={getmenu.event?.imagesCount == 2 ? "" : "hide"}>
                    
                        <div className="row margintop">
                        {
                      getmenu.event?.images?.map((gname, index) => (
                          <div className="col-lg-6 col-sm-12 col-md-12 margintop1">
                            <img src={gname} className='' style={{ width: "326px", height: "100%" }} />

                          </div>
 ))
}
                          {/* <div className="col-6">
            <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div> */}

                        </div>
                     
                  </div>
                  <div className={getmenu.event?.imagesCount == 1 ? "" : "hide"}>
                    
                        <div className="row margintop">
                        {
                      getmenu.event?.images?.map((gname, index) => (
                          <div className="col-12 col-md-12 col-sm-12 margintop1">
                            <img src={gname} className='' style={{ width: "100%", height: "90%" }} />

                          </div>
                           ))
                          }

                          {/* <div className="col-6">
            <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div> */}

                        </div>
                     
                  </div>
                 <div className={getmenu.event?.imagesCount == 3 ? "" : "hide"}>

                    <div className="row margintop">
                    {getmenu.event?.images?.map((gname, index) => (
                    <div
        className={`margintop1 ${index < 2 ? "col-6" : "col-12"}`}
        key={index}
      >
        <img src={gname} className="" style={{ width: "100%", height: "auto" }} />
      </div>
))}

                      

                    </div>
                    
                  </div>
                  <div className={getmenu.event?.imagesCount == 5 ? "" : "hide"}>

<div className="row margintop">
{getmenu.event?.images?.map((gname, index) => (
  <div
        className={`margintop1 ${
          index < 3 ? "col-4" : "col-6"
        }`}
        key={index}
      >
        <img src={gname} className="" style={{ width: "100%", height: "auto" }} />
      </div>
))}

  

</div>

</div>


                  <div className="row margintop1">
                    <div className="col-lg-6 marginleftmins2">
                      <div className="row">
                        <div className="col-lg-6 col-sm-12 textright"><span className="event-sub-detail">Event Type</span></div>
                        <div className="col-lg-6 col-sm-12"><span className="event-sub-detail1 whitenowrap">{getmenu.event?.eventType} </span></div>

                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-sm-12 col-md-12 textright"><span className="event-sub-detail">Event Date</span></div>
                        <div className="col-lg-6 col-sm-12 "><span className="event-sub-detail1"> {getmenu.event?.eventDate}</span></div>
                      </div>
                      <div className="row">
                        <div className="col-6 textright"><span className="event-sub-detail">Event Time</span></div>
                        <div className="col-6"><span className="event-sub-detail1">{getmenu.event?.eventTime}</span></div>
                      </div>

                    </div>


                    <div className="col-6">
                      <div className="row">
                        <div className="col-6 textright"><span className="event-sub-detail">Host Name </span></div>
                        <div className="col-6 "><span className="event-sub-detail1">{getmenu.event?.hostName}</span></div>
                      </div>
                      <div className="row">
                        <div className="col-6 textright"><span className="event-sub-detail">Location</span></div>
                        <div className="col-6 "><span className="event-sub-detail1">{getmenu.event?.address}
                        </span>
                          {/* <div><span className="event-sub-detail1"> Address Line2 </span></div> */}
                          <div><span className="event-sub-detail1"> {getmenu.event?.city} </span></div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2 col-sm-4 col-md-6">
                      {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                      {getmenu.event?.likeStatus == true ? <AiFillLike color="#FF8427" onClick={() => addLikes(getmenu.event?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.event?.id)} style={{ cursor: "pointer" }} color="#FF8427" />}<span className="likespn marginleft2" onClick={() => loadLikesInfo(getmenu.event?.id)}> {getmenu.event?.likeCount} Likes</span>
                    </div>
                    <div className="col-lg-2"></div>
                    <div className="col-lg-3 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.event?.id)} style={{ cursor: "pointer" }}><FaRegComments color="#FF8427" /><span className="likespn marginleft2">{getmenu.event?.commentCount} Comments</span></div>

                    <div className="col-2"></div>
                    {getmenu.event?.userSpamStatus == false ?
                      (
                        <div className="col-lg-3 col-sm-5 col-md-4 report-spm-div" onClick={() => addSpam(getmenu.event?.id)} style={{ cursor: "pointer" }}><AiOutlineDislike color="#D30F45" /><span className="marginleft2 report-spm">Report Spam</span> </div>

                      ) : (
                        <div className="col-3  report-spm-div" onClick={() => addSpam(getmenu.event?.id)} style={{ cursor: "pointer" }}><FaThumbsDown color="#D30F45" /><span className="marginleft2 report-spm">Remove Spam</span> </div>

                      )
                    }

                  </div>
                  <Card className={comment && getmenu.event?.id == cmntEventId ? "margintop3" : "hide row"} >
                    <div className="">
                      <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                      <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                        {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}

                          <Card.Body>
                            <div className="mt-2">
                              <FormGroup className="form-group " >
                                <Form.Control
                                  type="text"
                                  className="form-control borderadius25px "
                                  id="name1"
                                  name="txtCmnt"
                                  placeholder="Add a Comment"
                                  style={{ fontSize: "13px", borderRadius: "25px" }}
                                  onChange={handleChange}
                                  onKeyPress={handleKeypress}
                                  value={cmntValue.txtCmnt}
                                />
                                <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>

                              </FormGroup>

                              <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.event?.id)}>
                                Send Reply
                              </button>
                              {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                            </div>
                          </Card.Body>
                        {/* </Card> */}
                      </Card>
                    </div>
                    {
                      commentData.map((commentmenu, index) => (
                        <div className="">
                          <Card className="" style={{ height: "auto",border:"none" }}>
                            {/* <Card style={{ height: "auto" }}> */}
                              
                              <Card.Body>

                                <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">

                                  <div className="media-body">
                                    <h6 className="mt-0 mb-1 font-12 font-weight-semibold" style={{ fontSize: "13px" }}>
                                      {commentmenu.viewerName}

                                    </h6>
                                    <p className="font-11  mb-2 mt-2" style={{ fontSize: "12px" }}>
                                      {commentmenu.comment}

                                    </p>

                                    <Badge bg="" className={commentmenu.viewerId == uerAuthId ? " bg-danger" : "hide"} onClick={() => deleteComment(commentmenu.id)} style={{cursor:"pointer"}}>
                                      Delete
                                    </Badge>

                                  </div>
                                </div>


                              </Card.Body>
                            {/* </Card> */}
                          </Card>
                        </div>
                      ))

                    }

                  </Card>
                </div>

                <div className={getmenu.blog ? "cardiv-event" : "hide"}>
                  <div className="row margintop">
                    <div className="col-1" >
                      {/* <img src={getmenu.blog?.profilePic} id='profpicdisablesave6' className='eventlogoimg1' /> */}
                      {getmenu.blog?.profilePic == "" || getmenu.blog?.profilePic == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px",marginTop:"6px", height: "42px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.blog?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.blog?.profilePic} id='profpicdisablesave6' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      } 
                    </div>
                    <div className="col-1 textright" style={{marginTop:"4px"}}><span className="evntxt ">Blog</span></div>
                    <div className="col-9" style={{marginTop:"4px"}}><span className="marginleftmins5 event-type-text whitenowrap" key={getmenu.blog?.id}>{getmenu.blog?.blogTitle}</span></div>
                    <div className="col-6 event-date-div"><span className="event-date">{getmenu.blog?.scheduledDate} at {getmenu.blog?.scheduledTime}</span><span className="event-publisher ">Published by {getmenu.event?.hostName}</span><span className="marginleft1 blog-auth-text">Author - {getmenu.blog?.authorName}</span></div>
                  </div>

                  <div className="row margintop3">
                    <img src={getmenu.blog?.bannerImagePath} className='blogimg' />
                    <div className="img-source-spn">Image Source - {getmenu.blog?.imageSource}</div>

                    <div className="blog-textdiv margintop3">
                      <span className="blog-text ">
                        {/* {getmenu.blog?.blogContent} */}
                        {getmenu.blog?.blogContent.split('\n').slice(0, 2).join('\n')}
                      </span>
                      {showFullDescription && (
        <div>
          {getmenu.blog?.blogContent.split('\n').slice(2).join('\n')}
        </div>
      )}
                      <div className="textright">
                        {/* <span className="read-more-blog">Read More</span> */}
                        {getmenu.blog?.blogContent.split('\n').length > 2 && (
        <span className="read-more-blog" onClick={toggleDescription}>
          {showFullDescription ? 'Read Less' : 'Read More'}
        </span>
      )}
                        </div>

                    </div>
                  </div>

                  <div className="row margintop1">
                    <div className="col-2">
                      {getmenu.blog?.likeStatus == true ? <AiFillLike color="#FF8427" onClick={() => addLikes(getmenu.blog?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.blog?.id)} style={{ cursor: "pointer" }} color="#FF8427" />}<span style={{ cursor: "pointer" }} className="likespn marginleft2" onClick={() => loadLikesInfo(getmenu.blog?.id)}> {getmenu.blog?.likeCount} Likes</span>
                    </div>
                    <div className="col-3"></div>
                    <div className="col-3" onClick={() => commentInfo(getmenu.blog?.id)} style={{ cursor: "pointer" }}><FaRegComments color="#FF8427" /><span className="likespn marginleft2">{getmenu.blog?.commentCount} Comments</span></div>
                    <div className="col-1"></div>
                    {getmenu.blog?.userSpamStatus == false ?
                      (
                        <div className="col-3 report-spm-div" onClick={() => addSpam(getmenu.blog?.id)} style={{ cursor: "pointer" }}><AiOutlineDislike color="#D30F45" /><span className="marginleft2 report-spm">Report Spam</span></div>
                      ) :
                      (
                        <div className="col-3 report-spm-div" onClick={() => addSpam(getmenu.blog?.id)} style={{ cursor: "pointer" }}><FaThumbsDown color="#D30F45" /><span className="marginleft2 report-spm">Remove Spam</span></div>
                      )

                    }
                  </div>
                  <Card className={comment && getmenu.blog?.id == cmntEventId ? "margintop3" : "hide row"} >
                    <div className="">
                      <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                      <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                        {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}

                          <Card.Body>
                            <div className="mt-2">
                              <FormGroup className="form-group " >
                                <Form.Control
                                  type="text"
                                  className="form-control borderadius25px "
                                  id="name1"
                                  name="txtCmnt"
                                  placeholder="Add a Comment"
                                  style={{ fontSize: "13px", borderRadius: "25px" }}
                                  onChange={handleChange}
                                  onKeyPress={handleKeypress}
                                  value={cmntValue.txtCmnt}
                                />
                                <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>

                              </FormGroup>

                              <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.blog?.id)}>
                                Send Reply
                              </button>
                              {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                            </div>
                          </Card.Body>
                        {/* </Card> */}
                      </Card>
                    </div>
                    {
                      commentData.map((commentmenu, index) => (
                        <div className="">
                          <Card className="" style={{ height: "auto",border:"none" }}>
                            {/* <Card style={{ height: "auto" }}> */}
                              
                              <Card.Body>

                                <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">

                                  <div className="media-body">
                                    <h6 className="mt-0 mb-1 font-12 font-weight-semibold" style={{ fontSize: "13px" }}>
                                      {commentmenu.viewerName}

                                    </h6>
                                    <p className="font-11  mb-2 mt-2" style={{ fontSize: "12px" }}>
                                      {commentmenu.comment}

                                    </p>

                                    <Badge bg="" className={commentmenu.viewerId == uerAuthId ? " bg-danger" : "hide"} onClick={() => deleteComment(commentmenu.id)} style={{cursor:"pointer"}}>
                                      Delete
                                    </Badge>

                                  </div>
                                </div>


                              </Card.Body>
                            {/* </Card> */}
                          </Card>
                        </div>
                      ))

                    }

                  </Card>
                </div>



                <div className={getmenu.kyc ? "cardiv-event" : "hide"}>
                  <div className="row margintop">
                    <div className="col-1">
                      {/* <img src={getmenu.kyc?.profilePic} id='profpicdisablesave19' className='eventlogoimg1' /> */}
                      {getmenu.kyc?.bannerImagePath == "" || getmenu.kyc?.bannerImagePath == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px",marginTop:"6px", height: "42px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.kyc?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.kyc?.bannerImagePath} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
                    </div>
                    <div className="col-1 textright" style={{marginTop:"4px"}}><span className="evntxt ">KYC</span></div>
                    <div className="col-9" style={{marginTop:"4px"}}><span className="marginleftmins5 event-type-text whitenowrap" key={getmenu.kyc?.id}>{getmenu.kyc?.kycPerson}</span><span className="marginleft2 event-location whitenowrap"></span></div>
                    <div className="col-6 event-date-div"><span className="event-date">{getmenu.kyc?.scheduledDate} at {getmenu.kyc?.scheduledTime}</span><span className="event-publisher ">Published by {getmenu.event?.hostName}</span><span className="marginleft1 blog-auth-text">Author - {getmenu.kyc?.authorName}</span></div>
                  </div>

                  <div className="row margintop3">
                    <img src={getmenu.kyc?.bannerImagePath} className='blogimg' />
                    <div className="img-source-spn">Image Source - {getmenu.kyc?.imageSource}</div>
                    {/* <div className="margintop1 textcenter"><span className="kyc-subheading">Edison of India</span></div> */}
                    

                    <div className="blog-textdiv margintop3">
              <span className="blog-text">
              {/* {getmenu.blog?.blogContent} */}
              {getmenu.kyc?.kycContent.split(' ').slice(0, 2).join(' ')}

              </span>
              {showFullDescriptionkyc && (
        <div>
          {getmenu.kyc?.kycContent.split(' ').slice(2).join(' ')}
        </div>
      )}
              <div className="textright margintop">
              {getmenu.kyc?.kycContent.split(' ').length > 10 && (
        <span className="read-more-blog" onClick={toggleDescriptionkyc}>
          {showFullDescriptionkyc ? 'Read Less' : 'Read More'}
        </span>
      )}                </div>
              

            </div>
                    {/* <div className="textright margintop1"><span className="read-more-blog">Read More</span></div> */}

                    <div className="margintop1">
                      <span className="kyc-ref">References:</span>

                      {
                        getmenu.kyc?.reference?.map((gname, index) => (
                          <div>
                            <div className="kyc-ref margintop1"><Link><span className="link-refrence">{gname}</span></Link></div>
                            {/* <div className="kyc-ref">2. <Link><span className="link-refrence">A non-conformist genius Architects of Coimbatore</span></Link> */}
                            {/* </div> */}
                          </div>
                        ))
                      }

                    </div>
                  </div>
                  <div className="row margintop1">
                    <div className="col-2">
                      {getmenu.kyc?.likeStatus == true ? <AiFillLike color="#FF8427" onClick={() => addLikes(getmenu.kyc?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.kyc?.id)} style={{ cursor: "pointer" }} color="#FF8427" />}<span style={{ cursor: "pointer" }} className="likespn marginleft2" onClick={() => loadLikesInfo(getmenu.kyc?.id)}> {getmenu.kyc?.likeCount} Likes</span>
                    </div>
                    <div className="col-3"></div>
                    <div className="col-3" onClick={() => commentInfo(getmenu.kyc?.id)} style={{ cursor: "pointer" }}><FaRegComments color="#FF8427" /><span className="likespn marginleft2">{getmenu.kyc?.commentCount} Comments</span></div>
                    <div className="col-1"></div>
                    {getmenu.event?.userSpamStatus == false ?
                      (
                        <div className="col-3 report-spm-div" onClick={() => addSpam(getmenu.kyc?.id)} style={{ cursor: "pointer" }}><AiOutlineDislike color="#D30F45" />{getmenu.kyc?.userSpamStatus == false ? <span className="marginleft2 report-spm">Report Spam</span> : <span className="marginleft2 report-spm">Remove Spam</span>} </div>

                      ) : (
                        <div className="col-3 report-spm-div" onClick={() => addSpam(getmenu.kyc?.id)} style={{ cursor: "pointer" }}><FaThumbsDown color="#D30F45" />{getmenu.kyc?.userSpamStatus == false ? <span className="marginleft2 report-spm">Remove Spam</span> : <span className="marginleft2 report-spm">Remove Spam</span>} </div>

                      )
                    }
                  </div>
                  <Card className={comment && getmenu.kyc?.id == cmntEventId ? "margintop3" : "hide row"} >
                    <div className="">
                      <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                      <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                        {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}

                          <Card.Body>
                            <div className="mt-2">
                              <FormGroup className="form-group " >
                                <Form.Control
                                  type="text"
                                  className="form-control borderadius25px "
                                  id="name1"
                                  name="txtCmnt"
                                  placeholder="Add a Comment"
                                  style={{ fontSize: "13px", borderRadius: "25px" }}
                                  onChange={handleChange}
                                  onKeyPress={handleKeypress}
                                  value={cmntValue.txtCmnt}
                                />
                                <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>

                              </FormGroup>

                              <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.kyc?.id)}>
                                Send Reply
                              </button>
                              {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                            </div>
                          </Card.Body>
                        {/* </Card> */}
                      </Card>
                    </div>
                    {
                      commentData.map((commentmenu, index) => (
                        <div className="">
                          <Card className="" style={{ height: "auto",border:"none" }}>
                            {/* <Card style={{ height: "auto" }}> */}
                              
                              <Card.Body>

                                <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">

                                  <div className="media-body">
                                    <h6 className="mt-0 mb-1 font-12 font-weight-semibold" style={{ fontSize: "13px" }}>
                                      {commentmenu.viewerName}

                                    </h6>
                                    <p className="font-11  mb-2 mt-2" style={{ fontSize: "12px" }}>
                                      {commentmenu.comment}

                                    </p>

                                    <Badge bg="" className={commentmenu.viewerId == uerAuthId ? " bg-danger" : "hide"} onClick={() => deleteComment(commentmenu.id)} style={{cursor:"pointer"}}>
                                      Delete
                                    </Badge>

                                  </div>
                                </div>


                              </Card.Body>
                            {/* </Card> */}
                          </Card>
                        </div>
                      ))

                    }

                  </Card>

                </div>

                {/* <div className="cardiv-event1">
          <div className="row margintop" >
              <div className="col-1">
              <img src={imgupload}  className='eventlogoimg1'/>
              </div>
              <div className="col-5 "><span className="evntxt-birthday ">Birthday Greetings!</span></div>
            <div className="col-7 event-date-div"><span className="event-date-birthday">August 3 at 4:00 PM</span></div>
            </div>
            
            <div className="textcenter">
              <span className="birdtday-date-text">23 December 2022</span>
            </div>
            <div className="textcenter">
              <span className="birdtday-date-text">Heartiest Birthday wish from Naidu Directory</span>
            </div>
            <div className="row margintop" >
            <div className="col-1 "></div>

              <div className="col-2  ">             
               <img src={imgupload}  className='birthday-date-img marginleftmins6'/>
               <span className="birthday-date-nme1">First Name,Last Name</span>
          </div>
          <div className="col-1 "></div>

              <div className="col-2 mr-3 ">
              <img src={imgupload}  className='birthday-date-img2 '/>
              <span className="birthday-date-nme2">First Name,Last Name</span>

              </div>

              <div className="col-2 ml-3 ">
              <img src={imgupload}  className='birthday-date-img3 '/>
              <span className="birthday-date-nme3">First Name,Last Name</span>

              </div>
              <div className="col-1 "></div>

              <div className="col-2 mr-3 marginleftmins">
              <img src={imgupload}  className='birthday-date-img'/>
              <span className="birthday-date-nme">First Name,Last Name</span>

              </div>
              <div className="col-1  "></div>


            </div>
            <div className="row margintop" >
            <div className="col-1 "></div>

              <div className="col-2  ">             
               <img src={imgupload}  className='birthday-date-img marginleftmins6'/>
               <span className="birthday-date-nme1">First Name,Last Name</span>
          </div>
          <div className="col-1 "></div>

              <div className="col-2 mr-3 ">
              <img src={imgupload}  className='birthday-date-img2 '/>
              <span className="birthday-date-nme2">First Name,Last Name</span>

              </div>

              <div className="col-2 ml-3 ">
              <img src={imgupload}  className='birthday-date-img3 '/>
              <span className="birthday-date-nme3">First Name,Last Name</span>

              </div>
              <div className="col-1 "></div>

              <div className="col-2 mr-3 marginleftmins">
              <img src={imgupload}  className='birthday-date-img'/>
              <span className="birthday-date-nme">First Name,Last Name</span>

              </div>
              <div className="col-1  "></div>


            </div>



            </div> */}

                <div className={getmenu.coffeeMeet ? "cardiv-coffee  margintop border1px" : "hide"}>
                <div className="row mt-3">
  
  <div className="col-1">

  {getmenu.coffeeMeet?.bannerImagePath == "" || getmenu.coffeeMeet?.bannerImagePath == null ?
                        (<Avatar className="" style={{ width: "44px",marginTop:"6px", height: "42px",backgroundColor:bgColor,color:"#ffffff" }}>{getmenu.coffeeMeet?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.coffeeMeet?.bannerImagePath} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
    </div>
<div className="col-11" style={{marginTop:"0px"}}><h6 className="card-title mb-1" style={{whiteSpace:'nowrap'}}>


<span className='evntxt'  key={getmenu.coffeeMeet?.id}>{getmenu.coffeeMeet?.hostName} - Host</span>

             </h6>
             <div style={{marginTop:"4px"}}>
             
             <span className=" text-muted " style={{fontSize:"11px"}}>
             Creaeted On - {getmenu.coffeeMeet?.createdDate} {getmenu.coffeeMeet?.createdTime}
</span>
<span className="marginleft20px text-muted " style={{fontSize:"11px"}}>
City - {getmenu.coffeeMeet?.city}
</span>
             </div>
             </div>


</div>
                  <div className="row margintop3">
                    <div className="col-8">
                      <span className="event-sub-detail" key={getmenu.coffeeMeet?.id}>{getmenu.coffeeMeet?.date} At {getmenu.coffeeMeet?.time} </span><span className="event-sub-detail" style={{ marginLeft: '12px' }}>{getmenu.coffeeMeet?.city}</span>
                    </div>
                    {
                      getmenu.coffeeMeet?.invited == true &&
                      (
                        <div className="col-3" style={{float:"right"}}>
                          {
                            getmenu.coffeeMeet?.attendStatus == 'Attending' ? (
                              <span className="event-sub-detail" style={{ marginTop: '6px', position: "absolute",marginLeft:"20px" }}>Attending</span>

                            ) :
                              (<span className="event-sub-detail" style={{ marginTop: '6px', position: "absolute" }}>Not Attending</span>
                              )
                          }
                        </div>
                      )
                    }

                  </div>

                  <div className="row margintop2">
                    <div className="">
                      <span className="event-sub-detail">Venue:- </span>
                      <span className="event-sub-detail1" style={{ margin: "5px", fontSize: '13px' }}>{getmenu.coffeeMeet?.venue}</span>
                      {/* <a href={`/${getmenu.coffeeMeet?.googleMapsLink}`} target="_blank"><span className="bluetextforlink" style={{ margin: "7px", fontSize: '10px' }}>Check on Google Maps</span></a> */}
                      < a href={`/${getmenu.coffeeMeet?.googleMapsLink}`} target='_blank'><span className="bluetextforlink" style={{ margin: "7px", fontSize: '10px' }}>Check on Google Maps</span></a>
                    </div>
                  </div>
                  
                  <div className="row margintop3">
                    <div className="">
                      <span className="event-sub-detail1" style={{ margin: "5px", fontSize: '13px' }}>{getmenu.guests?.length} Members are attending this Coffee Meet Up</span>
                    </div>
                  </div>
                  <div className="row margintop">
                    {
                      getmenu.coffeeMeet?.guests?.map((gname, index) => (
                        // <div className="col-2">
                        //   <img src={gname.profilePicture} id='profpicdisablesave7' className=' ' style={{ width: "100px", height: "100px", borderRadius: "50%" }} />
                        //   <div><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '2px' }}>{gname.name}</span></div>
                        // </div>
                        <div className="col-2">
                                                             
                                         <span className={gname.status == 'Reject'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnrej' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px' }} /></span>
                                         <span className={gname.status == 'Attend'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnatt' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px'  }} /></span>
                                         <span className={gname.status == 'Pending'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnpen' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px'  }} /></span>



                                {/* <span className="borderstatusspn"><img src={gname.profilePicture} id="othercoffeeprofid" className=' ' style={{ width: "100px", height: "100px", borderRadius: "50%" }} /></span> */}
                                {/* <div className="textcenter"><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '0px' }}>{gname.name}</span></div> */}
                                <div className="textcenter" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

    <span className="event-sub-detail1" style={{ fontSize: '10px', maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal', lineHeight: '1.2', overflow: 'hidden' }}>{gname.name}</span>
    </div>
    <div className="textcenter" style={{ textAlign: 'center', minHeight: '20px' }}>
    <span className={gname.status === 'Reject' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#E75480", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
    <span className={gname.status === 'Attend' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#4CAF50", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
    <span className={gname.status === 'Pending' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#FFC107", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
  </div>
                                  {/* <span className={gname.status == 'Reject'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#E75480" }}>{gname.status}</span>
                                  <span className={gname.status == 'Attend'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#4CAF50" }}>{gname.status}</span>
                                  <span className={gname.status == 'Pending'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#FFC107" }}>{gname.status}</span> */}

                              </div>
                      ))
                    }
                    {/* <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
               <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div> */}


                  </div>
                  <div className="row margintop3"></div>
                </div>

<div className={getmenu.job ? " margintop " : "hide"}>
  <div className="card" style={{paddingTop:"18px",paddingBottom:"16px"}}>
  <div className="row">
    <div className="col-1">
      {/* <img src={getmenu.job?.profilePic} id='profpicdisablesave5' className='eventlogoimg' /> */}
      {getmenu.job?.profilePic == "" || getmenu.job?.profilePic == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px", height: "42px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.job?.postedByName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.job?.profilePic} id='profpicdisablesave5' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
      </div>
    <div className="col-11">
      <div className=""><span className="evntxt ">Open for Opportunities</span>
      <span className=" event-type-text marginleft2" key={getmenu.job?.id}>{getmenu.job?.jobOpeningFor}</span>
      <span className="marginleft2 event-location">{getmenu.job?.currentLocation}</span>
       </div>
       <div className="">
       <span className="event-date">{getmenu.job?.createdDate} at {getmenu.job?.createdTime}</span>
       <span className="event-publisher ">Published by {getmenu.job?.postedByName}</span>
       </div>
      
    </div>

  </div>
  <div className="row mt-3">
    <div className="col-6">
      <div className="row">
      <div className="col-7"><span className="event-sub-detail">Community Member</span></div>
      <div className="col-5"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.postedByName} </span></div>
</div>
<div className="row mt-2">
      <div className="col-7"><span className="event-sub-detail">Job Title</span></div>
      <div className="col-5"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.jobOpeningFor}</span></div>
</div>
<div className="row mt-2">
      <div className="col-7"><span className="event-sub-detail">Total Experience</span></div>
      <div className="col-5"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.experience}</span></div>
</div>
<div className="row mt-2">
      <div className="col-7"><span className="event-sub-detail">Current Location</span></div>
      <div className="col-5"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.currentLocation}</span></div>
</div>
<div className="row mt-2">
      <div className="col-7"><span className="event-sub-detail">Home Town</span></div>
      <div className="col-5"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.homeLocation}</span></div>
</div>
    </div>
    
    <div className="col-6">
    <div className="row mt-2">
      <div className="col-7"><span className="event-sub-detail">Resume</span></div>
      <div className="col-5">{
        getmenu.job?.resumePath=="" || getmenu.job?.resumePath==null?
        (<span className='event-sub-detail' style={{color:"#8B0000"}}>Not Uploaded</span>):(  < a href={`/${getmenu.job?.resumePath}`} target='_blank'><AiOutlineFilePdf size='1.9em' color='#8B0000' onClick={()=>viewContactJobPostPdf(getmenu.job?.id)}/></a>
        )
      }
        </div>
</div>
<div className="row mt-2">
      <div className="col-7"><span className="event-sub-detail">Available for job in</span></div>
      <div className="col-5"><span className="event-sub-detail1 ">{getmenu.job?.availableIn}</span></div>
</div>
<div className="row mt-2">
      <div className="col-7"><span className="event-sub-detail">Contact Details</span></div>
      <div className="col-5"><span className="event-sub-detail1 whitenowrap" style={{color:"#0500E6",cursor:"pointer"}} onClick={()=>viewContactJobPost(getmenu.job?.id)}>Click to View</span></div>
      {/* {isOpenJobPostContactPopup && <ContactViewPop

handleClose={toggleJobPostPopupContact} uerAuthId={uerAuthId} 
/>} */}
<div className={viewContactJobpostDiv ? "row" : "hide row"}>
      <div className="popup-boxrefmember">
      <div className="boxrefmember" style={{width:"26%"}}>
        <div className="topnewsrow ">
            <div className="row">
                <div className="col-4"></div>
                <div className="col-6">
                <span className=" blood-top-text ">Contact Details</span>
                 {/* <MdFileCopy color='#fff' className="marginleft2"style={{color:"#555555"}}/>
                 <FaShareAltSquare color='#fff' className="marginleft5"style={{color:"#555555"}}/> */}
                 </div>
                 <div className='col-2'>
                 <span className="lastpop-top-text" onClick={() => ContactJobPostpoupopen(false)}  style={{cursor:"pointer"}}><AiOutlineClose size="1.3em" style={{float:"right"}}/></span>

                 </div>
            </div>
           
        
        </div>
<div style={{overflowY:"",height:"80px"}}>

<div className="row mt-3">
      <div className="col-4 textright"><span className="event-sub-detail">Email Id</span></div>
      <div className="col-8 textleft"><span className="event-sub-detail1  complet-name" style={{color:"#FFFFFF"}}>{getmenu.job?.emailId}</span></div>
</div>
<div className="row mt-2">
      <div className="col-4 textright"><span className="event-sub-detail">Mobile</span></div>
      <div className="col-8 textleft"><span className="event-sub-detail1  complet-name" style={{color:"#FFFFFF"}}>{getmenu.job?.mobileNumber}</span></div>
</div>
    
   
</div>
                  <div className='popupbtn-div margintop3'>

          <div className='row '>
                <div className='col-4'></div>
                <div className='col-3'>
                    <button className='cancelbtn'  onClick={() => ContactJobPostpoupopen(false)} style={{width:'110px',height:"40px",fontSize:"13px"}}>Close</button>
                </div>
                

              </div>
              </div>
      </div>
    </div>
    </div>
</div>
    </div>

  </div>

  <div className="row mt-2">
                    <div className="col-lg-2 col-sm-4 col-md-6">
                      {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                      {getmenu.job?.likeStatus == true ? <AiFillLike color="#FF8427" onClick={() => addLikes(getmenu.job?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.job?.id)} style={{ cursor: "pointer" }} color="#FF8427" />}<span className="likespn marginleft2" onClick={() => loadLikesInfo(getmenu.event?.id)}> {getmenu.job?.likeCount} Likes</span>
                    </div>
                    <div className="col-lg-2"></div>
                    <div className="col-lg-3 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.job?.id)} style={{ cursor: "pointer" }}><FaRegComments color="#FF8427" /><span className="likespn marginleft2">{getmenu.job?.commentCount} Comments</span></div>

                    <div className="col-2"></div>
                    {getmenu.job?.userSpamStatus == false ?
                      (
                        <div className="col-lg-3 col-sm-5 col-md-4 report-spm-div" onClick={() => addSpam(getmenu.job?.id)} style={{ cursor: "pointer" }}><AiOutlineDislike color="#D30F45" /><span className="marginleft2 report-spm">Report Spam</span> </div>

                      ) : (
                        <div className="col-3  report-spm-div" onClick={() => addSpam(getmenu.job?.id)} style={{ cursor: "pointer" }}><FaThumbsDown color="#D30F45" /><span className="marginleft2 report-spm">Remove Spam</span> </div>

                      )
                    }

                  </div>
                  <Card className={comment && getmenu.job?.id == cmntEventId ? "margintop3" : "hide row"} >
                    <div className="">
                      <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                      <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                        {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}

                          <Card.Body>
                            <div className="mt-2">
                              <FormGroup className="form-group " >
                                <Form.Control
                                  type="text"
                                  className="form-control borderadius25px "
                                  id="name1"
                                  name="txtCmnt"
                                  placeholder="Add a Comment"
                                  style={{ fontSize: "13px", borderRadius: "25px" }}
                                  onChange={handleChange}
                                  onKeyPress={handleKeypress}
                                  value={cmntValue.txtCmnt}
                                />
                                <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>

                              </FormGroup>

                              <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.job?.id)}>
                                Send Reply
                              </button>
                              {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                            </div>
                          </Card.Body>
                        {/* </Card> */}
                      </Card>
                    </div>
                    {
                      commentData.map((commentmenu, index) => (
                        <div className="">
                          <Card className="" style={{ height: "auto",border:"none" }}>
                            {/* <Card style={{ height: "auto" }}> */}
                              
                              <Card.Body>

                                <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">

                                  <div className="media-body">
                                    <h6 className="mt-0 mb-1 font-12 font-weight-semibold" style={{ fontSize: "13px" }}>
                                      {commentmenu.viewerName}

                                    </h6>
                                    <p className="font-11  mb-2 mt-2" style={{ fontSize: "12px" }}>
                                      {commentmenu.comment}

                                    </p>

                                    <Badge bg="" className={commentmenu.viewerId == uerAuthId ? " bg-danger" : "hide"} onClick={() => deleteComment(commentmenu.id)} style={{cursor:"pointer"}}>
                                      Delete
                                    </Badge>

                                  </div>
                                </div>


                              </Card.Body>
                            {/* </Card> */}
                          </Card>
                        </div>
                      ))

                    }

                  </Card>
</div>
</div>


                {/* <div className={getmenu.coffeeMeet?"cardiv-event ":"hide"} style={{width:"600px"}}>
          <div className="row margintop">
              <div className="col-1">
              <img src={imgupload}  className='eventlogoimg1'/>
              </div>
              <div className="col-3 textright"><span className="evntxt ">Coffee Meet Up - {getmenu.city }</span></div>
            <div className="col-6 event-date-div"><span className="event-date">Created on {getmenu.coffeeMeets?.createdDate} at {getmenu.blog?.time}</span><span className="event-publisher ">hosted by {getmenu.hostName}</span></div>
            </div>

            <div className="row margintop3">
              <div className="col-9">
              <div className="coffeecardformain-div " style={{backgroundColor:"#411962",color:"#fff",paddingLeft:"20px",paddingTop:"0px",paddingBottom:"20px",width:"300px"}}>
       <div className="boederbotomforcoffee margintop3" style={{width:"170px"}}>
       <RiCupFill  className="" size='1.7em'/> <span className="event-sub-detail" style={{color:"#fff"}}>Event Details</span>

       </div>
       <div className="row margintop3">
        <div className="col-4"><AiOutlineCalendar size='1.5em'/> <span className="event-sub-detail1" style={{color:"#fff"}}>{getmenu.coffeeMeets?.date}</span></div>
        <div className="col-4"><RiTimeLine size='1.5em'/> <span className="event-sub-detail1" style={{color:"#fff"}}>{getmenu.coffeeMeets?.time} </span></div>

       </div>
       <div className="row margintop3">
        <div className="col-7"><MdLocationOn size='1.5em'/> <span className="event-sub-detail1" style={{color:"#fff"}}>{getmenu.coffeeMeets?.venue}</span></div>

       </div>
       <div className="row margintop3">
        <div className="col-7"><FaMapPin size='1.5em'/> <span className="event-sub-detail1" style={{color:"#fff"}}>{getmenu.coffeeMeets?.googleMapsLink}</span></div>

       </div>

            </div>
            <div className="">
              <span className="event-sub-detail">{getmenu.attendeesCount} Community Members are attending this Coffee Meet up</span>
              <div className="row margintop">
     {
          getmenu.guests?.map((gname, index) => (
        <div className="col-2">
        <img src={gname.profilePicture}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
            <div><span className="event-sub-detail1" style={{fontSize:'10px',marginLeft:'27px'}}>{gname.name}</span></div>
        </div>
              ))
}
</div>
            </div>
              </div>
            

               </div>

               <div className="row margintop1">
               <div className="col-2">              
              {getmenu.blog?.likeStatus==true?<AiFillLike color="#FF8427" onClick={()=>addLikes(getmenu.blog?.id)}  style={{cursor:"pointer"}}/>:<BiLike onClick={()=>addLikes(getmenu.blog?.id)} style={{cursor:"pointer"}} color="#FF8427"/>}<span style={{cursor:"pointer"}} className="likespn marginleft2" onClick={()=>loadLikesInfo(getmenu.blog?.id)}> {getmenu.blog?.likeCount} Likes</span>
            </div>
            <div className="col-3"></div>
            <div className="col-1"></div>
{getmenu.blog?.userSpamStatus==false ?
(
  <div className="col-3 report-spm-div" onClick={()=>addSpam(getmenu.blog?.id)} style={{cursor:"pointer"}}><AiOutlineDislike color="#D30F45"/><span className="marginleft2 report-spm">Report Spam</span></div>
):
( 
  <div className="col-3 report-spm-div" onClick={()=>addSpam(getmenu.blog?.id)} style={{cursor:"pointer"}}><AiOutlineDislike color="#D30F45"/><span className="marginleft2 report-spm">Remove Spam</span></div>
)

}
               </div>
              
            </div> */}
                {/* <div className="cardiv-coffee margintop border1px">
          <div className="row margintop3">
            <div className="col-8">
                <span className="event-sub-detail">01 Feb 2023 </span><span className="event-sub-detail" style={{marginLeft:'12px'}}>Pune</span>
            </div>
            <div className="col-2">

            <div class="dropdown" style={{backgroundColor:"rgba(238, 118, 0, 1)"}}>
  <button class="btn  dropdown-toggle attendbtn withdrawattendbtn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{width:"197px"}}>
  You are attending
  </button>
  <div class="dropdown-menu withdrawattendbtn" aria-labelledby="dropdownMenuButton" style={{backgroundColor:"rgba(238, 118, 0, 1)"}}>
    <a class="dropdown-item withdrawattendbtn" href="#" style={{fontSize:'13px'}}>Withdraw Attendance</a>
  </div>
</div>

          </div>
          </div>
          
          <div className="row margintop2">
            <div className="">
                <span className="event-sub-detail">Venue:- </span>
                <span className="event-sub-detail1" style={{margin:"5px",fontSize:'13px'}}>Good Luck Cafe, FC Road, Pune 01</span>
                <span className="bluetextforlink" style={{margin:"7px",fontSize:'10px'}}>Check on Google Maps</span>
            </div>
          </div>
          <div className="row margintop3">
      <div className="">
      <span className="event-sub-detail1" style={{margin:"5px",fontSize:'13px'}}>05 Members are attending this Coffee Meet Up</span>
    </div>
     </div>
     <div className="row margintop">
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
            <div><span className="event-sub-detail1" style={{fontSize:'10px',marginLeft:'27px'}}>First Name</span></div>
        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
               <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        

     </div>
          <div className="row margintop3"></div>
          </div> */}



              </div>
            ))

          }
          {
            loadPost && loadPost.length == 0 && (
              <div className='row margintop'>
                <div className='col-3'></div>
                <div className='col-5 margintop'> <span className='event-sub-detail'>Posts Are not Available</span>
                </div>

              </div>
            )
          }
          {/* <div className="card" style={{paddingTop:"18px",paddingBottom:"10px"}}>
  <div className="row">
    <div className="col-1"><img src={imgupload} id='profpicdisablesave21' className='eventlogoimg' style={{height:"90%"}} /></div>
    <div className="col-11">
      <div className=""><span className="evntxt ">Open for Opportunities</span>
      <span className=" event-type-text marginleft2" key=''>Java Developer</span>
      <span className="marginleft2 event-location">Pune</span>
       </div>
       <div className="">
       <span className="event-date">0000 at 888888</span>
       <span className="event-publisher " style={{marginLeft:"10px"}}>Published by ABC Admin</span>
       </div>
      
    </div>

  </div>
  <div className="row mt-3">
    <div className="col-6">
      <div className="row mt-2">
      <div className="col-6 textleft"><span className="event-sub-detail">Community Member</span></div>
      <div className="col-6 textleft"><span className="event-sub-detail1 whitenowrap">Sagar Naidu </span></div>
</div>
<div className="row mt-2">
      <div className="col-6 textleft"><span className="event-sub-detail">Job Title</span></div>
      <div className="col-6 textleft"><span className="event-sub-detail1 whitenowrap">Java Developer</span></div>
</div>
<div className="row mt-2">
      <div className="col-6 textleft"><span className="event-sub-detail">Total Experience</span></div>
      <div className="col-6 textleft"><span className="event-sub-detail1 whitenowrap">12 Years</span></div>
</div>
<div className="row mt-2">
      <div className="col-6 textleft"><span className="event-sub-detail">Current Location</span></div>
      <div className="col-6 textleft"><span className="event-sub-detail1 whitenowrap">Pune, Maharashtra</span></div>
</div>
<div className="row mt-2">
      <div className="col-6 textleft"><span className="event-sub-detail">Home Town</span></div>
      <div className="col-6 textleft"><span className="event-sub-detail1 whitenowrap">Latur, Maharashtra</span></div>
</div>
    </div>
    
    <div className="col-6">
    <div className="row mt-2">
      <div className="col-6 textleft"><span className="event-sub-detail">Resume</span></div>
      <div className="col-6 textleft"><span className="event-sub-detail1 whitenowrap">Latur, Maharashtra</span></div>
</div>
<div className="row mt-2">
      <div className="col-6 textleft"><span className="event-sub-detail">Available for job in</span></div>
      <div className="col-6 textleft"><span className="event-sub-detail1 whitenowrap">30 days</span></div>
</div>
<div className="row mt-2">
      <div className="col-6 textleft"><span className="event-sub-detail">Contact Details</span></div>
      <div className="col-6 textleft"><span className="event-sub-detail1 whitenowrap" style={{color:"#0500E6",cursor:"pointer"}} onClick={toggleJobPostPopupContact}>Click to View</span></div>
      
      {isOpenJobPostContactPopup && <ContactViewPop

handleClose={toggleJobPostPopupContact} uerAuthId={uerAuthId} 
/>}
</div>
    </div>

  </div>

  
</div> */}
          {/* <div className="row margintop">
          <div>
    {subset.map((item) => (
        <div key={item.id}>{item.title}</div>
    ))}
     <ReactPaginate
        pageCount={totalPages}
        onPageChange={handlePageChange}
        forcePage={currentPage}
    /> 
</div>
          </div> */}

        </div>

        <div className={communityDirect ? "col-6 heightforAll3Div communitydiv" : "hide"}>
          <div className="margintop3 row">
            <div className="col-lg-3 col-sm-12 col-md-4">
              <div class="form-group  has-search margintop1">
                <span class="fa fa-search form-control-feedback"></span>
                <input type="text" class="form-control" placeholder="Search Member" onChange={handleSearchForDirectChange} style={{ fontSize: '11px' }} />
              </div>                </div>
            <div className="col-1"><FaFilter size='1.2em' style={{ marginTop: "9px" }} /></div>
            <div className="col-2"></div>
            <div className="col-3 ">
              <select id="inputState" name='filterDirect'
                onChange={handleChangeDirectFilter}

                className="form-control drpdowncmdirect" >
                <option selected value="All"> All</option>
                <option value="country">Country</option>
                <option value="state">State</option>
                <option value="city">City</option>
                <option value="profession">Profession</option>
                <option value="blood group">Blood Group</option>
                <option value="bookmark profile">Bookmark Profile</option>

              </select>
            </div>
            <div className="col-3" style={{whiteSpace:"nowrap",display:"inline-flex"}}>
              {
                countryDiv == true && (
                  <div style={{whiteSpace:"nowrap",display:"inline-flex"}}>
                    <input type="text" className=" form-control" id="fst"
                    name="country"
                    placeholder="Enter Country"
                    style={{ fontSize: "12px",height:"32px" }}
                    onChange={handleChangeforFilterDirect}

                  />

                    <FaSearch onClick={filterCountry} size="1.3em" style={{marginLeft:"6px",marginTop:"6px",cursor:"pointer"}}/>
                  </div>
                )
              }
              {
                stateDiv == true && (
                  <div style={{whiteSpace:"nowrap",display:"inline-flex"}}>
                  <input type="text" className=" form-control" id="fst"
                    name="state"
                    placeholder="Enter State"
                    style={{ fontSize: "12px",height:"32px" }}
                    onChange={handleChangeforFilterState}

                  />
                 <FaSearch onClick={filterState} size="1.3em" style={{marginLeft:"6px",marginTop:"6px",cursor:"pointer"}}/>

                  </div>
                )
              }
              {
                cityDiv == true && (
                  <div style={{whiteSpace:"nowrap",display:"inline-flex"}}>
                  <input type="text" className=" form-control" id="fst"
                    name="city"
                    placeholder="Enter City"
                    style={{ fontSize: "12px",height:"32px" }}
                    onChange={handleChangeforFilterCity}

                  />
                  <FaSearch onClick={filterCity} size="1.3em" style={{marginLeft:"6px",marginTop:"6px",cursor:"pointer"}}/>

                  </div>
                )
              }
              {
                bloodGroupDIv == true && (
                  <div style={{whiteSpace:"nowrap",display:"inline-flex"}}>
                  <input type="text" className=" form-control" id="fst"
                    name="bloodGroup"
                    placeholder="Enter Blood Group"
                    style={{ fontSize: "12px",height:"32px" }}
                    onChange={handleChangeforFilterBlood}

                  />              
                  <FaSearch onClick={filterBlood} size="1.3em" style={{marginLeft:"6px",marginTop:"6px",cursor:"pointer"}}/>
              </div>
                )
              }
              {
                professionDiv == true && (
                  <div style={{whiteSpace:"nowrap",display:"inline-flex"}}>
                  <input type="text" className=" form-control" id="fst"
                    name="profession"
                    placeholder="Enter Profession"
                    style={{ fontSize: "12px",height:"32px" }}
                    onChange={handleChangeforFilterProfession}
                  />
                  <FaSearch onClick={filterProfession} size="1.3em" style={{marginLeft:"6px",marginTop:"6px",cursor:"pointer"}}/>

                  </div>
                )
              }

            </div>


          </div>

          {
            commDirectory.map((getmenu, index) => (
              <div className="communit-cardfirst margintop3">
                <div className="row  cardfirst-toprow">
                  <div className="col-lg-4 col-sm-12 col-md-4 margintop1"><span className="community-direct-nametxt" key={getmenu.id}>{getmenu.fullName}</span></div>
                  <div className="col-lg-1  col-sm-5 col-md-4 textleft">
                    {/* <img src={flag} className='margintop1 marginleftmins' /> */}
                    
                    {/* {
            CountryflagJson.map((getmenu11, index) => (
              
              <div>
                {getmenu.currentCountryCode==getmenu11.name &&
                   <img src={getmenu11.image} alt=''/>

                }
                </div>
            ))
            } */}
                  </div>
                  <div className="col-lg-4 col-sm-5 col-md-4 margintop1"><span className="marginleftmins6 community-country">{getmenu.currentCountry}</span><span className="communi-field marginleft1">{getmenu.profession}</span></div>
                  <div className="col-lg-3 col-md-6 col-sm-12 whitenowrap messg-div" onClick={() => togglePopupMessageDirect(getmenu.id,getmenu.blockStatus)} style={{cursor:"pointer"}}> <RiSendPlaneFill size='1.3em' className='marginleftmins5' />   Message {getmenu.firstName}
                  </div>
                  {isOpenMessagePopDirect && <MessagePopupDirectory

handleClose={togglePopupMessageDirect} uerAuthId={uerAuthId} idforpop={idforpop} 
userlist ={userlist} setuserlist={setuserlist} styleForMessgBlockstatusComm={styleForMessgBlockstatusComm}
/>}
                </div>

                <div className="row margintop3">
                  <div className="col-3"  >
                    <img src={getmenu.profilePicturePath} id='profpicdisablesave8' className='communitydir-prof' />
                  </div>

                  <div className="col-4 margintop1">
                    <div className="row margintop2">
                      <div className="col-1">
                        <img src={getmenu.profilePicturePath} id='profpicdisablesave9' className=' communitydrect-img' />
                      </div>
                      <div className="col-10 marginleft4 whitenowrap textleft">
                        <span className="marginleft4 communtdrc-cit-text">CID #</span>  <span className="comudrct-digitxt">{getmenu.id}</span>
                      </div>
                    </div>
                    <div className="row margintop2">
                      <div className="col-1">
                        <MdWaterDrop size='1.4em' color='#555555' />
                      </div>
                      <div className="col-10 marginleft4 whitenowrap textleft">
                        <span className="marginleft4 communtdrc-cit-text">Blood Group -</span>  <span className="comudrct-digitxt" style={{ textTransform: "capitalize" }}>{getmenu.bloodGroup}</span>
                      </div>
                    </div>
                    <div className="row margintop2">
                      <div className="col-1">
                        <img src={subcast} className=' communitydrect-img' />
                      </div>
                      <div className="col-10 marginleft4 whitenowrap textleft">
                        <span className="marginleft4 communtdrc-cit-text">Sub Cast -</span>  <span className="comudrct-digitxt" style={{ textTransform: "capitalize" }}>{getmenu.subCaste}</span>
                      </div>
                    </div>
                    <div className="row margintop2">
                      <div className="col-1">
                        <img src={refferal} className='communitydrect-img' />
                      </div>
                      <div className="col-10 marginleft4 whitenowrap textleft">
                        <span className="marginleft4 communtdrc-cit-text">Referrals #</span>  <span className="comudrct-digitxt">{getmenu.referralCount}</span>
                      </div>
                    </div>
                    <div className="row margintop2">
                      <div className="col-1">
                        <RiCupFill color="#555555" size='1.4em' />
                      </div>
                      <div className="col-10 marginleft4 whitenowrap textleft">
                        <span className="marginleft4 communtdrc-cit-text">Cups of Coffee #</span>  <span className="comudrct-digitxt">{getmenu.coffeeMeetsAttended}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-4 margintop1">
                    <div className="row margintop2">
                      <div className="col-1">
                        <img src={from} className=' communitydrect-img' />
                      </div>
                      <div className="col-10 marginleft4 whitenowrap textleft">
                        <span className="marginleft4 communtdrc-cit-text">From -</span>  <span className="comudrct-digitxt">{getmenu.homeCity}</span>
                      </div>
                    </div>
                    <div className="row margintop2">
                      <div className="col-1">
                        <MdLocationOn size='1.5em' color='#555555' />
                      </div>
                      <div className="col-10 marginleft4 whitenowrap textleft">
                        <span className="marginleft4 communtdrc-cit-text">Lives in -</span>  <span className="comudrct-digitxt">{getmenu.currentCity},{getmenu.currentState}</span>
                      </div>
                    </div>
                    <div className="row margintop2">
                      <div className="col-1">
                        <img src={radius} className=' communitydrect-img' />
                      </div>
                      <div className="col-10 marginleft4 whitenowrap textleft">
                        <span className="marginleft4 communtdrc-cit-text">Distance radius -</span>  <span className="comudrct-digitxt">{getmenu.distance}</span>
                      </div>
                    </div>
                    <div className="row margintop2">
                      <div className="col-1">

                        {getmenu.bookmarkStatus == true ? 
                        <FaBookmark color='#555555' size="1.4em" onClick={() => addCommDirectoryBookmark(getmenu.id)} style={{ cursor: "pointer" }} /> : <BsBookmark size="1.4em" onClick={() => addCommDirectoryBookmark(getmenu.id)} style={{ cursor: "pointer" }} color="#555555" />}

                      </div>
                      <div className="col-10 marginleft4 whitenowrap textleft">
                        <span className="marginleft4 communtdrc-cit-text">Bookmark Profile </span>  <span className="comudrct-digitxt"></span>
                      </div>
                    </div>
                    <div className="row margintop2">
                      <div className="col-1">
                        <img src={contactinfo} className=' communitydrect-img' />
                      </div>
                      <div className="col-10 marginleft4  whitenowrap textleft">
                        <span className="marginleft4 communtdrc-cit-text contct-infohover" onClick={() => contactInfopoupopen(getmenu.id, true)} >Contact Info </span>  <span className="comudrct-digitxt"></span>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="row margintop1">
                  <div className="col-1"></div>
                  <div className="col-2">
                    <span className=" community-viewprof" onClick={()=>toggleviewProfDiPop(getmenu.id,getmenu.blockStatus)} style={{cursor:"pointer"}}>View Profile</span>
                    {isOpenMessagePopViewDirect && <ViewProfCommunityDirectPop

handleClose={toggleviewProfDiPop} uerAuthId={uerAuthId} idforcommpop={idforcommpop} 
userlist ={userlist} setuserlist={setuserlist} styleForMessgBlockstatusCommView={styleForMessgBlockstatusCommView}
/>}
                  </div>
                  
                  
                  <div className="col-4"></div>

                  <div className="col-5  textcenter">
                    <button className={getmenu.connectionStatus == false && getmenu.blockStatus == 0 ? "com-conect-btn" : "hide"} style={{ marginLeft: "10px", color: "#F2F2F2", backgroundColor: "#FF8427" }} onClick={() => addCommDirectoryConnect(getmenu.id)}>Connect</button>
                    <span className={getmenu.blockStatus == 1 ? "communtdrc-cit-text" : "hide"} style={{ marginLeft: "10px", fontSize: "13px", color: "#555555" }} >Blocked</span>

                    <span className={getmenu.connectionStatus == true && getmenu.connection=="Sent" ? "community-direct-nametxt11" : "hide"} style={{marginLeft:"7px"}}>Connection Request Sent</span>
                    <span className={getmenu.connectionStatus == true && getmenu.connection=="Recieved" ? "community-direct-nametxt11" : "hide"} style={{marginLeft:"7px"}}>Connection Request Recieved</span>


                  </div>
                  {/* <div className="col-2 cuminvite-div">  
             <RiCupFill color="#FEFDFA" size='1.3em'/>
          <span className="cominvite-text">Invite</span>
           </div> */}
                </div>
                <div className="row margintop1"></div>
              </div>
            ))
          }
          {
            commDirectory && commDirectory.length == 0 && (
              <div className='row margintop'>
                <div className='col-3'></div>
                <div className='col-5 margintop'> <span className='event-sub-detail'> Community Members Are not Available</span>
                </div>

              </div>
            )
          }
          {/* <div className="communit-cardfirst margintop3">
          <div className="row  cardfirst-toprow">
        <div className="col-3 margintop1"><span className="community-direct-nametxt">Swapnaili Naidu</span></div>
        <div className="col-1  textleft">
          <img src={flag} className='margintop2 marginleftmins1' />
        </div>
        <div className="col-4 marginleftmins4 margintop1"><span className="marginleftmins1 community-country">India</span><span className="communi-field1 marginleft1">Women Enterpreneur</span></div>
        <div className="col-1"></div>
        <div className="col-3 whitenowrap marginleft2 messg-div"> <RiSendPlaneFill size='1.3em' className='marginleftmins5'/>   Message Sagar
      </div>
          </div>

          <div className="row margintop3">
            <div className="col-3">
            <img src={profile}  className='communitydir-prof'/>
            </div>
            
            <div className="col-4 margintop1">
              <div className="row margintop2">
                <div className="col-1">             
                 <img src={imgupload}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">CID #</span>  <span className="comudrct-digitxt">0000001</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">   
                <MdWaterDrop size='1.3em' color='#555555'/>          
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Blood Group -</span>  <span className="comudrct-digitxt">B+ve</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={subcast}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Sub Cast -</span>  <span className="comudrct-digitxt">Balija Naidu</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={refferal}  className='communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Referrals #</span>  <span className="comudrct-digitxt">34</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <RiCupFill color="#555555" size='1.3em'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Cups of Coffee #</span>  <span className="comudrct-digitxt">112</span>
                </div>
           </div>
            </div>
            
            <div className="col-4 margintop1">
              <div className="row margintop2">
                <div className="col-1">             
                 <img src={from}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">From -</span>  <span className="comudrct-digitxt">Latur</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">   
                <MdLocationOn size='1.4em' color='#555555'/>          
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Lives in -</span>  <span className="comudrct-digitxt">Pune, Maharashtra</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={radius}  className='communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Distance radius -</span>  <span className="comudrct-digitxt">15 Kms approx</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                    <BsBookmark color="#555555" size='1.3em'/>         
                      </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Bookmark Profile </span>  <span className="comudrct-digitxt"></span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                <img src={contactinfo}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4  whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Contact Info</span>  <span className="comudrct-digitxt"></span>
                </div>
           </div>
            </div>
          </div>
          <div className="row margintop1">
            <div className="col-1"></div>
            <div className="col-2">
            <span className=" community-viewprof">View Profile</span>

            </div>
            <div className="col-5"></div>
            
            <div className="col-2  textcenter">
              <button className="com-conect-btn">Connect</button>
                       
           </div>
            <div className="col-2 cuminvite-div">  
             <RiCupFill color="#FEFDFA" size='1.3em'/>
          <span className="cominvite-text">Invite</span>
           </div>
       </div>
       <div className="row margintop1"></div>
        </div>

        <div className="communit-cardfirst margintop3">
          <div className="row  cardfirst-toprow">
        <div className="col-2 margintop1"><span className="community-direct-nametxt">Sagar Naidu</span></div>
        <div className="col-1  textleft">
          <img src={flag} className='margintop2 marginleftmins' />
        </div>
        <div className="col-4 margintop1"><span className="marginleftmins6 community-country">India</span><span className="communi-field marginleft1">Information Technology</span></div>
        <div className="col-2"></div>
        <div className="col-3 whitenowrap messg-div"> <RiSendPlaneFill size='1.3em' className='marginleftmins5'/>   Message Sagar
      </div>
          </div>

          <div className="row margintop3">
            <div className="col-3">
            <img src={profile}  className='communitydir-prof'/>
            </div>
            
            <div className="col-4 margintop1">
              <div className="row margintop2">
                <div className="col-1">             
                 <img src={imgupload}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">CID #</span>  <span className="comudrct-digitxt">0000001</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">   
                <MdWaterDrop size='1.3em' color='#555555'/>          
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Blood Group -</span>  <span className="comudrct-digitxt">B+ve</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={subcast}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Sub Cast -</span>  <span className="comudrct-digitxt">Balija Naidu</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={refferal}  className='communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Referrals #</span>  <span className="comudrct-digitxt">34</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <RiCupFill color="#555555" size='1.3em'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Cups of Coffee #</span>  <span className="comudrct-digitxt">112</span>
                </div>
           </div>
            </div>
            
            <div className="col-4 margintop1">
              <div className="row margintop2">
                <div className="col-1">             
                 <img src={from}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">From -</span>  <span className="comudrct-digitxt">Latur</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">   
                <MdLocationOn size='1.4em' color='#555555'/>          
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Lives in -</span>  <span className="comudrct-digitxt">Pune, Maharashtra</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={radius}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Distance radius -</span>  <span className="comudrct-digitxt">15 Kms approx</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                    <BsBookmark color="#555555" size='1.3em'/>         
                      </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Bookmark Profile </span>  <span className="comudrct-digitxt"></span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                <img src={contactinfo}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4  whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Contact Info</span>  <span className="comudrct-digitxt"></span>
                </div>
           </div>
            </div>
          </div>
          <div className="row margintop1">
            <div className="col-1"></div>
            <div className="col-2">
            <span className=" community-viewprof">View Profile</span>

            </div>
            <div className="col-3"></div>
            <div className="col-1">

            </div>
            <div className="col-3 textcenter">
              <span className="communityconnect-txt">Connect Request Sent</span>
            </div>
            <div className="col-2 cuminvite-div">  
             <RiCupFill color="#FEFDFA" size='1.3em'/>
          <span className="cominvite-text">Invite</span>
           </div>
       </div>
       <div className="row margintop1"></div>
        </div>
        <div className="communit-cardfirst margintop3">
          <div className="row  cardfirst-toprow">
        <div className="col-2 margintop1"><span className="community-direct-nametxt">Sagar Naidu</span></div>
        <div className="col-1  textleft">
          <img src={flag} className='margintop2 marginleftmins' />
        </div>
        <div className="col-4 margintop1"><span className="marginleftmins6 community-country">India</span><span className="communi-field marginleft1">Information Technology</span></div>
        <div className="col-2"></div>
        <div className="col-3 whitenowrap messg-div"> <RiSendPlaneFill size='1.3em' className='marginleftmins5'/>   Message Sagar
      </div>
          </div>

          <div className="row margintop3">
            <div className="col-3">
            <img src={profile}  className='communitydir-prof'/>
            </div>
            
            <div className="col-4 margintop1">
              <div className="row margintop2">
                <div className="col-1">             
                 <img src={imgupload}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">CID #</span>  <span className="comudrct-digitxt">0000001</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">   
                <MdWaterDrop size='1.3em' color='#555555'/>          
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Blood Group -</span>  <span className="comudrct-digitxt">B+ve</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={subcast}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Sub Cast -</span>  <span className="comudrct-digitxt">Balija Naidu</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={refferal}  className='communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Referrals #</span>  <span className="comudrct-digitxt">34</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <RiCupFill color="#555555" size='1.3em'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Cups of Coffee #</span>  <span className="comudrct-digitxt">112</span>
                </div>
           </div>
            </div>
            
            <div className="col-4 margintop1">
              <div className="row margintop2">
                <div className="col-1">             
                 <img src={from}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">From -</span>  <span className="comudrct-digitxt">Latur</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">   
                <MdLocationOn size='1.4em' color='#555555'/>          
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Lives in -</span>  <span className="comudrct-digitxt">Pune, Maharashtra</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={radius}  className='communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Distance radius -</span>  <span className="comudrct-digitxt">15 Kms approx</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                    <BsBookmark color="#555555" size='1.3em'/>         
                      </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Bookmark Profile </span>  <span className="comudrct-digitxt"></span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                <img src={contactinfo}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4  whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Contact Info</span>  <span className="comudrct-digitxt"></span>
                </div>
           </div>
            </div>
          </div>
          <div className="row margintop1">
            <div className="col-1"></div>
            <div className="col-2">
            <span className=" community-viewprof">View Profile</span>

            </div>
            <div className="col-3"></div>
            <div className="col-1">

            </div>
            <div className="col-3 textcenter">
              <span className="communityconnect-txt">Connect Request Sent</span>
            </div>
            <div className="col-2 cuminvite-div">  
             <RiCupFill color="#FEFDFA" size='1.3em'/>
          <span className="cominvite-text">Invite</span>
           </div>
       </div>
       <div className="row margintop1"></div>
        </div>

        <div className="communit-cardfirst margintop3">
          <div className="row  cardfirst-toprow">
        <div className="col-2 margintop1"><span className="community-direct-nametxt">Sagar Naidu</span></div>
        <div className="col-1  textleft">
          <img src={flag} className='margintop2 marginleftmins' />
        </div>
        <div className="col-4 margintop1"><span className="marginleftmins6 community-country">India</span><span className="communi-field marginleft1">Information Technology</span></div>
        <div className="col-2"></div>
        <div className="col-3 whitenowrap messg-div"> <RiSendPlaneFill size='1.3em' className='marginleftmins5'/>   Message Sagar
      </div>
          </div>

          <div className="row margintop3">
            <div className="col-3">
            <img src={profile}  className='communitydir-prof'/>
            </div>
            
            <div className="col-4 margintop1">
              <div className="row margintop2">
                <div className="col-1">             
                 <img src={imgupload}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">CID #</span>  <span className="comudrct-digitxt">0000001</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">   
                <MdWaterDrop size='1.3em' color='#555555'/>          
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Blood Group -</span>  <span className="comudrct-digitxt">B+ve</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={subcast}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Sub Cast -</span>  <span className="comudrct-digitxt">Balija Naidu</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={refferal}  className='communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Referrals #</span>  <span className="comudrct-digitxt">34</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <RiCupFill color="#555555" size='1.3em'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Cups of Coffee #</span>  <span className="comudrct-digitxt">112</span>
                </div>
           </div>
            </div>
            
            <div className="col-4 margintop1">
              <div className="row margintop2">
                <div className="col-1">             
                 <img src={from}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">From -</span>  <span className="comudrct-digitxt">Latur</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">   
                <MdLocationOn size='1.4em' color='#555555'/>          
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Lives in -</span>  <span className="comudrct-digitxt">Pune, Maharashtra</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={radius}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Distance radius -</span>  <span className="comudrct-digitxt">15 Kms approx</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                    <BsBookmark color="#555555" size='1.3em'/>         
                      </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Bookmark Profile </span>  <span className="comudrct-digitxt"></span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                <img src={contactinfo}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4  whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Contact Info</span>  <span className="comudrct-digitxt"></span>
                </div>
           </div>
            </div>
          </div>
          <div className="row margintop1">
            <div className="col-1"></div>
            <div className="col-2">
            <span className=" community-viewprof">View Profile</span>

            </div>
            <div className="col-3"></div>
            <div className="col-1">

            </div>
            <div className="col-3 textcenter">
              <span className="communityconnect-txt">Connect Request Sent</span>
            </div>
            <div className="col-2 cuminvite-div">  
             <RiCupFill color="#FEFDFA" size='1.3em'/>
          <span className="cominvite-text">Invite</span>
           </div>
       </div>
       <div className="row margintop1"></div>
        </div> */}
          <div className="row margintop1"></div>


        </div>
        <div className={matrimony ? "col-6 matrimon-div communitydiv heightforAll3Div" : "hide"}>
          <div className="row Matrimony-Top-div textcenter">
            <p>Community Matrimony Service</p>
          </div>

          <div className="row">
            <div className="text-wrap">
              <div className="border" style={{ marginRight: "-11px", marginLeft: "-11px" }}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="link-29">
                  <div className=" bg-gray-100 nav-bg horizontal-tabmenu ">

                    <Nav className="nav nav-tabs link-29 ">

                      <Nav.Item>
                        <Nav.Link eventKey="link-29" className="navlinkforverticaltab">
                          All Profiles
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="link-30" className="navlinkforverticaltab">
                          My Profile
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="link-31" className="navlinkforverticaltab">
                          Shortlist
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="link-32" className="navlinkforverticaltab">
                          My Subscription
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>

                  </div>

                  <Tab.Content className="card-body ">
                    <Tab.Pane eventKey="link-29">
                      <div className="">

                      <Tab.Pane eventKey="link-29">
                      <div className="">

                        <Tab.Container id="left-tabs-example" defaultActiveKey="link-29">
                          <div className='row'>
                            <div className="col-3">
                              <select id="inputState" name=''
                                className=" form-control" style={{ height: "34px", fontSize: "11px" }}>
                                <option selected value="">All 1502 profile</option>
                                <option value="pending response">Brides 750</option>
                                <option value="accepted invitations">Grooms 752</option>
                              </select>
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: "20px" }}>
                            
                            {
                              matrimonyAllProf.map((getmenu, index) => (
                                <div className="myproffile-mtr-div">
                                <Card className={ authlocalstoragedata.matrimonyId==0?"blurcardmat":"margintop3 "} style={{ borderColor: "#B5924C", }} key={getmenu.matrimonyId}>
                                  <div className="row textcenter">
                                    <div className="col-3 margintop3">
                                      <img src={getmenu.profilePhotoPath} id='profpicdisablesave10' className='margintop3 blurcardmatprof' style={{width:"140px",borderRadius:"48%"}}/>
                                      <div className="" style={{ marginLeft: '7px' }}>
                                       {getmenu.bookmarkStatus==1?
                                       (<AiFillHeart size="1.4em" onClick={() => addShortList(getmenu.matrimonyId)} style={{cursor:"pointer"}}/>):
                                       (<AiOutlineHeart size="1.4em" onClick={() => addShortList(getmenu.matrimonyId)} style={{cursor:"pointer"}} />)

                                      } <span style={{ fontSize: "14px", color: "#333333", fontWeight: "700", marginLeft: "7px" }}>{getmenu.name}</span>
                                        </div>
                                      <div className="" style={{ marginLeft: '0px' }}>
                                      </div>

                                    </div>

                                    <div className="col-4 ">
                                      <div className="row margintop3">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text">Caste</span></div>
                                        <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.caste}</span></div>
                                      </div>
                                      <div className="row margintop5">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text">Lives In</span></div>
                                        <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.livesIn}</span></div>
                                      </div>
                                      <div className="row margintop5">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text">From</span></div>
                                        <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.from}</span></div>
                                      </div>
                                      <div className="row margintop5">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text">Height</span></div>
                                        <div className="col-6 textleft"><span className="comudrct-digitxt">{getmenu.height}</span></div>
                                      </div>
                                      <div className="row margintop5">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text">Weight</span></div>
                                        <div className="col-6 textleft"><span className="comudrct-digitxt">{getmenu.weight}</span></div>
                                      </div>


                                    </div>
                                    <div className="col-5">
                                      <div className="row margintop3">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{ whiteSpace: "nowrap" }}>Mother Tongue</span></div>
                                        <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.motherTongue}</span></div>
                                      </div>

                                      <div className="row margintop5">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text">DOB</span></div>
                                        <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.dob}</span></div>
                                      </div>
                                      <div className="row margintop5">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{ whiteSpace: "nowrap", marginLeft: "" }}>Edu. Qualification</span></div>
                                        <div className="col-6 textleft"><span className="comudrct-digitxt" style={{ whiteSpace: "nowrap" }}>{getmenu.eduQualification}</span></div>
                                      </div>
                                      {/* <div className="row margintop5">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text">Working</span></div>
                                        <div className="col-6 textleft"><span className="comudrct-digitxt">{getmenu.working}</span></div>
                                      </div> */}
                                      <div className="row margintop5">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{ whiteSpace: "nowrap" }}>Specially Abled</span></div>
                                        <div className="col-6 textleft"><span className="comudrct-digitxt">{getmenu.speciallyAbled}</span></div>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="row textright">
                                    <div style={{left:"97%"}}>
                                    <Link to={`/MatrimonyViewProf/${getmenu.matrimonyId}`} ><p className="bluetextforlink" style={{ marginTop: "1px" }} >View Profile</p></Link>

                                    </div>
                                  </div>
                                </Card>
                                </div>

                              )
                              )}
                              
                            {/* <Card className="margintop3" style={{borderColor:"#B5924C",}}>
                        <div className="row textcenter">
                          <div className="col-3 margintop3">
                          <img src={profileshital}  className='margintop3'/>
                          <div className=""style={{marginLeft:'7px'}}><AiOutlineHeart size="1.2em"/><span style={{fontSize:"14px",color:"#333333",fontWeight:"700",marginLeft:"7px"}}>Shital Naidu</span></div>
                          <div className=""style={{marginLeft:'0px'}}><span style={{fontSize:"10px",color:"#333333",fontWeight:"400",marginLeft:"5px"}}>Never Married Age 32</span></div>

                          </div>
                          
                          <div className="col-4 ">
                            <div className="row margintop3">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Caste</span></div>
                              <div className="col-6 textleft"style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Balija Naidu</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Lives In</span></div>
                              <div className="col-6 textleft"style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Vijaywada</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">From</span></div>
                              <div className="col-6 textleft" style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Vijaywada</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Height</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">5'6"</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Weight</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">55</span></div>
                              </div>
                              
                          
                          </div>
                          <div className="col-5">
                          <div className="row margintop3">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap"}}>Mother Tongue</span></div>
                              <div className="col-6 textleft"style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Telugu</span></div>
                              </div>
                              
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">DOB</span></div>
                              <div className="col-6 textleft" style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">19 October 2000</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap",marginLeft:""}}>Edu. Qualification</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt"style={{whiteSpace:"nowrap"}}>BE Architecture</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Working</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">No</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap"}}>Specially Abled</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">No</span></div>
                              </div>
                          </div>

                        </div>
                        <div className="row textright"><p className="bluetextforlink" style={{marginTop:"-11px"}}>View Profile</p></div>
                      </Card>
                      <Card className="margintop3" style={{borderColor:"#B5924C",}}>
                        <div className="row textcenter">
                          <div className="col-3 margintop3">
                          <img src={profileshital}  className='margintop3'/>
                          <div className=""style={{marginLeft:'7px'}}><AiOutlineHeart size="1.2em"/><span style={{fontSize:"14px",color:"#333333",fontWeight:"700",marginLeft:"7px"}}>Shital Naidu</span></div>
                          <div className=""style={{marginLeft:'0px'}}><span style={{fontSize:"10px",color:"#333333",fontWeight:"400",marginLeft:"5px"}}>Never Married Age 32</span></div>

                          </div>
                          
                          <div className="col-4 ">
                            <div className="row margintop3">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Caste</span></div>
                              <div className="col-6 textleft"style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Balija Naidu</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Lives In</span></div>
                              <div className="col-6 textleft"style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Vijaywada</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">From</span></div>
                              <div className="col-6 textleft" style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Vijaywada</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Height</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">5'6"</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Weight</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">55</span></div>
                              </div>
                              
                          
                          </div>
                          <div className="col-5">
                          <div className="row margintop3">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap"}}>Mother Tongue</span></div>
                              <div className="col-6 textleft"style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Telugu</span></div>
                              </div>
                              
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">DOB</span></div>
                              <div className="col-6 textleft" style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">19 October 2000</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap",marginLeft:""}}>Edu. Qualification</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt"style={{whiteSpace:"nowrap"}}>BE Architecture</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Working</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">No</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap"}}>Specially Abled</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">No</span></div>
                              </div>
                          </div>

                        </div>
                        <div className="row textright"><p className="bluetextforlink" style={{marginTop:"-11px"}}>View Profile</p></div>
                      </Card>
                      <Card className="margintop3" style={{borderColor:"#B5924C",}}>
                        <div className="row textcenter">
                          <div className="col-3 margintop3">
                          <img src={profileshital}  className='margintop3'/>
                          <div className=""style={{marginLeft:'7px'}}><AiOutlineHeart size="1.2em"/><span style={{fontSize:"14px",color:"#333333",fontWeight:"700",marginLeft:"7px"}}>Shital Naidu</span></div>
                          <div className=""style={{marginLeft:'0px'}}><span style={{fontSize:"10px",color:"#333333",fontWeight:"400",marginLeft:"5px"}}>Never Married Age 32</span></div>

                          </div>
                          
                          <div className="col-4 ">
                            <div className="row margintop3">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Caste</span></div>
                              <div className="col-6 textleft"style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Balija Naidu</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Lives In</span></div>
                              <div className="col-6 textleft"style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Vijaywada</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">From</span></div>
                              <div className="col-6 textleft" style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Vijaywada</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Height</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">5'6"</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Weight</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">55</span></div>
                              </div>
                              
                          
                          </div>
                          <div className="col-5">
                          <div className="row margintop3">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap"}}>Mother Tongue</span></div>
                              <div className="col-6 textleft"style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Telugu</span></div>
                              </div>
                              
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">DOB</span></div>
                              <div className="col-6 textleft" style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">19 October 2000</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap",marginLeft:""}}>Edu. Qualification</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt"style={{whiteSpace:"nowrap"}}>BE Architecture</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Working</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">No</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap"}}>Specially Abled</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">No</span></div>
                              </div>
                          </div>

                        </div>
                        <div className="row textright"><p className="bluetextforlink" style={{marginTop:"-11px"}}>View Profile</p></div>
                      </Card> */}
                          </div>


                        </Tab.Container>
                      </div>
                    </Tab.Pane>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="link-30">
                      
                      {
                        matrimonyMyProf.map((getmenu, index) => (
                          <div className="myproffile-mtr-div">
                            <div className="row myproffile-mtr-div" style={{ marginTop: "20px" }}>
                              
                              <div className="col-12">
                              <div className={getmenu.profileDetails ? "backgroundcolor-card-matrimony" : "hide"} style={{ borderColor: "", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px" ,backgroundColor:"#FFFFFF",paddingBottom:"14px",marginTop:"15px"}}>
                                <div className="row margintop1">
                                  <div className="col-3"></div>
                                  <div className="col-8">
                                    <span style={{ fontSize: "14px", color: "#222227", fontWeight: "700", marginLeft: "7px" }}>{getmenu.profileDetails?.name}</span>
                                    <span style={{ fontSize: "10px", color: "#222227", fontWeight: "400", marginLeft: "9px" }}>{getmenu.profileDetails?.maritalStatus} Age {getmenu.profileDetails?.age}</span>
                                  </div>



                                </div>
                                <div className="row textcenter">
                                  <div className="col-3 margintop3">
                                    <img src={getmenu.profilePhotoPath} id='profpicdisablesave11' className='margintop3' />

                                  </div>

                                  <div className="col-4 ">
                                    <div className="row margintop">
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Caste</span></div>
                                      <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.profileDetails?.caste}</span></div>
                                    </div>
                                    <div className="row margintop5">
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Religion</span></div>
                                      <div className="col-6 textleft" style={{ whiteSpace: "nowrap",color:"#797979" }} ><span className="comudrct-digitxt">Hindu</span></div>
                                    </div>
                                    <div className="row margintop5">
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Lives In</span></div>
                                      <div className="col-6 textleft" style={{ whiteSpace: "nowrap",color:"#797979" }} ><span className="comudrct-digitxt">{getmenu.profileDetails?.livesIn}</span></div>
                                    </div>
                                    <div className="row margintop5">
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>From</span></div>
                                      <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.profileDetails?.from}</span></div>
                                    </div>
                                    <div className="row margintop5">
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{color:"#797979"}}>Height</span></div>
                                      <div className="col-6 textleft"><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.profileDetails?.height}</span></div>
                                    </div>
                                    <div className={getmenu.healthDetails?"row margintop5":"hide"}>
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Weight</span></div>
                                      <div className="col-6 textleft"><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.healthDetails?.weight}</span></div>
                                    </div>


                                  </div>
                                  <div className="col-5">
                                    <div className={getmenu.basicDetails?"row margintop":"hide"}>
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{ whiteSpace: "nowrap",color:"#797979" }} >Mother Tongue</span></div>
                                      <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.basicDetails?.motherTongue}</span></div>
                                    </div>

                                    <div className="row margintop5">
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text">DOB</span></div>
                                      <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt" >{getmenu.profileDetails?.dob}</span></div>
                                    </div>
                                    <div className="row margintop5">
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{ whiteSpace: "nowrap", marginLeft: "",color:"#797979" }} >Edu. Qualification</span></div>
                                      <div className="col-6 textleft"><span className="comudrct-digitxt" style={{ whiteSpace: "nowrap",color:"#797979" }} >{getmenu.profileDetails?.eduQualification}</span></div>
                                    </div>
                                    {/* <div className="row margintop5">
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text">Working</span></div>
                                      <div className="col-6 textleft"><span className="comudrct-digitxt">No</span></div>
                                    </div> */}
                                    <div className="row margintop5">
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{ whiteSpace: "nowrap" ,color:"#797979"}}>Specially Abled</span></div>
                                      <div className="col-6 textleft"><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.profileDetails?.speciallyAbled}</span></div>
                                    </div>
                                  </div>

                                </div>
                                {/* <div className="row textright"><p className="bluetextforlink" style={{marginTop:"-11px"}}>View Profile</p></div> */}
                              </div>
                              </div>
                              
                            </div>
                            <div className="row margintop3">
                              <div className="col-lg-6 col-sm-12 col-md-12">
                                <div className=""><span style={{ fontSize: "14px", color: "#222227", fontWeight: "700", marginLeft: "0px" }}>Basic Information <Link to={`/EditBasicDetail/${getmenu.basicDetails.matrimonyId}`}><FaEdit size='1.2em' color="#222227" style={{ marginLeft: '10px' }} /></Link></span></div>
                                <div className={getmenu.basicDetails ? "backgroundcolor-card-matrimony margintop1" : "hide"} style={{ borderColor: "", paddingRight: "",backgroundColor:"#ffffff",paddingBottom:"14px" }}>
                                  <div className="row margintop1">
                                    <div className="col-lg-5 col-sm-6 col-md-6 textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Profile Created By</span></div>
                                    <div className="col-lg-7  col-sm-6 col-md-6 textleft" style={{ marginLeft: "0px" }}><span className="comudrct-digitxt" style={{ marginLeft: "-1px" }}>{getmenu.basicDetails?.name}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Email ID</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ marginLeft: "0px" }}><span className="comudrct-digitxt" style={{ marginLeft: "-1" }}>{getmenu.basicDetails?.emailId}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Gender</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ marginLeft: "0px" }}><span className="comudrct-digitxt">{getmenu.basicDetails?.gender}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Blood Grp:</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ marginLeft: "0px" }}><span className="comudrct-digitxt" style={{ marginLeft: "-1px" }}>{getmenu.basicDetails?.bloodGroup}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Mother Tongue</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt" style={{ marginLeft: "0px" }}>{getmenu.basicDetails?.motherTongue}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Marital Status</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ marginLeft: "0" }}><span className="comudrct-digitxt">{getmenu.basicDetails?.maritalStatus} </span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Primary Mobile No:</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ marginLeft: "0px" }}><span className="comudrct-digitxt" style={{ marginLeft: "-1px" }}>{getmenu.basicDetails?.primaryMobileNumber} <FaWhatsapp /></span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Secondary Mobile No:</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ marginLeft: "0px" }}><span className="comudrct-digitxt" style={{ marginLeft: "-1px" ,color:"#797979"}}>{getmenu.basicDetails?.secondaryMobileNumber}</span></div>
                                  </div>

                                </div>
                              </div>

                              <div className="col-lg-6 col-sm-12 col-md-12">
                                <div className=""><span style={{ fontSize: "14px", color: "#F2F2F2", fontWeight: "700", marginLeft: "0px", color:"#222227"}}>Educations Details <Link to={`/EditEducationDetail/${getmenu.educationDetails.matrimonyId}`}><FaEdit size='1.2em' color="#222227" style={{ marginLeft: '10px' }} /></Link> </span></div>

                                <div className={getmenu.educationDetails ? "backgroundcolor-card-matrimony margintop1" : "hide"} style={{ borderColor: "",backgroundColor:"#ffffff",paddingBottom:"14px" }}>
                                  <div className="row margintop1">
                                    <div className="col-lg-7 col-sm-6 col-md-6 textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Education</span></div>
                                    <div className="col-lg-5  col-sm-6 col-md-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.educationDetails?.education}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Education in detail</span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft" style={{ whiteSpace: "" }}><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.educationDetails?.education}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Occupation</span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft" style={{ whiteSpace: "" }}><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.educationDetails?.occupation} </span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Employment Type</span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.educationDetails?.employmentType}</span></div>
                                  </div>

                                  <div className="row margintop5">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}> Annual Income</span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt"style={{color:"#797979"}}>{getmenu.educationDetails?.annualIncome}</span></div>
                                  </div>
                                  <div className="row margintop">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text"> </span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt"></span></div>
                                  </div>
                                  <div className="row margintop">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text"> </span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt"></span></div>
                                  </div>
                                  <div className="row margintop">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text"> </span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt"></span></div>
                                  </div>
                                  <div className="row margintop">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text"> </span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt"></span></div>
                                  </div>
                                  <div className="row margintop">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text"> </span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt"></span></div>
                                  </div>
                                  <div className="row margintop">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text"> </span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt"></span></div>
                                  </div>
                                  <div className="row margintop3"></div>


                                </div>
                              </div>
                            </div>

                            <div className="row margintop3">
                              <div className="col-lg-6 col-sm-12 col-md-12">
                                <div className=""><span style={{ fontSize: "14px", color: "#222227", fontWeight: "700", marginLeft: "0px" }}>Features and Health <Link to={`/EditHealthDetail/${getmenu.healthDetails.matrimonyId}`}><FaEdit size='1.2em' color="#222227" style={{ marginLeft: '10px' }} /></Link></span></div>
                                <div className={getmenu.healthDetails ? "backgroundcolor-card-matrimony margintop1" : "hide"} style={{ borderColor: "",backgroundColor:"#ffffff" ,paddingBottom:"14px"}}>
                                  <div className="row margintop1">
                                    <div className="col-lg-6 col-sm-6 col-md-6 textleft"><span className="communtdrc-cit-text">Height</span></div>
                                    <div className="col-lg-6  col-sm-6 col-md-6 textleft" style={{}}><span className="comudrct-digitxt">{getmenu.healthDetails?.heightFeet} ft {getmenu.healthDetails?.heightInches} inch</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Weight</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft" style={{}}><span className="comudrct-digitxt">{getmenu.healthDetails?.weight} Kg</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Skin Tone</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.healthDetails?.skinTone}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Body Type</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft" style={{}}><span className="comudrct-digitxt">{getmenu.healthDetails?.bodyType} </span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text"> Health Information</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.healthDetails?.healthInformation}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Disability</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.healthDetails?.disability}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Glasses</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.healthDetails?.glasses}</span></div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-12 col-md-12">
                                <div className=""><span style={{ fontSize: "14px", color: "#222227", fontWeight: "700", marginLeft: "0px" }}>Horoscope Details <Link to={`/EditHoroscopeDetail/${getmenu.horoscopeDetails.matrimonyId}`}><FaEdit size='1.2em' color="#222227" style={{ marginLeft: '10px' }} /></Link></span></div>

                                <div className={getmenu.horoscopeDetails ? "backgroundcolor-card-matrimony margintop1" : "hide"} style={{ borderColor: "" ,backgroundColor:"#ffffff",paddingBottom:"14px"}}>
                                  <div className="row margintop1">
                                    <div className="col-lg-6 col-sm-6 col-md-6 textleft"><span className="communtdrc-cit-text">DOB</span></div>
                                    <div className="col-lg-6  col-sm-6 col-md-6 textleft" style={{ whiteSpace: "norap" }}><span className="comudrct-digitxt">{getmenu.horoscopeDetails?.dob}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Time</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft" style={{ whiteSpace: "" }}><span className="comudrct-digitxt">{getmenu.horoscopeDetails?.time}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Place of Birth</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft" style={{ whiteSpace: "" }}><span className="comudrct-digitxt">{getmenu.horoscopeDetails?.placeOfBirth} </span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Rashi</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.horoscopeDetails?.raashi}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Goatra</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.horoscopeDetails?.goatra}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Nakshatra</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.horoscopeDetails?.nakshatra}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Manglik</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.horoscopeDetails?.manglik}</span></div>
                                  </div>
                                  <div className="row margintop">
                                  </div>

                                </div>
                              </div>

                            </div>
                            <div className="row margintop3">
                              <div className="col-lg-6 col-sm-12 col-md-12">
                                <div className=""><span style={{ fontSize: "14px", color: "#222227", fontWeight: "700", marginLeft: "0px" }}>Lifestyle<Link to={`/EditLifeStyleDetail/${getmenu.lifestyleDetails.matrimonyId}`}><FaEdit size='1.2em' color="#222227" style={{ marginLeft: '10px' }} /></Link></span></div>
                                <div className={getmenu.lifestyleDetails ? "backgroundcolor-card-matrimony margintop1" : "hide"} style={{ borderColor: "",backgroundColor:"#ffffff",paddingBottom:"14px" }}>
                                  <div className="row margintop1">
                                    <div className="col-lg-6 col-sm-6 col-md-6 textleft"><span className="communtdrc-cit-text">Family Values</span></div>
                                    <div className="col-lg-6  col-sm-6 col-md-6 textleft" style={{}}><span className="comudrct-digitxt">{getmenu.lifestyleDetails?.familyValues}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Diet</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft" style={{}}><span className="comudrct-digitxt">{getmenu.lifestyleDetails?.diet}</span></div>
                                  </div>
                                  {/* <div className="row margintop5">
                              <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Skin Tone</span></div>
                              <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.lifestyleDetails?.diet}</span></div>
                              </div> */}
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Smoke</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft" style={{}}><span className="comudrct-digitxt">{getmenu.lifestyleDetails?.smokes} </span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Drinks</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.lifestyleDetails?.drinks}</span></div>
                                  </div>
                                  <div className="row margintop3">

                                  </div>
                                  <div className="row margintop">

                                  </div>
                                  <div className="row margintop">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text"> </span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt"></span></div>
                                  </div><div className="row margintop">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text"> </span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt"></span></div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-12 col-md-12">
                                <div className=""><span style={{ fontSize: "14px", color: "#222227", fontWeight: "700", marginLeft: "0px" }}>About Yourself <Link to={`/EditAboutYourself/${authlocalstoragedata?.matrimonyId}`}><FaEdit size='1.2em' color="#222227" style={{ marginLeft: '10px' }} /></Link></span></div>

                                <div className="backgroundcolor-card-matrimony margintop1" style={{ borderColor: "",backgroundColor:"#ffffff" ,paddingBottom:"14px"}}>
                                  <div className="row margintop1">
                                    <div className="col-lg-7 col-sm-6 col-md-6 textleft"><span className="communtdrc-cit-text">About Yourself</span></div>
                                    <div className="col-lg-5  col-sm-6 col-md-6 textleft" style={{ whiteSpace: "norap" }}><span className="comudrct-digitxt">{getmenu.aboutYourself}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Time</span></div>
                              <div className="col-lg-5 col-sm-6 col-md-6  textleft"style={{whiteSpace:""}}><span className="comudrct-digitxt">12:40 PM</span></div> */}
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Place of Birth</span></div>
                              <div className="col-lg-5 col-sm-6 col-md-6  textleft" style={{whiteSpace:""}}><span className="comudrct-digitxt">Pune </span></div> */}
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Rashi</span></div>
                              <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">Vrish</span></div> */}
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Goatra</span></div>
                              <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">Atri</span></div> */}
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Nakshatra</span></div>
                              <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">Ashwini</span></div> */}
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Manglik</span></div>
                              <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">No</span></div> */}
                                  </div>
                                  <div className="row margintop">
                                  </div>
                                  <div className="row margintop">
                                  </div>
                                  <div className="row margintop">
                                  </div>
                                  <div className="row margintop">
                                  </div>

                                  <div className="row margintop3">
                                  </div>

                                </div>
                              </div>

                            </div>
                            <div className="row margintop3">
                              <div className="col-lg-6 col-sm-12 col-md-12">
                                <div className=""><span style={{ fontSize: "14px", color: "#222227", fontWeight: "700", marginLeft: "0px" }}>Family Background<Link to={`/EditFamilybackground/${authlocalstoragedata?.matrimonyId}`}><FaEdit size='1.2em' color="#222227" style={{ marginLeft: '10px' }} /></Link></span></div>
                                <div className="backgroundcolor-card-matrimony margintop1" style={{ borderColor: "",backgroundColor:"#ffffff",paddingBottom:"14px" }}>
                                  <div className="row margintop1">
                                    <div className="col-lg-6 col-sm-7 col-md-6 textleft"><span className="communtdrc-cit-text">Family Background</span></div>
                                    <div className="col-lg-6  col-sm-5 col-md-6 textleft" style={{}}><span className="comudrct-digitxt">{getmenu.familyBackground}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Diet</span></div>
                              <div className="col-lg-6 col-sm-6 col-md-6  textleft"style={{}}><span className="comudrct-digitxt">Vegetarian</span></div> */}
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Skin Tone</span></div>
                              <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">Fair</span></div> */}
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Smoke</span></div>
                              <div className="col-lg-6 col-sm-6 col-md-6  textleft" style={{}}><span className="comudrct-digitxt">No </span></div> */}
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Drinks</span></div>
                              <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">No</span></div> */}
                                  </div>
                                  <div className="row margintop5">

                                  </div>
                                  <div className="row margintop1">

                                  </div>
                                  <div className="row margintop"></div>
                                  <div className="row margintop"></div>
                                  <div className="row margintop"></div>
                                  <div className="row margintop"></div>
                                  <div className="row margintop"></div>
                                  <div className="row margintop"></div>
                                  <div className="row margintop"></div>

                                  <div className="row margintop"></div>


                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-12 col-md-12">
                                <div className=""><span style={{ fontSize: "14px", color: "#222227", fontWeight: "700", marginLeft: "0px" }}>Partner Preference<Link to={`/EditPreferenceDetail/${getmenu.preferenceDetails.matrimonyId}`}><FaEdit size='1.2em' color="#222227" style={{ marginLeft: '10px' }} /></Link></span></div>

                                <div className={getmenu.preferenceDetails ? "backgroundcolor-card-matrimony margintop1" : "hide"} style={{ borderColor: "",backgroundColor:"#ffffff",paddingBottom:"14px" }}>
                                  <div className="row margintop1">
                                    <div className="col-lg-5 col-sm-6 col-md-6 textleft"><span className="communtdrc-cit-text">Education</span></div>
                                    <div className="col-lg-7  col-sm-6 col-md-6 textleft" style={{ whiteSpace: "norap" }}><span className="comudrct-digitxt">{getmenu.preferenceDetails?.education}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Complexion</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ whiteSpace: "" }}><span className="comudrct-digitxt">{getmenu.preferenceDetails?.complexion}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Caste</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ whiteSpace: "" }}><span className="comudrct-digitxt">{getmenu.preferenceDetails?.caste}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Age From</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ whiteSpace: "nowrap" }}>
                                      <div className="row" >
                                        <div className="col-3" style={{marginLeft:"-15px"}}>
                                          <span className="comudrct-digitxt">{getmenu.preferenceDetails?.ageFrom}</span>
                                        </div>
                                        <div className="col-2"> <span className="comudrct-digitxt">To</span></div>
                                        <div className="col-3">
                                          <span className="comudrct-digitxt">{getmenu.preferenceDetails?.ageTo}</span>

                                        </div>
                                        <div className="col-2"><span className="comudrct-digitxt">Yrs</span></div>

                                      </div>



                                    </div>
                                  </div>

                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Height From</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ whiteSpace: "nowrap" }}>
                                      <div className="row">
                                        <div className="col-4" style={{marginLeft:"-15px"}}>
                                          <span className="comudrct-digitxt">{getmenu.preferenceDetails?.heightFeetFrom} ft</span>
                                        </div>
                                        <div className="col-4">
                                          <span className="comudrct-digitxt">{getmenu.preferenceDetails?.heightInchesFrom} inch</span>

                                        </div>

                                      </div>



                                    </div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Height to</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ whiteSpace: "nowrap" }}>
                                      <div className="row">
                                        <div className="col-4" style={{marginLeft:"-15px"}}>
                                          <span className="comudrct-digitxt">{getmenu.preferenceDetails?.heightFeetTo} ft</span>
                                        </div>
                                        <div className="col-4">
                                          <span className="comudrct-digitxt">{getmenu.preferenceDetails?.heightInchesTo} inch</span>

                                        </div>

                                      </div>



                                    </div>
                                  </div>

                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Other Expectations</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.preferenceDetails?.otherExpectations}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Manglik</span></div>
                              <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">No</span></div> */}
                                  </div>
                                  <div className="row margintop"></div>

                                  <div className="row margintop3">
                                  </div>

                                </div>
                              </div>

                            </div>
                            <div className="row margintop3">
                            <div className=""><span style={{ fontSize: "14px", color: "#222227", fontWeight: "700", marginLeft: "0px" }}>Album<Link to={`/EditAlbum/${authlocalstoragedata?.matrimonyId}`}><FaEdit size='1.2em' color="#222227" style={{ marginLeft: '10px' }} /></Link></span></div>
                            <div className={getmenu.profilePhoto !=null ||getmenu.profilePhoto !=""?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                          
                          <div className="backgroundcolor-card-matrimony margintop1" style={{borderColor:"",border:"none",backgroundColor:"#ffffff",paddingBottom:"0px",height:"200px"}}>
                         
                                <div className="row margintop1">
                                <img src={getmenu.profilePhoto} id='profpicdisablesavematmyprof1' className='' style={{width:"235px"}}/>
                  <div className="comudrct-digitxt">Profile Photo</div>
                                </div>
                                
                                
                                
                              
  
        </div>
                          </div>
                        <div className={getmenu.photoOnePath !=null ||getmenu.photoOnePath !=""?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                          
                        <div className="backgroundcolor-card-matrimony margintop1" style={{borderColor:"",border:"none",backgroundColor:"#ffffff" ,paddingBottom:"0px",height:"200px"}}>
                       
                              <div className="row margintop1">
                              <img src={getmenu.photoOnePath} id='profpicdisablesavematmyprof1' className='' style={{width:"235px"}}/>

                              </div>
                              
                              
                              
                            

      </div>
                        </div>
                        <div className={getmenu.photoTwoPath !=null ||getmenu.photoTwoPath !="" ?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                        <div className=""><span style={{fontSize:"14px",color:"#F2F2F2",fontWeight:"700",marginLeft:"0px"}}></span></div>

                        <div className="backgroundcolor-card-matrimony margintop1" style={{borderColor:"",border:"none",backgroundColor:"#ffffff",paddingBottom:"0px",height:"200px"}}>
                        
                              
                              <div className="row margintop1">
                              <img src={getmenu.photoTwoPath} id='profpicdisablesavematmyprof2'  className='margintop1' style={{width:"235px"}}/>

                              </div>
                          
                                

                          </div>
                        </div>
                        
                        
                      </div>
                      <div className="row margintop1">
                      <div className={getmenu.photoThreePath !=null ||getmenu.photoThreePath !="" ?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                          <div className=""><span style={{fontSize:"14px",color:"#F2F2F2",fontWeight:"700",marginLeft:"0px"}}></span></div>
                        <div className="backgroundcolor-card-matrimony margintop1" style={{borderColor:"",border:"none",backgroundColor:"#ffffff",paddingBottom:"0px",height:"200px"}}>
                       
                              <div className="row margintop1">
                              <img src={getmenu.photoThreePath} id='profpicdisablesavematmyprof3' className=''style={{width:"235px"}}/>

                              </div>
                              
                              
                    </div>
                        </div>
                        <div className={getmenu.photoFourPath !=null|| getmenu.photoFourPath !=""?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                        <div className=""><span style={{fontSize:"14px",color:"#F2F2F2",fontWeight:"700",marginLeft:"0px"}}></span></div>

                        <div className="backgroundcolor-card-matrimony margintop1" style={{borderColor:"",border:"none",backgroundColor:"#ffffff",paddingBottom:"0px",height:"200px"}}>
                        
                              
                              <div className="row margintop1">
                              <img src={getmenu.photoFourPath} id="profpicdisablesavematmyprof4" className='margintop1'/>

                              </div>
                          
                                

                          </div>
                        </div>
                        
                        
                      </div>
                          </div>
                        )
                        )}

                    </Tab.Pane>
                    <Tab.Pane eventKey="link-31">
                      <div className='row margintop3' style={{ whiteSpace: "" }}>
                        <div className="col-1 textright">
                          <span className="communtdrc-cit-text">Showing</span>
                        </div>
                        <div className="col-2 ">
                          <select id="inputState" name=''
                            className=" form-control" style={{ height: "32px", fontSize: "11px", marginLeft: "5px" }}>
                            <option selected value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>

                          </select>
                        </div>
                        <div className="col-4 textleft" style={{ marginLeft: "-11px" }}>
                          <span className="communtdrc-cit-text">0f 34 shortlisted profiles</span>
                        </div>
                        <div className="col-2"></div>
                        <div className="col-3">
                          <div class="form-group has-search ">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input type="text" class="form-control" placeholder="Search" onChange={handleSearchForShortMatrimony} style={{ fontSize: '12px' }} />
                          </div>
                        </div>

                      </div>
                      <div className="row" style={{ marginTop: "10px" }}>

                        {
                          matrimonyShortProf.map((getmenu, index) => (
                            <Card className="margintop3" style={{ borderColor: "#B5924C", }} key={getmenu.matrimonyId}>
                              <div className="row textcenter">
                                <div className="col-3 margintop3">
                                  <img src={getmenu.profilePhotoPath} id='profpicdisablesave16' oncontextmenu="return false" className='margintop3' style={{width:"140px",borderRadius:"48%"}} />
                                  <div className="" style={{ marginLeft: '7px' }}> <AiFillHeart size="1.4em" /><span style={{ fontSize: "14px", color: "#333333", fontWeight: "700", marginLeft: "7px" }}>{getmenu.name}</span></div>
                                  <div className="" style={{ marginLeft: '0px' }}><span style={{ fontSize: "10px", color: "#333333", fontWeight: "400", marginLeft: "5px" }}>{getmenu.maritalStatus} Age {getmenu.age}</span></div>

                                </div>

                                <div className="col-4 ">
                                  <div className="row margintop3">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text">Caste</span></div>
                                    <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.caste}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text">Lives In</span></div>
                                    <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.livesIn}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text">From</span></div>
                                    <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.from}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text">Height</span></div>
                                    <div className="col-6 textleft"><span className="comudrct-digitxt">{getmenu.height}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text">Weight</span></div>
                                    <div className="col-6 textleft"><span className="comudrct-digitxt">{getmenu.weight}</span></div>
                                  </div>


                                </div>
                                <div className="col-5">
                                  <div className="row margintop3">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{ whiteSpace: "nowrap" }}>Mother Tongue</span></div>
                                    <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.motherTongue}</span></div>
                                  </div>

                                  <div className="row margintop5">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text">DOB</span></div>
                                    <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.dob}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{ whiteSpace: "nowrap", marginLeft: "" }}>Edu. Qualification</span></div>
                                    <div className="col-6 textleft"><span className="comudrct-digitxt" style={{ whiteSpace: "nowrap" }}>{getmenu.eduQualification}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text">Working</span></div>
                                    <div className="col-6 textleft"><span className="comudrct-digitxt">{getmenu.working}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{ whiteSpace: "nowrap" }}>Specially Abled</span></div>
                                    <div className="col-6 textleft"><span className="comudrct-digitxt">{getmenu.speciallyAbled}</span></div>
                                  </div>
                                </div>

                              </div>
                              <div className="row textright">
                                    <div style={{left:"97%"}}>
                                    <Link to={`/MatrimonyViewProf/${getmenu.matrimonyId}`} ><p className="bluetextforlink" style={{ marginTop: "1px" }} >View Profile</p></Link>

                                    </div>
                                  </div>                            </Card>
                          )
                          )}
                        
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="link-32">
                      <div className="row tblclassmatsubscrip" style={{ marginTop: "20px" }}>
                        <Card className="margintop3" style={{ borderColor: "#B5924C", }}>
                          <div className="margintop3" style={{ marginLeft: '7px' }}><span style={{ fontSize: "14px", color: "#333333", fontWeight: "700", marginLeft: "7px" }}>Matrimony Subscription</span></div>

                          <div className="table-responsive fileexport pos-relative">
                            <MySubscriptionMatrimony />
                          </div>
                        </Card>


                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>

          </div>
        </div>
        <div className={businessDirect ? "col-6 matrimon-div heightforAll3Div communitydiv" : "hide"}>
          <div className="row Matrimony-Top-div textcenter">
            <p>Community Business Directory</p>
          </div>

          <div className="row">
            <div className="text-wrap">
              <div className="border" style={{ marginRight: "-11px", marginLeft: "-11px" }}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="link-29">
                  <div className=" bg-gray-100 nav-bg horizontal-tabmenu ">

                    <Nav className="nav nav-tabs link-29 ">

                      <Nav.Item>
                        <Nav.Link eventKey="link-29" className="navlinkforverticaltab">
                          All Business Listing
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="link-30" className="navlinkforverticaltab">
                          My Subscription
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="link-31" className="navlinkforverticaltab">
                          My Business Profile
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="link-32" className="navlinkforverticaltab">
                          Sponsor Business
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="link-33" className="navlinkforverticaltab">
                          Job Postings
                        </Nav.Link>
                      </Nav.Item>

                    </Nav>

                  </div>

                  <Tab.Content className="card-body ">
                    <Tab.Pane eventKey="link-29">
                      <div className="">

                        <Tab.Container id="left-tabs-example" defaultActiveKey="link-29">
                          <div className='row'>
                            <div class="form-group has-search col-5">
                              <span class="fa fa-search form-control-feedback"></span>
                              <input type="text" class="form-control" placeholder="Search Business by Name or City" onChange={handleSearchChangeBusiness} style={{ fontSize: '12px' }} />
                            </div>
                            <div className="col-3">
                              <select id="inputState" name='filterBusiness'
                              onChange={handleChangeBussFilter}
                                className=" form-control" style={{ height: "34px", fontSize: "11px" }}>
                                <option selected value="All Category">All Category</option>
                                <option value="Contractor">Contractor</option>
                                <option value="Education">Education</option>
                                <option value="Event Organizer">Event Organizer</option>
                                <option value="Hotels">Hotels</option>
                                <option value="Home & Decor">Home & Decor</option>
                                <option value="Hospitals">Hospitals</option>
                                <option value="Hostels">Hostels</option>
                                <option value="HealthCare">HealthCare</option>
                                <option value="Food">Food</option>
                                <option value="Real Estate">Real Estate</option>
                                <option value="Transport">Transport</option>
                                <option value="Travel">Travel</option>
                                <option value="Women Enterprenueurs">Women Enterprenueurs</option>


                              </select>
                            </div>
                            <div className="col-3" style={{whiteSpace:"nowrap",display:"inline-flex"}}>
              
              
              
              
            </div>
                          </div>
                          <div className="row  busnessdrectory" style={{ marginTop: "20px" }}>
                            {
                              allbusinessList.map((getmenu, index) => (
                                <div className="col-lg-6 col-sm-6 col-md-6">

                                  <Card className="margintop3 boxshadow" style={{ borderColor: "#B5924C", width: "327px",height:"240px" }} >
                                    <div className="row margintop3" style={{ paddingLeft: "0px" }}>
                                      <div className="col-lg-4 col-sm-12-col-md-12">
                                        <SimpleImageSlider
                                          width={100}
                                          height={90}
                                          images={getmenu.images}
                                          showBullets={false}
                                          showNavs={false}
                                          autoPlay={true}
                                          loop={true}
                                          startIndex={0}
                                          useGPURender={true}
                                          style={{ borderRadius: "4px", marginLeft: "-5px" }}
                                          className='rsis-image'
                                        />

                                      </div>
                                      <div className="col-lg-8 col-sm-12 col-md-12">
                                        <div><span style={{ fontSize: "14px", color: "#555555", fontWeight: "600", marginLeft: "0px" }}>{getmenu.buisnessName}</span></div>
                                        <div><span className="message-text">{getmenu.buisnessType}</span>
                                        </div>
                                        <div><span className="message-text" style={{ fontStyle: "normal" }}>{getmenu.addressLine1} {getmenu.addressLine2}{getmenu.landmark} {getmenu.city},{getmenu.country}</span></div>


                                      </div>

                                    </div>
                                    <div className="row bussbodertop margintop1" >
                                      <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Owner</span></div>
                                      <div className="col-lg-3 col-sm-12 col-md-12" > <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>{getmenu.userName}</span></div>
                                      <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Business Contact</span></div>
                                      <div className="col-lg-3 col-sm-12 col-md-12"> <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>{getmenu.primaryBuisnessCountryCode}/{getmenu.primaryBuisnessMobileNumber}</span></div>

                                    </div>
                                    <div className="row  margintop1" >
                                      <span className="communtdrc-cit-text textShowondotBuss" style={{ fontSize: "13px" }}>Deals in</span>

                                    </div>
                                    <div className="row  margintop1" >
                                      <span className="comudrct-digitxt" style={{ fontSize: "11px", fontStyle: "italic" }}>{getmenu.dealsIn}
                                      </span>
                                     <Link to={`/BusinessViewProfile/${getmenu.buisnessId}`}><span className="comudrct-digitxt" style={{ color: "blue", float: "right", fontSize: "11px",marginTop:"12px" }} >know More</span></Link> 

                                    </div>

                                  </Card>
                                </div>
                              )
                              )}
                             {/* <div className="col-lg-6 col-sm-6 col-md-6">
                      <Card className="margintop3 boxshadow" style={{borderColor:"#B5924C",width:"327px"}} >
                       <div className="row margintop3" style={{paddingLeft:"0px"}}>
                        <div className="col-lg-4 col-sm-12-col-md-12">
                        <SimpleImageSlider
        width={100}
        height={90}
        images={images}
        showBullets={false}
        showNavs={false}
        autoPlay={true}
        loop={true}
        startIndex={0}
        useGPURender={true}
        style={{borderRadius:"4px",marginLeft:"-5px"}}
        className='rsis-image'
      />

                        </div>
                        <div className="col-lg-8 col-sm-12 col-md-12">
                       <div><span style={{fontSize:"14px",color:"#555555",fontWeight:"600",marginLeft:"0px"}}>Radha Krushna Pure Veg</span></div> 
                       <div style={{whiteSpace:"nowrap"}}><span className="message-text">Restaurant</span>                       
                       <span className="complet-name" style={{backgroundColor:"#214B0D",marginLeft:"5px",fontSize:"10px"}}>Women Enterpreneur</span>
</div>
                       <div><span className="message-text" style={{fontStyle:"normal"}}>Sinhgad Road Manik Baug Pune,India</span></div>

                        
                        </div>

                       </div>
                       <div className="row bussbodertop margintop1" >
                       <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{fontSize:"11px"}}>Owner</span></div>
                       <div className="col-lg-3 col-sm-12 col-md-12" > <span className="comudrct-digitxt" style={{fontSize:"11px"}}>Sagar Naidu</span></div>
                       <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{fontSize:"11px"}}>Business Contact</span></div>
                       <div className="col-lg-3 col-sm-12 col-md-12"> <span className="comudrct-digitxt" style={{fontSize:"11px"}}>+91/9923474723</span></div>

                       </div>
                       <div className="row  margintop1" >
                        <span className="communtdrc-cit-text" style={{fontSize:"13px"}}>Deals in</span>

             </div>
             <div className="row  margintop1" >
                        <span className="comudrct-digitxt" style={{fontSize:"11px",fontStyle:"italic"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                        Aenean euismod bibendum laoreet.</span><span className="comudrct-digitxt" style={{color:"blue",float:"right",fontSize:"11px"}} onClick={redirectMybussProfilemain}>Read More</span>

             </div>
                       
                    </Card>

                      </div>  */}

                          </div>
                         

                          <div className="row margintop">

<div className="col-lg-4 "> </div>
<div className="col-lg-4 col-sm-12 col-md-12"> 
<Link to='/UserLanding'><button className="cancelbtn" style={{height:"38px"}} >
          Back
        </button></Link>
        </div>
<div className="col-lg-4 "> </div>
</div>
                          {/* <div className="row margintop1 busnessdrectory" style={{ marginTop: "20px" }}>
                            <div className="col-lg-6 col-sm-6 col-md-6">
                              <Card className=" boxshadow" style={{ borderColor: "#B5924C", width: "327px" }} >
                                <div className="row margintop3" style={{ paddingLeft: "0px" }}>
                                  <div className="col-lg-4 col-sm-12-col-md-12">
                                    <SimpleImageSlider
                                      width={100}
                                      height={90}
                                      images={images}
                                      showBullets={false}
                                      showNavs={false}
                                      autoPlay={true}
                                      startIndex={1}
                                      style={{ borderRadius: "4px", marginLeft: "-5px" }}
                                      className='rsis-image'
                                    />

                                  </div>
                                  <div className="col-lg-8 col-sm-12 col-md-12">
                                    <div><span style={{ fontSize: "14px", color: "#555555", fontWeight: "600", marginLeft: "0px" }}>Radha Krushna Pure Veg</span></div>
                                    <div><span className="message-text">Restaurant</span></div>
                                    <div><span className="message-text" style={{ fontStyle: "normal" }}>Sinhgad Road Manik Baug Pune,India</span></div>


                                  </div>

                                </div>
                                <div className="row bussbodertop margintop1" >
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Owner</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12" > <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>Sagar Naidu</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Business Contact</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>+91/9923474723</span></div>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="communtdrc-cit-text" style={{ fontSize: "13px" }}>Deals in</span>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="comudrct-digitxt" style={{ fontSize: "11px", fontStyle: "italic" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Aenean euismod bibendum laoreet.</span><span className="comudrct-digitxt" style={{ color: "blue", float: "right", fontSize: "11px" }} onClick={redirectMybussProfilemain}>Read More</span>

                                </div>

                              </Card>

                            </div>
                            <div className="col-lg-6 col-sm-6 col-md-6">
                              <Card className=" boxshadow" style={{ borderColor: "#B5924C", width: "327px" }} >
                                <div className="row margintop3" style={{ paddingLeft: "0px" }}>
                                  <div className="col-lg-4 col-sm-12-col-md-12">
                                    <SimpleImageSlider
                                      width={100}
                                      height={90}
                                      images={images}
                                      showBullets={false}
                                      showNavs={false}
                                      autoPlay={true}
                                      startIndex={1}
                                      style={{ borderRadius: "4px", marginLeft: "-5px" }}
                                      className='rsis-image'
                                    />

                                  </div>
                                  <div className="col-lg-8 col-sm-12 col-md-12">
                                    <div><span style={{ fontSize: "14px", color: "#555555", fontWeight: "600", marginLeft: "0px" }}>Radha Krushna Pure Veg</span></div>
                                    <div><span className="message-text">Restaurant</span></div>
                                    <div><span className="message-text" style={{ fontStyle: "normal" }}>Sinhgad Road Manik Baug Pune,India</span></div>


                                  </div>

                                </div>
                                <div className="row bussbodertop margintop1" >
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Owner</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12" > <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>Sagar Naidu</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Business Contact</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>+91/9923474723</span></div>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="communtdrc-cit-text" style={{ fontSize: "13px" }}>Deals in</span>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="comudrct-digitxt" style={{ fontSize: "11px", fontStyle: "italic" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Aenean euismod bibendum laoreet.</span><span className="comudrct-digitxt" style={{ color: "blue", float: "right", fontSize: "11px" }} onClick={redirectMybussProfilemain}>Read More</span>

                                </div>

                              </Card>

                            </div>

                          </div>
                          <div className="row margintop1 busnessdrectory" style={{ marginTop: "20px" }}>
                            <div className="col-lg-6 col-sm-6 col-md-6">
                              <Card className=" boxshadow" style={{ borderColor: "#B5924C", width: "327px" }} >
                                <div className="row margintop3" style={{ paddingLeft: "0px" }}>
                                  <div className="col-lg-4 col-sm-12-col-md-12">
                                    <SimpleImageSlider
                                      width={100}
                                      height={90}
                                      images={images}
                                      showBullets={false}
                                      showNavs={false}
                                      autoPlay={true}
                                      startIndex={1}
                                      style={{ borderRadius: "4px", marginLeft: "-5px" }}
                                      className='rsis-image'
                                    />

                                  </div>
                                  <div className="col-lg-8 col-sm-12 col-md-12">
                                    <div><span style={{ fontSize: "14px", color: "#555555", fontWeight: "600", marginLeft: "0px" }}>Radha Krushna Pure Veg</span></div>
                                    <div><span className="message-text">Restaurant</span></div>
                                    <div><span className="message-text" style={{ fontStyle: "normal" }}>Sinhgad Road Manik Baug Pune,India</span></div>


                                  </div>

                                </div>
                                <div className="row bussbodertop margintop1" >
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Owner</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12" > <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>Sagar Naidu</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Business Contact</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>+91/9923474723</span></div>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="communtdrc-cit-text" style={{ fontSize: "13px" }}>Deals in</span>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="comudrct-digitxt" style={{ fontSize: "11px", fontStyle: "italic" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Aenean euismod bibendum laoreet.</span><span className="comudrct-digitxt" style={{ color: "blue", float: "right", fontSize: "11px" }} onClick={redirectMybussProfilemain}>Read More</span>

                                </div>

                              </Card>

                            </div>
                            <div className="col-lg-6 col-sm-6 col-md-6">
                              <Card className=" boxshadow" style={{ borderColor: "#B5924C", width: "327px" }} >
                                <div className="row margintop3" style={{ paddingLeft: "0px" }}>
                                  <div className="col-lg-4 col-sm-12-col-md-12">
                                    <SimpleImageSlider
                                      width={100}
                                      height={90}
                                      images={images}
                                      showBullets={false}
                                      showNavs={false}
                                      autoPlay={true}
                                      startIndex={1}
                                      style={{ borderRadius: "4px", marginLeft: "-5px" }}
                                      className='rsis-image'
                                    />

                                  </div>
                                  <div className="col-lg-8 col-sm-12 col-md-12">
                                    <div><span style={{ fontSize: "14px", color: "#555555", fontWeight: "600", marginLeft: "0px" }}>Radha Krushna Pure Veg</span></div>
                                    <div><span className="message-text">Restaurant</span></div>
                                    <div><span className="message-text" style={{ fontStyle: "normal" }}>Sinhgad Road Manik Baug Pune,India</span></div>


                                  </div>

                                </div>
                                <div className="row bussbodertop margintop1" >
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Owner</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12" > <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>Sagar Naidu</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Business Contact</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>+91/9923474723</span></div>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="communtdrc-cit-text" style={{ fontSize: "13px" }}>Deals in</span>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="comudrct-digitxt" style={{ fontSize: "11px", fontStyle: "italic" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Aenean euismod bibendum laoreet.</span><span className="comudrct-digitxt" style={{ color: "blue", float: "right", fontSize: "11px" }} onClick={redirectMybussProfilemain}>Read More</span>

                                </div>

                              </Card>

                            </div>

                          </div>
                          <div className="row margintop1 busnessdrectory" style={{ marginTop: "20px" }}>
                            <div className="col-lg-6 col-sm-6 col-md-6">
                              <Card className=" boxshadow" style={{ borderColor: "#B5924C", width: "327px" }} >
                                <div className="row margintop3" style={{ paddingLeft: "0px" }}>
                                  <div className="col-lg-4 col-sm-12-col-md-12">
                                    <SimpleImageSlider
                                      width={100}
                                      height={90}
                                      images={images}
                                      showBullets={false}
                                      showNavs={false}
                                      autoPlay={true}
                                      startIndex={1}
                                      style={{ borderRadius: "4px", marginLeft: "-5px" }}
                                      className='rsis-image'
                                    />

                                  </div>
                                  <div className="col-lg-8 col-sm-12 col-md-12">
                                    <div><span style={{ fontSize: "14px", color: "#555555", fontWeight: "600", marginLeft: "0px" }}>Radha Krushna Pure Veg</span></div>
                                    <div><span className="message-text">Restaurant</span></div>
                                    <div><span className="message-text" style={{ fontStyle: "normal" }}>Sinhgad Road Manik Baug Pune,India</span></div>


                                  </div>

                                </div>
                                <div className="row bussbodertop margintop1" >
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Owner</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12" > <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>Sagar Naidu</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Business Contact</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>+91/9923474723</span></div>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="communtdrc-cit-text" style={{ fontSize: "13px" }}>Deals in</span>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="comudrct-digitxt" style={{ fontSize: "11px", fontStyle: "italic" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Aenean euismod bibendum laoreet.</span><span className="comudrct-digitxt" style={{ color: "blue", float: "right", fontSize: "11px" }} onClick={redirectMybussProfilemain}>Read More</span>

                                </div>

                              </Card>

                            </div>
                            <div className="col-lg-6 col-sm-6 col-md-6">
                              <Card className=" boxshadow" style={{ borderColor: "#B5924C", width: "327px" }} >
                                <div className="row margintop3" style={{ paddingLeft: "0px" }}>
                                  <div className="col-lg-4 col-sm-12-col-md-12">
                                    <SimpleImageSlider
                                      width={100}
                                      height={90}
                                      images={images}
                                      showBullets={false}
                                      showNavs={false}
                                      autoPlay={true}
                                      startIndex={1}
                                      style={{ borderRadius: "4px", marginLeft: "-5px" }}
                                      className='rsis-image'
                                    />

                                  </div>
                                  <div className="col-lg-8 col-sm-12 col-md-12">
                                    <div><span style={{ fontSize: "14px", color: "#555555", fontWeight: "600", marginLeft: "0px" }}>Radha Krushna Pure Veg</span></div>
                                    <div><span className="message-text">Restaurant</span></div>
                                    <div><span className="message-text" style={{ fontStyle: "normal" }}>Sinhgad Road Manik Baug Pune,India</span></div>


                                  </div>

                                </div>
                                <div className="row bussbodertop margintop1" >
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Owner</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12" > <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>Sagar Naidu</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Business Contact</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>+91/9923474723</span></div>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="communtdrc-cit-text" style={{ fontSize: "13px" }}>Deals in</span>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="comudrct-digitxt" style={{ fontSize: "11px", fontStyle: "italic" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Aenean euismod bibendum laoreet.</span><span className="comudrct-digitxt" style={{ color: "blue", float: "right", fontSize: "11px" }} onClick={redirectMybussProfilemain}>Read More</span>

                                </div>

                              </Card>

                            </div>

                          </div> */}


                        </Tab.Container>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="link-30">
                      <div className="row tblclassmatsubscrip" style={{ marginTop: "20px" }}>
                        <Card className="margintop3" style={{ borderColor: "#B5924C", }}>
                          <div className="margintop3" style={{ marginLeft: '7px' }}><span style={{ fontSize: "14px", color: "#333333", fontWeight: "700", marginLeft: "7px" }}>My Business Subscription</span></div>

                          <div className="table-responsive fileexport pos-relative">

                            <MySubscription />

                          </div>
                        </Card>


                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="link-31">
                      <div className="row " style={{ marginTop: "20px" }}>
                        <Card className="margintop3" style={{ borderColor: "#B5924C", }}>
                          <div className="margintop3" style={{ marginLeft: '0px' }}><span style={{ fontSize: "13px", color: "#333333", fontWeight: "700", marginLeft: "7px" }}>My Business Profile</span>
                            <Link to={`/EditBusinessProfile/${myProfilebusinessList.buisnessId}`}><span className="comudrct-digitxt" style={{ color: "blue", marginLeft: "20px" }}>Edit Business</span></Link>
                          </div>

                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Business Name</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">{myProfilebusinessList.buisnessName}</span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Business Category</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">{myProfilebusinessList.buisnessType}</span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text"> Business Address</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4">
                              <span className="comudrct-digitxt">{myProfilebusinessList.addressLine1} {myProfilebusinessList.addressLine2}</span>
                              <div className=""><span className="comudrct-digitxt">{myProfilebusinessList.landmark}</span></div>
                              <div className=""><span className="comudrct-digitxt">{myProfilebusinessList.city} {myProfilebusinessList.pinCode}</span></div>

                            </div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Business Owner Name</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">{myProfilebusinessList.userName}</span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Business Contact Number</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">{myProfilebusinessList.primaryBuisnessCountryCode}/{myProfilebusinessList.primaryBuisnessMobileNumber} {myProfilebusinessList.secondaryBuisnessMobileNumber}</span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Deals In</span></div>

                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-11 col-sm-12 col-md-4"><span className="comudrct-digitxt">
                              {myProfilebusinessList.dealsIn}
                            </span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Business Establishment Date</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">{myProfilebusinessList.registerationDate}</span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Type of Establishment</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">{myProfilebusinessList.buisnessCategory}</span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">GST Registered</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">{myProfilebusinessList.registeredGst}</span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">GST Number</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">{myProfilebusinessList.gstNumber}</span>
                              {/* <span className="complet-name" style={{fontStyle:"italic",marginLeft:"20px",fontSize:"13px"}}>Verified</span> */}
                            </div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">No. of Employees</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">{myProfilebusinessList.numberOfEmployees}</span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Business Brief</span></div>

                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-11 col-sm-12 col-md-4"><span className="comudrct-digitxt">
                              {myProfilebusinessList.aboutBuisness}
                            </span>

                            </div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Business Timings</span></div>

                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-1"></div>
                            <div className="col-lg-11 col-sm-12 col-md-12">
                              <Card style={{ borderColor: "#333333" }}>
                                {
                                  myProfilebusinessList?.buisnessTime?.map((getmenu, index) =>  (
                                    <div className=" margintop1">
                                      {
                                        getmenu.dayOff == false ? (
                                          <div className="row">
                                            <div className="col-lg-2 col-sm-12 col-md-12"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap"}}>{getmenu.day}</span></div>
                                            <div className="col-lg-5 col-sm-12 col-md-12"style={{marginLeft:"19px"}}>
                                             {getmenu.startTime1==""||getmenu.startTime1==null?(<span className="comudrct-digitxt">Not mentioned </span>):(<span className="comudrct-digitxt">{getmenu.startTime1} </span>)} 
                                              <span className="communtdrc-cit-text" style={{ marginLeft: "5px" }}>To</span>
                                              {getmenu.endTime1==""||getmenu.endTime1==null?(<span className="comudrct-digitxt" style={{ marginLeft: "5px" }}>Not mentioned </span>):(<span className="comudrct-digitxt"style={{ marginLeft: "5px" }}>{getmenu.endTime1} </span>)} 

                                            </div>
                                            <div className="col-lg-5 col-sm-12 col-md-12" style={{marginLeft:"-19px"}}>
                                            {getmenu.startTime2==""||getmenu.startTime2==null?(<span className="comudrct-digitxt">Not mentioned </span>):(<span className="comudrct-digitxt">{getmenu.startTime2} </span>)} 

                                              <span className="communtdrc-cit-text" style={{ marginLeft: "5px" }}>To</span>
                                              {getmenu.endTime2==""||getmenu.endTime2==null?(<span className="comudrct-digitxt" style={{ marginLeft: "5px" }}>Not mentioned </span>):(<span className="comudrct-digitxt"style={{ marginLeft: "5px" }}>{getmenu.endTime2} </span>)} 

                                            </div>
                                          </div>
                                        ) : (
                                          <div className="row">
                                            <div className="col-lg-2 col-sm-12 col-md-12"><span className="communtdrc-cit-text">{getmenu.day}</span></div>
                                            <div className="col-lg-5 col-sm-12 col-md-12">
                                              <span className="comudrct-digitxt"style={{marginLeft:"19px"}}>Closed </span>

                                            </div>
                                          </div>
                                        )
                                      }

                                    </div>
                                  )
                                  )}

                                {/* <div className="row">
<div className="col-lg-2 col-sm-12 col-md-12"><span className="communtdrc-cit-text">Tue</span></div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">9:00 AM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt"style={{marginLeft:"5px"}}>2:00 PM </span>
</div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">4:00 PM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt" style={{marginLeft:"5px"}}>9:00 PM </span>
</div>
</div>
 <div className="row">
<div className="col-lg-2 col-sm-12 col-md-12"><span className="communtdrc-cit-text">Wed</span></div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">9:00 AM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt"style={{marginLeft:"5px"}}>2:00 PM </span>
</div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">4:00 PM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt" style={{marginLeft:"5px"}}>9:00 PM </span>
</div>
</div>
<div className="row">
<div className="col-lg-2 col-sm-12 col-md-12"><span className="communtdrc-cit-text">Thir</span></div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">9:00 AM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt"style={{marginLeft:"5px"}}>2:00 PM </span>
</div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">4:00 PM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt" style={{marginLeft:"5px"}}>9:00 PM </span>
</div>
</div>
<div className="row">
<div className="col-lg-2 col-sm-12 col-md-12"><span className="communtdrc-cit-text">Fri</span></div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">9:00 AM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt"style={{marginLeft:"5px"}}>2:00 PM </span>
</div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">4:00 PM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt" style={{marginLeft:"5px"}}>9:00 PM </span>
</div>
</div>
<div className="row">
<div className="col-lg-2 col-sm-12 col-md-12"><span className="communtdrc-cit-text">Sat</span></div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">9:00 AM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt"style={{marginLeft:"5px"}}>2:00 PM </span>
</div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">4:00 PM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt" style={{marginLeft:"5px"}}>9:00 PM </span>
</div>
</div>
<div className="row">
<div className="col-lg-2 col-sm-12 col-md-12"><span className="communtdrc-cit-text">Sun</span></div>
<div className="col-lg-3 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">Closed </span>
  
</div>
</div> */}
                                <div className="row margintop1"></div>
                              </Card>
                            </div>
                            <div className="col-lg-1"></div>

                          </div>
                          <div className="row margintop1">
                            <div className=""><span className="communtdrc-cit-text">Business Photos</span></div>

                          </div>
                          {/* <div className="row margintop1">
                            {
                              myProfilebusinessList?.images?.map((getmenu, index) => (
                                <div className="col-lg-6 col-sm-12 col-md-12"><img src={getmenu} width='322px' /></div>
                              )
                              )}
                          </div> */}
<div className="row margintop3">

<div className={myProfilebusinessList.profilePhotoPath !=null ||myProfilebusinessList.profilePhotoPath !=""?"col-lg-4 col-sm-12 col-md-12":"hide"}>
  
<div className=" margintop1" style={{borderColor:"",border:"none"}}>

      <div className="row margintop1">
      <img src={myProfilebusinessList.profilePhotoPath} id='profpicdisablesavematmyprof1' className='' style={{width:"235px"}}/>

      </div>
      
      
      
    

</div>
</div>

</div>
                <div className="row margintop3">

                        <div className={myProfilebusinessList.photoOnePath !=null ||myProfilebusinessList.photoOnePath !=""?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                          
                        <div className=" margintop1" style={{borderColor:"",border:"none"}}>
                       
                              <div className="row margintop1">
                              <img src={myProfilebusinessList.photoOnePath} id='profpicdisablesavematmyprof1' className='' style={{width:"235px"}}/>

                              </div>
                              
                              
                              
                            

      </div>
                        </div>
                        <div className={myProfilebusinessList.photoTwoPath !=null ||myProfilebusinessList.photoTwoPath !="" ?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                        <div className=""><span style={{fontSize:"14px",color:"#F2F2F2",fontWeight:"700",marginLeft:"0px"}}></span></div>

                        <div className=" margintop1" style={{borderColor:"",border:"none"}}>
                        
                              
                              <div className="row margintop1">
                              <img src={myProfilebusinessList.photoTwoPath} id='profpicdisablesavematmyprof2'  className='margintop1' style={{width:"235px"}}/>

                              </div>
                          
                                

                          </div>
                        </div>
                        <div className={myProfilebusinessList.photoThreePath !=null ||myProfilebusinessList.photoThreePath !="" ?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                          <div className=""><span style={{fontSize:"14px",color:"#F2F2F2",fontWeight:"700",marginLeft:"0px"}}></span></div>
                        <div className=" margintop1" style={{borderColor:"",border:"none"}}>
                       
                              <div className="row margintop1">
                              <img src={myProfilebusinessList.photoThreePath} id='profpicdisablesavematmyprof3' className=''style={{width:"235px"}}/>

                              </div>
                              
                              
                    </div>
                        </div>
                        
                      </div>
                      <div className="row margintop1">
                        
                        <div className={myProfilebusinessList.photoFourPath !=null|| myProfilebusinessList.photoFourPath !=""?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                        <div className=""><span style={{fontSize:"14px",color:"#F2F2F2",fontWeight:"700",marginLeft:"0px"}}></span></div>

                        <div className=" margintop1" style={{borderColor:"",border:"none"}}>
                        
                              
                              <div className="row margintop1">
                              <img src={myProfilebusinessList.photoFourPath} id="profpicdisablesavematmyprof4" className='margintop1'/>

                              </div>
                          
                                

                          </div>
                        </div>
                        <div className={myProfilebusinessList.photoFivePath !=null|| myProfilebusinessList.photoFivePath !=""?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                        <div className=""><span style={{fontSize:"14px",color:"#F2F2F2",fontWeight:"700",marginLeft:"0px"}}></span></div>

                        <div className=" margintop1" style={{borderColor:"",border:"none"}}>
                        
                              
                              <div className="row margintop1">
                              <img src={myProfilebusinessList.photoFivePath} id="profpicdisablesavematmyprof4" className='margintop1'/>

                              </div>
                          
                                

                          </div>
                        </div>
                        <div className={myProfilebusinessList.photoSixPath !=null|| myProfilebusinessList.photoSixPath !=""?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                        <div className=""><span style={{fontSize:"14px",color:"#F2F2F2",fontWeight:"700",marginLeft:"0px"}}></span></div>

                        <div className=" margintop1" style={{borderColor:"",border:"none"}}>
                        
                              
                              <div className="row margintop1">
                              <img src={myProfilebusinessList.photoFourPath} id="profpicdisablesavematmyprof4" className='margintop1'/>

                              </div>
                          
                                

                          </div>
                        </div>
                        
                      </div>
                        </Card>


                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="link-32">
                      <div className="row " style={{ marginTop: "20px" }}>
                        <Card className="margintop3" style={{ borderColor: "#B5924C", }}>
                          <div className="margintop3" style={{ marginLeft: '0px' }}>
                            <span style={{ fontSize: "13px", color: "#333333", fontWeight: "700", marginLeft: "7px" }}>Sponsored Business

                            </span>
                          </div>
                          <div className="row">
                            <div className="col-lg-11 col-sm-12 col-md-4">
                              <span className="comudrct-digitxt">
                                Sponsor your business now with a minimal amount for a greater visibility of your business within the community. Sponsored businesses are displayed on homepage for maximum reach.
                              </span>
                            </div>

                          </div>
                          <div className="margintop3" style={{ marginLeft: '0px' }}>
                            <span style={{ fontSize: "13px", color: "#333333", fontWeight: "700", marginLeft: "7px" }}>Contact Details</span>
                          </div>
                          <div className="row">
                            <div className="col-lg-11 col-sm-12 col-md-4">
                              <span className="comudrct-digitxt">
                                To sponsor your business contact:-

                              </span>
                            </div>

                          </div>

                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Email:-</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">business@naidusangham.com</span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Mobile:-</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">9823423424</span></div>
                          </div>



                        </Card>


                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="link-33">
                      <div className="row tblclassmatsubscrip" style={{ marginTop: "20px" }}>
                        <Card className="margintop3" style={{ borderColor: "#B5924C", }}>

                          <div className="margintop3" style={{ marginLeft: '0px' }}>
                            <Link to="/MyJobPostingMain" ><span className="comudrct-digitxt" style={{ fontSize: "13px", fontWeight: "700", marginLeft: "0px" }}>My Job Postings</span></Link>
                           {authlocalstoragedata.buisnessId ==0?(
                           <span className="comudrct-digitxt" style={{ fontSize: "13px", color: "gray", marginLeft: "10px", cursor:"not-allowed"}}>Add New Job Posting</span>):
                           (<Link to='/AddJobPosting'><span className="comudrct-digitxt" style={{ fontSize: "13px", color: "#0000ff", marginLeft: "10px" }}>Add New Job Posting</span></Link>
                           )
                           }
                          </div>

                          <div className="table-responsive fileexport pos-relative">

                            <DataTableExtensions {...tableData}>
                              <DataTable
                                columns={columns}
                                data={data}
                                noDataComponent="Data is Not Available" //or your component
                                striped
                                noHeader
                                defaultSortField="id"
                                defaultSortAsc={true}
                                pagination
                                highlightOnHover
                                print={false}
                                export={true}

                              />
                            </DataTableExtensions>

                          </div>
                        </Card>


                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>

          </div>
        </div>





        <div className="col-lg-3 col-sm-4 col-md-4 bloodreq-head-div heightforAll3Div right-div">
          <div className="row  margintop1">
            <div className="borderbottom-radius ">
              <span className="radius-heding">Urgent Blood Requirement</span>   <MdAddBox size='1.5em' onClick={togglePopupBlood} />
              {isOpenBloodPopup && <BloodRequirePopup

                handleClose={togglePopupBlood}
              />}
              <div className="radius-div11 " style={{ marginTop: 8, paddingTop: 4,paddingBottom:2 }}>
                <SliderPageNew />
              </div>
            </div>

            <div className="borderbottom-radius ">
              <span className="radius-heding" style={{ paddingTop: "5px" }}>Community Business</span> <span className="sponser-text" style={{ paddingTop: "5px" }}>Sponsored</span>
              <div className="radius-div11 " style={{ marginTop: 8, paddingTop: 6 }}>
                {/* <div className="backimg-businessdirect"></div> */}
                <div className="">
                  <SponserBusiness/>
                </div>
              </div>
            </div>


            <div className="borderbottom-radius ">
              <span className="radius-heding">Job Openings</span>
              <div className="radius-div11 " style={{ marginTop: 6, paddingTop: 10 }}>
                <div className="row margintop3"></div>
                {
                  jobPostingList.map((job, index) => (
                    <div>
                      <div className="row margintop3" >
                        <div className="col-5">
                          <lable className='radius-fourth-card1-lefttext'>{job.role}</lable>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-4">
                          <lable className='radius-fourth-card1-lefttext'>{job.city}</lable>

                        </div>
                      </div>
                      <div className="row margintop2">
                        <span className="radius-fourth-card1-text">
                          {job.jobDescription}
                        </span>
                      </div>
                    </div>
                  )
                  )}
{
     jobPostingList  && jobPostingList.length== 0 &&(
      <div className="row">
      <span className="slider-name textcenter" style={{paddingBottom:"5px"}}>No Job Requirements Currently</span>
      </div>
     )
}
                {/* <div className="row margintop3" >
            <div className="col-5">
            <lable className='radius-fourth-card1-lefttext'>Shop Assistant</lable>
            </div>
            <div className="col-1"></div>
            <div className="col-4">
            <lable className='radius-fourth-card1-lefttext'>Pune</lable>

            </div>
          </div>
          <div className="row margintop2">
            <span className="radius-fourth-card1-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum
            </span>
          </div>

          <div className="row margintop3" >
            <div className="col-5">
            <lable className='radius-fourth-card1-lefttext'>Graphics Designer</lable>
            </div>
            <div className="col-1"></div>
            <div className="col-4">
            <lable className='radius-fourth-card1-lefttext'>Nasik</lable>

            </div>
          </div>
          <div className="row margintop2">
            <span className="radius-fourth-card1-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum
            </span>
          </div> */}
{
     jobPostingList  && jobPostingList.length > 0 &&(
                <div>
                  <div className="row">
                  <span style={{ textAlign: 'right', cursor: "pointer" }} className='seall-text' onClick={togglePopupJob}>See All</span>
                </div>
                {isOpenJobPop && <JobAllSeePop

                  handleClose={togglePopupJob}
                />}
                </div>
                 )
        }
                </div>
            </div>


            <div className="borderbottom-radius ">
              <span className="radius-heding">My Community Messenger</span> <FaEdit style={{ marginLeft: '70px' }} />
              <br />
              <div className="radius-div11" style={{ marginTop: 4, paddingTop: 6 }}>
                {/* <div className="row margintop3">
            <input type='search' className="margintop1" placeholder="Search Messages" style={{borderColor:'lightgray',borderTopColor:"lightgray",borderLeftColor:"lightgray",fontSize:'14px'}}/>
          </div> */}
                <div class="form-group has-search margintop3">
                  <span class="fa fa-search form-control-feedback"></span>
                  <input type="text" class="form-control" placeholder="Search Messages" onChange={handleSearchChangeMessage} style={{ fontSize: '12px' }} />
                </div>

                {
                  userlist.map((user, index) => (
                    <Card className={styleForMessg && user.userId == styleForMessgid ? "hoverformesgcard margintop1" : " margintop1"} style={{ paddingBottom: "8px", paddingTop: "5px", lineHeight: "1.6", cursor: "pointer" }}

                      onMouseEnter={() => omMouseHover(user.userId,user.blockStatus)}
                      onMouseLeave={() => omMouseLeve(user.userId)}
                    >
                      <div className="row margintop1" key={user.userId}>
                        <div className="col-2 margintop1" onClick={() => togglePopupMessage(user.userId)}>
                          <img src={user.profilePicturePath} id='profpicdisablesave17' className=' messengerprofile' />
                          
                        </div>
                        <div className="col-10">
                          <div className="row">
                            <div className="col-9" onClick={() => togglePopupMessage(user.userId)}>
                              <span className="radius-fourth-card1-lefttext" style={{ marginLeft: "-9px" }} >{user.userName}</span>
                            </div>
                            <div className="col-3 textright">
                              {
                                styleForMessg && user.userId == styleForMessgid ?
                                  (<span className={styleForMessgblock && user.userId == styleForMessgid ? "threedotblockunclock" : ""}
                                    onMouseEnter={() => omMouseHoverblock(user.userId)}
                                    onMouseLeave={() => omMouseLeveBlock(user.userId)}
                                    onClick={() => blockunblockpoupopen(true)}>
                                    <BsThreeDots style={{ cursor: "pointer" }} />
                                  </span>)
                                  :
                                  (<span className="radius-fourth-card1-text" style={{ marginLeft: '-16px' }}>{user.time}</span>)
                              }
                            </div>

                          </div>
                          <div onClick={() => togglePopupMessage(user.userId)}>
                            <span className="textShowondot message-text" style={{ lineHeight: '80%' }}>
                              {user.lastMessage}

                            </span>
                          </div>

                        </div>
                        <div className={blockPop && user.userId == styleForMessgid ? "" : "hide"}>
                          <div className="" style={{ backgroundColor: "fff", boxShadow: "0px 5px 5px 0px rgba(242, 242, 242, 1)", borderRadius: "3px", width: "150px", fontSize: "12px", float: "right", marginTop: "-62px", marginLeft: "-20px" }}>
                            <div className="">
                              <ul class="list-group ">
                                {
                                  user.blockStatus == false ?
                                    (<li class="list-group-item"  style={{ cursor: "pointer" }}> <span onClick={() => addblockUnblock(user.userId)}>Block</span> <MdOutlineClose  onClick={() => blockunblockpoupopen(false)} style={{marginLeft:"0px",marginTop:"-7px",float:"right",marginRight:"-14px"}}/></li>) :
                                    (<li class="list-group-item"  style={{ cursor: "pointer" }}><span onClick={() => addblockUnblock(user.userId)}>Unblock</span> <MdOutlineClose  onClick={() => blockunblockpoupopen(false)} style={{marginLeft:"0px",marginTop:"-7px",float:"right",marginRight:"-14px"}}/></li>)

                                }
                              </ul>
                            </div>
                          </div>

                        </div>
                      </div>


                    </Card>
                  ))

                }

                {isOpenMessagePop && <MessagePopup

                  handleClose={togglePopupMessage} uerAuthId={uerAuthId} styleForMessgid={styleForMessgid}
                  userlist ={userlist} setuserlist={setuserlist} styleForMessgBlockstatus={styleForMessgBlockstatus}
                />}



                {/* <Card className="" style={{paddingBottom:"5px"}}>
          <div className="row margintop1">
            <div className="col-3 margintop1">
            <img src={conprof}  className='margintop1'/>
            </div>
            <div className="col-9">
              <div className=""><span className="radius-fourth-card1-lefttext ">Sagar Naidu</span>  <span className="radius-fourth-card1-text" style={{marginLeft:'60px'}}>10:30</span>
     </div>
              <div>
              <span className=" message-text" style={{lineHeight: '80%'}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum

                </span>
              </div>
 
            </div>
            
            </div>
            </Card>
        
            <Card className=" " style={{paddingBottom:"8px"}}>
          <div className="row margintop1">
            <div className="col-3 margintop1">
            <img src={conprof}  className='margintop1'/>
            </div>
            <div className="col-9">
              <div className=""><span className="radius-fourth-card1-lefttext ">Sagar Naidu</span>  <span className="radius-fourth-card1-text" style={{marginLeft:'60px'}}>10:30</span>
     </div>
              <div className="">
              <span className=" message-text" style={{lineHeight: '80%'}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum

                </span>
              </div>
 
            </div>
            
            </div>
            </Card>

            <div className="radius-fourth-card1 " style={{paddingBottom:"8px"}}>
          <div className="row margintop1">
            <div className="col-3 margintop1">
            <img src={conprof}  className='margintop1'/>
            </div>
            <div className="col-9">
              <div className=""><span className="radius-fourth-card1-lefttext ">Sagar Naidu</span>  <span className="radius-fourth-card1-text" style={{marginLeft:'40px',whiteSpace:'nowrap'}}>32 Oct,2022</span>
     </div>
              <div>
              <span className=" message-text" style={{lineHeight: '80%'}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum

                </span>
              </div>
 
            </div>
            
            </div>
            </div> */}



              </div>
            </div>


          </div>




        </div>

      </div>


      <div className={LikeDiv ? "row" : "hide row"}>

        <div className="popup-boxdate">
          <div className="boxdate" style={{ width: '30%', marginLeft: "620px", marginTop: "100px" }}>
            <div className='bordernonemessg' style={{ marginLeft: "-16px", marginRight: "-16px" }}>
              <div className=" row" style={{ borderBottom: "1px", borderColor: "lightgray" }} >
                <div className='col-1'></div>
                <div className=" col-9" style={{ textAlign: "left", fontSize: "16px" }}><span className="event-sub-detail">Reactions</span></div>
                <div className='col-1'>
                  <span className="" onClick={() => Likepoupopen(false)}><MdClose size='1.5em' /></span>
                </div>
                <br />
              </div>
              <div className='row' style={{ marginTop: "20px" }}></div>
            </div>

            <div style={{ overflowY: "scroll", height: "350px", overflowX: "hidden" }}>

              {
                likeData.map((likemenu, index) => (
                  <div className="radius-fourth-card1 bordernonemessg1 margintop3" style={{ marginTop: "20px" }}>
                    <div className="row margintop1">

                      <div className="col-9">
                        <div className=""><span className="event-sub-detail" style={{ fontSize: '14px', color: "black" }}>{likemenu.viewerName}</span>
                        </div>
                        <div>
                          {/* <span className="event-sub-detail1" style={{lineHeight: '80%'}}>
                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum
 
                 </span> */}
                        </div>

                      </div>

                    </div>
                    <div className='row' style={{ marginTop: "10px" }}></div>

                  </div>
                ))

              }


            </div>


          </div>
        </div>

      </div>

      <div className={contactInfoDiv ? "row" : "hide row"}>
        <div className="popup-boxcontact">
          <div className="boxcontact">
            <div className="row margintpmins">
              <div className="col-5 textleft"><span className="contactpop-txt textleft">{commanView.fullName}</span></div>
              <div className="col-6"></div>
              <div className="col-1">
                <ImCross size='0.9em' color='black' onClick={() => contactInfopoupopen(0, false)} style={{ cursor: "pointer" }} />
              </div>

            </div>
            <div className="cont-verify-div margintop2">
              <div className="row ">
                <div className="col-1">
                  <TbCheckbox size='1.1em' />
                </div>
                <div className="col-3">
                  <span className="cont-verifytxt">Verified Account</span>

                </div>
              </div>
              <div className="row">
                <span className=" cont-verifytext">A Phone number associated with this aacount has been Verified. Contact info will be displayed as per members contact sharing prefrences.
                  No contact detailes are shared by Naidu Directory portal. As per the Naidu Directory guidelines we strongly recomends to avoid Spam
                  calls. Naidu Directory reserves rights to suspend access 0 contact details if reported for spam calls.  </span>
              </div>
              <div className="row margintop1"></div>
            </div>
            <div className=""><span className="cont-continfotext">Contact Info</span></div>
            <div className="row margintop2">
              <div className="col-6">
                <div className="row">
                  <div className="col-1">
                    <img src={commanView.profilePicturePath} id='profpicdisablesave20' className=' communitydrect-img' />
                  </div>
                  <div className="col-10">
                    <span className="cont-contactinfo-txt">{commanView.fullName}'s Profile</span>
                    <div className=""><span className="cont-contactinfo-subtxt">xyzcommunity.com/sagarnaidu26</span></div>
                  </div>
                </div>
                <div className="row margintop2">
                  <div className="col-1">
                    <MdEmail size='1.2em' color="#555555" />
                  </div>
                  <div className="col-10">
                    <span className="cont-contactinfo-txt">Email</span>
                    <div className=""><span className="cont-contactinfo-subtxt">{commanView.emailId}</span></div>
                  </div>
                </div>

              </div>


              <div className="col-6">
                <div className="row">
                  <div className="col-1">
                    <FaWhatsapp size='1.2em' color="#555555" />
                  </div>
                  <div className="col-10">
                    <span className="cont-contactinfo-txt">Whatsapp</span>
                    <div className="">

                      <span className="cont-contactinfo-subtxt">{commanView.mobileNumber}</span>

                    </div>
                  </div>
                </div>
                <div className="row margintop2">
                  <div className="col-1">
                    <ImMobile size='1.2em' color="#555555" />
                  </div>
                  <div className="col-10">
                    <span className="cont-contactinfo-txt">Mobile</span>
                    <div className=""><span className="cont-contactinfo-subtxt">{commanView.mobileNumber}</span></div>
                  </div>
                </div>

              </div>

            </div>

            <div className="row margintop2"></div>

            <div className="row margintop">
              <div className="col-6"></div>
              <div className="col-5 textcenter">
                {/* <button className="com-conect-btn">Connect</button> */}
                <button className={commanView.connectionStatus == false ? "com-conect-btn" : "hide"} style={{ marginLeft: "10px", color: "#F2F2F2", backgroundColor: "#FF8427" }}>Connect</button>
                <span className={commanView.connectionStatus == true ? "community-direct-nametxt11" : "hide"}>Connection Request Sent</span>
              </div>
              {/* <div className="col-3 cuminvite-div">  
             <RiCupFill color="#FEFDFA" size='1.3em'/>
          <span className="cominvite-text">Invite</span>
           </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className={coffeediv ? "row" : "hide row"}>
        <div className="popup-boxdate">
          <div className="boxdate" style={{ width: '52%', marginLeft: "400px", marginTop: "120px" }}>
            <div className='bordernonemessg' style={{ marginLeft: "-16px", marginRight: "-16px" }}>
              <div className=" row" style={{ borderBottom: "1px", borderColor: "lightgray" }} >
                <div className=" col-11" style={{ textAlign: "left", fontSize: "16px" }}><span className="event-sub-detail">View Invitation</span></div>
                <div className='col-1'>
                  <span className="" onClick={() => coffeeInfopoupopen(0, false)}><MdClose size='1.5em' /></span>
                </div>
                <br />
              </div>
              <div className='row' style={{ marginTop: "0px" }}></div>
            </div>

            <div style={{ overflowX: "hidden" }}>


              <div className="cardiv-coffee margintop border1px">
                <div className="row margintop3">
                  <div className="col-8">
                    <span className="event-sub-detail">{coffeeView.date} </span><span className="event-sub-detail" style={{ marginLeft: '12px' }}>{coffeeView.city}</span>
                  </div>
                  <div className="col-3" style={{ float: "right" }}>
                    <button className={coffeeView.attendStatus == 'Pending' ? "attendbtn" : "hide"} style={{ marginTop: '6px', position: "absolute", width: "180px" }} onClick={() => attendStatusCofeeMeet(coffeeView.id)}>Attend</button>
                    {/* <button className={getmenu.attendStatus=='Pending'?"hide ":"attendbtn"} style={{marginTop:'6px',position:"absolute"}} onClick={()=>attendStatusCofeeMeet(getmenu.id)}>Attend</button> */}
                    <div class={coffeeView.attendStatus == 'Pending' ? "hide" : "dropdown"}>
                      <button class="btn  dropdown-toggle attendbtn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width: "180px" }} onClick={() => attendStatusCofeeMeet(coffeeView.id)}>
                        You are Attending
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" style={{ fontSize: '14px', }}>Withdraw Attendance</a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row margintop2">
                  <div className="">
                    <span className="event-sub-detail">Venue:- </span>
                    <span className="event-sub-detail1" style={{ margin: "5px", fontSize: '13px' }}>{coffeeView.venue}</span>
                    {/* <span className="bluetextforlink" style={{margin:"7px",fontSize:'10px'}}>Check on Google Maps</span> */}
                    <Link to={`/${coffeeView.googleMapsLink}`}><span className="bluetextforlink" style={{ margin: "7px", fontSize: '10px' }}>Check on Google Maps</span></Link>

                  </div>
                </div>
                <div className="row margintop3">
                  <div className="">
                    <span className="event-sub-detail1" style={{ margin: "5px", fontSize: '13px' }}>{coffeeView.guests.length} Members are attending this Coffee Meet Up</span>
                  </div>
                </div>
                <div className="row margintop">

                  {coffeeView.guests.map((geuest, index) => (
                    <div className="col-2 ">
                      <img src={geuest.profilePicture} id='profpicdisablesave18' className=' ' style={{ width: "100px", height: "100px", borderRadius: "50%" }} />
                      <div className="margintop1"><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '0px' }}>{geuest.name}</span></div>
                    </div>
                  ))
                  }



                </div>
                <div className="row margintop3"></div>
              </div>



            </div>


          </div>
        </div>
      </div>

      <div className={updatedData.verification == "Pending" ? "row" : "hide row"}>
        <div className="popup-boxkycpending">
          <div className="boxkycpending">
            <div className=" textcenter topnewsrow ">
              <MdWarning color="#F2F2F2" size="1.5em" />
              <span className="marginleft2">KYC Pending</span></div>
            <span>
              Your KYC is pending. Since Naidu Directory is strictly for Naidu Directory members on the social platform, we have to take required mesaures to avoid tail-gating of non community members. Better safe than sorry. This is ultimately for the benefit of the community. We regret the inconvenience you might have faced if any during the onboarding process and are committed to fix it on priority. We request you to connect with our support team at
              support@abccommunity.com to fix the issues you are facing. To know more about how to complete the KYC
              process please click here.
            </span>
            <div className="row texcenter">
              <div className="col-4"></div>
              <div className="col-4">
              <Link to='/CompleteProfile'><button className="cancelbtn" style={{width:"170px",height:"38px"}}>Complete KYC Now</button></Link>

              </div>

            </div>


          </div>
        </div>
      </div>
      {/* <div className={authlocalstoragedata.matrimonyId != 0 ? "row" : "hide row"}>
        <div className="popup-boxMatsubspending">
          <div className="boxMatsubspending">
            


          </div>
        </div>
      </div> */}
      
    </div>
  );
};

export default BusinessListingMain;