
import React from "react";
import './JoinNow.css';
import payment from '../../images/paymentfail.svg';
import success from '../../images/fail.svg';


 
const PaymentFail = () => {
  
  const { paymentId, userId } = useParams();
  
  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
  console.log("authdataappjs", authlocalstoragedata)
  
  const [joinNowRes,setjoinNowRes]= useState()

  const [updatedData, setupdatedData] = useState(false);

  useEffect(() => {
   loadUpdatedData();
  }, []);

  const loadUpdatedData = async () => {
    debugger;
    const result = await axios.get(`${configData.ApiUrl}/getUpdatedData?userId=${authlocalstoragedata.userAuthenticationId}`);
    setupdatedData(result.data.data);
    if (result.data.data != null) {

     localStorage.setItem('JoinNowData',JSON.stringify(result.data.data));
     setjoinNowRes(result.data.data)
     debugger;
     localStorage.setItem('name',result.data.data.name);
     history.push("/JoinNow/null");


 }
    
    console.log("updatedadata",updatedData)
  };

  return (
    <div className="container">
        <div className="margintop  row"></div>
    <div className="margintop  row ">
        <div className="col-2"></div>
          <div className="col-9 form-containerwhite">
            <div className="margintop textcenter">
            <img src={payment} class='margintop'/>
            <img src={success} class='succimg1 '/>
            </div>
            <div className="margintop row"></div>

            <div className="textcenter margintop">
              <span className="paidfail-text"> Your Payment to Naidu Directory  Org.failed</span>
            </div>
            <div className="margintop3 row"></div>
            <div className="textcenter margintop">
              <span className="recivemail-text">Try making the payment wih different payment option. In case of any queries you can drop an  </span>
              <div ><span className="recivemail-text"> email to support@XYZcommunity.com</span></div>
            </div>
            <div className="margintop row"></div>
            <div className="margintop row"></div>


            <div className="margintop textcenter">
              <button className="marginleftmins3 compl-propilebtn" onClick={loadUpdatedData}>Retry Payment</button>
            </div>
          </div>
          </div>
          </div>
  );
};
 
export default PaymentFail;

