import './AddNewsPopup.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect,useRef } from "react";
import AddNewsPopup from './AddNewsPopup';
import AddBlogPopup from './AddBlogPopup';
import {FaInfoCircle} from 'react-icons/fa';
import {MdAddBox} from 'react-icons/md';
import ReactTooltip from "react-tooltip";
import validator from "validator";
import imgupload from '../../images/imageupload.svg'

import axios from "axios";
import { toast } from "react-toastify";
import moment, { duration } from 'moment'
import {BsPlusLg} from 'react-icons/bs'
import {MdOutlineDeleteOutline} from 'react-icons/md'
import AddJobPop from './AddJobPop';
import {configData} from "../../Config"
import { YearData } from './YearData';


 
const AddKycPopup = props => {

  const [serviceList, setServiceList] = useState([{ service: "" }]);
const filearr=[];
  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index] = e.target.value;
    setServiceList(list);
debugger;


setKyc(preState => ({
       ...preState,
       reference : serviceList
     }))

    // setAppointment(preState => ({
    //   ...preState,
    //   "attachFiles" : e.target.files[0]
    // }))
    filearr.push(e.target.value);
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const handleServiceAdd = () => {
    setServiceList([...serviceList, { service: "" }]);
  };

    const [startDate, setStartDate] = useState(new Date());

    const handleChangedate= (e) => {
      debugger
     setStartDate(e)
     
     setKyc(preState => ({
      ...preState,
      "scheduledDate": (moment(e).format("DD MMM yyyy")),

    }))
    }
    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)

    const [kyc, setKyc] = useState({
      hostId:"",
      authorName:"",
      kycPerson:"",
      imageSource:"",
      kycContent:"",
      from:"",
      to:"",
      achievements:"",
      category:"",
      others:"",
      reference:[],
      publishStatus:"",
      scheduledDate:"",
      scheduledTime:"",
      
      })
      const [imgData, setImgData] = useState(null);
      const fileref = useRef(null);
      

      const [attachment, setAttachment] = useState(null)

      const [error, setError] = useState(false);
      const [MiddleNameEroor ,setMiddleNameEroor] = useState(false);
      const [referenceError, setReferenceError] = useState(false);

      const submitFormData = (e) => {
        debugger
        e.preventDefault();
        let hasEmptyReference = false;

        // Check if any of the references are empty
        for (let service of serviceList) {
          if (service.service?.trim() === "") {
            hasEmptyReference = true;
            break;
          }
        }
      
        if (hasEmptyReference) {
          setReferenceError(true); // Set error if any reference is empty
        } else {
          setReferenceError(false);
        }
        // checking if value of first name and last name is empty show error else take to step 2
        if (validator.isEmpty(kyc.kycPerson)) {
            setError(true);
        }
        else if (!/^[A-Za-z\s]*$/.test(kyc.kycPerson)) {
          setMiddleNameEroor(true);
        }
        else if (kyc.kycPerson.length > 75) {
          setError(true);

        }
        else if (validator.isEmpty(kyc.to)) {
          setError(true);
      }
      else if (validator.isEmpty(kyc.from)) {
        setError(true);
      }
      else if (validator.isEmpty(kyc.achievements)) {
        setError(true);
     }
      else if (validator.isEmpty(kyc.category)) {
       setError(true);
      }
      else if (validator.isEmpty(kyc.others)&& kyc.category=='Others') {
        setError(true);
    
     }
        else if (validator.isEmpty(kyc.imageSource)) {
            setError(true);
        }
        else if (hasEmptyReference) {
          setReferenceError(true); // This triggers the error display for the reference field
        } 
        
        else if (kyc.imageSource.length > 100) {
          setError(true);

        }
        else if (validator.isEmpty(kyc.kycContent)) {
          setError(true);
        }
        else if (kyc.kycContent.length > 7000) {
          setError(true);

        }
        
        else if (validator.isEmpty(kyc.publishStatus)) {
          setError(true);
    
        }
        else if (validator.isEmpty(kyc.scheduledDate) && kyc.publishStatus=='schedulelater') {
            setError(true);
        }
        else if (validator.isEmpty(kyc.scheduledTime) && kyc.publishStatus=='schedulelater') {
            setError(true);
        }
       else if (attachment == "" || attachment == null) {
        setError(true);
       }
       
       
    else {
      
      addKycDetails();      
      
      }
    };

    const addKycDetails =()=>{
      
      const formData = new FormData();

      if(kyc.authorName!=undefined){
        setKyc(preState =>({
          ...preState,
          "authorName":authlocalstoragedata?.name2
        }))
      }

      if(kyc.authorName!=undefined){
        setKyc(preState =>({
          ...preState,
          "hostId":authlocalstoragedata?.userAuthenticationId
        }))
      }
      
      setKyc(preState => ({
        ...preState,
        reference : serviceList
      }))
debugger;
      formData.append('kyc',JSON.stringify(kyc));
      formData.append('bannerImage', attachment);

      const response = axios.post(configData.ApiUrl+'/CommunityPost/createKycPost', formData)
      response.then(() => {
            toast.success("KYC Added Successfully!");
            props.handleClose(togglePopupKyc)
            setSelected("");

          })
         response.catch((err) => {
           alert("Getting error in featching data")

         });
         console.log("response", response?.data)
          console.log("kyc",kyc)
     }

     const banerImageChange = event=>{

      // if(event.currentTarget.files != null)
      //    {
      //      setAttachment(event.target.files[0]);
      //    }
      if (event.currentTarget.files[0]) {
        console.log("picture: ", event.target.files);
        setAttachment(event.currentTarget.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData(reader.result);
        });
        reader.readAsDataURL(event.currentTarget.files[0]);
      }
   }

    const [selected, setSelected] = useState('kyc');

const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  const [isOpenBlog, setIsOpenBlog] = useState(false);
  const togglePopupBlog = () => {
    setIsOpenBlog(!isOpenBlog);
  }
  const [isOpenkyc, setIsOpenkyc] = useState(false);
  const togglePopupKyc = () => {
    setIsOpenkyc(!isOpenkyc);
  }
  const handleChange = event => {
    setSelected(event.target.value);
    const { name, value, type, checked } = event.target;

    setKyc(preState => ({
      ...preState,
      [name]: type === 'checkbox' ? checked : value
    }))
    if(event.currentTarget.files != null)
        {
            setKyc(event.target.files[0]);
        }
        if(kyc.authorName!=undefined){
          setKyc(preState =>({
            ...preState,
            "authorName":authlocalstoragedata?.name2
          }))
        }
  
        if(kyc.authorName!=undefined){
          setKyc(preState =>({
            ...preState,
            "hostId":authlocalstoragedata?.userAuthenticationId
          }))
        }

        setKyc(preState => ({
          ...preState,
          reference : serviceList
        }))
  };
 //for opening fields depends on radio cheked
 const [showhide, setShowhide]= useState("");


 const handleshow= e=>{
   const getshow= e.target.value;
   setShowhide(getshow);
 }

 const [isOpentopjobPop, setisOpentopjobPop] = useState(false);
  const togglePopuptopJobPop = () => {
    setisOpentopjobPop(!isOpentopjobPop);
  }
//for close popup when click on outside
const popupBlogdivRef = useRef(null);
const handleClickOutsideBlogtdiv = (event) => {
  if (popupBlogdivRef.current && !popupBlogdivRef.current.contains(event.target)) {
    props.handleClose();
    setSelected("");
  }
};
useEffect(() => {
  document.addEventListener('mousedown', handleClickOutsideBlogtdiv);
  return () => {
    document.removeEventListener('mousedown', handleClickOutsideBlogtdiv);
  };
}, []);

  return (
    <div className="popup-boxnews">
      <div className="boxnews" ref={popupBlogdivRef}>
                  <div className="topnewsrow"style={{paddingTop:"11px",paddingBottom:"2px"}}>
                  <div className="row " id="sharediv">
                    <div className="col-2"style={{marginTop:"-2px",}}><span>Share an</span></div>
                    <div className="col-2  textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onClick={togglePopup}
                    checked={selected === 'event'}
                    onChange={handleChange}
                    />  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>Event</label> */}
                     <label class="radiodivcust radiobtn-text1" >Event
                     <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onClick={togglePopup}
                    checked={selected === 'event'}
                    onChange={handleChange}
                    />   
                      <span class="checkmark"></span>
           </label>
                     {isOpen && <AddNewsPopup
      
      handleClose={togglePopup}
    />}
                    </div>
                    <div className="col-2  textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onChange={handleChange}
                    onClick={togglePopupBlog}
                    checked={selected === 'blog'}
                   value='blog'
                    />  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>Blog</label> */}
                     <label class="radiodivcust radiobtn-text1" >Blog
                     <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onChange={handleChange}
                    onClick={togglePopupBlog}
                    checked={selected === 'blog'}
                   value='blog'
                    />  
                      <span class="checkmark"></span>
           </label>
                     {isOpenBlog && <AddBlogPopup
      
                 handleClose={togglePopupBlog}
               />}
                    </div>
                    {/* <div className="col-2  textleft"> */}
                    {/* <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                      onChange={handleChange}
                      checked={selected === 'recipie'}
                      value='recipie'
                    />  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>Recipie</label> */}
                     {/* <label class="radiodivcust radiobtn-text1" >Recipie
                     <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                      onChange={handleChange}
                      checked={selected === 'recipie'}
                      value='recipie'
                    />  
                      <span class="checkmark"></span>
           </label> */}
                    {/* </div> */}
                    {/* <div className="col-2 whitenowrap  textleft"> */}
                    {/* <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onChange={handleChange}
                    checked={selected === 'vlog'}
                    value='vlog'
                    />  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>Vlog</label> */}
                     {/* <label class="radiodivcust radiobtn-text1" >Vlog
                     <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onChange={handleChange}
                    checked={selected === 'vlog'}
                    value='vlog'
                    />  
                      <span class="checkmark"></span>
           </label>
                    </div> */}
                    <div className="col-1 whitenowrap  textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                     onClick={togglePopupKyc}
                     onChange={handleChange}
                     checked={selected === 'kyc'}
                     value='kyc'
                    />  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>KYC</label> */}
                     <label class="radiodivcust radiobtn-text1" >KYC
                     <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                     onClick={togglePopupKyc}
                     onChange={handleChange}
                     checked={selected === 'kyc'}
                     value='kyc'
                    />  
                      <span class="checkmark"></span>
           </label>
                     {isOpenkyc && <AddKycPopup
      
                handleClose={togglePopupKyc}
               />}
                    </div>
                    <div className="col-2 whitenowrap  textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onClick={togglePopupKyc}
                    onChange={handleChange}
                    checked={selected === 'kyc'}
                    value='kyc'
                    />  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>KYC</label> */}
                     <label class="radiodivcust radiobtn-text1" style={{marginLeft:"10px"}} >Looking for Job Post
                     <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onClick={togglePopuptopJobPop}
                    onChange={handleChange}
                    checked={selected === 'job'} 
                    value='job'
                    />  
                      <span class="checkmark"></span>
           </label>
                     {isOpentopjobPop && <AddJobPop
      
      handleClose={togglePopuptopJobPop}
     />}
                    </div>

                  </div>
                  </div>
                  <form onSubmit={submitFormData}>
                  <div className='row'style={{overflowY:"scroll",height:"245px"}}>
                    
                    <div className="row margintop3">
                    <div className="col-3 textright whitenowrap"><span className="lbl ">Author Name</span></div>
                    <div className="col-7 textleft "><span className="complet-name"style={{fontSize:"13px"}}>{authlocalstoragedata?.name2}</span></div>
                </div>
                <div className="row margintop">
                    <div className="col-3 textright mt-1"><span className="lbl whitenowrap "> KYC Person</span></div>
                    <div className="col-5 textleft form-group">
                 <input type="text" class=" form-control" placeholder='First Name Middle Name Last Name' name='kycPerson' onChange={handleChange} style={{fontSize:"13px"}}/>
                  </div>
                  <div className='col-1'><FaInfoCircle color='#B5924C' size='1.2em'data-tip data-for="registerTip" className='kycinfo'/></div>
                  <ReactTooltip id="registerTip" place="right" backgroundColor='#B5924C' class='tooltip' effect="solid">
                    Name of person who you are writing for
                 </ReactTooltip>
                </div>
                <div className='row '>
                  <div className='col-3'></div>
               <div className="col-6">
               <span style={{ color: "red",fontSize:"12px" }} className={MiddleNameEroor?"":'hide'}>  Contains only charecters</span>

                                    {error && kyc.kycPerson == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                           Kyc Person  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {error &&  kyc.kycPerson.length >75 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Kyc Person  cannot exceed more than 75 characters.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 
  <div className="row margintop3">
                    <div className="col-3 textright mt-1"><span className="lbl whitenowrap "> Era</span></div>
                    <div className="col-3 textleft form-group">
                 <select   id="inputState" name='from'   
           onChange={handleChange}
           className="form-control textboxselect" style={{fontSize:'12px',textTransform:"capitalize"}}>
                          <option selected value="">Year(From)</option>
                {
                  
                  YearData.map((result) =>(
                  <option value={result.year}>{result.year}</option>
                  ) )

                }
               
            </select>
                 
                  </div>
                  <div className="col-3 textleft form-group">
                  <select   id="inputState" name='to'   
           onChange={handleChange}
           className="form-control textboxselect" style={{fontSize:'12px',textTransform:"capitalize"}}>
                          <option selected value="">Year(To)</option>
                          <option  value="Present">Present</option>

                {
                  
                  YearData.map((result) =>(
                  <option value={result.year}>{result.year}</option>
                  ) )

                }
               
            </select>                 
                  </div>
                  
                </div>
                <div className='row '>
                  <div className='col-3'></div>
               <div className="col-6">

                                    {error && kyc.from == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                           Year From is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {error && kyc.to == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                           Year To is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    
                                </div>
                                
                                
  </div> 
  <div className="row margintop3">
                    <div className="col-3 textright mt-1"><span className="lbl whitenowrap "> Achivements</span></div>
                    <div className="col-5 textleft form-group">
                
                    <textarea class="form-control" name='achievements'  id="exampleFormControlTextarea1" rows="3" placeholder="Enter Achivements or Contribution" onChange={handleChange} style={{fontSize:"12px"}}/>

                 
                  </div>
                  
                  
                </div>
                <div className='row '>
                  <div className='col-3'></div>
               <div className="col-6">

                                    {error && kyc.achievements == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                           Achivements  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    
                                </div>
                                
                                
  </div> 
                <div className="row margintop3">
                    <div className="col-3 textright mt-1"><span className="lbl whitenowrap "> Category</span></div>
                    <div className="col-5 textleft form-group">
                 <select   id="inputState" name='category'   
           onChange={handleChange}
           className="form-control textboxselect" style={{fontSize:'12px',textTransform:"capitalize"}}>
                          <option selected value="">Please Select</option>
                          <option  value="Community Service">Community Service</option>
                          <option  value="Personal Achivement">Personal Achivement</option>
                          <option  value="Social Contribution">Social Contribution</option>
                          <option  value="Entrepreneurship">Entrepreneurship</option>
                          <option  value="Education">Education</option>
                          <option  value="Medicine">Medicine</option>
                          <option  value="Art & Culture">Art & Culture</option>
                          <option  value="Sports">Sports</option>
                          <option  value="Other">Other</option>
                
               
            </select>
            <div className={kyc.category=="Other"?'margintop':"hide"}>
              {
    kyc.category=="others" && (
      <input type="text" className=" form-control" id="fst"
         name="others"
         placeholder="Category"
         onChange={handleChange}
         value={kyc.othercategory}
         style={{fontSize:'12px',textTransform:"capitalize"}}    />
    )
    }
            </div>
                 
                  </div>
                  
                  
                </div>
                <div className='row '>
                  <div className='col-3'></div>
               <div className="col-6">

                                    {error && kyc.category == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                           Category  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {error  && kyc.category=="" && kyc.category=='others' ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Other Category is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 

                <div className="row margintop3">
                    <div className="col-3 textright"><span className="lbl ">Banner Image</span></div>
                    <div className='col-7 margintop1'>
                            <label for="selectFile" name="bannerImage" className="" style={{ backgroudColor: "white" }}   >
                            {
                  imgData !=null ?(
                    <img id='image' className="playerProfilePic_home_tileblog"
                    src={imgData} 
               
                />
                  ):
                       ( <img src={imgupload}  className='imagePreview' style={{height:"160px"}} onChange={banerImageChange}/>)
                  }
                           </label>
                     <input id="selectFile" type="file" ref={fileref} onChange={banerImageChange} className="fileupload hide" 
                       name="bannerImage"
                        />
                        <div className={imgData ==null?'margintpmins1 kycimg-div':"hide"}><span className='photoupload-lbl textleft'>Add Banner Image to your blog</span></div>
                              </div>
                </div>
                
                <div className='row '>
                  <div className='col-3'></div>
               <div className="col-6">
               {error && attachment == null ? (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Banner Image is required.
                  </span>
                ) : (
                  ""
                )}
                                    
                </div>
                  </div> 
                
                <div className="row margintop">
                    <div className="col-3 mt-1 textright"><span className="lbl ">Image Source</span></div>
                    <div className="col-5 textleft form-group">
                    <input type="text" class=" form-control" placeholder='Add Image Source' onChange={handleChange} name='imageSource' style={{fontSize:"13px"}}/>
                   </div>
                   <div className='col-1'>
                    <FaInfoCircle color='#B5924C' data-tip data-for="registerTip1" size='1.2em' className='kycinfo'/></div>
                   <ReactTooltip id="registerTip1" place="right" backgroundColor='#B5924C' class='tooltip width' effect="solid">
                 References website where image is taken from
                 </ReactTooltip>
                </div>
                <div className='row '>
                  <div className='col-3'></div>
               <div className="col-6">
                                    {error && kyc.imageSource == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                           Image Source  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {error &&  kyc.imageSource.length >100 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        ImageSource  cannot exceed more than 100 characters.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                            </div> 
                
                
                <div className="row margintop3">
                    <div className="col-3 textright"><span className="lbl ">Add KYC Content</span></div>
                    <div className="col-9 textleft form-group">
                    <textarea class="form-control " id="exampleFormControlTextarea1" onChange={handleChange} rows="10" name='kycContent' placeholder="Kyc Content" style={{fontSize:"13px"}}/>
                        </div>
                </div>
                <div className='row '>
                  <div className='col-3'></div>
               <div className="col-6">
                                    {error && kyc.kycContent == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                           kyc Content  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {error &&  kyc.kycContent.length >7000 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        kyc Content  cannot exceed more than 7000 characters.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                            </div> 

                <div className="row margintop1" style={{whiteSpace:'nowrap'}}>
                    <div className="col-3 textright margintop" ><span className="lbl ">Add References</span></div>
                    {/* <div className="col-5 textleft form-group">
                    <input type="text" class="form-control" placeholder='Add Content Source' name='reference' style={{fontSize:"13px"}}/>
                    </div> */}

                    {/* <div className='col-1'><MdAddBox color='#0000FF' className='kycinfo'  size='1.7em'/>
                    <FaInfoCircle color='#B5924C' size='1.2em' data-tip data-for="registerTip2" className='marginleft2'/></div> */}
                    <div className='col-9'>
                    {serviceList.map((singleService, index) => (
      <div className='row' key={index}  style={{whiteSpace:'nowrap',marginTop:"0px"}}>
<div className=' '></div>
      <div  className=' col-8 margintop'>
      <input type="text" class="form-control" placeholder='Add Content Source' name='reference' style={{fontSize:"13px"}}
            value={singleService.service}
              onChange={(e) => handleServiceChange(e, index)}
     />
     <div className='row '>
                  <div className='col-3'></div>
               <div className="col-6">
               {referenceError && singleService.service === "" ? (
          <span style={{ color: "red", fontSize: "12px" }}>Reference is required.</span>
        ) : null}
                </div>
                  </div>
     
      </div>
      <div className='col-1 margintop'>
      <FaInfoCircle color='#B5924C' size='1.1em' data-tip data-for="registerTip2" className=''/>

      <ReactTooltip id="registerTip2" place="right" backgroundColor='#B5924C' class='tooltip width margintop1' effect="solid">
           References website where Content is taken from
           </ReactTooltip>
      </div>
      <div className=' col-1  margintop'>
      {serviceList.length - 1 === index && serviceList.length < 5 && (
        <BsPlusLg onClick={handleServiceAdd} size='1.1em'/>
                
              )}
      </div>
      <div className=' col-1 margintop'>
              {serviceList.length !== 1 && (
                <MdOutlineDeleteOutline size='1.5em' onClick={() => handleServiceRemove(index)}/>
                
              )}
      </div>
      
      </div>

        ))}
        </div>
                    
                </div>
                
                 
                <div className="row margintop">
                    <div className='col-3'></div>
                <div className="col-3 margintop1 textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews' 
                    name="addblogradio" value="publishnow" onClick={ handleshow }
                    />  
                     <label for="defaultRadio1" className='marginleft2 radiobtn-textblog'>Publish Now</label> */}
                     <label class="radiodivcust radiobtn-text1" style={{color:""}}>Publish Now
                     <input type="radio" id="radio1" className='radiobtnnews' 
                    name="publishStatus" value="publishnow" onClick={ handleshow } onChange={handleChange}
                    /> 
                      <span class="checkmark"></span>
           </label>
                    </div>
                    <div className="col-4 margintop1 textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews' 
                    onClick={ handleshow }
                    name="addblogradio" value="schedulelater"
                    />  
                     <label for="defaultRadio1" className='marginleft2 radiobtn-textblog'>Schedule for later</label> */}
                     <label class="radiodivcust radiobtn-text1" style={{color:""}}>Schedule for later
                     <input type="radio" id="radio1" className='radiobtnnews' 
                    onClick={ handleshow } onChange={handleChange}
                    name="publishStatus" value="schedulelater"
                    />  
                      <span class="checkmark"></span>
           </label>
                    </div>

                 </div>
                 <div className='row '>
                  <div className='col-3'></div>
               <div className="col-6">
                                    {error && kyc.publishStatus == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            This  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>   
                    
                    
                    
                 {
                    showhide==='schedulelater' && (
                    <div className='row'>
                      <div className='col-2'></div>
                    <div className='col-4 marginleftmins4 textright'>
                     <label  className="form-label schedulforlbl"> Schedule Date Time</label> 
                     </div>
                     <div className='col-3 textleft'>
                     <DatePicker
                    className="adreservdate heightfordt inputwidth"
                    name='scheduledDate'
                    selected={startDate}
                    onChange={date => handleChangedate(date)}
                    selectsStart // tells this DatePicker that it is part of a range*
                    startDate={startDate}
                    dateFormat="dd  MMM yyyy" 
                    minDate={moment().toDate()}

                />   
                 <div className='row '>
                  <div className=''></div>
               <div className="col-12">
                                    {error && kyc.scheduledDate == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Date is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>              
                     </div>
                     <div className="col-3 textleft"> 
                     <input type="time" name='scheduledTime' onChange={handleChange} className='heightfordt form-control'/>                                                 
                     <div className='row '>
                  <div className=''></div>
               <div className="col-12">
                                    {error && kyc.scheduledTime == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Time  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>                             
                    </div>
                    </div>

                    )

                    }  
                   

                  </div>
                  <div className='popupbtn-div margintop3'>

                  <div className='row '>
                    <div className='col-3'></div>
                    <div className='col-3'>
                        <button className='cancelbtn'  onClick={props.handleClose} style={{height:"42px",width:"140px"}}>Cancel</button>
                    </div>
                    <div className='col-3'>
                    {
                    showhide==='publishnow' && (
                     <button className='createeventbtn' type='submit' style={{height:"42px",width:"140px"}}>Publish KYC</button> 
                  )

                }    
                {
                    showhide==='schedulelater' && (
                      <div>
                     <button className='createeventbtn' type='submit' style={{height:"42px",width:"140px"}}>Schedule KYC</button> 
                      </div>
                      )

                }             
                
                 </div>

                  </div>
            </div>
                </form>  
      </div>
    </div>
  );
};
 
export default AddKycPopup;