import React, { useState, useEffect } from "react";
import '../JoinNow/JoinNow.css';
import {RiInformationFill} from 'react-icons/ri'
import { ModalHover } from 'react-modal-hover'
import InformPopup from "./InformPopup";
import csc from "country-state-city";
import Select from "react-select";
import validator from "validator";
import axios from "axios";
import { useHistory } from "react-router-dom";
import OtpInput from 'react-otp-input';
import ReactTooltip from "react-tooltip";
import {AiOutlineClose} from 'react-icons/ai'
import {FaRegComments,FaUserClock,FaFilter,FaInfoCircle,FaRegWindowClose,FaRegHandshake,FaEdit,FaWhatsapp,FaPhoneSquareAlt, FaLessThanEqual,} from 'react-icons/fa';
import { toast } from "react-toastify";
import {configData} from "../../Config"
import { event } from "jquery";

 
const ContactPrefrence = ({additional,setadditional,handleBack,handleNext}) => {

  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  

  const countries = csc.getAllCountries();
    //console.log(csc.getAllCountries())

    const updatedCountries = countries.map((country) => ({
        label: country.name,
        value: country.id,
        ...country
    }));

    const updatedStates = (countryId) => csc.getStatesOfCountry(countryId)
        .map((state) => ({ label: state.name, value: state.name, ...state }));

    const updatedCities = (stateId) =>
        csc
            .getCitiesOfState(stateId)
            .map((city) => ({ label: city.name, value: city.name, ...city }));

    const CountriesForCurrentAddress = countries.map((country) => ({
              label: country.name,
              value: country.id,
              ...country
          }));


          const updatedStatesForCurrent = (countryId) => csc.getStatesOfCountry(countryId)
        .map((state) => ({ label: state.name, value: state.name, ...state }));

    const updatedCitiesForCurrent = (stateId) =>
        csc
            .getCitiesOfState(stateId)
            .map((city) => ({ label: city.name, value: city.name, ...city }));



            useEffect(() => {
              console.log(additional);
          }, [additional]);


        const [selectedVal, setSelectedVal] = useState({
            label:additional.currentCountry,
            id:additional.currentCountryId
        });
        const [selectedValstate, setSelectedValstate] = useState({
          label:additional.currentState,
          id:additional.currentstateId
      });
      const [selectedValcity, setSelectedValcity] = useState({
          label:additional.currentCity,
          id:additional.currentcityId
      });

      //defauld selected 

      const [homeCountryDefault, sethomeCountryDefault] = useState({
        label:additional.homeCountry,
        id:additional.countryId
    });

    const [homeStateDefault, sethomeStateDefault] = useState({
      label:additional.homeState,
      id:additional.stateId
  });

  const [cityDefault, setcityDefault] = useState({
    label:additional.homeCity,
    id:additional.cityId
});

//for diabling checkbox
const [showCheckbox, setshowCheckbox] = useState(null)
const [showCheckbox1, setshowCheckbox1] = useState(null)


        const handleChange = (e) => {
          debugger;
          const { name, value, type, checked } = e.currentTarget;
  
        if ( name =='mobileVisibility' && value=='1') {
            
            setadditional(preState => ({
              ...preState,
              "mobileVisibility": 1
          }));

          }
          if ( name =='mobileVisibility' && value=='0' ) {
            
            setadditional(preState => ({
              ...preState,
              "mobileVisibility": 0
          }));

          }

          setadditional(preState => ({
              ...preState,
              [name]: type === 'checkbox' ? checked : value
          }));
         //for disabling other checkbox
         
         if ( name == "sameashomeAddress"  ) {
            if( checked==true){
              setshowCheckbox(true)

            }
            else{
              setshowCheckbox(false)
    
            }

        }
        
          if ( name == "sameashomelocation"  ) {
            if( checked==true){

            setshowCheckbox1(true)

          }
          else{
            setshowCheckbox1(false)

          }
        }


debugger
          if (name == "sameashomelocation" && checked) {
            setSelectedVal({label:additional.homeCountry,id:additional.countryId})
            setSelectedValstate({label:additional.homeState,id:additional?.stateId})
            setSelectedValcity({label:additional.homeCity,id:additional?.cityId})

            additional.currentCountryCode=null;
            additional.currentCountry=null;
            additional.currentStateCode=null;
            additional.currentState=null;
            additional.currentCityCode=null;
            additional.currentCity=null;

            setadditional(preState => ({
              ...preState,
              "currentPinCode": additional.homePinCode,
              "currentCountry":additional.homeCountry,
              "currentCountryId" : additional.countryId,
              "currentState":additional.homeState,
              "currentstateId": additional.stateId,
              "currentCity":additional.homeCity,
              "currentcityId":additional.cityId

          }));

          }
          if (name == "sameashomelocation" && !checked) {
            setSelectedVal({label:"",id:""})
            setSelectedValstate({label:"",id:""})
            setSelectedValcity({label:"",id:""})

            setadditional(preState => ({
              ...preState,
              "currentPinCode": "",
              "currentCountry":"",
              "currentCountryId" : "",
              "currentState":"",
              "currentstateId": "",
              "currentCity":"",
              "currentcityId":""
          }));

          }
          

          if (name == "sameashomecountry" && checked) {
            setSelectedVal({label:additional.homeCountry,id:additional.countryId});

            setadditional(preState => ({
              ...preState,
              "currentCountry":additional.homeCountry,
              "currentCountryId" : additional.countryId

          }));


          }
          if (name == "sameashomecountry" && !checked) {
            setSelectedVal({label:"",id:""});
            setadditional(preState => ({
              ...preState,
              "currentCountry":"",
              "currentCountryId" : ""
          }));

          }
          if (name == "sameashomestate" && checked) {
            setSelectedValstate({label:additional.homeState,id:additional?.stateId});

            setadditional(preState => ({
              ...preState,
              "currentState":additional.homeState,
              "currentstateId": additional.stateId
            }));

          }
          if (name == "sameashomestate" && !checked) {
            setSelectedValstate({label:"",id:""});

            setadditional(preState => ({
              ...preState,
             
              "currentState":"",
              "currentstateId": "",
              

          }));

          }
          if (name == "sameashomecity" && checked) {
            setSelectedValcity({label:additional.homeCity,id:additional?.cityId});

            setadditional(preState => ({
              ...preState,
            
              "currentCity":additional.homeCity,
              "currentcityId":additional.cityId

          }));


          }
          if (name == "sameashomecity" && !checked) {
            setSelectedValcity({label:"",id:""});

            setadditional(preState => ({
              ...preState,
              "currentCity":"",
              "currentcityId":""

          }));

          }
          if (name == "sameashomezip" && checked) {
            setadditional({
              ...additional, 
              'currentPinCode': additional.homePinCode,              
            });
          }
          if (name == "sameashomezip" && !checked) {
            setadditional({
              ...additional, 
              'currentPinCode': "",              
            });
          }
          if (name == "sameashomeAddress" && checked) {
            
            setadditional(preState => ({
              ...preState,
              "currentAddress1": additional.homeAddress1,
              "currentAddress2":additional.homeAddress2,
              "currentLandmark":additional.homeLandmark
          }));

          }
          if (name == "sameashomeAddress" && !checked) {
            
            setadditional(preState => ({
              ...preState,
              "currentAddress1": "",
              "currentAddress2":"",
              "currentLandmark":""
          }));

          }

          if (name == "sameashomeAddress1" && checked) {
            
            setadditional(preState => ({
              ...preState,
              "currentAddress1": additional.homeAddress1
          }));

          }
          if (name == "sameashomeAddress1" && !checked) {
            
            setadditional(preState => ({
              ...preState,
              "currentAddress1": ""
          }));

          }

          if (name == "sameashomeAddress2" && checked) {
            
            setadditional(preState => ({
              ...preState,
              "currentAddress2": additional.homeAddress2
          }));

          }
          if (name == "sameashomeAddress2" && !checked) {
            
            setadditional(preState => ({
              ...preState,
              "currentAddress2": ""
          }));

          }
          if (name == "sameashomelandmark" && checked) {
            
            setadditional(preState => ({
              ...preState,
              "currentLandmark": additional.homeLandmark
          }));

          }
          if (name == "sameashomelandmark" && !checked) {
            
            setadditional(preState => ({
              ...preState,
              "currentLandmark": ""
          }));

          }
          
          
      }

      const countryHomeHandle=(event)=>{

        setadditional({ ...additional, homeCountry: event.name, countryId: event.id ,homeCountryCode: event.id})
        sethomeCountryDefault({...homeCountryDefault, label:event.name,id:event.id})
      }

      const stateHomeHandle=(event)=>{
        setadditional({ ...additional, homeState: event.name, stateId: event.id, homeStateCode: event.id })
        sethomeStateDefault({...homeStateDefault, label:event.name,id:event.id})
      }

      const cityHomeHandle=(event)=>{
        setadditional({ ...additional, homeCity: event.name, cityId: event.id,homeCityCode: event.id })
        setcityDefault({...cityDefault, label:event.name,id:event.id})
      }


      const countrycurrentHandle =(event)=>{
        debugger;
         setadditional({ ...additional, currentCountry: event.name, currentCountryId: event.id,currentCountryCode: event.id });
          setSelectedVal({...selectedVal, label:event.name,id:event.id})
                                    
      }
      const stateCurrentHandle=(event)=>{
         setadditional({ ...additional, currentState: event.name, currentstateId: event.id,currentStateCode: event.id })
         setSelectedValstate({...selectedValstate,label:event.name,id:event.id})

      }

      const cityCurrentHandle=(event)=>{
        setadditional({ ...additional, currentCity: event.name, currentcityId: event.id,currentCityCode: event.id })
        setSelectedValcity({...selectedValcity,label:event.name,id:event.id})

     }

        
      const [error, setError] = useState(false);
      const [errorVerify, setErrorVerify] = useState(false);


      const submitFormData = (e) => {
        debugger
        e.preventDefault();
    
        // checking if value of first name and last name is empty show error else take to step 2
        if (additional.homeCountry==""||additional.homeCountry==null) {
            setError(true);
        }
      //   if (additional.mobileVisibility==null) {
      //     setError(true);
      // }
        else if (additional.homeState==""||additional.homeState==null) {
            setError(true);
        }
        else if (additional.homeCity==""||additional.homeCity==null) {
          setError(true);
        }
        else if (additional.homePinCode==""||additional.homePinCode==null) {
          setError(true);
    
        }
        else if (additional.homeAddress1==""||additional.homeAddress1==null) {
            setError(true);
    
        }
        else if (additional.homeAddress2==""||additional.homeAddress2==null) {
          setError(true);
  
        }
        else if (additional.homeLandmark==""||additional.homeLandmark==null) {
        setError(true);

       }
       else if (additional.currentCountry==""||additional.currentCountry==null) {
       setError(true);

      }
      else if (additional.currentState==""||additional.currentState==null) {
      setError(true);

    }
    else if (additional.currentCity==""||additional.currentCity==null) {
      setError(true);

    }
    else if (additional.currentPinCode==""||additional.currentPinCode==null) {
      setError(true);

    }
    else if (additional.currentAddress1==""||additional.currentAddress1==null) {
      setError(true);

    }
    else if (additional.currentAddress2==""||additional.currentAddress2==null) {
      setError(true);

    }
    else if (additional.currentLandmark==""||additional.currentLandmark==null) {
      setError(true);

    }
    else if (localStorage.countryCode=="91") {
      setErrorVerify(true);

    }
  //   else if (additional.mobileVisibility==null) {
  //     setError(true);
  // }
        
        
        else {
          handleNext()
        }
    };
  
          //for home country city state
          const [countryData, setcountryData] = useState([]);

          useEffect(() => {
            loadCountryData();
          }, []);
          
          const loadCountryData = async () => {
            debugger
            const result = await axios.get(configData.ApiUrl+"/user/listOfCountries");
            setcountryData(result.data.data);
            console.log("country",result.data.data)
          };
          
          const handleCountryChange = (event) => {
            const [id, country] = event.target.value.split("-");
            setadditional((prevState) => ({
              ...prevState,
              homeCountryCode: id,
              homeCountry: country,
            }));
            loadStateData(id);
          };
          //for state city state
          const [stateData, setstateData] = useState([]);
          
          useEffect(() => {
          loadStateData();
          }, []);
          
          const loadStateData = async (countryId) => {
          debugger
          const result = await axios.get(`${configData.ApiUrl}/user/listOfStates?countryId=${countryId}`);
          setstateData(result.data.data);
          console.log("state",result.data.data)
          };
          
          const handleStateChange = (event) => {
          debugger
          const [id, state] = event.target.value.split("-");
          setadditional((prevState) => ({
          ...prevState,
          homeStateCode: id,
          homeState: state,
          }));
          loadCityData(id);
          
          };
          //for state city state
          const [cityData, setcityData] = useState([]);
          
          useEffect(() => {
          loadCityData();
          }, []);
          
          const loadCityData = async (stateId) => {
          debugger
          const result = await axios.get(`${configData.ApiUrl}/user/listOfCities?stateId=${stateId}`);
          setcityData(result.data.data);
          console.log("city",result.data.data);
          };
          
          const handleCityChange = (event) => {
          const [id, city] = event.target.value.split("-");
          setadditional((prevState) => ({
          ...prevState,
          homeCityCode: id,
          homeCity: city,
          }));
          };
    
    
     //for current country city state
     const [countryDataCurrent, setcountryDataCurrent] = useState([]);
    
     useEffect(() => {
      loadCurrentCountryData();
     }, []);
     
     const loadCurrentCountryData = async () => {
       debugger
       const result = await axios.get(configData.ApiUrl+"/user/listOfCountries");
       setcountryDataCurrent(result.data.data);
       console.log("country",result.data.data)
     };
     
     const handleCurrentCountryChange = (event) => {
       const [id, country] = event.target.value.split("-");
       setadditional((prevState) => ({
         ...prevState,
         currentCountryCode: id,
         currentCountry: country,
       }));
       loadStateDataCurrent(id);
     };
     //for state city state
     const [stateDataCurrent, setstateDataCurrent] = useState([]);
     
     useEffect(() => {
     loadStateDataCurrent();
     }, []);
     
     const loadStateDataCurrent = async (countryId) => {
     debugger
     const result = await axios.get(`${configData.ApiUrl}/user/listOfStates?countryId=${countryId}`);
     setstateDataCurrent(result.data.data);
     console.log("state",result.data.data)
     };
     
     const handleStateChangeCurrent = (event) => {
     debugger
     const [id, state] = event.target.value.split("-");
     setadditional((prevState) => ({
     ...prevState,
     currentStateCode: id,
     currentState: state,
     }));
     loadCityDataCurrent(id);
     
     };
     //for state city state
     const [cityDataCurrent, setcityDataCurrent] = useState([]);
     
     useEffect(() => {
      loadCityDataCurrent();
     }, []);
     
     const loadCityDataCurrent = async (stateId) => {
     debugger
     const result = await axios.get(`${configData.ApiUrl}/user/listOfCities?stateId=${stateId}`);
     setcityDataCurrent(result.data.data);
     console.log("city",result.data.data);
     };
     
     const handleCityChangeCurrent = (event) => {
     const [id, city] = event.target.value.split("-");
     setadditional((prevState) => ({
     ...prevState,
     currentCityCode: id,
     currentCity: city,
     }));
     };
    

const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
const JoinNowData = JSON.parse(localStorage.getItem('JoinNowData'));
console.log("authdata11",authlocalstoragedata)

if(authlocalstoragedata !=null && authlocalstoragedata?.userAuthenticationId !=null 
  && authlocalstoragedata?.name1 != null&& authlocalstoragedata?.mobileNumber !=null){
  localStorage.id = authlocalstoragedata?.userAuthenticationId;
  localStorage.name = authlocalstoragedata?.name1;
  localStorage.emailId = authlocalstoragedata?.emailId;
  localStorage.phoneNo = authlocalstoragedata?.mobileNumber;
  localStorage.countryCode = authlocalstoragedata?.countryCode;
  localStorage.status = authlocalstoragedata?.status;




}else{
  localStorage.name = JoinNowData.name;

  localStorage.id = JoinNowData.id;
  localStorage.emailId = JoinNowData.emailId;
  localStorage.phoneNo = JoinNowData.phoneNo;
  localStorage.countryCode = JoinNowData.countryCode;
  localStorage.status = JoinNowData.status;


}


    //for email verify
    const [lastnamePop ,setlastnamePop] = useState(false);
function lastNamepoupopen (isLastname){
  if(isLastname){
    debugger
    setlastnamePop(true);
    sendOtp();
  }
  else{
    setlastnamePop(false);
  }
}

// const [lastname,setLastname]= useState({
//   id:"",
  
//   emailId:""

//  })

// const [isOpendiv, setIsOpendiv] = useState(true);

  // const onNext = (isback) => {
  //  debugger
  //   if(isback){

  //     setIsOpendiv(true);
  //     setIsotpDiv(false);
  //   }
  //   else{
  //     setIsOpendiv(false);
  //     setIsotpDiv(true);
  //     sendOtp();
  //   }
  //   return
  // }
//   const [emailEroor ,setemailEroor] = useState(false);
//   const submitFormDataEmail = (e) => {
//     debugger
//     const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

//     e.preventDefault();

//     // checking if value of first name and last name is empty show error else take to step 2
//     if (validator.isEmpty(lastname.emailId)) {
//         setError(true);
//     }
//     else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(lastname.emailId)) {
//       setemailEroor(true);
//     }
    
    
    
    
//     else {
//       debugger
//       // if(isOtpDiv)
//       // {
//       sendOtp();
//     // }
//     }
// };

const sendOtp = async()=>{
  debugger
  const result = await axios.get(`${configData.ApiUrl}/user/sendEmailOTPForVerification?emailId=${localStorage.emailId}`);
  console.log("sendotp",result.data.data)
    if(result.data.code==200){
      toast.success("Otp sent!")
  
    }
    
     else if(result.data.code==409){
      toast.warn("Email Already Exist!");
     }
    else{
      toast.warn("Otp Does not sent!")
  
  
  
    }
  
  
  
  }

  const [enablejoin, setenableResend] = useState(false);
  const [otpNew, setOtpNew] = useState('');

  const handleVerifyOptp =async()=>{
    debugger;
    const result =await  axios.get(configData.ApiUrl+'/user/validateEmailOtpAccountSettings?emailId='+
    localStorage.emailId+ '&otp='+otpNew);
  console.log("sendotp",result.data.data)
    if(result.data.code==200){
      toast.success("Otp Verified!");
  
      }
    else{
      alert("fail")
  
    }
  
  
  }

  //resend otp
const resendOtp =async()=>{
  debugger;

  const result = await axios.get(configData.ApiUrl+'/user/resendUserOTP?mobileNo='+
  localStorage.mobileNumber+ '&emailId='+localStorage.emailId +'&countryCode='+localStorage.countryCode);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Sent!");

  }
  else{
    toast.warn("Otp Sent fail")

  }


}
  
  const counterandbtn =()=>{
    setCounter(30);
    setenableResend(false);
   resendOtp()     
  }
  
      const [counter, setCounter]= useState(46)
;
    useEffect(() =>{
      if(counter === 0){
        setenableResend(true);
    
      }
      
      const timer =
      counter > 0 && setInterval(() =>setCounter(counter-1), 1000);
      return () => clearInterval(timer);
    },[counter]);

    const mobileNumberFormate = localStorage.mobileNumber?.substr(0, 2) + 'xxxxxx' 
   + localStorage.mobileNumber?.substr(8, 9);
   
   


   //for lastname emeil
   let email = localStorage.emailId
   let chunks = email.split("@");
   let Email = `${chunks[0].slice(0, 3)}xxxxxx${chunks[0].substr(chunks[0].length - 3)}@${chunks[1]}`

    return (
    <div className="container">
      <form onSubmit={submitFormData}>
        <div className="row margintop">
                  <div className="col-lg-3 col-sm-12 col-md-12">
                    <span className="contactmain-labl">Contact prefrences:</span>
                  </div>
      </div>
      <div className="row margintop1" >
        <div className="textright col-lg-3 col-sm-12 col-md-12">
          <span className="lbl popinsfont" style={{fontSize:'11px'}}>Mobile Number</span>
        </div>
        <div className="col-lg-2 col-sm-12 col-md-12 textleft "><span className="complet-name" style={{fontSize:"13px"}}>{localStorage.phoneNo}</span></div>
       

      </div>



      <div className="row margintop1">
        <div className="col-lg-3 col-sm-12 col-md-3 textright"><span className="lbl popinsfont" style={{fontSize:'11px'}}>Email Id</span></div>
        <div className="col-lg-7 col-sm-12 col-md-3" style={{whiteSpace:"",display:"inline-block"}}>
          <span className="complet-name" style={{fontSize:"13px" }}>{localStorage.emailId}</span>
          {
          localStorage.countryCode =="+91"?
          (<span className="visibletext-spn colororg" onClick={() => lastNamepoupopen(true)} style={{cursor:"pointer",marginLeft:"7px"}}>Verify Now</span>
          ):
          (<span className="visibletext-spn"  style={{cursor:"pointer",color:"green",marginLeft:"7px"}}>Email Verified!</span>
          )
         }
          <span className={errorVerify?"visibletext-spn":"hide" } style={{cursor:"pointer",color:"red",marginLeft:"10px"}}>Plese Verify Your Email</span>
          </div>
         {/* <div className="col-lg-2 col-sm-12 col-md-3 textleft"> */}
          

         
          {/* </div>  */}

      </div>
      <div className="row margintop1"></div>
<div className="borderbott margintop row"></div>

<div className="row margintop3">
                  <div className="col-lg-3 col-sm-12 col-md-12">
                    <span className="contactmain-labl">Permanent Address:</span>
                  </div>
      </div>
      
    <div className="row margintop3">
        <div className="col-lg-3 col-md-12 col-sm-12 textright"><span className="lbl popinsfont" style={{fontSize:'12px'}}>Home Address</span></div>
        

      </div>
      <div className="row margintop3">
        <div className="col-lg-3 col-md-12 col-sm-12 textright fontsize16"><label for="contact" style={{fontSize:'12px',marginTop:"7px"}}> Address Line1 </label></div>
        <div className="col-lg-6 col-md-12 col-sm-12">

        <input type="text" className=" form-control" id="fst"
         name="homeAddress1"
         placeholder="Address1"
         style={{fontSize:'11px',height:"35px"}}
         onChange={handleChange}
         value={additional?.homeAddress1}
    />
    {error && additional.homeAddress1 == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Address is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
              
        </div>
       
        

      </div>

      <div className="row margintop3">
        <div className="col-lg-3 col-md-12 col-sm-12 textright fontsize16"><label for="contact" style={{fontSize:'12px',marginTop:"7px"}}> Address Line2 </label></div>
        <div className="col-lg-6 col-md-12 col-sm-12">

        <input type="text" className=" form-control" id="fst"
         name="homeAddress2"
         placeholder="Address2"
         style={{fontSize:'11px',height:"35px"}}
         onChange={handleChange}
         value={additional?.homeAddress2}

    />
    {error && additional.homeAddress2 == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Address is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
              
        </div>
        </div>
        <div className="row margintop3">
        <div className="col-lg-3 col-md-12 col-sm-12 textright fontsize16"><label for="contact" style={{fontSize:'12px',marginTop:"7px"}}> Home Landmark </label></div>
        <div className="col-lg-6 col-md-12 col-sm-12">

        <input type="text" className=" form-control" id="fst"
         name="homeLandmark"
         placeholder="HomeLandmark"
         style={{fontSize:'11px',height:"35px"}}
         onChange={handleChange}
         value={additional.homeLandmark}
    />
          {error && additional.homeLandmark == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Landmark is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}    
        </div>
        </div>

      

      <div className="row margintop3">
        <div className="col-lg-3 col-md-12 col-sm-12 textright"><span className="lbl popinsfont" style={{fontSize:'12px'}}>Home Location</span></div>
        

      </div>
      <div className="row margintop3">
        <div className="col-lg-3 col-md-12 col-sm-12 textright fontsize16"><label for="contact" style={{fontSize:'12px',marginTop:"7px"}}> Country </label></div>
        <div className="col-lg-6 col-md-12 col-sm-12">

        {/* <Select
              style={{fontSize:"11px"}}
                                    id="country"
                                    name="homeCountry"
                                    label="country"
                                    className="countrydrp "
                                    rules={{ required: true }}
                                    options={updatedCountries}
                                    value={homeCountryDefault}
                                    onChange={countryHomeHandle}
                                    // onChange={(event) =>
                                    //     setadditional({ ...additional, homeCountry: event.name, countryId: event.id ,homeCountryCode: event.id})
                                    // }


                                /> */}
                                 <select   id="countryy"  
                className=" form-control "
                name='homeCountry'
                // value={additional?.homeCountry}
                onChange={handleCountryChange}>
                <option selected value="">{additional?.homeCountry}</option>
                {
                  
                  countryData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.country}`}>{result.country}</option>
                  ) )

                }
                </select> 
                                {error && additional.homeCountry == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Country is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
              
        </div>
       
        

      </div>

      <div className="row margintop3">
        <div className="col-lg-3 col-md-12 col-sm-12 textright fontsize16"><label for="contact" style={{fontSize:'12px',marginTop:"7px"}}> State </label></div>
        <div className="col-lg-6 col-md-12 col-sm-12">

        {/* <Select
                      style={{fontSize:"11px"}}

                                    name="homeState"
                                    id="state"
                                    className="countrydrp"
                                    value={homeStateDefault}
                                    options={updatedStates(additional.countryId ? additional.countryId : null)}
                                   onChange={
                                    stateHomeHandle}
                                    // onChange={(event) =>
                                    //     setadditional({ ...additional, homeState: event.name, stateId: event.id, homeStateCode: event.id })
                                    // }
                               

                                /> */}
                                 <select   id="state"  
                className=" form-control "
                name='homeState'
                // value={additional?.homeState}
                onChange={handleStateChange}>
                <option selected value="">{additional?.homeState}</option>
                {
                  
                  stateData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.state}`}>{result.state}</option>
                  ) )

                }
                </select> 
                                {error && additional.homeState == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             State is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
              
        </div>
        </div>
        <div className="row margintop3">
        <div className="col-lg-3 col-md-12 col-sm-12 textright fontsize16"><label for="contact" style={{fontSize:'12px',marginTop:"7px"}}>City </label></div>
        <div className="col-lg-6 col-md-12 col-sm-12">

        <Select
                      style={{fontSize:"11px"}}

                                    id="city"
                                    className="countrydrp"
                                    name="homeCity"
                                    options={updatedCities(additional.stateId ? additional.stateId : null)}
                                    value={cityDefault}
                                    onChange={cityHomeHandle}
                                    // onChange={(event) =>
                                    //     setadditional({ ...additional, homeCity: event.name, cityId: event.id,homeCityCode: event.id })
                                    // }

                                />
                                {error && additional.homeCity == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             City is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
              
        </div>
        </div>
        <div className="row margintop3">
        <div className="col-lg-3 col-md-12 col-sm-12 textright fontsize16"><label for="contact" style={{fontSize:'12px',marginTop:"7px"}}>Zip Code</label></div>
        <div className="col-lg-6 col-md-12 col-sm-12">
        <input type="text" className=" form-control" id="fst"
         name="homePinCode"
         placeholder="Zip Code"
         onChange={handleChange}
         value={additional.homePinCode}   

          style={{fontSize:'11px',textTransform:"uppercase",height:"35px"}}
    />
             {error && additional.homePinCode == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Zip Code is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}  
        </div>
        </div>


   
        <div className="row margintop3">
                  <div className="col-lg-3 col-sm-12 col-md-12">
                    <span className="contactmain-labl">Current Address:</span>
                  </div>
      </div>
      
    <div className="row margintop3">
        <div className="col-lg-3 col-md-12 col-sm-12 textright"><span className="lbl popinsfont" style={{fontSize:'12px'}}>Current Address</span></div>
        
  <div class="form-check textleft  col-lg-3 col-sm-12 col-md-12">
    <label class="form-check-label">
    <input type="checkbox" id='Adrr' class="form-check-input chek" 
    defaultChecked={additional.sameashomeAddress?true:false}
     value={additional.sameashomeAddress} name="sameashomeAddress" onChange={handleChange}/>
    <span className="chekboxlblcomp">All as above</span>
    </label>
  </div>

      </div>
      <div className="row margintop3">
        <div className="col-lg-3 col-md-12 col-sm-12 textright fontsize16"><label for="contact" style={{fontSize:'12px',marginTop:"7px"}}> Address Line1 </label></div>
        <div className="col-lg-6 col-md-12 col-sm-12">

        <input type="text" className=" form-control" id="fst"
         name="currentAddress1"
         placeholder="Address1"
         style={{fontSize:'11px',height:"35px"}}
         value={additional.currentAddress1}
         onChange={handleChange}
    />
    {error && additional.currentAddress1 == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Address is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
              
        </div>
        <div className={showCheckbox==true || additional.sameashomeAddress==true?"hide":"form-check margintop1 textleft col-lg-3 col-sm-12 col-md-12"}>
    <label class="form-check-label">
    <input type="checkbox" class="form-check-input chek" value={additional.sameashomeAddress1} name="sameashomeAddress1" onChange={handleChange}/>
    <span className="chekboxlblcomp">Address1 as above</span>
    </label>
  </div>
        

      </div>

      <div className="row margintop3">
        <div className="col-lg-3 col-md-12 col-sm-12 textright fontsize16"><label for="contact" style={{fontSize:'12px',marginTop:"7px"}}> Address Line2 </label></div>
        <div className="col-lg-6 col-md-12 col-sm-12">

        <input type="text" className=" form-control" id="fst"
         name="currentAddress2"
         placeholder="Address2"
         style={{fontSize:'11px',height:"35px"}}
         value={additional.currentAddress2}

         onChange={handleChange}
    />
    {error && additional.currentAddress2 == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Address is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
        </div>
        <div className={showCheckbox==true || additional.sameashomeAddress==true?"hide":"form-check textleft margintop1 col-lg-3 col-sm-12 col-md-12"}>
    <label class="form-check-label">
    <input type="checkbox" class="form-check-input chek" value={additional.sameashomeAddress2} name="sameashomeAddress2" onChange={handleChange}/>
    <span className="chekboxlblcomp">Address2 as above</span>
    </label>
  </div> 
        </div>
        <div className="row margintop3">
        <div className="col-lg-3 col-md-12 col-sm-12 textright fontsize16"><label for="contact" style={{fontSize:'12px',marginTop:"7px"}}> Current Landmark </label></div>
        <div className="col-lg-6 col-md-12 col-sm-12">

        <input type="text" className=" form-control" id="fst"
         name="currentLandmark"
         placeholder="Current Landmark"
         style={{fontSize:'11px',height:"35px"}}
         value={additional.currentLandmark}

         onChange={handleChange}
    />
         {error && additional.currentLandmark == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Landmark is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}    
        </div>
        <div className={showCheckbox==true || additional.sameashomeAddress==true?"hide":"form-check textleft margintop1 col-lg-3 col-sm-12 col-md-12"}>
    <label class="form-check-label">
    <input type="checkbox" class="form-check-input chek" value={additional.sameashomelandmark} name="sameashomelandmark" onChange={handleChange}/>
    <span className="chekboxlblcomp">Landmark as above</span>
    </label>
  </div> 
        </div>

      

      <div className="row margintop3">
        <div className="col-lg-3 col-md-12 col-sm-12 textright"><span className="lbl popinsfont" style={{fontSize:'12px'}}>Current Location</span></div>
        <div className="form-check textleft  col-lg-3 col-sm-12 col-md-12">
    <label class="form-check-label">
    <input type="checkbox" id='loc' class="form-check-input chek"  name="sameashomelocation" 
    defaultChecked={additional.sameashomelocation?true:false}
    value={additional.sameashomelocation} onChange={handleChange}/>
    <span className="chekboxlblcomp">All as above</span>
    </label>
  </div> 

      </div>
      <div className="row margintop3">
        <div className="col-lg-3 col-md-12 col-sm-12 textright fontsize16"><label for="contact" style={{fontSize:'12px',marginTop:"7px"}}> Country </label></div>
        <div className="col-lg-6 col-md-12 col-sm-12">

        {/* <Select
                            style={{fontSize:"11px"}}

                                    // required
                                    id="country"
                                    name="currentCountry"
                                    label="country"
                                    className="countrydrp "
                                    rules={{ required: true }}
                                    options={CountriesForCurrentAddress}
                                    value={selectedVal}
                                    //selectedVal
                                    // onChange={(value) => {
                                    //   setValues({ country: value, state: null, city: null }, false);
                                    // }}
                                    // defaultValue={updatedCountries.filter((value) => value.name == 'India')}
                                    onChange={countrycurrentHandle}

                                      //  setadditional({ ...additional, currentCountry: event.name, currentCountryId: event.id,currentCountryCode: event.id }),
                                       // setSelectedVal({...selectedVal, label:additional.homeCountry,id:additional.countryId})
                                    //}

                                /> */}
                                <select   id="countryy"  
                className=" form-control "
                name='currentCountry'
                // value={additional?.currentCountry}
                onChange={handleCurrentCountryChange}>
                <option selected value="">{additional?.currentCountry}</option>
                {
                  
                  countryDataCurrent.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.country}`}>{result.country}</option>
                  ) )

                }
                </select>
                                {error && additional.currentCountry == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Country is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
              
        </div>
       
        <div className={showCheckbox1==true || additional.sameashomelocation==true?"hide":"form-check textleft margintop1 col-lg-3 col-sm-12 col-md-12"}>
    <label class="form-check-label">
    <input type="checkbox" class="form-check-input chek" value={additional.sameashomecountry} onChange={handleChange} name="sameashomecountry"/>
    <span className="chekboxlblcomp">Country as above</span>
    </label>
  </div>  

      </div>

      <div className="row margintop3">
        <div className="col-lg-3 col-md-12 col-sm-12 textright fontsize16"><label for="contact" style={{fontSize:'12px'}}> State </label></div>
        <div className="col-lg-6 col-md-12 col-sm-12">

        {/* <Select
                                   
                                   style={{fontSize:"11px"}}
                                   name="currentState"
                                    id="state"
                                    className="countrydrp"
                                    value={selectedValstate}
                                    options={updatedStatesForCurrent(additional.currentCountryId ? additional.currentCountryId : null)}
                                   
                                    onChange={
                                      //(event) =>
                                       // setadditional({ ...additional, currentState: event.name, currentstateId: event.id,currentStateCode: event.id })
                                       stateCurrentHandle
                                    }

                                /> */}
                                <select   id="state"  
                className=" form-control "
                name='currentState'
                // value={additional?.currentState}
                onChange={handleStateChangeCurrent}>
                <option selected value="">{additional?.currentState}</option>
                {
                  
                  stateDataCurrent.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.state}`}>{result.state}</option>
                  ) )

                }
                </select>
                                {error && additional.currentState == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             State is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
              
        </div>
        <div className={showCheckbox1==true || additional.sameashomelocation==true?"hide":"form-check textleft margintop1 col-lg-3 col-sm-12 col-md-12"}>
    <label class="form-check-label">
    <input type="checkbox" class="form-check-input chek"  onChange={handleChange} name="sameashomestate" value={additional.sameashomestate}/>
    <span className="chekboxlblcomp">State as above</span>
    </label>
  </div>
        </div>
        <div className="row margintop3">
        <div className="col-lg-3 col-md-12 col-sm-12 textright fontsize16"><label for="contact" style={{fontSize:'12px'}}>City </label></div>
        <div className="col-lg-6 col-md-12 col-sm-12">

        {/* <Select
                                    id="city"
                                    className="countrydrp"
                                    name="currentCity"
                                    value={selectedValcity}
                                    options={updatedCitiesForCurrent(additional.currentstateId ? additional.currentstateId : null)}
                                    
                                    onChange={
                                      // (event) =>
                                      //   setadditional({ ...additional, currentCity: event.name, currentcityId: event.id,currentCityCode: event.id })
                                      cityCurrentHandle
                                    }
                                    style={{fontSize:'11px'}}
                                /> */}
                                 <select   id="city"  
                className=" form-control "
                name='currentCity'
                value={additional?.currentCity}
                onChange={handleCityChangeCurrent}>
                <option selected value="">{additional?.currentCity}</option>
                {
                  
                  cityDataCurrent.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.city}`}>{result.city}</option>
                  ) )

                }
                </select>
                               {error && additional.currentCity == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             City is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
              
        </div>
        <div className={showCheckbox1==true || additional.sameashomelocation==true?"hide":"form-check textleft margintop1 col-lg-3 col-sm-12 col-md-12"}>
    <label class="form-check-label">
    <input type="checkbox" class="form-check-input chek" value={additional.sameashomecity} onChange={handleChange} name="sameashomecity"/>
    <span className="chekboxlblcomp">City as above</span>
    </label>
  </div> 
        </div>
        <div className="row margintop3">
        <div className="col-lg-3 col-md-12 col-sm-12 textright fontsize16"><label for="contact" style={{fontSize:'12px',marginTop:"7px"}}>Zip Code</label></div>
        <div className="col-lg-6 col-md-12 col-sm-12">
        <input type="text" className=" form-control" id="fst"
         name="currentPinCode"
         onChange={handleChange}
         value={additional.currentPinCode}
         placeholder="Zip Code"
         style={{fontSize:'11px',textTransform:"uppercase",height:"35px"}}
    />
             {error && additional.currentPinCode == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Zip Code is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}   
        </div>
        <div className={showCheckbox1==true || additional.sameashomelocation==true?"hide":"form-check textleft margintop1 col-lg-3 col-sm-12 col-md-12"}>
    <label class="form-check-label">
    <input type="checkbox" class="form-check-input chek" value={additional.sameashomezip} onChange={handleChange} name="sameashomezip"/>
    <span className="chekboxlblcomp">Zip as above</span>
    </label>
  </div>
        </div>


   
   
   
   
   
   
   
   
   
   
   
    
     
      


    <div className="margintop3 row">
            <div className="col-lg-3"></div>
            <div className="col-lg-3 col-sm-12 col-md-12 margintop3 textright">
            <button
               className="prevbtn sourcesansfont"
              type="submit"
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-lg-2 col-sm-12 margintop3">
          <button
            className="nextbtn1 sourcesansfont"
              variant="contained"
              color="primary"
              
            >
               Next
            </button>
            
            
          </div>
          <div className="margintop2  row"></div>

        

            <div className="row">

            <div className="margintop1 col-lg-12 col-sm-12 col-md-12 textleft">
            <div className="borderbott1 margintop3 "></div>

            <span className="alreadyspn1comp">
            You can change the Contact Preferences  and Location Details any time later in My Profile area.
        </span>
            </div>
           
            </div>

            
            
          </div> 
    
    </form>

    <div className={lastnamePop?"":"hide"}>
            <div className="popup-boxlastnamepop">
      <div className="boxlastnamepop">
        <form >
      
        
        <div className="">
        <div className="topnewsrowlastspellpop ">
            <div className="row">
                <div className="col-4"></div>
                <div className="col-7">
                <span className="lastpop-top-text ">Verify Email!</span>
            
                 </div>
                 <div className="col-1" >
                  <span className="lastpop-top-text" onClick={() => lastNamepoupopen(false)}><AiOutlineClose size="1.3em"/></span>
                 </div>
            </div>
           </div>
<div className="row margintop1">
  <div className='col-1'></div>
          <div className="margintop col-11">
            <span className="enterotptxt" style={{fontSize:'13px'}}>Enter the OTP send to your mobile number {mobileNumberFormate}</span>
            <div className='margintop3' style={{whiteSpace:'nowrap',textAlign:"center"}}>
              <span className='nri-text' style={{fontSize:'13px'}}>
                NRIs check the OTP sent on your registered email id {Email}
              </span>
            </div>
            </div>
            </div>
            
<div className='row margintop3'>
  <div className='col-3'></div>
  <div className='col-8'>
  <OtpInput
      value={otpNew}
      onChange={setOtpNew}
      numInputs={4}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} style={{width:"3rem",height:"3rem",textAlign:"center",borderColor:"EE7600"}} />}
    />
  </div>


</div>

            <div className=' row margintop '>
              <div className='col-2'></div>
              <div className=' col-9'>
              <span className='dindopt-txt'style={{fontSize:'13px'}}>Didn't receive OTP? <span className={enablejoin ?"resend-text resendtxt":'dindopt-txt dindopt-txt1'} onClick={resendOtp} >Resend</span>  in <span className='resend-text'>{counter} seconds</span></span>

              </div>
            </div>
            <div className='row margintop'>
                    <div className='col-2'></div>
                    <div className='col-4'>
                        <button className='cancelbtn'  style={{width:'130px',height:"42px"}} onClick={() => lastNamepoupopen(false)}>Cancel</button>
                    </div>
                    <div className='col-4'>
                    <button className='createeventbtn' style={{width:'130px',height:"42px",justifySelf:"right"}} onClick={handleVerifyOptp}>Submit</button>
                  </div>
                  {/* {isOpen && <SuccessPop
      
      handleClose={togglePopup}
    />} */}
                    <div className='col-1'></div>

                  </div>

        </div>
        </form>
</div>
          
        
        </div>
</div>
    </div>
  );
};
 
export default ContactPrefrence;