import React, { useState, useEffect, useCallback,useRef,useReducer } from "react";

import './UserLandingTest.css';
import clogo from '../../images/Naidus directory_Symbol.svg';
import profile from '../../images/sagarsir.svg';
import profileshital from '../../images/shitalnaidu.svg';

import { MdOutlineArrowDropDown, MdLocationOn, MdNotifications, MdWaterDrop } from 'react-icons/md';
import { Link } from "react-router-dom";
import { CgProfile } from 'react-icons/cg'
import { Box } from '@material-ui/core';
// import { Slider } from '@material-ui/core';
import AddNewsPopup from "./AddNewsPopup";
import eventlogo from '../../images/eventlogo.svg';
import eventimg1 from '../../images/event1.png';
import eventimg2 from '../../images/event2.png';
import eventimg3 from '../../images/event3.png';
import eventimg4 from '../../images/eventimg4jpg.jpg';
import { BiLike, } from 'react-icons/bi';
import { FaRegComments, FaUserClock, FaFilter,FaThumbsDown, FaInfoCircle,FaSearch,FaArrowLeft, FaRegHandshake, FaEdit, FaBookmark } from 'react-icons/fa';

import { AiOutlineDislike, AiFillLike, AiOutlineHeart,AiOutlineFilePdf ,AiFillHeart,AiOutlineClose,AiFillDislike} from 'react-icons/ai'
import { HiOutlineLogout } from 'react-icons/hi'
import { BsBookmark } from 'react-icons/bs'
import { RiCupFill, RiSendPlaneFill, RiTimeLine } from 'react-icons/ri';
import imgupload from '../../images/imageupload.svg'
import blogimg from '../../images/blog.png'
import kycimg from '../../images/kyc.png'
import AddBlogPopup from "./AddBlogPopup";
import AddKycPopup from "./AddKycPopup";
import flag from '../../images/india.png';
import subcast from '../../images/subcast.svg';
import refferal from '../../images/refferalsvg.svg';
import cups from '../../images/cups.svg';
import from from '../../images/from.svg';
import radius from '../../images/radius.svg';
import conprof from '../../images/coneect.svg';
import hotelpic from '../../images/u1165.png'
import bussslider from '../../images/bussslider.png'
import bussslider1 from '../../images/bussslider1.png'

import contactinfo from '../../images/contactinfo.png';
import connect from '../../images/connect.png';
import ContactInfoPopup from "./ContactInfoPopup";
import NotificationPopup from "./NotificationPopup";
import { MdAddBox, MdWarning,MdOutlineClose } from 'react-icons/md';
import SliderPage from "./SliderPage";
import BloodRequirePopup from "./BloodRequirePopup";
import MessagePopup from "./MessagePopup";
import { Badge, Button, Tab, Nav, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { MdClose } from 'react-icons/md'
import { event } from "jquery";
import { ImCross, ImMobile } from 'react-icons/im'
import { AiOutlineCalendar } from 'react-icons/ai'
import { TbCheckbox } from 'react-icons/tb'
import { FaWhatsapp, FaArrowRight, FaMapPin } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import ReferInviteCommMemberPopup from "../ViewProfile/ReferInviteCommMemberPopup";
import Gallery from "react-photo-gallery";
import Carousel1, { Modal, ModalGateway } from "react-images";
import { photos } from "./photos";
// import Carousel1 from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-grid-carousel'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import SignUpMatrimony from "../SignUpMatrimony/SignUpMatrimony";
import { BsThreeDots } from 'react-icons/bs';
import './Blockpopup.css';
import { MdArrowDropDown } from 'react-icons/md'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment, { duration } from 'moment'
import csc from "country-state-city";
import Select from "react-select";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import MySubscription from "./DataBussnes";
import SimpleImageSlider from "react-simple-image-slider";
import MyJobPosting from "./MyJobPosting";
import MyJobPostingMain from "./MyJobPostingMain";
import MySubscriptionMatrimony from "./MySubscriptionMatrimony";
import SignUpBusiness from "../SignUpBusiness/SignUpBusiness";
import JobAllSeePop from "./JobAllSeePop";
import ReactPaginate from 'react-paginate';
import Avatar from '@mui/material/Avatar';
import SliderPageNew from "./SliderPageNew";
import MessagePopupDirectory from "./MessegePopupDirectory";
import SponserBusiness from "./SponserBusiness";
import ViewProfCommunityDirectPop from "./ViewProfCommunityDirectPop.js";
import SubscribePreMiemPop from "./SubscribePremiemPop";
import SubscribePremiem from "./SubscribePremiemPop";
import BusinessFirstPage from "../SignUpBusiness/BusinessFirstPage";
import AddJobPop from "./AddJobPop";
import ContactViewPop from "./ContactViewPop";
import {configData} from "../../Config"
import ReactCountryFlag from "react-country-flag"
import { CounryflagJson, CountryflagJson } from './CountryflagJson';
import logo from '../../images/naidudismall.png';
import { IoPinSharp } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { IoMdCheckmark } from "react-icons/io";
import {FiMaximize2} from 'react-icons/fi'
import MessageExpandPopup from "./MessageExpandPopup.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const UserLandingNew = () => {
    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
    console.log("authdata11", authlocalstoragedata)
  
    const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
    const [uerCurrentCity, setuerCurrentCity] = useState(authlocalstoragedata?.currentCity);
  
    //for session
    // const [session ,setSession] = useState(false);
  
    // const gesessiondata = async () => {
    //    debugger;
    //    axios.get('/checkSession')
  
    //    .then(result =>{
    //      if(result.data.code == 501){
    //        setSession(true)  ;
    //         }
    //     else{
    //          history.push("/Login");
  
    //     }
    //      debugger;
    //      console.log(result.data.code);
    //    }).catch(error =>{
    //      console.log(error);
    //    })
    //  };
  
    //        console.log("checksession on campaign",session);
  
    // useEffect(() => {
    //     gesessiondata();
    //   }, [session]);
  
  //for new session for userlogin
   //for updaating authdata value
   
   const [updatedData, setupdatedData] = useState({});
  
  
    useEffect(() => {
      loadUpdatedData();
     }, []);
  
     const loadUpdatedData = async () => {
       debugger;
       const result = await axios.get(`${configData.ApiUrl}/getUpdatedData?userId=${uerAuthId}`);
       setupdatedData(result.data.data);
  
       console.log("updatedadataforuserlanding",result.data.data)
  
   //console.log("updatedadataforuserlanding+++++++",updatedData)
     };
     const [updatedDataMatrimonyid, setupdatedDataMatrimonyid] = useState(updatedData.matrimonyId);
     console.log("updatedadataforuserlanding+++++++",updatedData  )
  
  
    const [loadProfile, setloadProfile] = useState([]);
  
    useEffect(() => {
      loadProfilePic();
    }, [loadProfile]);
  
    const loadProfilePic = async () => {
  
      debugger;
      const result = await axios.get(`${configData.ApiUrl}/user/getProfilePicture?id=${uerAuthId}`);
      setloadProfile(result.data.data);
      console.log("profpic", result.data.data);
      const rootElement1 = document.getElementById('profpicdisablesave1');
  
      if(rootElement1){
        rootElement1.addEventListener('contextmenu', handleContextMenuimgdisable);
      }
      if(rootElement1){
        return () => {
          rootElement1.removeEventListener('contextmenu', handleContextMenuimgdisable);
        };
      }
      const rootElement4 = document.getElementById('profpicdisablesave4');
  
      if(rootElement4){
        rootElement4.addEventListener('contextmenu', handleContextMenuimgdisable);
      }
      if(rootElement4){
      return () => {
        rootElement4.removeEventListener('contextmenu', handleContextMenuimgdisable);
      };
    }
      
    };
  
    const avtarProf = authlocalstoragedata?.name2.substr(0, 2)
  
    //for paginatin
    //for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 5
  
  
    //for gallary
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
  
    const openLightbox = useCallback((event, { photo, index }) => {
      debugger
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);
  
    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };
  
  
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 4 // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 767, min: 464 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
      }
    };
  
    const sliderImageUrl = [
      //First image url
  
      {
        url:
          "https://picsum.photos/800/600?random=1"
      },
      {
        url:
          "https://picsum.photos/800/600?random=2"
      },
      {
        url:
          "https://source.unsplash.com/Dm-qxdynoEc/800x799"
      },
  
      {
        url:
          "https://picsum.photos/800/600?random=2"
      },
      {
        url:
          "https://picsum.photos/800/600?random=2"
      },
      //Second image url
  
    ];
  
    const images = [
       { url: bussslider1 },
       { url: bussslider },
      // { url: bussslider1 },
      // { url: bussslider1 },
      // { url: bussslider },
      // { url: bussslider1 },
      // { url: bussslider },
    ];
  
    const [profdivhide, setprofdivhide] = useState(false);
  
  
    //for like functionality
    const appReducer = (state, action) => {
      switch (action.type) {
        case 'HANDLE_LIKE':
          return {
            ...state,
            likes: state.likes + action.payload
          }
        case 'HANDLE_DISLIKE':
          return {
            ...state,
            dislikes: state.dislikes + action.payload
          }
        default:
          return state
      }
    }
  
  

    
    const [status, setStatus] = useState(null)
  
  
    const [isOpenMessagePop, setIsOpenMessagePop] = useState(false);
    const togglePopupMessage = (userId) => {
      debugger;
      if (!styleForMessgblock || !blockPop) {
        setIsOpenMessagePop(!isOpenMessagePop);
  
      }
      setstyleForMessgid(userId)
      //loadMesseges(userId)
  
    }
  
    //for community popup
    const [isOpenMessagePopDirect, setIsOpenMessagePopDirect] = useState(false);
    const [idforpop, setidforpop] = useState(0);
    const [styleForMessgBlockstatusComm, setstyleForMessgBlockstatusComm] = useState(null);
  
    const togglePopupMessageDirect = (id,blockStatus) => {
      setIsOpenMessagePopDirect(!isOpenMessagePopDirect);
      setidforpop(id);
      setstyleForMessgBlockstatusComm(blockStatus);
    }
    const [isOpenMessagePopViewDirect, setisOpenMessagePopViewDirect] = useState(false);
    const [idforcommpop, setidforcommpop] = useState(0);
    const [styleForMessgBlockstatusCommView, setstyleForMessgBlockstatusCommView] = useState(null);
  
    
    const toggleviewProfDiPop = (id,blockStatus) => {
      setisOpenMessagePopViewDirect(!isOpenMessagePopViewDirect);
      setidforcommpop(id);
      setstyleForMessgBlockstatusCommView(blockStatus)
    }
  
    
  
    const [isOpenNotification, setIsOpenNotification] = useState(false);
    const togglePopupNotify = () => {
      setIsOpenNotification(!isOpenNotification);
    }
  
    const [isOpenContactPopup, setisOpenContactPopup] = useState(false);
    const togglePopupContact = () => {
      setisOpenContactPopup(!isOpenContactPopup);
    }
  
    const [selected, setSelected] = useState('');
  
    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
  
      setIsOpen(!isOpen);
  
  
    }
  
    const [isOpenBlog, setIsOpenBlog] = useState(false);
    const togglePopupBlog = () => {
      setIsOpenBlog(!isOpenBlog);
    }
    const [isOpenkyc, setIsOpenkyc] = useState(false);
    const togglePopupKyc = () => {
      setIsOpenkyc(!isOpenkyc);
    }
    const [isOpentopjobPop, setisOpentopjobPop] = useState(false);
    const togglePopuptopJobPop = () => {
      setisOpentopjobPop(!isOpentopjobPop);
    }
    
  
    const [connectedStatus, setConnectedStatus] = useState(0);
  
    const handleChange = event => {
      debugger;
      setSelected(event.target.value);
  
      const { name, value, type, checked } = event.currentTarget;
  
  
      setcmntValue(preState => ({
        ...preState,
        [name]: type === 'checkbox' ? checked : value
      }))
  
      // setConnectedStatus(preState =>({
      //   ...preState,
      //   [name]:type ==='checkbox'?checked: value
      // }))
  
      if (type == "checkbox") {
        //loadMembers(event.currentTarget.checked);
        //setConnectedStatus(1);
        loadMembers(checked);
  
      }
  
  
  
    }
  
    const [sliderval, setSliderVal] = useState()
  
    const getValue = (e, val) => {
      setSliderVal(val);
    }
  
    const mark = [
      {
        value: 25,
        label: "25 Km",
      },
      {
        value: 50,
        label: "50 Km"
      },
      {
        value: 75,
        label: "75 Km"
      },
      {
        value: 100,
        label: "100 Km"
      }
  
  
    ]
  
  
  
    const [news, setNews] = useState(true);
    const [communityDirect, setcommunityDirect] = useState(false);
    const [matrimony, setMatrimony] = useState(false);
    const [communityMeet, setcommunityMeet] = useState(false);
    const [businessDirect, setbusinessDirect] = useState(false);
  
    const showNews = () => {
      setNews(true);
      setcommunityDirect(false);
      setMatrimony(false);
      setbusinessDirect(false);
      setcommunityMeet(false);
  
  
  
    }
  
    const showCommunityDirect = () => {
      setNews(false);
      setcommunityDirect(true);
      setMatrimony(false);
      setbusinessDirect(false);
      setcommunityMeet(false);
  
    }
    const showMatrimony = () => {
      setNews(false);
      setcommunityDirect(false);
      setMatrimony(true);
      setbusinessDirect(false);
      setcommunityMeet(false);
  
    }
    const showBusinessDirect = () => {
      setNews(false);
      setcommunityDirect(false);
      setMatrimony(false);
      setbusinessDirect(true);
      setcommunityMeet(false);
    }
    const showCommunityMeet = () => {
      setNews(false);
      setcommunityDirect(false);
      setMatrimony(false);
      setbusinessDirect(false);
      setcommunityMeet(true);
    }
  
  
    const [isOpenBloodPopup, setisOpenBloodPopup] = useState(false);
    const togglePopupBlood = () => {
      setisOpenBloodPopup(!isOpenBloodPopup);
    }
  
  
    // const [comment ,setComment] = useState(false);
    // const commentClick = () => {
    //   setComment(true);
    // }
    //logout
    
  
    
  
    let history = useHistory();
  
    const logoutclick = async() => {
      debugger
      const result = await axios.get(configData.ApiUrl+'/logout');
      localStorage.clear()
  
      history.push("/Login");
    }
  
    //for disable backbtn
    window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
      window.history.go(1);
  };
  
    //for bloodreq slider
  
  
  
    // const [bloodReqSlider, setbloodReqSlider] = useState([]);
  
    // useEffect(() => {
    //   loadBloodReqSlider();
    // }, []);
  
    // const loadBloodReqSlider = async () => {
  
    //   const result = await axios.get(`/bloodReq/getCommunityBloodRequirementsForMain?id=${uerAuthId}`);
    //   setbloodReqSlider(result.data.data);
    //   console.log("bldreqslider", result.data.data)
    // };
  
    const [loadPost, setloadPost] = useState([]);
  
    // useEffect(() => {
    //   loadPostList();
    // }, []);
    
    useEffect(() => {
      const intervalId = setInterval(() => {
        loadPostList();
      }, 5100); // Poll every 5 seconds
  
      return () => clearInterval(intervalId);
    }, []);
  
    const loadPostList = async () => {
  
  
      const result = await axios.get(`${configData.ApiUrl}/CommunityPost/getListOfPosts?id=${uerAuthId}`);
      setloadPost(result.data.data);
      console.log("postlist", result.data.data)
      debugger;
      //for pagination
      setTotalPages(Math.ceil(result.data.length / itemsPerPage));
  
      const rootElement5 = document.getElementById('profpicdisablesave5');
  
      if(rootElement5){
        rootElement5.addEventListener('contextmenu', handleContextMenuimgdisable);
      }
      if(rootElement5){
        return () => {
          rootElement5.removeEventListener('contextmenu', handleContextMenuimgdisable);
        };
      }
      const rootElement6 = document.getElementById('profpicdisablesave6');
  
      if(rootElement6){
        rootElement6.addEventListener('contextmenu', handleContextMenuimgdisable);
      }
      if(rootElement6){
        return () => {
          rootElement6.removeEventListener('contextmenu', handleContextMenuimgdisable);
        };
      }
      const rootElement7 = document.getElementById('profpicdisablesave7');
  
      if(rootElement7){
        rootElement7.addEventListener('contextmenu', handleContextMenuimgdisable);
      }
      if(rootElement7){
        return () => {
          rootElement7.removeEventListener('contextmenu', handleContextMenuimgdisable);
        };
      }
      const rootElement19 = document.getElementById('profpicdisablesave19');
  
      if(rootElement19){
        rootElement19.addEventListener('contextmenu', handleContextMenuimgdisable);
      }
      if(rootElement19){
        return () => {
          rootElement19.removeEventListener('contextmenu', handleContextMenuimgdisable);
        };
      }
    };
    //for pagination
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const subset = loadPost.slice(startIndex, endIndex);
  
    const handlePageChange = (selectedPage) => {
      setCurrentPage(selectedPage.selected);
    };
  
    //for addlikes
    const [postid, setPostid] = useState();
  
    // for(var i=0;i<loadPost.length;i++)
    // {
    //   debugger
    //   loadPost[i].postId=postid ;
    //   console.log(postid)
    // }
  
    const addLikes = async (id) => {
  
      const response = await axios.post(configData.ApiUrl+'/CommunityPost/like?postId=' + id + '&userId=' + uerAuthId)
      if (response.status == 200 && response.data.code) {
        loadPostList();
  
      }
      else {
        alert("Something Went Wrong..!")
      }
  
  
  
    }
  
  
    //for getlikesbyid
    const [LikeDiv, setLikeDiv] = useState(false);
    function Likepoupopen(isLike) {
      if (isLike) {
        setLikeDiv(true);
      }
      else {
        setLikeDiv(false);
      }
    }
  
    
    
    const { id } = useParams();
  
    const [likeData, setlikeData] = useState([]);
  
    useEffect(() => {
      //loadlikes();
    }, []);
  
    const loadLikesInfo = async (id) => {
      console.log(id)
      const result = await axios.get(`${configData.ApiUrl}/CommunityPost/getListOfLikesById?id=${id}`);
      setlikeData(result.data.data);
      //  debugger;
      console.log("likesdt", result.data.data);
  
      Likepoupopen(true);
    };
  
    //for contactinfopopup
    const [contactInfoDiv, setcontactInfoDiv] = useState(false);
    const [commanView, setcommanView] = useState({
      fullName: "",
      emailId: "",
      countryCode: "",
      mobileNumber: "",
      connectionStatus: "",
      mobileVisibility: ""
    })
    var info = "";
    const contactInfopoupopen = (id, isContInfo) => {
      if (id > 0) {
        info = commDirectory.filter((obj) =>
          id == obj.id)[0];
  
        //setcommanView({...commanView :info} );
        commanView.fullName = info.fullName;
  
  
        commanView.emailId = info.emailId;
        commanView.mobileVisibility = info.mobileVisibility;
        if (commanView.mobileVisibility == 0) {
          commanView.mobileNumber = info.mobileNumber.substr(0, 2) + 'xxxxxx'
            + info.mobileNumber.substr(8, 9);
        }
        else {
          commanView.mobileNumber = info.mobileNumber
        }
        commanView.connectionStatus = info.connectionStatus
      }
  
      console.log("conm View =", commanView);
  
      if (isContInfo) {
        setcontactInfoDiv(true);
      }
      else {
        setcontactInfoDiv(false);
      }
    }
  
    //for cofeediv
    const [coffeediv, setcoffeediv] = useState(false);
    const [coffeeView, setcoffeeView] = useState({
      hostId: "",
      hostName: "",
      date: "",
      city: "",
      venue: "",
      time: "",
      guests: [],
      attendeesCount: "",
      googleMapsLink: "",
      attendStatus: "",
      id: "",
      profilePicturePath:""
    })
    var info = "";
    const coffeeInfopoupopen = (id, isCoffeeInfo,attendStatus) => {
      if (id > 0) {
        info = coffeeMeets.filter((obj) =>
          id == obj.id)[0];
  
        //setcommanView({...commanView :info} );
        coffeeView.hostName = info.hostName;
        coffeeView.date = info.date;
        coffeeView.city = info.city;
        coffeeView.venue = info.venue;
        coffeeView.time = info.time;
        coffeeView.guests = info.guests;
        coffeeView.attendeesCount = info.attendeesCount;
        coffeeView.googleMapsLink = info.googleMapsLink;
        coffeeView.attendStatus = info.attendStatus;
        coffeeView.id = info.id;
  coffeeView.profilePicturePath = info.profilePicturePath
     }
      console.log("conm View =", coffeeView);
  
      if (isCoffeeInfo) {
        setcoffeediv(true);
      }
      else {
        setcoffeediv(false);
      }
    }
  
    //for comments
    const [comment, setComment] = useState(false);
    const [cmntEventId, setCmntEventId] = useState(0);
  
    function Commentpoupopen(commentopen) {
      if (commentopen) {
        setComment(true);
      }
      else {
        setComment(false);
      }
    }
    //for close popup when click on outside
  const popupcommentRef = useRef(null);
  const handleClickOutsideCommentdiv = (event) => {
    if (popupcommentRef.current && !popupcommentRef.current.contains(event.target)) {
      setComment(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideCommentdiv);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideCommentdiv);
    };
  }, []);
  
    const [commentData, setcommentData] = useState([]);
    const [cmntValue, setcmntValue] = useState({
      txtCmnt: ""
    });
  
  
  
  
    useEffect(() => {
      //loadlikes();
    }, []);
  
    const commentInfo = async (id) => {
      console.log(id)
      const result = await axios.get(`${configData.ApiUrl}/CommunityPost/getListOfCommentsById?id=${id}`);
      setcommentData(result.data.data.sort((a, b) => b.id - a.id));
      //  debugger;
      console.log("commentdt", result.data.data.sort((a, b) => b.id - a.id));
      setCmntEventId(id);
  
      Commentpoupopen(true);
      setcmntValue(preState => ({
        ...preState,
        "txtCmnt": ""
      }))
    };
    const [commentValiderr, setcommentValiderr] = useState(false)
  
    const handleKeypress = e => {
      //it triggers by pressing the enter key
      debugger
      if (e.key === "Enter") {
        saveComent(id);
  
      }
    };
  
    const saveComent = async (id) => {
      if (cmntValue.txtCmnt == "") {
        setcommentValiderr(true)
        return false;
      }
      else {
        setcommentValiderr(false)
        const response = await axios.post(configData.ApiUrl+'/CommunityPost/comment?postId=' + id + '&userId=' + uerAuthId + '&comment=' + cmntValue.txtCmnt)
        setcmntValue(preState => ({
          ...preState,
          "txtCmnt": ""
        }))
        debugger;
        loadPostList();
        commentInfo(id);
  
  
      }
  
  
    }
  
    const deleteComment = (id) => {
  
      const response = axios.delete(`${configData.ApiUrl}/CommunityPost/deleteComment?commentId=${id}`)
      response.then(() => {
        toast.success("Comment deleted Successfully!");
        loadPostList();
  
        commentInfo(id);
  
  
      })
      response.catch((err) => {
        alert("Getting error in featching data")
      });
  
  
    }
  
    //for community directory
    const [commDirectory, setcommDirectory] = useState([]);
  
    // useEffect(() => {
    //   loadCommDirectory();
    // }, []);
    useEffect(() => {
      const intervalId = setInterval(() => {
        loadCommDirectory();
      }, 3000); // Poll every 5 seconds
  
      return () => clearInterval(intervalId);
    }, []);
  
    const loadCommDirectory = async () => {
      debugger
      const result = await axios.get(`${configData.ApiUrl}/subscriber/getAllSubscribers?id=${uerAuthId}`);
      setcommDirectory(result.data.data);
      console.log("comdirectory",result.data.data)
      const rootElement8 = document.getElementById('profpicdisablesave8');
      const rootElement9 = document.getElementById('profpicdisablesave9');
      
      if(rootElement8){
        rootElement8.addEventListener('contextmenu', handleContextMenuimgdisable);
      }
      if(rootElement8){
        return () => {
          rootElement8.removeEventListener('contextmenu', handleContextMenuimgdisable);
        };
      }
      if(rootElement9){
        rootElement9.addEventListener('contextmenu', handleContextMenuimgdisable);
      }
      if(rootElement9){
        return () => {
          rootElement9.removeEventListener('contextmenu', handleContextMenuimgdisable);
        };
      }
      const rootElement20 = document.getElementById('profpicdisablesave20');
      if(rootElement20){
        rootElement20.addEventListener('contextmenu', handleContextMenuimgdisable);
      }
      if(rootElement20){
        return () => {
          rootElement20.removeEventListener('contextmenu', handleContextMenuimgdisable);
        };
      }
    };
  
  
    function handleContextMenuimgdisable(e) {
      debugger
      e.preventDefault(); // prevents the default right-click menu from appearing
    }
  
    //bookmark
    //for community directory
  
  
    const addCommDirectoryBookmark = async (id) => {
  
      const response = await axios.post(configData.ApiUrl+'/subscriber/bookMark?hostId=' + uerAuthId + '&userId=' + id)
      if (response.status == 200 && response.data.code) {
        //toast.success("Bookmarked Successfully!")
        loadCommDirectory();
  
      }
      else {
        alert("Something Went Wrong..!")
      }
    }
    //forconnection reqsent in commDirectory
    const [commDirectoryconReq, setcommDirectoryconReq] = useState(false);
  
    const addCommDirectoryConnect = async (id) => {
      debugger
      const response = await axios.post(configData.ApiUrl+'/connection/sendConnection?senderId=' + uerAuthId + '&recieverId=' + id)
      if (response.status == 200 && response.data.code) {
        //setcommDirectoryconReq(true)
        loadCommDirectory();
  
      }
      else {
        setcommDirectoryconReq(false)
        alert("Something Went Wrong..!")
      }
    }
  
    //for  getall connection of 
    const [connection, setconnection] = useState([]);
  
    // useEffect(() => {
    //   loadConnection();
    // }, []);
    useEffect(() => {
      const intervalId = setInterval(() => {
        loadConnection();
      }, 1500); // Poll every 5 seconds
  
      return () => clearInterval(intervalId);
    }, []);
  
    const loadConnection = async () => {
  
      const result = await axios.get(`${configData.ApiUrl}/connection/getListRecievedConnections?id=${uerAuthId}`);
      setconnection(result.data.data);
      console.log("connections", result.data.data)
      const rootElement3 = document.getElementById('profpicdisablesave3');
  
      if(rootElement3){
        rootElement3.addEventListener('contextmenu', handleContextMenuimgdisable);
      }
    };
    //for accept connection in my connection
    const acceptConnection = async (senderId) => {
      debugger
      const response = await axios.post(configData.ApiUrl+'/connection/acceptConnection?senderId=' + senderId + '&recieverId=' + uerAuthId)
      if (response.status == 200 && response.data.code) {
        toast.success("Successfully Accepted Connection Request")
  
        loadConnection();
  
      }
      else {
        alert("Something Went Wrong..!")
      }
    }
    //for Reject connection in my connection
    const rejectConnection = async (senderId) => {
      debugger
      const response = await axios.post(configData.ApiUrl+'/connection/rejectConnection?senderId=' + senderId + '&recieverId=' + uerAuthId)
      if (response.status == 200 && response.data.code) {
        toast.success("Successfully Rejected Connection Request")
        loadConnection();
  
      }
      else {
        alert("Something Went Wrong..!")
      }
    }
  
  
    //for load list of coffee meets
    const [coffeeMeets, setcoffeeMeets] = useState([]);
  
    // useEffect(() => {
    //   loadCoffeeMeets();
    // }, []);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        loadCoffeeMeets();
      }, 5000); // Poll every 5 seconds
  
      return () => clearInterval(intervalId);
    }, []);
  
    const loadCoffeeMeets = async (senderId) => {
  
      const result = await axios.get(`${configData.ApiUrl}/coffeeMeet/getListOfUpcomingCoffeeMeets?hostId=${uerAuthId}`);
      setcoffeeMeets(result.data.data);
      console.log("coffeemeet", result.data.data);
      const rootElement18 = document.getElementById('profpicdisablesave18');
  
      if(rootElement18){
        rootElement18.addEventListener('contextmenu', handleContextMenuimgdisable);
      }
      if(rootElement18){
        return () => {
          rootElement18.removeEventListener('contextmenu', handleContextMenuimgdisable);
        };
      }
      if(coffeediv && senderId>0){
        var info = "";
          if (senderId > 0) {
            info = result.data.data.filter((obj) =>
            senderId == obj.id)[0];
      
            //setcommanView({...commanView :info} );
            coffeeView.hostName = info.hostName;
            coffeeView.date = info.date;
            coffeeView.city = info.city;
            coffeeView.venue = info.venue;
            coffeeView.time = info.time;
            coffeeView.guests = info.guests;
            coffeeView.attendeesCount = info.attendeesCount;
            coffeeView.googleMapsLink = info.googleMapsLink;
            coffeeView.attendStatus = info.attendStatus;
            coffeeView.id = info.id;
      coffeeView.profilePicturePath = info.profilePicturePath
         }
  
      }
    };
  
    //for my coffee meet search functionality
    //const [searchVal, setSearchVal] = useState("");
    const handleSearchChange = (e) => {
      debugger
      if (e.currentTarget.value === "") {
        loadCoffeeMeets();
        return;
      }
      const filterBySearch = coffeeMeets.filter((item) => {
        if (item != null && item.hostName != null) {
          if (item?.hostName?.toLowerCase().includes(e.currentTarget.value)) { return item; }
        }
        // if ( item != null && item.profession != null){
        //   if (item?.profession?.toLowerCase()
        //       .includes(e.currentTarget.value)) { return item; }}
      })
      setcoffeeMeets(filterBySearch);
    }
  
    //second filter
    //for filter
    const [pendingDIv, setpendingDIv] = useState(false);
    const [acceptedDiv, setacceptedDiv] = useState(false);
    const [allDiv, setallDiv] = useState(false);
  
  
    const [filter, setfilter] = useState({
      filterstatus: ""
    });
  
    const handleChangeFilter = (e) => {
      debugger
      const { name, value, type, checked } = e.currentTarget;
  
  
      setfilter(preState => ({
        ...preState,
        [name]: type === 'checkbox' ? checked : value
      }));
  
      if (e.target.value == "Pending") {
        //setisfilterdata( e.target.value )
        //setAadharDIv(true);
        setpendingDIv(true);
        setacceptedDiv(false);
        setallDiv(false);
  
      }
      else if (e.target.value == "accepted") {
        //setAadharDIv(false);
        setacceptedDiv(true);
        setpendingDIv(false);
        setallDiv(false);
      }
  
      else {
        setallDiv(true);
        setpendingDIv(false);
        setacceptedDiv(false)
      }
  
  
    }
  
  
    const [countmenu, setData] = useState([]);
  
    // useEffect(() => {
    //   loadUsers();
    // }, []);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        loadUsers();
      }, 1000); // Poll every 5 seconds
  
      return () => clearInterval(intervalId);
    }, []);
  
  
    const loadUsers = async () => {
      const result = await axios.get(`${configData.ApiUrl}/subscriber/getCountForInvitations?id=${uerAuthId}`);
  
      setData(result.data.data);
      console.log("getCountForInvitations", result.data.data)
  
    };
  
  
    const [isOpenMessagePop1, setIsOpenMessagePop1] = useState(false);
    const togglePopupMessage1 = () => {
      setIsOpenMessagePop1(!isOpenMessagePop1);
    }
  
    //for spampost
    const addSpam = async (id) => {
  
      const response = await axios.post(configData.ApiUrl+'/CommunityPost/spam?postId=' + id + '&userId=' + uerAuthId)
      if (response.status == 200 && response.data.code) {
        loadPostList();
  
      }
      else {
        alert("Something Went Wrong..!")
      }
  
    }
  
    const redirectTorefferal = () => {
  
      history.push("/ViewProfile");
  
    }
  
  
    //for members in my location
  
    const [members, setMembers] = useState([]);
  
    useEffect(() => {
      loadMembers();
    }, []);
  
  
    const loadMembers = async (checkedval) => {
      console.log("checkedval", checkedval)
      if (checkedval == true) {
  
        const result = await axios.get(configData.ApiUrl+'/user/getUsersNearMe?id=' + uerAuthId + '&connectedStatus=' + 1 + '&currentCity=' + uerCurrentCity);
  
        setMembers(result.data.data);
        console.log("getmembersinlocation", result.data.data)
        const rootElement2 = document.getElementById('profpicdisablesave2');
  
      if(rootElement2){
        rootElement2.addEventListener('contextmenu', handleContextMenuimgdisable);
      }
      if(rootElement2){
        return () => {
          rootElement2.removeEventListener('contextmenu', handleContextMenuimgdisable);
        };
      }
      }
      else {
  
        const result = await axios.get(configData.ApiUrl+'/user/getUsersNearMe?id=' + uerAuthId + '&connectedStatus=' + 0 + '&currentCity=' + uerCurrentCity);
  
        setMembers(result.data.data);
        console.log("getmembersinlocation", result.data.data);
        const rootElement2 = document.getElementById('profpicdisablesave2');
  
      if(rootElement2){
        rootElement2.addEventListener('contextmenu', handleContextMenuimgdisable);
      }
      if(rootElement2){
        return () => {
          rootElement2.removeEventListener('contextmenu', handleContextMenuimgdisable);
        };
      }
      }
  
  
  
    };
  
    const [isOpenSignMatriPopup, setisOpenSignMatriPopup] = useState(false);
    const togglePopupSignUpmatri = () => {
      setisOpenSignMatriPopup(!isOpenSignMatriPopup);
    }
  
    const [isOpenSignBusinessPopup, setisOpenSignBusinessPopup] = useState(false);
    const togglePopupSignUpBusiness = () => {
      setisOpenSignBusinessPopup(!isOpenSignBusinessPopup);
    }
  
    
    ///for mesenger
    const [userlist, setuserlist] = useState([]);
  
    // useEffect(() => {
    //   loadUserList();
    // }, []);
    
    useEffect(() => {
      const intervalId = setInterval(() => {
        loadUserList();
      }, 5000); // Poll every 5 seconds
  
      return () => clearInterval(intervalId);
    }, []);
  
  
    const loadUserList = async () => {
      const result = await axios.get(`${configData.ApiUrl}/messenger/getUsersListMessenger?hostId=${uerAuthId}`);
  
      setuserlist(result.data.data);
      console.log("getuserlist", result.data.data);
      const rootElement17 = document.getElementById('profpicdisablesave17');
  
      if(rootElement17){
        rootElement17.addEventListener('contextmenu', handleContextMenuimgdisable);
      }
      if(rootElement17){
        return () => {
          rootElement17.removeEventListener('contextmenu', handleContextMenuimgdisable);
        };
      }
    };
  
    //for search messg
    const handleSearchChangeMessage = (e) => {
      debugger
      if (e.currentTarget.value === "") {
        loadUserList();
        return;
      }
      const filterBySearch = userlist.filter((item) => {
        if (item != null && item.userName != null) {
          if (item?.userName?.toLowerCase().includes(e.currentTarget.value)) { return item; }
        }
         if ( item != null && item.lastMessage != null){
           if (item?.lastMessage?.toLowerCase()
               .includes(e.currentTarget.value)) { return item; }}
      })
      setuserlist(filterBySearch);
    }
  
  
    const [styleForMessg, setstyleForMessg] = useState(true);
    const [styleForMessgid, setstyleForMessgid] = useState(0);
    const [styleForMessgBlockstatus, setstyleForMessgBlockstatus] = useState("");
  
    const omMouseHover = (userId,blockStatus) => {
  
      setstyleForMessg(true);
      setstyleForMessgid(userId);
      setstyleForMessgBlockstatus(blockStatus)
  
    }
    const omMouseLeve = (userId) => {
  
      setstyleForMessg(false);
  
    }
  
    //for three dot for bpck unblock
    const [styleForMessgblock, setstyleForMessgblock] = useState(true);
    const [style, setStyle] = useState({ display: 'none' });
  
    const omMouseHoverblock = (userId) => {
  
      setIsOpenMessagePop(false)
      setstyleForMessgid(userId)
  
      //setStyle({display: 'block',border: '1px solid gray',  padding: 6, borderRadius:50});
      setstyleForMessgblock(true)
    }
    const omMouseLeveBlock = (userId) => {
  
      setstyleForMessgblock(false)
  
    }
  
  
    const addblockUnblock = async (userId) => {
  debugger
      const response = await axios.post(configData.ApiUrl+'/messenger/block-unblock?hostId=' + uerAuthId + '&userId=' + userId)
      if (response.status == 200 && response.data.code) {
        toast.success("Updated Block Status Successfully!");
        loadUserList();
        blockunblockpoupopen(false)
        setblockPop(false)
      }
      else {
        alert("Something Went Wrong..!")
      }
    }
  
    //for block popup
    const [blockPop, setblockPop] = useState(false);
  
    function blockunblockpoupopen(blockpopopen) {
      if (blockpopopen) {
        setblockPop(true);
      }
      else {
        setblockPop(false);
      }
    }
  
    const attendStatusCofeeMeet = async (id) => {
  
  
  
      debugger;
      const response = await axios.post(configData.ApiUrl+'/coffeeMeet/editGuestStatus?guestId=' + uerAuthId + '&coffeeMeetId=' + id)
      if (response.status == 200 && response.data.code) {
        toast.success("Update Status  Successfully!")
        loadCoffeeMeets(id);
  
      }
      else {
        alert("Something Went Wrong..!")
      }
    }
  
    //for matrimony
    const [matrimonyDiv, setmatrimonyDiv] = useState(true);
    const [matrimonyEditDiv, setmatrimonyEditDiv] = useState(false);
  
  
  
    const onNext = (isback) => {
      debugger;
      if (isback) {
        setmatrimonyDiv(true);
        setmatrimonyEditDiv(false)
      }
      else {
        setmatrimonyDiv(false);
        setmatrimonyEditDiv(true)
      }
    }
  
    const [startDate, setStartDate] = useState(new Date());
  
    const handleChangedate = (e) => {
      setStartDate(e)
      //  setBlog(preState => ({
      //   ...preState,
      //   "scheduledDate": (moment(e).format("DD MMM yyyy")),
  
      // }))
    }
    const [formData, setFormData] = useState({
  
      reqCountryId: "",
      reqCountry: "",
      reqStateId: "",
      reqState: "",
      reqCityId: "",
      reqCity: "",
  
  
    });
  
    const countries = csc.getAllCountries();
    ////console.log(csc.getAllCountries())
  
    const updatedCountries = countries.map((country) => ({
      label: country.name,
      value: country.id,
      ...country
    }));
  
    const updatedStates = (countryId) => csc.getStatesOfCountry(countryId)
      .map((state) => ({ label: state.name, value: state.name, ...state }));
  
    const updatedCities = (stateId) =>
      csc
        .getCitiesOfState(stateId)
        .map((city) => ({ label: city.name, value: city.name, ...city }));
  
  
  
  
    //for business directory
  
    const columns = [
      {
        name: "Job Id",
        selector: "id",
        sortable: true,
        width: "100px",
        cell: (d) => (
          <Link to={`/ViewJobPosting/${d.id}`} className="dlink">
            {d.id}
          </Link>
        ),
  
      },
  
      {
        name: "Date",
        selector: "createdDate",
        sortable: true,
  
      },
      {
        name: "Position",
        selector: "role",
        sortable: true,
        width: "100px"
      },
  
      {
        name: "Location",
        selector: "city",
        sortable: true,
        className: "texttransformcapitalize"
  
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        cell: (d) => [
          d.status == "Open" ? <span className="" style={{ color: '#70B603' }}>Open</span>
            : <span className="" style={{ color: '#FA114F' }}>Closed</span>
  
        ]
  
      },
  
  
    ];
  
    //  const data = [
    //     {
    //         jobId:"1002",
    //         date:"1 May 2023",
    //         position: "Sales Executive",
    //         location: "Pune",
    //          status: "Open",
  
  
    //       },
    //       {
    //         jobId:"1003",
    //         date:"1 May 2023",
    //         position: "Sales Executive",
    //         location: "Pune",
    //          status: "Closed",
  
    //         },
  
    //             ]
  
  
  
    const [data, setDataJob] = useState([]);
  
    const tableData = {
      columns,
      data,
  
    };
  
    useEffect(() => {
      loadJobList();
    }, []);
  
    const loadJobList = async () => {
      debugger
      const result = await axios.get(`${configData.ApiUrl}/buisness/getListJobPostings?buisnessId=${authlocalstoragedata?.buisnessId}`);
      setDataJob(result.data.data);
      console.log("getalljobpost", result.data.data)
  
  
    };
  
  
    const redirecttoViewProf = () => {
      history.push("/ViewProfile");
    }
    const redirectToReferal = () => {
      history.push("/RefferalMain");
    }
    const redirectToContact = () => {
      history.push("/ContactMain");
    }
    const redirectToContactRecive = () => {
      history.push("/ViewContactRecive");
    }
    const redirectToCoffeemeetInvite = () => {
      history.push("/ViewCoffeeMeetinvitatin");
    }
    const redirectMybussProfilemain = () => {
      history.push("/MybussProfileMain");
  
    }
    const redirectToAccSetting = () => {
      history.push("/AccountMain");
    }
    const redirectToBloodReq = () => {
      history.push("/BloodReqMain");
    }
  
    ///for matrimony all profile
    const [matrimonyAllProf, setmatrimonyAllProf] = useState([]);
  
    useEffect(() => {
      loadMatAllProf();
    }, []);
  
  
    const loadMatAllProf = async () => {
      const result = await axios.get(`${configData.ApiUrl}/matrimony/getMatrimonyProfiles?matrimonyId=${authlocalstoragedata?.matrimonyId}`);
  
      setmatrimonyAllProf(result.data.data);
      console.log("getAllMatProflist", result.data.data)
      const rootElement10 = document.getElementById('profpicdisablesave10');
  
       if(rootElement10){
         rootElement10.addEventListener('contextmenu', handleContextMenuimgdisable);
       }
       if(rootElement10){
         return () => {
           rootElement10.removeEventListener('contextmenu', handleContextMenuimgdisable);
         };
       }
    };
  
    ///for shortlisted  profile
    const [matrimonyShortProf, setmatrimonyShortProf] = useState([]);
  
    useEffect(() => {
      loadMatShortProf();
    }, []);
  
  
    const loadMatShortProf = async () => {
      const result = await axios.get(`${configData.ApiUrl}/matrimony/getMatrimonyShortlistedProfiles?matrimonyId=${authlocalstoragedata?.matrimonyId}`);
  
      setmatrimonyShortProf(result.data.data);
      console.log("getShortMatProflist", result.data.data);
      const rootElement16 = document.getElementById('profpicdisablesave16');
  
       if(rootElement16){
         rootElement16.addEventListener('contextmenu', handleContextMenuimgdisable);
       }
       if(rootElement16){
         return () => {
           rootElement16.removeEventListener('contextmenu', handleContextMenuimgdisable);
         };
       }
    };
    ///for Myprofile  profile
    const [matrimonyMyProf, setmatrimonyMyProf] = useState([]);
  
    useEffect(() => {
      loadMatMyProf();
    }, []);
  
  
    const loadMatMyProf = async () => {
      const result = await axios.get(`${configData.ApiUrl}/matrimony/getMatrimonyMyProfile?matrimonyId=${authlocalstoragedata?.matrimonyId}`);
  
      setmatrimonyMyProf(result.data.data);
      console.log("getMyMatProflist", result.data.data);
      const rootElement11 = document.getElementById('profpicdisablesave11');
  
       if(rootElement11){
         rootElement11.addEventListener('contextmenu', handleContextMenuimgdisable);
       }
       if(rootElement11){
         return () => {
           rootElement11.removeEventListener('contextmenu', handleContextMenuimgdisable);
         };
       }
  
       const rootElement12 = document.getElementById('profpicdisablesave12');
  
       if(rootElement12){
         rootElement12.addEventListener('contextmenu', handleContextMenuimgdisable);
       }
  
       if(rootElement12){
         return () => {
           rootElement12.removeEventListener('contextmenu', handleContextMenuimgdisable);
         };
       }
       const rootElement13 = document.getElementById('profpicdisablesave13');
  
       if(rootElement13){
         rootElement13.addEventListener('contextmenu', handleContextMenuimgdisable);
       }
       if(rootElement13){
         return () => {
           rootElement13.removeEventListener('contextmenu', handleContextMenuimgdisable);
         };
       }
       const rootElement14 = document.getElementById('profpicdisablesave14');
  
       if(rootElement14){
         rootElement14.addEventListener('contextmenu', handleContextMenuimgdisable);
       }
       if(rootElement14){
         return () => {
           rootElement14.removeEventListener('contextmenu', handleContextMenuimgdisable);
         };
       }
       const rootElement15 = document.getElementById('profpicdisablesave15');
  
       if(rootElement15){
         rootElement15.addEventListener('contextmenu', handleContextMenuimgdisable);
       }
       if(rootElement15){
         return () => {
           rootElement15.removeEventListener('contextmenu', handleContextMenuimgdisable);
         };
       }
    };
  
     ///for viewprofile  profile
     const [matrimonyViewProf, setmatrimonyViewProf] = useState([]);
  
     useEffect(() => {
      loadMatViewProf();
     }, []);
   
   
     const loadMatViewProf = async () => {
       const result = await axios.get(`${configData.ApiUrl}/matrimony/getMatrimonyMyProfile?matrimonyId=${authlocalstoragedata?.matrimonyId}`);
   
       setmatrimonyViewProf(result.data.data);
       console.log("getViewMatProflist", result.data.data)
   
     };
  
    //for Matrimony directory
  
  
    const addShortList = async (id) => {
  
      const response = await axios.post(configData.ApiUrl+'/matrimony/shortlist?hostMatrimonyId=' + authlocalstoragedata?.matrimonyId + '&userMatrimonyId=' + id)
      if (response.status == 200 && response.data.code) {
        //toast.success("Bookmarked Successfully!")
        //loadMatAllProf();
        const result = await axios.get(`${configData.ApiUrl}/matrimony/getMatrimonyProfiles?matrimonyId=${authlocalstoragedata?.matrimonyId}`);
  
      setmatrimonyAllProf(result.data.data);
      console.log("getAllMatProflist", result.data.data)
      const rootElement10 = document.getElementById('profpicdisablesave10');
  
       if(rootElement10){
         rootElement10.addEventListener('contextmenu', handleContextMenuimgdisable);
       }
       if(rootElement10){
         return () => {
           rootElement10.removeEventListener('contextmenu', handleContextMenuimgdisable);
         };
       }
      }
      else {
        alert("Something Went Wrong..!")
      }
    }
  
  
    ///for business listing  profile
    const [allbusinessList, setallbusinessList] = useState([]);
  
    useEffect(() => {
      loadBusinessList();
    }, []);
  
  
    const loadBusinessList = async () => {
      const result = await axios.get(`${configData.ApiUrl}/buisness/getBuisnessProfiles?buisnessId=${authlocalstoragedata?.buisnessId}`);
  
      setallbusinessList(result.data.data);
      console.log("getallbusiness", result.data.data)
  
    };
  
    ///for business myprofile
    const [myProfilebusinessList, setmyProfilebusinessList] = useState([]);
  
    useEffect(() => {
      loadBusinessMyProfile();
    }, []);
  
  
    const loadBusinessMyProfile = async () => {
      const result = await axios.get(`${configData.ApiUrl}/buisness/getBuisnessProfilesById?buisnessId=${authlocalstoragedata?.buisnessId}`);
  
      setmyProfilebusinessList(result.data.data);
      console.log("getmyprofbussness", result.data.data)
  
    };
  console.log("mybussprofbyid",myProfilebusinessList)
    //for job psting fr userlanding pg
    const [jobPostingList, setjobPostingList] = useState([]);
  
    useEffect(() => {
      loadJobPostingList();
    }, []);
  
  
    const loadJobPostingList = async () => {
      const result = await axios.get(`${configData.ApiUrl}/buisness/getListJobOpenings?buisnessId=${authlocalstoragedata?.buisnessId}`);
  
      setjobPostingList(result.data.data);
      console.log("jobPostingList", result.data.data)
  
    };
  
    //for opening job popup
    const [isOpenJobPop, setisOpenJobPop] = useState(false);
    const togglePopupJob = () => {
      setisOpenJobPop(!isOpenJobPop);
    }
  
    //for directory filter
  
    const [bloodGroupDIv, setbloodGroupDIv] = useState(false);
    const [countryDiv, setcountryDiv] = useState(false);
    const [stateDiv, setstateDiv] = useState(false);
    const [cityDiv, setcityDiv] = useState(false);
    const [professionDiv, setprofessionDiv] = useState(false);
  
  
    const [filterDirect, setfilterDirect] = useState({
      name: ""
    });
  
    const [filterdiData, setfilterdiData] = useState({
      city: "",
      country: "",
      state: "",
      bloodGroup: "",
      profession: ""
    })
  
    const handleChangeDirectFilter = (e) => {
      debugger
      const { name, value, type, checked } = e.currentTarget;
  
  
      setfilterdiData(preState => ({
        ...preState,
        [name]: type === 'checkbox' ? checked : value
      }));
  
      setfilterDirect(preState => ({
        ...preState,
        "name" : value
      }));
      
  
      if (e.target.value == "country") {
  
        setcountryDiv(true);
        setstateDiv(false)
        setcityDiv(false);
        setbloodGroupDIv(false);
        setprofessionDiv(false);
        //loadCommDirectory(e.target.value)
  
      }
      else if (e.target.value == "state") {
        //setAadharDIv(false);
        setcountryDiv(false);
        setstateDiv(true)
        setcityDiv(false);
        setbloodGroupDIv(false);
        setprofessionDiv(false);
        //loadCommDirectory(e.target.value)
  
  
      }
      else if (e.target.value == "city") {
        //setAadharDIv(false);
        setcountryDiv(false);
        setstateDiv(false)
        setcityDiv(true);
        setbloodGroupDIv(false);
        setprofessionDiv(false);
        //loadCommDirectory(e.target.value)
  
  
      }
      else if (e.target.value == "blood group") {
        //setAadharDIv(false);
        setcountryDiv(false);
        setstateDiv(false)
        setcityDiv(false);
        setbloodGroupDIv(true);
        setprofessionDiv(false);
        //loadCommDirectory(e.target.value)
  
      }
      else if (e.target.value == "profession") {
        //setAadharDIv(false);
        setcountryDiv(false);
        setstateDiv(false)
        setcityDiv(false);
        setbloodGroupDIv(false);
        setprofessionDiv(true);
        //loadCommDirectory(e.target.value)
  
      }
  
      else {
        setcountryDiv(false);
        setstateDiv(false)
        setcityDiv(false);
        setbloodGroupDIv(false);
        setprofessionDiv(false);
        //loadCommDirectory1
        if (e.target.value == "All") {
          loadCommDirectory1(e.target.value)
  
        }
        else if (e.target.value == "bookmark profile") {
          loadCommDirectory1(e.target.value)
  
         
        }
  
      }
  
  
    }
  
  
    const [filterCountryName, setfilterCountryName] = useState("")
  
    const handleChangeforFilterDirect = (e) => {
      debugger
      const { name, value, type, checked } = e.currentTarget;
  
  
      setfilterCountryName(e.target.value)
  
    }
  
    const filterCountry = (e) => {
      loadCommDirectory1('country')
    }
  
    const [filterStateName, setfilterStateName] = useState("")
  
    const handleChangeforFilterState = (e) => {
      debugger
      const { name, value, type, checked } = e.currentTarget;
  
      setfilterStateName(e.target.value)
  
    }
  
    const filterState = (e) => {
      loadCommDirectory1('state')
    }
  
    const [filterCityName, setfilterCityName] = useState("")
  
    const handleChangeforFilterCity = (e) => {
      debugger
      const { name, value, type, checked } = e.currentTarget;
  
      setfilterCityName(e.target.value);
  
    }
  
    const filterCity = (e) => {
      loadCommDirectory1('city')
    }
  
    const [filterBlooodName, setfilterBloodName] = useState("")
  
    const handleChangeforFilterBlood = (e) => {
      debugger
      const { name, value, type, checked } = e.currentTarget;
  
      setfilterBloodName(e.target.value);
  
    }
  
    const filterBlood = (e) => {
      loadCommDirectory1('blood group')
    }
    const [filterProfessionName, setfilterProfessionName] = useState("")
  
    const handleChangeforFilterProfession = (e) => {
      debugger
      const { name, value, type, checked } = e.currentTarget;
  
      setfilterProfessionName(e.target.value);
  
    }
  
    const filterProfession = (e) => {
      loadCommDirectory1('profession')
    }
  
  
    const loadCommDirectory1 = async (directFilter) => {
      debugger;
  
      if (directFilter == 'All') {
        debugger
        const result =  await axios.get(`${configData.ApiUrl}/subscriber/getAllSubscribers?id=${uerAuthId}`);
        setcommDirectory(result.data.data);
        console.log("comdirectory", result.data.data)
        const rootElement8 = document.getElementById('profpicdisablesave8');
    const rootElement9 = document.getElementById('profpicdisablesave9');
    
     if(rootElement8){
       rootElement8.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement8){
       return () => {
         rootElement8.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
     if(rootElement9){
       rootElement9.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement9){
       return () => {
         rootElement9.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
     const rootElement20 = document.getElementById('profpicdisablesave20');
     if(rootElement20){
       rootElement20.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement20){
       return () => {
         rootElement20.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
      }
      else if(directFilter=="bookmark profile"){
        const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + directFilter);
        setcommDirectory(result.data.data);
        console.log("comdirectory", result.data.data)
        const rootElement8 = document.getElementById('profpicdisablesave8');
    const rootElement9 = document.getElementById('profpicdisablesave9');
    
     if(rootElement8){
       rootElement8.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement8){
       return () => {
         rootElement8.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
     if(rootElement9){
       rootElement9.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement9){
       return () => {
         rootElement9.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
     const rootElement20 = document.getElementById('profpicdisablesave20');
     if(rootElement20){
       rootElement20.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement20){
       return () => {
         rootElement20.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
      }
      else if (directFilter == 'country') {
        debugger
  
        const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + filterDirect.name + '&country=' + filterCountryName);
        setcommDirectory(result.data.data);
        console.log("comdirectory", result.data.data)
        const rootElement8 = document.getElementById('profpicdisablesave8');
    const rootElement9 = document.getElementById('profpicdisablesave9');
    
     if(rootElement8){
       rootElement8.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement8){
       return () => {
         rootElement8.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
     if(rootElement9){
       rootElement9.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement9){
       return () => {
         rootElement9.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
     const rootElement20 = document.getElementById('profpicdisablesave20');
     if(rootElement20){
       rootElement20.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement20){
       return () => {
         rootElement20.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
      }
      else if (filterdiData.filterDirect == 'state') {
        debugger
  
        const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + filterDirect.name + '&state=' + filterStateName);
        setcommDirectory(result.data.data);
        console.log("comdirectory", result.data.data)
        const rootElement8 = document.getElementById('profpicdisablesave8');
    const rootElement9 = document.getElementById('profpicdisablesave9');
    
     if(rootElement8){
       rootElement8.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement8){
       return () => {
         rootElement8.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
     if(rootElement9){
       rootElement9.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement9){
       return () => {
         rootElement9.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
     const rootElement20 = document.getElementById('profpicdisablesave20');
     if(rootElement20){
       rootElement20.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement20){
       return () => {
         rootElement20.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
      }
      else if (filterdiData.filterDirect == 'city') {
        debugger
  
        const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + filterDirect.name + '&city=' + filterCityName);
        setcommDirectory(result.data.data);
        console.log("comdirectory", result.data.data)
        const rootElement8 = document.getElementById('profpicdisablesave8');
        const rootElement9 = document.getElementById('profpicdisablesave9');
        
         if(rootElement8){
           rootElement8.addEventListener('contextmenu', handleContextMenuimgdisable);
         }
         if(rootElement8){
           return () => {
             rootElement8.removeEventListener('contextmenu', handleContextMenuimgdisable);
           };
         }
         if(rootElement9){
           rootElement9.addEventListener('contextmenu', handleContextMenuimgdisable);
         }
         if(rootElement9){
           return () => {
             rootElement9.removeEventListener('contextmenu', handleContextMenuimgdisable);
           };
         }
         const rootElement20 = document.getElementById('profpicdisablesave20');
         if(rootElement20){
           rootElement20.addEventListener('contextmenu', handleContextMenuimgdisable);
         }
         if(rootElement20){
           return () => {
             rootElement20.removeEventListener('contextmenu', handleContextMenuimgdisable);
           };
         }
      }
      else if (filterdiData.filterDirect == 'blood group') {
        debugger
        const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + filterDirect.name + '&bloodGroup=' + filterBlooodName);
        setcommDirectory(result.data.data);
        console.log("comdirectory", result.data.data)
        const rootElement8 = document.getElementById('profpicdisablesave8');
      const rootElement9 = document.getElementById('profpicdisablesave9');
      
       if(rootElement8){
         rootElement8.addEventListener('contextmenu', handleContextMenuimgdisable);
       }
       if(rootElement8){
         return () => {
           rootElement8.removeEventListener('contextmenu', handleContextMenuimgdisable);
         };
       }
       if(rootElement9){
         rootElement9.addEventListener('contextmenu', handleContextMenuimgdisable);
       }
       if(rootElement9){
         return () => {
           rootElement9.removeEventListener('contextmenu', handleContextMenuimgdisable);
         };
       }
       const rootElement20 = document.getElementById('profpicdisablesave20');
       if(rootElement20){
         rootElement20.addEventListener('contextmenu', handleContextMenuimgdisable);
       }
       if(rootElement20){
         return () => {
           rootElement20.removeEventListener('contextmenu', handleContextMenuimgdisable);
         };
       }
      }
      else if (filterdiData.filterDirect == 'profession') {
        debugger
        const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + filterDirect.name + '&profession=' + filterProfessionName);
        setcommDirectory(result.data.data);
        console.log("comdirectory", result.data.data)
        const rootElement8 = document.getElementById('profpicdisablesave8');
    const rootElement9 = document.getElementById('profpicdisablesave9');
    
     if(rootElement8){
       rootElement8.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement8){
       return () => {
         rootElement8.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
     if(rootElement9){
       rootElement9.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement9){
       return () => {
         rootElement9.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
     const rootElement20 = document.getElementById('profpicdisablesave20');
     if(rootElement20){
       rootElement20.addEventListener('contextmenu', handleContextMenuimgdisable);
     }
     if(rootElement20){
       return () => {
         rootElement20.removeEventListener('contextmenu', handleContextMenuimgdisable);
       };
     }
      }
      else {
        debugger
        const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + filterDirect.name);
        setcommDirectory(result.data.data);
        console.log("comdirectory", result.data.data)
        const rootElement8 = document.getElementById('profpicdisablesave8');
      const rootElement9 = document.getElementById('profpicdisablesave9');
      
       if(rootElement8){
         rootElement8.addEventListener('contextmenu', handleContextMenuimgdisable);
       }
       if(rootElement8){
         return () => {
           rootElement8.removeEventListener('contextmenu', handleContextMenuimgdisable);
         };
       }
       if(rootElement9){
         rootElement9.addEventListener('contextmenu', handleContextMenuimgdisable);
       }
       if(rootElement9){
         return () => {
           rootElement9.removeEventListener('contextmenu', handleContextMenuimgdisable);
         };
       }
       const rootElement20 = document.getElementById('profpicdisablesave20');
       if(rootElement20){
         rootElement20.addEventListener('contextmenu', handleContextMenuimgdisable);
       }
       if(rootElement20){
         return () => {
           rootElement20.removeEventListener('contextmenu', handleContextMenuimgdisable);
         };
       }
      }
  
    };
  
    //   const loadCommDirectory = async (abc) => {
    //    debugger
    //    const result = await axios.get('/subscriber/getAllSubscribers?id='+uerAuthId+'&filter='+abc);
    //    setcommDirectory(result.data.data);
    //    console.log("comdirectory",result.data.data)
    //  };
  
    const handleSearchForDirectChange = (e) => {
      debugger
      if (e.currentTarget.value === "") {
        loadCommDirectory();
        return;
      }
      const filterBySearch = commDirectory.filter((item) => {
        if (item != null && item.fullName != null) {
          if (item?.fullName?.toLowerCase().includes(e.currentTarget.value)) { return item; }
        }
         if ( item != null && item.profession != null){
           if (item?.profession?.toLowerCase()
               .includes(e.currentTarget.value)) { return item; }
              }
        
          if ( item != null && item.subCaste != null){
             if (item?.subCaste?.toLowerCase()
                .includes(e.currentTarget.value)) { return item; }
                }
            if ( item != null && item.currentCity != null){
              if (item?.currentCity?.toLowerCase()
                 .includes(e.currentTarget.value)) { return item; }
                }
      })
      setcommDirectory(filterBySearch);
    }
  //disable download and rightclick on image
  // const noRightClick = document.getElementById("profpicdisablesave");
  // if(noRightClick){
  //   noRightClick.addEventListener("contextmenu", (e) => {e.preventDefault()});
  
  // }
  //disable download and rightclick on image
  // useEffect(() => {
  //   function handleContextMenu(e) {
  //     e.preventDefault(); // prevents the default right-click menu from appearing
  //   }
  //   // add the event listener to the component's root element
  //   const rootElement = document.getElementById('profpicdisablesave');
  //   rootElement.addEventListener('contextmenu', handleContextMenu);
  //   // remove the event listener when the component is unmounted
  
  //   return () => {
  //     rootElement.removeEventListener('contextmenu', handleContextMenu);
  //   };
  // }, []);
  
  
    //for nidudirectory
    const [isOpenNaiduDiPopup, setisOpenNaiduDiPopup] = useState(false);
    
    const togglePopupNaiduDi = () => {
      setisOpenNaiduDiPopup(!isOpenNaiduDiPopup);
    }
    //for businessdirectory
    const [isOpenbusinessDiPopup, setisOpenbusinessDiPopup] = useState(false);
    
    const togglePopupbussDi = () => {
      setisOpenbusinessDiPopup(!isOpenbusinessDiPopup);
    }
  
    const [isOpenJobPostContactPopup, setisOpenJobPostContactPopup] = useState(false);
    const toggleJobPostPopupContact = () => {
      setisOpenJobPostContactPopup(!isOpenJobPostContactPopup);
    }
  
    const [viewContactJobpostDiv, setviewContactJobpost] = useState(false);
    function ContactJobPostpoupopen(iscontactJobPost) {
      if (iscontactJobPost) {
        setviewContactJobpost(true);
      }
      else {
        setviewContactJobpost(false);
      }
    }
  
    const viewContactJobPost = async (id) => {
  
      debugger;
      const response = await axios.post(configData.ApiUrl+'/CommunityPost/viewJobContactDetails?userId=' + uerAuthId + '&postId=' + id)
      if (response.status == 200 && response.data.code) {
        // toast.success("Update Status  Successfully!")
        setviewContactJobpost(true);
      loadPostList();
      }
      else {
        alert("Something Went Wrong..!")
      }
    }
  
    const viewContactJobPostPdf = async (id) => {
  
      debugger;
      const response = await axios.post(configData.ApiUrl+'/CommunityPost/viewJobContactDetails?userId=' + uerAuthId + '&postId=' + id)
      if (response.status == 200 && response.data.code) {
        // toast.success("Update Status  Successfully!")
       // setviewContactJobpost(true);
      loadPostList();
      }
      else {
        alert("Something Went Wrong..!")
      }
    }
  
    //for bussness filter
  
  
    const handleSearchChangeBusiness = (e) => {
      debugger
      if (e.currentTarget.value === "") {
        loadBusinessList();
        return;
      }
      const filterBySearch = allbusinessList.filter((item) => {
        if (item != null && item.buisnessName != null) {
          if (item?.buisnessName?.toLowerCase().includes(e.currentTarget.value)) { return item; }
        }
         if ( item != null && item.city != null){
           if (item?.city?.toLowerCase()
               .includes(e.currentTarget.value)) { return item; }}
      })
      setallbusinessList(filterBySearch);
    }
  
  
  
  
  
  const filterLoadBusiness = async (value)=>{
    debugger
    const result = await axios.get(configData.ApiUrl+'/buisness/getBuisnessProfiles?buisnessId=' + authlocalstoragedata.buisnessId + '&businessCategory=' + value );
    setmyProfilebusinessList(result.data.data);
      console.log("bussdirectfilter", result.data.data)
  }
  
    
    const handleChangeBussFilter = (e) => {
      debugger
      const { name, value, type, checked } = e.currentTarget;
     
      filterLoadBusiness( e.target.value)
      
      
    }
  
  
    //for matrimony shortlist search
    const handleSearchForShortMatrimony = (e) => {
      debugger
      if (e.currentTarget.value === "") {
        loadMatShortProf();
        return;
      }
      const filterBySearch = matrimonyShortProf.filter((item) => {
        if (item != null && item.caste != null) {
          if (item?.caste?.toLowerCase().includes(e.currentTarget.value)) { return item; }
        }
         if ( item != null && item.livesIn != null){
           if (item?.livesIn?.toLowerCase()
               .includes(e.currentTarget.value)) { return item; }
              }
        
          if ( item != null && item.from != null){
             if (item?.from?.toLowerCase()
                .includes(e.currentTarget.value)) { return item; }
                }
            if ( item != null && item.motherTongue != null){
              if (item?.motherTongue?.toLowerCase()
                 .includes(e.currentTarget.value)) { return item; }
                }
            if ( item != null && item.eduQualification != null){
                  if (item?.eduQualification?.toLowerCase()
                     .includes(e.currentTarget.value)) { return item; }
                    }
            if ( item != null && item.working != null){
                      if (item?.working?.toLowerCase()
                         .includes(e.currentTarget.value)) { return item; }
                        }
              if ( item != null && item.name != null){
                          if (item?.name?.toLowerCase()
                             .includes(e.currentTarget.value)) { return item; }
                            }  
      })
      setmatrimonyShortProf(filterBySearch);
    }
    
     //for read more blog text
   
   const [showFullDescription, setShowFullDescription] = useState(false);
    
   // Function to toggle description display
   const toggleDescription = () => {
     setShowFullDescription(!showFullDescription);
   };
   //for read more kyc text
   
   const [showFullDescriptionkyc, setShowFullDescriptionkyc] = useState(false);
    
   // Function to toggle description display
   const toggleDescriptionkyc = () => {
    setShowFullDescriptionkyc(!showFullDescriptionkyc);
   };
  
      //for close popup when click on outside
  const popupLikeRef = useRef(null);
  const handleClickOutsideLikediv = (event) => {
    if (popupLikeRef.current && !popupLikeRef.current.contains(event.target)) {
      setLikeDiv(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideLikediv);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideLikediv);
    };
  }, []);
  
  
  //for randm color
   const colors = ["#ff8427", "#3498db", "#2ecc71", "#e74c3c", "#9b59b6", "#f1c40f", "#e67e22"];
   const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };
  const [bgColor, setBgColor] = useState(""); // Initialize state for background color
  
    useEffect(() => {
      // Set random color only once during the initial render
      setBgColor(getRandomColor());
    }, []);
    //for opening expand message popup
    const [isOpenExpandMessage, setisOpenExpandMessage] = useState(false);
    const togglePopupExpand = () => {
      setisOpenExpandMessage(!isOpenExpandMessage);
    }
    //for coffeemeetup search hide show
    const [showSearchBox, setShowSearchBox] = useState(false);

  const handleSearchClick = () => {
    setShowSearchBox(true);
  };
  const handleResetClick = () => {
    setShowSearchBox(false); // Reset view to "Sort By" and search icon
  };
  //for coffeemeet slider
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };

return(
<div className="container-fluid containerfluidpadding0">
      <div className="container-fluid  userlandtop-div">
      <div className="row">
          <div className="col-lg-1 col-sm-12 col-md-3">
            <img src={logo} className='' id='profpicdisablesave' style={{paddingTop:"14px"}}/>

          </div>
          <div className="col-lg-1 "></div>
          
          <div className="col-lg-8 col-sm-12 col-md-12" style={{display:'inline-flex',paddingTop:"31px",justifyContent: "center", gap: "20px"}}>
          {/* <div className="row " style={{paddingTop:"31px",justifyContent: "center", gap: "10px" }}> */}
         
          <div className=" ">
            <span className={news ? "textdecorunder menu-name" : "menu-name"} onClick={showNews} name="news">News & Updates</span>
          </div>
          <div className=" ">
            <span className={communityDirect ? "textdecorunder menu-name" : "menu-name"} onClick={showCommunityDirect} name="cdirect">Community Directory</span>
          </div>
          <div className=" ">
            <span className={matrimony ? "textdecorunder menu-name" : "menu-name"} onClick={showMatrimony} name="matrimony">Matrimony</span>
          </div>
          <div className=" ">
            <span className={businessDirect ? "textdecorunder menu-name" : "menu-name"} onClick={showBusinessDirect} name="bdirect">Business Directory</span>
          </div>
          {/* <div className="col-lg-2 col-sm-12 col-md-4 textcenter">
            <span className={communityMeet ? "textdecorunder menu-name" : "menu-name"} onClick={showCommunityMeet} name="communitym">Community Meetups</span>
          </div> */}

        {/* </div> */}

          </div>
          {/* <div className="col-lg-1 "></div> */}

          <div className="col-1 displyinline  ">
            <MdNotifications size='1.8em' className="notifiicon" onClick={togglePopupNotify} />
            {isOpenNotification && <NotificationPopup
              handleClose={togglePopupNotify}
            />
            }
            <p className="notifi-text">12</p>
            {/* <RiCupFill className="cupicon-top" size='1.7em' />
            <p className="notifi-text1">2</p> */}

          </div>
          <div className="col-lg-1 col-sm-12 col-md-12 textcenter">

            <div className=" margintop1 textright">
              
                
                  <div className="row  textcenter">
                    <div className="col-lg-1 col-md-2 col-sm-12 margintop1">
                      {loadProfile == "" || loadProfile == null ?
                        (<Avatar className="" style={{marginTop:"16px", width: "40px", height: "37px",fontSize:"15px",backgroundColor:bgColor,color:"#ffffff" }}>{avtarProf}</Avatar>)
                        : (<img src={loadProfile} id='profpicdisablesave1' className='prof-img-right topprofsmimg' style={{marginTop:"16px"}} />)
                      }

                    </div>
                    {/* <div className="col-lg-8 col-sm-12 col-md-9 margintop1 textcenter" style={{paddingTop:"4px"}}>
                      <span className="proftop-name textShowondottopprofname capitalizetxxxt">{authlocalstoragedata?.name2}</span>
                    </div> */}
                     <div className="col-lg-1 col-md-1 col-sm-12 margintop1">
                     <div class="dropdown drpdiv" style={{marginLeft:"10px",marginTop:"20px"}}>
                     <button type="button" class="drpbtn-menu dropdown-toggle" data-toggle="dropdown">
                      {/* <MdArrowDropDown size='1.9em' style={{marginTop:"6px"}}/> */}
                      </button>
                      <div class="dropdown-menu drpdn-meni-item" style={{ cursor: "pointer" }}>
                  <a class="dropdown-item drp-item" onClick={redirecttoViewProf} style={{ cursor: "pointer" }}>View Profile</a>
                  <a class="dropdown-item drp-item" onClick={redirectToContact}>View Contacts</a>
                  <a class="dropdown-item drp-item" onClick={redirectToBloodReq}>Blood Requirement Request</a>
                  <a class="dropdown-item drp-item" >View Subscriptions</a>
                  <a class="dropdown-item drp-item" onClick={redirectToContactRecive}>View Connect requests</a>
                  <a class="dropdown-item drp-item" onClick={redirectToReferal}>Referrals</a>
                  <a class="dropdown-item drp-item" onClick={redirectToAccSetting}>Change Password</a>
                  <a class="dropdown-item drp-item" onClick={redirectToAccSetting}>My Account</a>
                  <a class="dropdown-item drp-item" onClick={logoutclick}>Sign Out <HiOutlineLogout size='1.4em' className="marginleft2" /></a>
                </div>
                      </div>

                    </div> 
                  </div>
               
              
            </div>


          </div>


        </div>
            
            </div>
            <div className="row margintop1">
        <div className="col-lg-3 col-sm-12 col-md-12 leftdivuser heightforAll3Div" id="radiusdivchek">
        <div className="row margintop1">

<div className="borderbottom-radius">
  

  <div className="margintop1 "></div>
  <div className="radius-div" style={{ marginLeft: "-10px" }} >
    <div className="textleft radiussub-div"><IoPinSharp size="1.3em"/>    <span className="radius-heding" style={{marginLeft:"5px"}}> Members In My Location({members && members.length})</span><span className="seealltxt">See All</span></div>
    {/* <div className="row">
      <div className="col-lg-4"></div>
      <div className="col-lg-2 col-sm-4 col-md-4">
        <span className="radius-heding">{members && members.length}</span></div>

    </div> */}
    <div className="row margintop3"></div>


    <div style={{ marginLeft: "px" }}>

      <Carousel cols={4} rows={2} gap={10} loop autoplay={1200} >
        

{members.map((image, index) => (
          // <Tippy content={imageUrl.name} style={{fontSize:"11px"}}>

<Carousel.Item onClick={openLightbox}>
          {image.profilePhotoPath == "" || image.profilePhotoPath == null ?
            (<Avatar className="" style={{ width: "57px", height: "56px",backgroundColor: bgColor,color:"#ffffff",textTransform:"capitalize" }}>{image.name?.substring(0, 2)}</Avatar>)
            : (<img src={image.profilePhotoPath} id='profpicdisablesave2' title={image.name} className='prof-img-right topprofsmimg eventlogoimg1' style={{ cursor: "pointer",borderRadius:"50%",width: "57px", height: "56px" }} />)
          }
            {/* <img width="100%" src={image.profilePhotoPath} id='profpicdisablesave2' title={image.name} style={{ cursor: "pointer" }} /> */}
          </Carousel.Item>
          // </Tippy>
        ))
        }
        {
          members && members.length == 0 && (
            <Carousel.Item >
              <div style={{ width: "100%", height: "20px", color: "gray", marginLeft: "90px", paddingTop: '10px' }}>0 Members</div>
            </Carousel.Item>
          )
        }
        <ModalGateway >
          {viewerIsOpen ? (

            <Modal onClose={closeLightbox}>
              <Carousel1
                currentIndex={currentImage}
                views={
                  //members.map(x => ({
                  //   ...x,
                  //   srcset: x.srcSet,
                  //   caption: x.title
                  // }))
                  sliderImageUrl
                }
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </Carousel>

    </div>


    <div className="margintop3" style={{ marginLeft: "5px", marginTop: "10px" }}>
      
      <label class="chechboxxcustom" style={{fontSize:"12px",marginTop:"5px",fontWeight:"500"}}>Show Connected Members Only
        <input type="checkbox" class=" form-check-input " name='connectedStatus' onChange={handleChange} value="" />
        <span class="checkmark1"></span>
      </label>
    </div>
  </div>
</div>
<div className="borderbottom-radius margintop3">
  

  <div className="margintop1 "></div>
  <div className="radius-div" style={{ marginLeft: "-10px",paddingBottom:"0px" }} >
    <div className="textleft radiussub-div"><IoPinSharp size="1.3em"/>    <span className="radius-heding" style={{marginLeft:"5px"}}> My Community Networks</span>
    </div>
    <div className="row margintop3">
                  <div className="col-lg-7 col-sm-12 col-md-6">
                    <span className="radius-secobd-text " style={{marginLeft:"-10px"}}>Members Joined <span style={{color:"#212529"}}>({countmenu.count2})</span></span>
                  </div>
                  
                  
                </div>
                <div className="row margintop1"></div>
                <div className="textcenter margintop3 radiussub-btn-div"><span className="radius-heding-invite" style={{marginLeft:"0px",fontSize:"14px",fontWeight:"500",color:"#ffffff"}} onClick={togglePopupMessage1}> Invite New Members</span></div>
    
                {isOpenMessagePop1 && <ReferInviteCommMemberPopup

       handleClose={togglePopupMessage1}
      />}

    
  </div>
</div>

<div className="borderbottom-radius margintop3">
  

  <div className="margintop1 "></div>
  <div className="radius-div" style={{ marginLeft: "-10px" ,paddingBottom:'0px'}} >
    <div className="textleft radiussub-div"><IoPinSharp size="1.3em"/>    <span className="radius-heding" style={{marginLeft:"5px"}}>  My Community Platforms</span>
    </div>
    
    <div className="margintop">
                  {/* <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" checked value=""/>
    <span className="radius-secobd-text">Community Listing & Directory </span>
    </label> */}
                  {/* <label class="chechboxxcustom">Community Listing & Directory
                    <input type="checkbox" checked={authlocalstoragedata?.directory == true ? true : false} class=" form-check-input " value="" />
                    <span class="checkmark1"></span>
                  </label> */}
                   <label class="chechboxxcustom" style={{ cursor: "pointer",fontSize:"12px",fontWeight:"500" }}>Naidu Directory -Free 
                    <input type="checkbox" checked={authlocalstoragedata?.directory == true ? true : false} class=" form-check-input " value="" />
                    <span class="checkmark1"></span>
                  </label> 
                </div>
                <div className=" margintop2" style={{ whiteSpace: "nowrap" }}>
                  {/* <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" checked value=""/>
    <span className="radius-secobd-text">Community Meetups</span>
    </label> */}
                  {/* <label class="chechboxxcustom">Community Meetups
                    <input type="checkbox" class="form-check-input" checked={authlocalstoragedata?.directory == true ? true : false} value="" />
                    <span class="checkmark1"></span>
                  </label> */}
                  <div className="row ">
                    <div className="col-lg-8 col-sm-12 col-md-12 displyinline " style={{ marginLeft: "-15px" }}>
                      <label class="chechboxxcustom" style={{ cursor: "pointer",fontSize:"12px",fontWeight:"500" }}>Naidu Directory -Premium 
                        <input type="checkbox" class=" form-check-input " value="" />
                        <span class="checkmark1"></span>
                      </label>
                      <span className="radius-second-linktext" onClick={togglePopupNaiduDi} style={{cursor:"pointer",marginLeft:'5px',color:"#FF8427",fontStyle:"italic",fontSize:"12px",fontWeight:"500"}}>Sign Up</span>
                      {isOpenNaiduDiPopup && <SubscribePremiem
                      handleClose={togglePopupNaiduDi}
                    />
                    }
                    </div>
                    
                  </div>
                </div>
                <div className=" ">
                  {/* <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" checked value=""/>
    <span className="radius-secobd-text">Business Directory</span>
    </label> */}
                  <label class="chechboxxcustom" style={{ cursor: "pointer",fontSize:"12px",fontWeight:"500" }}>Business Directory
                    <input type="checkbox" class=" form-check-input " checked value="" />
                    <span class="checkmark1"></span>
                  </label>
                </div>
                <div className=" margintop2" style={{ whiteSpace: "nowrap" }}>
                  {/* <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" value=""/>
    <span className="radius-secobd-text">Community Matrimony</span>  <span className="radius-second-linktext">Sign Up</span>
    </label> */}
                  <div className="row ">
                    <div className="col-lg-7 col-sm-12 col-md-12 displyinline " style={{ marginLeft: "-15px" }}>
                      <label class="chechboxxcustom" style={{ cursor: "pointer" ,fontSize:"12px",fontWeight:"500"}}>Community Matrimony
                        <input type="checkbox" class=" form-check-input " value="" checked={authlocalstoragedata?.matrimony == true ? true : false}/>
                        <span class="checkmark1"></span>
                      </label>
                      <span className={authlocalstoragedata.matrimony ==false?"radius-second-linktext":"hide"} style={{ marginLeft: "5px" ,cursor:"pointer",color:"#FF8427",fontStyle:"italic",fontSize:"12px",fontWeight:"500"}} onClick={togglePopupSignUpmatri}>Sign Up</span>
                      {isOpenSignMatriPopup && <SignUpMatrimony
                    handleClose={togglePopupSignUpmatri}
                  />
                  }
                    </div>
                    
                    

                  </div>
                  

                </div>
                <div className=" " style={{ whiteSpace: "nowrap" }}>
                  {/* <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" value=""/>
    <span className="radius-secobd-text">Business Listing</span> <FaInfoCircle color='#214B0D' size='0.9em'/> <span className="radius-second-linktext">Sign Up</span>
    </label> */}
                  <div className="row">
                    <div className="col-lg-6 col-sm-12 col-md-12 displyinline" style={{ marginLeft: "-15px" }}>
                      <label class="chechboxxcustom" style={{ cursor: "pointer",fontSize:"12px",fontWeight:"500" }}>Business Listing
                        <input type="checkbox" class=" form-check-input " value="" checked={authlocalstoragedata?.buisnessDirectory == true ? true : false} />
                        <span class="checkmark1"></span>
                      </label>
                      <span className={authlocalstoragedata.buisnessDirectory ==false?"radius-second-linktext":"hide"} onClick={togglePopupbussDi} style={{cursor:"pointer",marginLeft:"5px",color:"#FF8427",fontStyle:"italic",fontSize:"12px",fontWeight:"500"}}>Sign Up</span>
                      {isOpenbusinessDiPopup && <BusinessFirstPage
                    handleClose={togglePopupbussDi}
                  />
                  }
                    </div>
                    
                  </div>
                  
                  

                </div>

    
  </div>
</div>

<div className="borderbottom-radius margintop3">
  

  <div className="margintop1 "></div>
  <div className="radius-div" style={{ marginLeft: "-10px" ,paddingBottom:'px'}} >
    <div className="textleft radiussub-div"><IoPinSharp size="1.3em"/>    <span className="radius-heding" style={{marginLeft:"5px"}}>  My Coffee Meet Up </span><span className="seealltxt">See All</span>
    </div>
    <div className="row margintop3"></div>
    {
                  coffeeMeets && coffeeMeets.length > 0 ? (
                    <span className="radius-fourth-subspn" style={{fontSize:"12px",fontWeight:"500",fontStyle:"italic",color:"#964B00",marginLeft:'2px'}}>You have {coffeeMeets.length} Upcoming Cofee Meet Ups</span>
                  ) : (
                    <span className="radius-fourth-subspn" style={{fontSize:"12px",fontWeight:"500",fontStyle:"italic",color:"#964B00",marginLeft:'2px'}}>You have 0 Upcoming Cofee Meet Ups</span>

                  )
                }
                <div>

                {!showSearchBox && (
 <div className="row margintop2 ">
                 
 {/* <div className="col-lg-3 col-sm-12 col-md-12 margintop1 "></div> */}
  <div className="col-lg-10 col-sm-12 col-md-12 margintop1 displyinline minusleftmarg">
  <span className="sortbytxt" style={{whiteSpace:"nowrap",marginTop:"6px"}}>Sort By:</span>
    <select id="inputState" name='filterstatus' onChange={handleChangeFilter}
      className=" form-control sortbytxt" style={{ height: "28px", fontSize: "11px",borderColor:"#D9D9D9" ,marginLeft:"5px"}}>
      <option selected value="">Date</option>
      <option value="Pending">Pending Response</option>
      <option value="accepted">Accepted Invitations</option>
    </select>
    
  </div>
   <div className=" col-lg-1 col-md-6 col-sm-6 has-search margintop1">
   <FaSearch onClick={handleSearchClick} color="#626262" style={{ cursor: 'pointer', margintop: '5px' }}/>
  
  </div> 

</div>)}
{showSearchBox && (
  <div className="row margintop2">
                <div class="form-group col-lg-11 col-md-6 col-sm-6 has-search margintop1" style={{ display: 'flex', alignItems: 'center' }}>
                <FaArrowLeft
                color="#626262"
                onClick={handleResetClick}
                style={{ cursor: 'pointer', marginRight: '8px' }}
              />
                    <span class="fa fa-search form-control-feedback"></span>
                    <input type="text" class="form-control" placeholder="Search by Organizer" onChange={handleSearchChange} style={{ fontSize: '11px',borderRadius:'15px' }} />
                    
                  </div>
                </div>
                )}
                </div>
                
                

                  
    <div>

    
    <Carousel cols={1} rows={1} gap={10} loop autoplay={4000} hideArrow={true} showDots={false}>
    <Carousel.Item>
      
      <div className="row margintop2" >
        <div className="col-lg-12 minusleftmarg8 margintop2" >
          <div className="row" style={{borderWidth:"1px",borderStyle:"solid",borderColor:"#964B00",borderRadius:"5px",marginRight:"px", paddingBottom:'5px',paddingTop:"5px"}}>
          <span className="fontroboto40012px">Hosting Coffee Meet Up on <span style={{fontWeight:"600"}}>11 Jan 2025 </span> at <span style={{fontWeight:"600"}}>Good Luck Cafe</span>, Starting at <span style={{fontWeight:"600"}}>10:00 AM.</span></span>
        </div>
        </div>
      </div>
      <div className="row margintop3">
        <span className="fontroboto40012px" style={{fontSize:"11px"}}>5/10 Members are attending this Coffee Meet Up</span>
      </div>
      <div className="minusleftmarg margintop3">
      <Carousel cols={3} rows={1} gap={1} loop autoplay={4000} hideArrow={true} showDots={true} style={{width:"200px"}}>
    

    <Carousel.Item>
      
      <div className="textcenter">
    
    <span className=""><img src={imgupload} id="othercoffeeprofid" className='borderstatusspnrej' style={{ width: "70px", height: "70px", borderRadius: "50%",marginBottom: 'px' }} /></span>
    <div className="textcenter" style={{ }}>
          
          <span className="event-sub-detail1" style={{ fontSize: '10px', maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal' }}>Anuj Naidu</span>
          

                        </div> 
                        <div className="textcenter" style={{ textAlign: 'center', minHeight: '20px' }}>
              
              <span className="" style={{ fontSize: '10px', color: "#4CAF50", whiteSpace: 'nowrap',marginLeft:"px" }}>Attend</span>
             
            </div>                             
{/* <span className={gname.status == 'Reject'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnrej' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px' }} /></span>
<span className={gname.status == 'Attend'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnatt' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px'  }} /></span>
<span className={gname.status == 'Pending'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnpen' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px'  }} /></span>
          
          
          

<div className="textcenter" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          
<span className="event-sub-detail1" style={{ fontSize: '10px', maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal', lineHeight: '1.2', overflow: 'hidden' }}>{gname.name}</span>
              </div>
              <div className="textcenter" style={{ textAlign: 'center', minHeight: '20px' }}>
              <span className={gname.status === 'Reject' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#E75480", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
              <span className={gname.status === 'Attend' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#4CAF50", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
              <span className={gname.status === 'Pending' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#FFC107", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
            </div> */}
                                            
          
      </div>
     </Carousel.Item>
     <Carousel.Item>
      
      <div className="textcenter">
    
    <span className=""><img src={imgupload} id="othercoffeeprofid" className='borderstatusspnrej' style={{ width: "70px", height: "70px", borderRadius: "50%",marginBottom: 'px' }} /></span>
    <div className="textcenter" style={{ }}>
          
          <span className="event-sub-detail1" style={{ fontSize: '10px', maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal' }}>Anuj Naidu</span>
          

                        </div> 
                        <div className="textcenter" style={{ textAlign: 'center', minHeight: '20px' }}>
              
              <span className="" style={{ fontSize: '10px', color: "#4CAF50", whiteSpace: 'nowrap',marginLeft:"px" }}>Attend</span>
             
            </div>                             
{/* <span className={gname.status == 'Reject'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnrej' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px' }} /></span>
<span className={gname.status == 'Attend'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnatt' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px'  }} /></span>
<span className={gname.status == 'Pending'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnpen' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px'  }} /></span>
          
          
          

<div className="textcenter" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          
<span className="event-sub-detail1" style={{ fontSize: '10px', maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal', lineHeight: '1.2', overflow: 'hidden' }}>{gname.name}</span>
              </div>
              <div className="textcenter" style={{ textAlign: 'center', minHeight: '20px' }}>
              <span className={gname.status === 'Reject' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#E75480", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
              <span className={gname.status === 'Attend' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#4CAF50", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
              <span className={gname.status === 'Pending' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#FFC107", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
            </div> */}
                                            
          
      </div>
     </Carousel.Item>
     <Carousel.Item>
      
      <div className="textcenter">
    
    <span className=""><img src={imgupload} id="othercoffeeprofid" className='borderstatusspnrej' style={{ width: "70px", height: "70px", borderRadius: "50%",marginBottom: 'px' }} /></span>
    <div className="textcenter" style={{ }}>
          
          <span className="event-sub-detail1" style={{ fontSize: '10px', maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal' }}>Anuj Naidu</span>
          

                        </div> 
                        <div className="textcenter" style={{ textAlign: 'center', minHeight: '20px' }}>
              
              <span className="" style={{ fontSize: '10px', color: "#4CAF50", whiteSpace: 'nowrap',marginLeft:"px" }}>Attend</span>
             
            </div>                             
{/* <span className={gname.status == 'Reject'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnrej' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px' }} /></span>
<span className={gname.status == 'Attend'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnatt' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px'  }} /></span>
<span className={gname.status == 'Pending'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnpen' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px'  }} /></span>
          
          
          

<div className="textcenter" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          
<span className="event-sub-detail1" style={{ fontSize: '10px', maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal', lineHeight: '1.2', overflow: 'hidden' }}>{gname.name}</span>
              </div>
              <div className="textcenter" style={{ textAlign: 'center', minHeight: '20px' }}>
              <span className={gname.status === 'Reject' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#E75480", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
              <span className={gname.status === 'Attend' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#4CAF50", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
              <span className={gname.status === 'Pending' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#FFC107", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
            </div> */}
                                            
          
      </div>
     </Carousel.Item>
     
               
                

                    </Carousel>

      </div>
   
      <div className="row margintop3 " style={{ marginLeft:"15px" }}>
                      <button class="btn  dropdown-toggle attendbtn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width: "301px",marginLeft:"10px" ,borderRadius:"20px"}} onClick={() => attendStatusCofeeMeet(coffeeView.id)}>
                        You are Attending
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" style={{ fontSize: '14px', }}>Withdraw Attendance</a>
                      </div>
                    </div>
                  

      </Carousel.Item>
      </Carousel>           
                
    
    {/* {
                  coffeeMeets.map((getmenu, index) => (
                    <div>
                      <div className={pendingDIv && getmenu.attendStatus == 'Pending' ? "" : "hide"}>
                       
                       
                       
                       
                       
                       
                       
                        <div className="radius-fourth-card1 margintop3">
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Date/Place</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-text">{getmenu.date}</span>  <span className="marginleft5 radius-fourth-card1-text">{getmenu.time}</span><span className="marginleft5 radius-fourth-card1-text">{getmenu.city}</span>
                            </div>
                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Organizer</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-text">{getmenu.hostName}</span>
                            </div>
                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Guests:- {getmenu.guests?.length}</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              {
                                getmenu.guests?.map((gname, index) => (
                                  <span className="radius-fourth-card1-text">{gname.name}</span>
                                ))
                              }

                            </div>
                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Venue</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-text">{getmenu.venue}</span>
                            </div>
                          </div>
                          <div className="marginleft1 whitenowrap">
                            <span className={getmenu.attendStatus == "Pending" ? "hide" : "radius-fourth-subspn "}>You have accepted this invitation</span>
                            <span className={getmenu.attendStatus == "Pending" ? "radius-fourth-subspn" : "hide  "}>You did not respond to this invitation</span>
                            <span className="radius-fourth-linktext marginleft1" onClick={() => coffeeInfopoupopen(getmenu.id, true,getmenu.attendStatus)}>View Invitation</span>

                          </div>



                        </div>
                      </div>

                      <div className={acceptedDiv && getmenu.attendStatus == 'accepted' ? "" : "hide"}>
                        <div className="radius-fourth-card1 margintop3">
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Date/Place</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-text">{getmenu.date}</span>  <span className="marginleft5 radius-fourth-card1-text">{getmenu.time}</span><span className="marginleft5 radius-fourth-card1-text">{getmenu.city}</span>
                            </div>
                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Organizer</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-text">{getmenu.hostName}</span>
                            </div>
                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Guests:- {getmenu.guests?.length}</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              {
                                getmenu.guests?.map((gname, index) => (
                                  <span className="radius-fourth-card1-text">{gname.name}</span>
                                ))
                              }

                            </div>
                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Venue</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-text">{getmenu.venue}</span>
                            </div>
                          </div>
                          <div className="marginleft1 whitenowrap">
                            <span className={getmenu.attendStatus == "Pending" ? "hide" : "radius-fourth-subspn "}>You have accepted this invitation</span>
                            <span className={getmenu.attendStatus == "Pending" ? "radius-fourth-subspn" : "hide  "}>You did not respond to this invitation</span>
                            <span className="radius-fourth-linktext marginleft1" onClick={() => coffeeInfopoupopen(getmenu.id, true,getmenu.attendStatus)}>View Invitation</span>

                          </div>



                        </div>
                      </div>
                      <div className={pendingDIv == false && acceptedDiv == false ? '' : 'hide'}>
                        <div className="radius-fourth-card1 margintop3">
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Date/Place</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-text">{getmenu.date}</span>  <span className="marginleft5 radius-fourth-card1-text">{getmenu.time}</span><span className="marginleft5 radius-fourth-card1-text">{getmenu.city}</span>
                            </div>
                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Organizer</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-text">{getmenu.hostName}</span>
                            </div>
                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Guests:- {getmenu.guests?.length}</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              {
                                getmenu.guests?.map((gname, index) => (
                                  <span className="radius-fourth-card1-text">{gname.name}</span>
                                ))
                              }

                            </div>
                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-4 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-lefttext">Venue</span>
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                              <span className="radius-fourth-card1-text">{getmenu.venue}</span>
                            </div>
                          </div>
                          <div className="marginleft1 whitenowrap">
                            <span className={getmenu.attendStatus == "Pending" ? "hide" : "radius-fourth-subspn "}>You have accepted this invitation</span>
                            <span className={getmenu.attendStatus == "Pending" ? "radius-fourth-subspn" : "hide  "}>You did not respond to this invitation</span>
                            <span className="radius-fourth-linktext marginleft1" onClick={() => coffeeInfopoupopen(getmenu.id, true,getmenu.attendStatus)}>View Invitation</span>

<div className={coffeediv ? "row" : "hide row"}>
        <div className="popup-boxdate">
          <div className="boxdate" style={{ width: '52%', marginLeft: "400px", marginTop: "120px" }}>
            <div className='bordernonemessg' style={{ marginLeft: "-16px", marginRight: "-16px" }}>
              <div className=" row" style={{ borderBottom: "1px", borderColor: "lightgray" }} >
                <div className=" col-11" style={{ textAlign: "left", fontSize: "16px" }}><span className="event-sub-detail">View Invitation</span></div>
                <div className='col-1'>
                  <span className="" onClick={() => coffeeInfopoupopen(0, false,coffeeView.attendStatus)}><MdClose size='1.5em' /></span>
                </div>
                <br />
              </div>
              <div className='row' style={{ marginTop: "0px" }}></div>
            </div>

            <div style={{ overflowX: "hidden" }}>


              <div className="cardiv-coffee margintop border1px">
                <div className="row margintop3">
                  <div className="col-8">
                    <span className="event-sub-detail">{coffeeView.date} </span><span className="event-sub-detail" style={{ marginLeft: '12px' }}>{coffeeView.city}</span>
                  </div>
                  <div className="col-3" style={{ float: "right" }}>
                    <button className={coffeeView.attendStatus == 'Pending' ? "attendbtn" : "hide"} style={{ marginTop: '6px', position: "absolute", width: "180px" }} onClick={() => attendStatusCofeeMeet(coffeeView.id)}>Attend</button>
                    <div class={coffeeView.attendStatus == 'Pending' ? "hide" : "dropdown"}>
                      <button class="btn  dropdown-toggle attendbtn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width: "180px" }} onClick={() => attendStatusCofeeMeet(coffeeView.id)}>
                        You are Attending
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" style={{ fontSize: '14px', }}>Withdraw Attendance</a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row margintop2">
                  <div className="">
                    <span className="event-sub-detail">Venue:- </span>
                    <span className="event-sub-detail1" style={{ margin: "5px", fontSize: '13px' }}>{coffeeView.venue}</span>
                   
                    < a href={`/${coffeeView.googleMapsLink}`} target='_blank'><span className="bluetextforlink" style={{ margin: "7px", fontSize: '10px' }}>Check on Google Maps</span></a>
                  </div>
                </div>
                <div className="row margintop3">
                  <div className="">
                    <span className="event-sub-detail1" style={{ margin: "5px", fontSize: '13px' }}>{coffeeView.guests.length} Members are attending this Coffee Meet Up</span>
                  </div>
                </div>
                <div className="row margintop">

                  {coffeeView.guests.map((geuest, index) => (
                    <div className="col-2 ">
                      <img src={geuest.profilePicture} id='profpicdisablesave18' className=' ' style={{ width: "100px", height: "100px", borderRadius: "50%" }} />
                      <div className="margintop1"><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '0px' }}>{geuest.name}</span></div>
                    </div>
                  ))
                  }



                </div>
                <div className="row margintop3"></div>
              </div>



            </div>


          </div>
        </div>
      </div>
                          </div>



                        </div>
                      </div>
                    </div>
                  ))
                } */}
      
       </div>
    
  </div>
</div>
<div className="borderbottom-radius margintop3">
  

  <div className="margintop1 "></div>
  <div className="radius-div" style={{ marginLeft: "-10px",paddingLeft:'5px' }} >
    <div className="textleft radiussub-div"><FaRegHandshake size="1.3em" style={{marginLeft:"15px"}}/>    <span className="radius-heding" style={{marginLeft:"5px"}}> My Connection Requests</span><span className="seealltxt">See All</span></div>
    {/* <div className="row">
      <div className="col-lg-4"></div>
      <div className="col-lg-2 col-sm-4 col-md-4">
        <span className="radius-heding">{members && members.length}</span></div>

    </div> */}
    <div className="row margintop3"></div>


    <div style={{ marginLeft: "14px" }}>

    {
                  connection && connection.length > 0 ? (
                    <span className="radius-fourth-subspn" style={{fontSize:"12px",fontWeight:"500",fontStyle:"italic",color:"#626262"}}>You have {connection.length} new connection requests</span>
                  )
                    :
                    (
                      <span className="radius-fourth-subspn" style={{fontSize:"12px",fontWeight:"500",fontStyle:"italic",color:"#626262"}}>You have 0 new connection requests</span>
                    )

                }
                                {
                  connection.map((getmenu, index) => (
                    <div className=" margintop2">
                      <div className="row minusleftmarg">
                        <div className="col-lg-2 col-sm-12 col-md-12 margintop1">
                          <img src={getmenu.profilePicturePath} id='profpicdisablesave3' className='topprofsmimg margintop1' />
                          {/* <div className=""><span className="radius-fourth-linktext">View Profile</span></div> */}
                        </div>
                        <div className="col-lg-8 col-sm-12 col-md-12" >
                          <div className=""><span className="radius-fourth-card1-lefttext ">{getmenu.senderName}</span> </div>
                          
                          <div className=""><span className="radius-fourth-card1-text">{getmenu.currentCity}, {getmenu.currentState}, {getmenu.currentCountry}</span> </div>

                        </div>
                      </div>
                      <div className="row margintop2 marginleftrightminus" style={{marginLeft:'-15px',marginRight:'-15px'}}>
                      <div className="col-lg-6 col-sm-12 col-md-12  border-btn1 margintop1" style={{marginLeft:'px', cursor: "pointer"}} onClick={() => rejectConnection(getmenu.senderId)}>
                      <IoCloseOutline size="1.3em" color="#D9001B"/> <span className="radius-fourth-subspn" style={{ color: "#D30F45", marginLeft: "9px", cursor: "pointer" }} >Reject</span>

                      </div>

                        <div className="col-lg-6 col-sm-12 col-md-12 border-btn1 margintop1" onClick={() => acceptConnection(getmenu.senderId)} style={{marginRight:"px", cursor: "pointer"}}>
                        <IoMdCheckmark size="1.1em" color="#214B0D"/> <span className="radius-fourth-subspn" style={{ color: "#30D158", marginLeft: "9px", cursor: "pointer" }} >Accept</span>

                       </div>
                      </div>
                      <div className=" margintop3 borderBottombtn"></div>



                    </div>
                  ))
                }

    </div>


    
  </div>
</div>

</div>

        </div>
        <div className={news ? "col-lg-6 col-sm-12 col-md-12  newsfeeddiv heightforAll3Div" : "hide"}></div>
        <div className="col-lg-3 col-sm-4 col-md-4 bloodreq-head-div heightforAll3Div right-div">
        <div className="row margintop1">
        <div className="borderbottom-radius margintop3">
  

  <div className="margintop1 "></div>
  <div className="radius-div" style={{ marginLeft: "-10px",paddingBottom:"0px" }} >
    <div className="textleft radiussub-div"><IoPinSharp size="1.3em"/>  <span className="radius-heding" style={{marginLeft:"5px"}}> Urgent Blood Requirement</span><span className="seealltxt" style={{marginLeft:"15px"}}>See All</span>
    </div>
    <div className="row margintop3">
    <SliderPageNew />
                  
                  
                </div>
                <div className="row margintop1"></div>
                <div className="textcenter margintop3 radiussub-btn-div"><span className="radius-heding-invite" style={{marginLeft:"0px",fontSize:"14px",fontWeight:"500",color:"#ffffff"}} onClick={togglePopupBlood}> Add New Requirement</span></div>
    
                {isOpenBloodPopup && <BloodRequirePopup

                   handleClose={togglePopupBlood}
             />}

    
  </div>
</div>

<div className="borderbottom-radius margintop3">
  

  <div className="margintop1 "></div>
  <div className="radius-div" style={{ marginLeft: "-10px",paddingBottom:"0px" }} >
    <div className="textleft radiussub-div" style={{paddingBottom:'2px'}}><IoPinSharp size="1.3em"/>    <span className="radius-heding" style={{marginLeft:"5px"}}> My Community Platforms</span><br/><div style={{fontStyle:"italic",marginLeft:"30px",marginTop:"-9px"}}><span className="slider-name" style={{fontStyle:"italic",marginLeft:"0px",marginTop:"-5px"}}>Sponsored</span></div>
    
   
    </div>

    <div className="row margintop3  ">
    <SponserBusiness />
                  
                  
                </div>
                <div className="row margintop1"></div>
    

    
  </div>
</div>
<div className="borderbottom-radius margintop3">
  

  <div className="margintop1 "></div>
  <div className="radius-div" style={{ marginLeft: "-10px" }} >
    <div className="textleft radiussub-div"><IoPinSharp size="1.3em"/>    <span className="radius-heding" style={{marginLeft:"5px"}}> Job Openings({jobPostingList && jobPostingList.length})</span></div>
    {/* <div className="row">
      <div className="col-lg-4"></div>
      <div className="col-lg-2 col-sm-4 col-md-4">
        <span className="radius-heding">{members && members.length}</span></div>

    </div> */}
    <div className="row margintop3"></div>
    {/* {
                  jobPostingList.map((job, index) => ( */}
                    <div>
                      <div className="row minusleftmarg">
        <div className="col-7"><span className="radius-secobd-text" style={{textAlign:"right",color:"#797979"}}>Job Id</span></div>
        <div className="col-5 textleft"><span className="slider-name  " style={{marginLeft:"-22px"}}> 222111</span></div>

        </div>
        <div className="row minusleftmarg">
        <div className="col-7"><span className="radius-secobd-text" style={{textAlign:"right",color:"#797979"}}> Role/Position</span></div>
        <div className="col-5 textleft"><span className="slider-name  " style={{marginLeft:"-22px"}}> Business Manager</span></div>

        </div>
        <div className="row minusleftmarg">
        <div className="col-7"><span className="radius-secobd-text" style={{textAlign:"right",color:"#797979"}}> Date Posted</span></div>
        <div className="col-5 textleft"><span className="slider-name  " style={{marginLeft:"-22px"}}> 22 nov,2024</span></div>

        </div>
        <div className="row minusleftmarg">
        <div className="col-7"><span className="radius-secobd-text" style={{textAlign:"right",color:"#797979"}}>Location</span></div>
        <div className="col-5 textleft"><span className="slider-name  " style={{marginLeft:"-22px"}}> Pune</span></div>

        </div>
        <div className="row minusleftmarg">
        <div className="col-7"><span className="radius-secobd-text" style={{textAlign:"right",color:"#797979"}}>Educational Qualification:</span></div>
        <div className="col-5 textleft"><span className="slider-name  " style={{marginLeft:"-22px"}}> MBA</span></div>

        </div>
                     
                     
                      <div className="row margintop2">
                        <span className="radius-fourth-card1-text" style={{fontSize:"12px"}}>
                          {/* {job.jobDescription} */}Lorem Ipsum sit amet
                        </span>
                      </div>
                    </div>
                  {/* )
                  )} */}
{
     jobPostingList  && jobPostingList.length== 0 &&(
      <div className="row">
      <span className="slider-name " style={{paddingBottom:"5px"}}>No Job Requirements Currently</span>
      </div>
     )
}


    
  </div>
</div>
<div className="borderbottom-radius margintop3">
  

  <div className="margintop1 "></div>
  <div className="radius-div" style={{ marginLeft: "-10px" }} >
    <div className="textleft radiussub-div"><IoPinSharp size="1.3em"/>    <span className="radius-heding" style={{marginLeft:"5px"}}> My Messanger({userlist && userlist.length})</span><span style={{float:"right",marginRight:"10px"}}><FaEdit style={{ marginLeft: '0px',color:'#FF8427' }} /><FiMaximize2  style={{cursor:'pointer',marginLeft:"10px"}} onClick={togglePopupExpand}/></span></div>
    {isOpenExpandMessage && <MessageExpandPopup
              handleClose={togglePopupExpand}
            />
            }
    
    <div className="row margintop3"></div>
    <div class="form-group has-search margintop3">
                  <span class="fa fa-search form-control-feedback"></span>
                  <input type="text" class="form-control" placeholder="Search Messages" onChange={handleSearchChangeMessage} style={{ fontSize: '12px' }} />
                </div>
                {
                  userlist.map((user, index) => (
                    <Card className={styleForMessg && user.userId == styleForMessgid ? "hoverformesgcard margintop1" : " margintop1"} style={{ paddingBottom: "8px", paddingTop: "5px", lineHeight: "1.6", cursor: "pointer" ,borderTop:"none",borderLeft:"none",borderRight:"none",borderBottomWidth:"1px",borderRadius:"0px"}}

                      onMouseEnter={() => omMouseHover(user.userId,user.blockStatus)}
                      onMouseLeave={() => omMouseLeve(user.userId)}
                    >
                      <div className="row margintop1" key={user.userId}>
                        <div className="col-2 margintop1" onClick={() => togglePopupMessage(user.userId)}>
                          <img src={user.profilePicturePath} id='profpicdisablesave17' className=' messengerprofile' />
                          
                        </div>
                        <div className="col-10">
                          <div className="row">
                            <div className="col-9" onClick={() => togglePopupMessage(user.userId)}>
                              <span className="radius-fourth-card1-lefttext" style={{ marginLeft: "-9px" }} >{user.userName}</span>
                            </div>
                            <div className="col-3 textright">
                              {
                                styleForMessg && user.userId == styleForMessgid ?
                                  (<span className={styleForMessgblock && user.userId == styleForMessgid ? "threedotblockunclock" : ""}
                                    onMouseEnter={() => omMouseHoverblock(user.userId)}
                                    onMouseLeave={() => omMouseLeveBlock(user.userId)}
                                    onClick={() => blockunblockpoupopen(true)}>
                                    <BsThreeDots style={{ cursor: "pointer" }} />
                                  </span>)
                                  :
                                  (<span className="radius-fourth-card1-text" style={{ marginLeft: '-16px' }}>{user.time}</span>)
                              }
                            </div>

                          </div>
                          <div onClick={() => togglePopupMessage(user.userId)}>
                            <span className="textShowondot message-text" style={{ lineHeight: '80%' }}>
                              {user.lastMessage}

                            </span>
                          </div>

                        </div>
                        <div className={blockPop && user.userId == styleForMessgid ? "" : "hide"}>
                          <div className="" style={{ backgroundColor: "fff", boxShadow: "0px 5px 5px 0px rgba(242, 242, 242, 1)", borderRadius: "3px", width: "150px", fontSize: "12px", float: "right", marginTop: "-62px", marginLeft: "-20px" }}>
                            <div className="">
                              <ul class="list-group ">
                                {
                                  user.blockStatus == false ?
                                    (<li class="list-group-item"  style={{ cursor: "pointer" }}> <span onClick={() => addblockUnblock(user.userId)}>Block</span> <MdOutlineClose  onClick={() => blockunblockpoupopen(false)} style={{marginLeft:"0px",marginTop:"-7px",float:"right",marginRight:"-14px"}}/></li>) :
                                    (<li class="list-group-item"  style={{ cursor: "pointer" }}><span onClick={() => addblockUnblock(user.userId)}>Unblock</span> <MdOutlineClose  onClick={() => blockunblockpoupopen(false)} style={{marginLeft:"0px",marginTop:"-7px",float:"right",marginRight:"-14px"}}/></li>)

                                }
                              </ul>
                            </div>
                          </div>

                        </div>
                      </div>


                    </Card>
                  ))

                }

                {isOpenMessagePop && <MessagePopup

                  handleClose={togglePopupMessage} uerAuthId={uerAuthId} styleForMessgid={styleForMessgid}
                  userlist ={userlist} setuserlist={setuserlist} styleForMessgBlockstatus={styleForMessgBlockstatus}
                />}

    
  </div>
</div>

        </div>
        </div>
        </div>
        

</div>
)

}
export default UserLandingNew;