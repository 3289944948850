import React, { useState, useEffect } from "react";
import logo from '../../images/Naidus directory.png';
import '../JoinNow/JoinNow.css';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";

import {
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import BasicDetail from "./BasicDetail";
import ContactPrefrence from "./ContactPreference";
import VerifiIdentity from "./VeryfiIdentity";
import axios from "axios";
import {configData} from "../../Config"

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Basic details", "Contact prefrences & location details ", "Verify identity"
  ];
}







const CompleteProfile = () => {
//for session
// const [session ,setSession] = useState(false);

// const gesessiondata = async () => {
//    debugger;
//    axios.get('/checkSession')
    
//    .then(result =>{
//      if(result.data.code == 501){
//        setSession(true)  ;
//         }
//     else{
//          history.push("/Login");

//     }
//      debugger;
//      console.log(result.data.code);
//    }).catch(error =>{
//      console.log(error);
//    })
//  };

//        console.log("checksession on campaign",session);

// useEffect(() => {
//     gesessiondata();
//   }, [session]);



  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  var newStepValue=activeStep+2;


  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)


//for new session for userlogin
// const redirecToLandingdata =  () => {
 
//   if(authlocalstoragedata?.kycStatus ==null &&  authlocalstoragedata?.kycStatus ==''){
//     history.push("/");

//   }
// }
   
//    useEffect(() => {
//     redirecToLandingdata();
//  });
  const getStepContent = () => {
    debugger;
    if (newStepValue === 0) {
      return <BasicDetail additional={additional} setadditional={setadditional} handleBack={handleBack} handleNext={handleNext} />;
    } else if (newStepValue === 1) {
      return <ContactPrefrence additional={additional} setadditional={setadditional} handleBack={handleBack} handleNext={handleNext}/>;
    } else {
      return <VerifiIdentity additional={additional} setadditional={setadditional} attachment={attachment} setAttachment={setAttachment} handleBack={handleBack} handleNext={handleNext}/>;
    }
  };
  
  const [loaclId, setloaclId] = useState("");
const [localusername, setLocalUsername] = useState("");

useEffect(() => {  
  setLocalUsername(localStorage.getItem("localusername"))
  setloaclId(localStorage.getItem("loaclId"))
    
  
})
  // const username = localStorage.getItem('name');
  // //const userid = localStorage.getItem('id');
  // const userid = localStorage.id;
  // debugger;
  // if(authlocalstoragedata !=null && authlocalstoragedata?.userAuthenticationId !=null 
  //   && authlocalstoragedata?.name1 != null){
  //   localStorage.id = authlocalstoragedata?.userAuthenticationId;
  //   localStorage.name = authlocalstoragedata?.name1;

  // }else{
  //   localStorage.name = localStorage.name;

  //   localStorage.id = localStorage.id;
  // }
const JoinNowData = JSON.parse(localStorage.getItem('JoinNowData'));
console.log("authdata11",authlocalstoragedata)

if(authlocalstoragedata !=null && authlocalstoragedata?.userAuthenticationId !=null 
  && authlocalstoragedata?.name1 != null){
  localStorage.id = authlocalstoragedata?.userAuthenticationId;
  localStorage.name = authlocalstoragedata?.name1;
  localStorage.emailId = authlocalstoragedata?.emailId;
  localStorage.mobileNumber = authlocalstoragedata?.mobileNumber;
  localStorage.countryCode = authlocalstoragedata?.countryCode;
  localStorage.status = authlocalstoragedata?.status;




}else{
  localStorage.name = JoinNowData.name;

  localStorage.id = JoinNowData.id;
  localStorage.emailId = JoinNowData.emailId;
  localStorage.phoneNo = JoinNowData.phoneNo;
  localStorage.countryCode = JoinNowData.countryCode;
  localStorage.status = JoinNowData.status;


}


  const [additional, setadditional] = useState({
    id:localStorage.id,
    gender:null,
    birthDate:null,
    bloodGroup:null,
    maritalStatus:null,
    familyCount:null,
    profession:null,
    natureOfBuisness:null,
    bio:null,
    // mobileVisibility:null,
    homeCountryCode:null,
    homeCountry:null,
    homeStateCode:null,
    homeState:null,
    homeCityCode:null,
    homeCity:null,
    homePinCode:null,
    homeAddress1:null,
    homeAddress2:null,
    homeLandmark:null,
    currentCountryCode:null,
    currentCountry:null,
    currentStateCode:null,
    currentState:null,
    currentCityCode:null,
    currentCity:null,
    currentPinCode:null,
    currentAddress1:null,
    currentAddress2:null,
    currentLandmark:null,
    kycStatus:null,
    documentType:null,
    identificationNo1:null,
    identificationNo2:null,

    // sameashomelocation:false,
    // sameashomecountry:false,
    // sameashomestate:false,
    // sameashomecity:false,
    // sameashomezip:false

  });

  const [attachment, setAttachment] = useState({
    frontDocPhoto: null,
    backDocPhoto: null,
    
    })
    
  

    let history = useHistory();


   const isStepOptional = (step) => {
     return step === 4 || step === 5;
   };

  // const isStepSkipped = (step) => {
  //   return skippedSteps.includes(step);
  // };

  const handleNext = () => {

    // setAppointment(preState => ({
    //   ...preState,
    //   attachFiles : serviceList
    // }))
    if (newStepValue === 0) {
      const formData = new FormData();
    debugger
    
      formData.append('additional',JSON.stringify(additional));
      
      // formData.append('profilePhoto', attachment.profilePhoto);
      // formData.append('photo1', attachment.photo1);
      

const response = axios.post(configData.ApiUrl+'/user/addAdditionalDetails',formData,{
  headers: {
    'content-type': 'multipart/form-data'
}
}

)
      response.then(() => {
        toast.success("Data Added Successfully!");

      })
      response.catch((err) => {
        alert("Getting error in featching data")
      });
    }

  if (newStepValue === 1) {
    debugger
    const formData = new FormData();


    additional.gender = null;
    additional.birthDate = null;
    additional.bloodGroup = null;
    additional.maritalStatus = null;
    additional.familyCount = null;
    additional.profession = null;
    additional.natureOfBuisness = null;
    additional.bio=null;
    
      
    debugger
      
    formData.append('additional',JSON.stringify(additional));
      
      // formData.append('profilePhoto', attachment.profilePhoto);
    

const response = axios.post(configData.ApiUrl+'/user/addAdditionalDetails',formData,{
  headers: {
    'content-type': 'multipart/form-data'
}
}

)
      response.then(() => {
        toast.success("Data Added Successfully!");

      })
      response.catch((err) => {
        alert("Getting error in featching data")
      });

    }
    if (newStepValue === 2) {
      debugger
      setadditional(preState => ({
        ...preState,
        "frontDocPhoto" : attachment.frontDocPhoto
      }))
      setadditional(preState => ({
        ...preState,
        "backDocPhoto" : attachment.backDocPhoto
      }))
      const formData = new FormData();
  
  
      additional.gender = null;
      additional.birthDate = null;
      additional.bloodGroup = null;
      additional.maritalStatus = null;
      additional.familyCount = null;
      additional.profession = null;
      additional.natureOfBuisness = null;
      additional.bio = null;

      // additional.mobileVisibility=null;
      additional.homeCountryCode=null;
      additional.homeCountry=null;
    additional.homeStateCode=null;
    additional.homeState=null;
    additional.homeCityCode=null;
    additional.homeCity=null;
    additional.homePinCode=null;
    additional.homeAddress1=null;
    additional.homeAddress2=null;
    additional.homeLandmark=null;
    additional.currentCountryCode=null;
    additional.currentCountry=null;
    additional.currentStateCode=null;
    additional.currentState=null;
    additional.currentCityCode=null;
    additional.currentCity=null;
    additional.currentPinCode=null;
    additional.currentAddress1=null;
    additional.currentAddress2=null;
    additional.currentLandmark=null;
        
      debugger
        
      formData.append('additional',JSON.stringify(additional));
        
         formData.append('frontDocPhoto', attachment.frontDocPhoto);
          formData.append('backDocPhoto', attachment.backDocPhoto);

      
  
  const response = axios.post(configData.ApiUrl+'/user/addAdditionalDetails',formData,{
    headers: {
      'content-type': 'multipart/form-data'
  }
  }
  
  )
        response.then(() => {
          toast.success("Data Added Successfully!");
          history.push("/Login")

  
        })
        response.catch((err) => {
          alert("Getting error in featching data")
        });
      }

    setActiveStep(activeStep+1);
    newStepValue =newStepValue+1;

    // setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
  };

  
  
  const handleBack = () => {
    newStepValue =newStepValue-1;

    setActiveStep(activeStep - 1);
  };

  // const handleSkip = () => {
  //   if (!isStepSkipped(activeStep)) {
  //     setSkippedSteps([...skippedSteps, activeStep]);
  //   }
  //   setActiveStep(activeStep + 1);
  // };
  newStepValue = activeStep;
//   if(activeStep !=0)
//   {
//     newStepValue = activeStep+0;
// }
//   if(activeStep !=0)
//   {
//     newStepValue = activeStep+1;
// }
// if(activeStep !=0)
//   {
//     newStepValue = activeStep+2;
// }
debugger

  if(authlocalstoragedata != null && authlocalstoragedata?.birthDate==null && activeStep === 0)
  {
    debugger;
    newStepValue=0;

  }
  else if(authlocalstoragedata != null && authlocalstoragedata?.currentCountry==null && activeStep === 0)
  {
    debugger;
    newStepValue=1;

  }
 else if(authlocalstoragedata != null && authlocalstoragedata?.kycStatus==null && activeStep === 0)
  {
    debugger;
    newStepValue=2;

  }
  else {
    debugger;
    //history.push("/UserLanding")

  }
  
  return (
    <div className=" container-fluid">

          <div className="margintop row">
            <div className="col-1">                 </div>
            <div className="textleft col-lg-1 col-sm-12 col-md-12">
            {/* <img src={logo} style={{width:"104px", height:"77px"}}/> */}
            </div>
            {/* <div className="col-1"></div> */}
            <div className="col-lg-9 col-md-12 col-sm-12">
<span className='registerspn merriweatherfont'>Complete your profile to connect with the community ...</span>
<div className="margintop3">
<div className="row marglrftrightminusrow">
          <div className={activeStep === 1?"col-lg-11 col-sm-12 col-md-12 form-container2":"col-lg-11 col-sm-12 col-md-12 form-container1"}>
<Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography
                variant="caption"
                align="center"
                style={{ display: "block" }}
              >
                optional
              </Typography>
            );
          }
          // if (isStepSkipped(index)) {
          //   stepProps.completed = false;
          // }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <Typography variant="h5" align="center">
          {/* Now Your Profile is Completed! */}
             </Typography>
      ) : (
        <>
        
          <div className="">{getStepContent(activeStep)}</div>
          
          {/* <div className="margintop3 row">
            <div className="col-3"></div>
            <div className="col-3 margintop3 textright">
            <button
               className={activeStep === 0?"displynoneprev":"prevbtn sourcesansfont"}
              
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-2 margintop3">
          <button
            className={activeStep === 0?"nextbtn sourcesansfont":activeStep === 2?"nextbtn2 sourcesansfont ":"nextbtn1 sourcesansfont"}
              variant="contained"
              color="primary"
              
              onClick={handleNext}
            >
              {activeStep === steps.length - 1 ? "Submit and Start Socializing" : "Next"}
            </button>
            
            
          </div>
          <div className="margintop2  row"></div>

        

            <div className={activeStep === 2?"displynoneprev":activeStep === 0?"displynoneprev":"  row"}>

            <div className="margintop1 col-12 textleft">
            <div className="borderbott1 margintop3 "></div>

            <span className="alreadyspn1comp">You can connect with other members through Community Connect Messenger even if the member is not connected or your contact numbers are not visible. 
            You can change the Contact Preferences  and Location Details any time later in My Profile area.
        </span>
            </div>
           
            </div>

            
            
          </div> */}
         
          
        </>
      )}
</div>
</div>
</div>
</div>
          </div>
      
          

    </div>
  );
};

export default CompleteProfile;