import './MessageExpandPopup.css'
import conprof from '../../images/coneect.svg';
import {FiMaximize2} from 'react-icons/fi'
import {AiOutlineCloseSquare} from 'react-icons/ai'
import React, { useState, useEffect ,useRef} from "react";
import {over} from 'stompjs';
import SockJS from 'sockjs-client';
import axios from "axios";
import { toast } from "react-toastify";
import { BiBorderRadius } from 'react-icons/bi';
import {configData} from "../../Config"
import ReactTooltip from "react-tooltip";
import { IoPinSharp } from "react-icons/io5";
import { FaRegComments, FaUserClock, FaFilter,FaThumbsDown, FaInfoCircle,FaSearch, FaRegHandshake, FaEdit, FaBookmark } from 'react-icons/fa';
import { Badge, Button, Tab, Nav, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { MdAddBox, MdWarning,MdOutlineClose } from 'react-icons/md';
import { BsThreeDots } from 'react-icons/bs';
import MessagePopup from './MessagePopup';


var stompClient =null;

const MessageExpandPopup = (props)=> {

    
 const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
 console.log("authdata11", authlocalstoragedata)

  
    const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
    const [uerCurrentCity, setuerCurrentCity] = useState(authlocalstoragedata?.currentCity);
  
 //for messenger
 const [privateChats, setPrivateChats] = useState(new Map());     
 const [publicChats, setPublicChats] = useState([]); 
 const [tab,setTab] =useState("CHATROOM");
 // const [userData, setUserData] = useState({
 //     username: props.uerAuthId,
 //     receivername: props.styleForMessgid,
 //     connected: false,
 //     message: ''
 //   });



//   const sendPrivateValue=()=>{
//     if (stompClient) {
//       var chatMessage = {
//         senderId: userData.username,
//         receiverId:tab,
//         message: userData.message,
//         status:"MESSAGE"
//       };
     
//       if(userData.username !== tab){
//         privateChats.get(tab).push(chatMessage);
//         setPrivateChats(new Map(privateChats));
//       }
//       stompClient.send("/messenger/private-message", {}, JSON.stringify(chatMessage));
//       setUserData({...userData,"message": ""});
//     }
// }

// const handleMessage =(event)=>{
//   const {value}=event.target;
//   setUserData({...userData,"message": value});
// }
    const [userData, setUserData] = useState({
     senderId: props.uerAuthId,
     receiverId: props.styleForMessgid,
    message:"" 
    })

   //  const [msgData,setMsgData] = useState({
   //   toUserIdMsg:props.userId,
   //   userAuthId: props.uerAuthId

   //  })


    useEffect(() => {
     console.log(userData);
   }, [userData]);

    const handleMessage =(e)=>{
     const {name,value,type,checked}=e.currentTarget;
   debugger
   setUserData(preState =>({
     ...preState,
     [name]:type ==='checkbox'?checked: value
   }))
   
   }

   const [userDatamsgClear, setuserDatamsgClear] = useState(userData.message)

   const sendPrivateValue =()=>{
     debugger
   if(userData.message !=""&& userData.message != null){

if(userData.message.length >200){
 alert("Message length 200 charecters only")
}
else{
 const response = axios.post(configData.ApiUrl+'/messenger/sendMessage', userData)
     response.then(() => {
           //toast.success("Messege sent Successfully!");
           loadMesseges();
   loadUserListUpdate();

           setUserData(preState =>({
             ...preState,
             "message": ""
           }))
    })
        response.catch((err) => {
          alert("Getting error in featching data")
        });
        console.log("response", response?.data)
       
 console.log("meegsend",userData)
}
 
     
   }
      
         
   }

 const [expand, setExpand] = useState(false);
 const toggleExpand = () => {
   setExpand(!expand);
 }

 const handleKeypress = e => {
   //it triggers by pressing the enter key
 if (e.key === "Enter") {
   sendPrivateValue();

 }
};

 //for list of messeges
 const [listMesseges, setlistMesseges] = useState([]);
 const [MessegesData, setMessegesData] = useState([]);


 useEffect(() => {
   loadMesseges();
 },[listMesseges]);

 
 const loadMesseges = async () => {
   
   const result = await axios.get(configData.ApiUrl+'/messenger/listmessage?from='+userData.senderId+'&to='+userData.receiverId);

   setlistMesseges(result.data.data.messages);
   //setlistMesseges(result.data.data.messages.sort((a, b) => a.id - b.id));
   console.log("msgtdt",result.data.data.messages);

   setMessegesData(result.data.data);
   console.log("getmessegeslists",result.data.data)
debugger
   const el = document.getElementById('chat-feed');
   // id of the chat container ---------- ^^^
   if (el) {
     el.scrollTop = el.scrollHeight+10;
   }

  };

  //for scorll down auto
  const messageEl = useRef(null);
  const scrollToElement = () => messageEl?.current?.scrollIntoView();
 useEffect(()=>{
 if(messageEl){
   debugger
   scrollToElement()
 }
 }, [messageEl])

 //  const messageEl = useRef(null);

 //  useEffect(() => {
 //   if (messageEl) {
 //     messageEl?.current?.addEventListener('DOMNodeInserted', event => {
 //       const { currentTarget: target } = event;
 //       target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
 //     });
 //   }
 // }, [])



 useEffect(() => {
   loadUserListUpdate();
 }, []);


 const loadUserListUpdate = async () => {
   const result = await axios.get(`${configData.ApiUrl}/messenger/getUsersListMessenger?hostId=${props.uerAuthId}`);

   props.setuserlist(result.data.data);
   console.log("getuserlistUpdated", result.data.data);
   // const rootElement17 = document.getElementById('profpicdisablesave17');

   // if(rootElement17){
   //   rootElement17.addEventListener('contextmenu', handleContextMenuimgdisable);
   // }
   // if(rootElement17){
   //   return () => {
   //     rootElement17.removeEventListener('contextmenu', handleContextMenuimgdisable);
   //   };
   // }
 };

 function handleContextMenuimgdisable(e) {
    debugger
    e.preventDefault(); // prevents the default right-click menu from appearing
  }
//for close popup when click on outside
const popupMessagdivRef = useRef(null);
const handleClickOutsideMessagediv = (event) => {
 if (popupMessagdivRef.current && !popupMessagdivRef.current.contains(event.target)) {
   props.handleClose();
 }
};
useEffect(() => {
 document.addEventListener('mousedown', handleClickOutsideMessagediv);
 return () => {
   document.removeEventListener('mousedown', handleClickOutsideMessagediv);
 };
}, []);


const [isOpenMessagePop, setIsOpenMessagePop] = useState(false);
    const togglePopupMessage = (userId) => {
      debugger;
      if (!styleForMessgblock || !blockPop) {
        setIsOpenMessagePop(!isOpenMessagePop);
  
      }
      setstyleForMessgid(userId)
      //loadMesseges(userId)
  
    }
 ///for mesenger
 const [userlist, setuserlist] = useState([]);
  
 // useEffect(() => {
 //   loadUserList();
 // }, []);
 
 useEffect(() => {
   const intervalId = setInterval(() => {
     loadUserList();
   }, 5000); // Poll every 5 seconds

   return () => clearInterval(intervalId);
 }, []);


 const loadUserList = async () => {
   const result = await axios.get(`${configData.ApiUrl}/messenger/getUsersListMessenger?hostId=${uerAuthId}`);

   setuserlist(result.data.data);
   console.log("getuserlist", result.data.data);
   const rootElement17 = document.getElementById('profpicdisablesave17');

   if(rootElement17){
     rootElement17.addEventListener('contextmenu', handleContextMenuimgdisable);
   }
   if(rootElement17){
     return () => {
       rootElement17.removeEventListener('contextmenu', handleContextMenuimgdisable);
     };
   }
 };

 //for search messg
 const handleSearchChangeMessage = (e) => {
   debugger
   if (e.currentTarget.value === "") {
     loadUserList();
     return;
   }
   const filterBySearch = userlist.filter((item) => {
     if (item != null && item.userName != null) {
       if (item?.userName?.toLowerCase().includes(e.currentTarget.value)) { return item; }
     }
      if ( item != null && item.lastMessage != null){
        if (item?.lastMessage?.toLowerCase()
            .includes(e.currentTarget.value)) { return item; }}
   })
   setuserlist(filterBySearch);
 }


 const [styleForMessg, setstyleForMessg] = useState(true);
 const [styleForMessgid, setstyleForMessgid] = useState(0);
 const [styleForMessgBlockstatus, setstyleForMessgBlockstatus] = useState("");

 const omMouseHover = (userId,blockStatus) => {

   setstyleForMessg(true);
   setstyleForMessgid(userId);
   setstyleForMessgBlockstatus(blockStatus)

 }
 const omMouseLeve = (userId) => {

   setstyleForMessg(false);

 }

 //for three dot for bpck unblock
 const [styleForMessgblock, setstyleForMessgblock] = useState(true);
 const [style, setStyle] = useState({ display: 'none' });

 const omMouseHoverblock = (userId) => {

   setIsOpenMessagePop(false)
   setstyleForMessgid(userId)

   //setStyle({display: 'block',border: '1px solid gray',  padding: 6, borderRadius:50});
   setstyleForMessgblock(true)
 }
 const omMouseLeveBlock = (userId) => {

   setstyleForMessgblock(false)

 }


 const addblockUnblock = async (userId) => {
debugger
   const response = await axios.post(configData.ApiUrl+'/messenger/block-unblock?hostId=' + uerAuthId + '&userId=' + userId)
   if (response.status == 200 && response.data.code) {
     toast.success("Updated Block Status Successfully!");
     loadUserList();
     blockunblockpoupopen(false)
     setblockPop(false)
   }
   else {
     alert("Something Went Wrong..!")
   }
 }

 //for block popup
 const [blockPop, setblockPop] = useState(false);

 function blockunblockpoupopen(blockpopopen) {
   if (blockpopopen) {
     setblockPop(true);
   }
   else {
     setblockPop(false);
   }
 }


 return (
   <div className="popup-boxmessageexpand">
       <div className="boxmessageexpand">
       <div className="borderbottom-radius margintop3">
  

  <div className="margintop1 "></div>
  <div className="radius-div" style={{ marginLeft: "-10px" ,marginTop:"-13px",width:"347px"}} >
    <div className="textleft radiussub-div" style={{marginLeft:"0px"}}><IoPinSharp size="1.3em"/>    <span className="radius-heding" style={{marginLeft:"5px"}}> My Messanger({userlist && userlist.length})</span><span style={{float:"right",marginRight:"10px"}}><FaEdit style={{ marginLeft: '0px',color:'#FF8427' }} /><FiMaximize2  style={{cursor:'pointer',marginLeft:"10px"}} onClick={props.handleClose}/></span></div>
    
    <div className="row margintop3"></div>
    <div class="form-group has-search margintop3" style={{marginLeft:'2px'}}>
                  <span class="fa fa-search form-control-feedback"></span>
                  <input type="text" class="form-control" placeholder="Search Messages" onChange={handleSearchChangeMessage} style={{ fontSize: '12px' ,borderColor:"#214B0D"}} />
                </div>
                {
                  userlist.map((user, index) => (
                    <Card className={styleForMessg && user.userId == styleForMessgid ? "hoverformesgcard margintop1" : " margintop1"} style={{ paddingBottom: "8px", paddingTop: "5px", lineHeight: "1.6", cursor: "pointer" ,borderTop:"none",borderLeft:"none",borderRight:"none",borderBottomWidth:"1px",borderRadius:"0px"}}

                      onMouseEnter={() => omMouseHover(user.userId,user.blockStatus)}
                      onMouseLeave={() => omMouseLeve(user.userId)}
                    >
                      <div className="row margintop1" key={user.userId}>
                        <div className="col-2 margintop1" onClick={() => togglePopupMessage(user.userId)}>
                          <img src={user.profilePicturePath} id='profpicdisablesave17' className=' messengerprofile' />
                          
                        </div>
                        <div className="col-10">
                          <div className="row">
                            <div className="col-9" onClick={() => togglePopupMessage(user.userId)}>
                              <span className="radius-fourth-card1-lefttext" style={{ marginLeft: "-9px" }} >{user.userName}</span>
                            </div>
                            <div className="col-3 textright">
                              {
                                styleForMessg && user.userId == styleForMessgid ?
                                  (<span className={styleForMessgblock && user.userId == styleForMessgid ? "threedotblockunclock" : ""}
                                    onMouseEnter={() => omMouseHoverblock(user.userId)}
                                    onMouseLeave={() => omMouseLeveBlock(user.userId)}
                                    onClick={() => blockunblockpoupopen(true)}>
                                    <BsThreeDots style={{ cursor: "pointer" }} />
                                  </span>)
                                  :
                                  (<span className="radius-fourth-card1-text" style={{ marginLeft: '-16px' }}>{user.time}</span>)
                              }
                            </div>

                          </div>
                          <div onClick={() => togglePopupMessage(user.userId)}>
                            <span className="textShowondot message-text" style={{ lineHeight: '80%' }}>
                              {user.lastMessage}

                            </span>
                          </div>

                        </div>
                        <div className={blockPop && user.userId == styleForMessgid ? "" : "hide"}>
                          <div className="" style={{ backgroundColor: "fff", boxShadow: "0px 5px 5px 0px rgba(242, 242, 242, 1)", borderRadius: "3px", width: "150px", fontSize: "12px", float: "right", marginTop: "-62px", marginLeft: "-20px" }}>
                            <div className="">
                              <ul class="list-group ">
                                {
                                  user.blockStatus == false ?
                                    (<li class="list-group-item"  style={{ cursor: "pointer" }}> <span onClick={() => addblockUnblock(user.userId)}>Block</span> <MdOutlineClose  onClick={() => blockunblockpoupopen(false)} style={{marginLeft:"0px",marginTop:"-7px",float:"right",marginRight:"-14px"}}/></li>) :
                                    (<li class="list-group-item"  style={{ cursor: "pointer" }}><span onClick={() => addblockUnblock(user.userId)}>Unblock</span> <MdOutlineClose  onClick={() => blockunblockpoupopen(false)} style={{marginLeft:"0px",marginTop:"-7px",float:"right",marginRight:"-14px"}}/></li>)

                                }
                              </ul>
                            </div>
                          </div>

                        </div>
                      </div>


                    </Card>
                  ))

                }

                {isOpenMessagePop && <MessagePopup

                  handleClose={togglePopupMessage} uerAuthId={uerAuthId} styleForMessgid={styleForMessgid}
                  userlist ={userlist} setuserlist={setuserlist} styleForMessgBlockstatus={styleForMessgBlockstatus}
                />}

    
  </div>
</div>

     </div>
   </div>
 );
};

export default MessageExpandPopup;