
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment, { duration } from 'moment'
import csc from "country-state-city";
import Select from "react-select";
import {configData} from "../../Config"

const BusinessAddress = ({additional,setadditional,handleBack,handleNext}) => {

  //for session
  let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  

    const [startDate, setStartDate] = useState(new Date());

    const handleChangeDate = (e) => {
        setStartDate(e);
        // setStartDate(preState => ({
        //     ...preState,
        //     "birthday": (moment(e).format("dd-MMM-yyyy")),
    
        //   }))
    
        setadditional(preState => ({
            ...preState,
            "birthDate": (moment(e).format("DD MMM yyyy")),
    
        }))
    
        console.log(additional.birthday);
    }
    
    const [error, setError] = useState(false);
    const [contactValidEroor ,setContactValidEroor] = useState(false);

    
    const submitFormData = (e) => {
      debugger
      e.preventDefault();
  
      // checking if value of first name and last name is empty show error else take to step 2
      
      
       if (additional.addressLine1==""||additional.addressLine1==null) {
        setError(true);
      }
      else if (additional.addressLine2==""||additional.addressLine2==null) {
        setError(true);
      }
      
      else if (additional.landmark==""||additional.landmark==null) {
          setError(true);
  
      }
      else if (additional.country==""||additional.country==null) {
        setError(true);

      }
      else if (additional.state==""||additional.state==null) {
        setError(true);

      }
      else if (additional.city==""||additional.city==null) {
        setError(true);

      }
      else if (additional.pinCode==""||additional.pinCode==null) {
        setError(true);

      }
      
      
  else {
        debugger
        handleNext()
      }
  };
      const handleChange = (e) => {
        debugger;
        const { name, value, type, checked } = e.currentTarget;
  
  
        setadditional(preState => ({
            ...preState,
            [name]: type === 'checkbox' ? checked : value
        }));
    }
    
   

  

  const countries = csc.getAllCountries();
    //console.log(csc.getAllCountries())
  
    const updatedCountries = countries.map((country) => ({
      label: country.name,
      value: country.id,
      ...country
    }));
    
    const updatedStates = (countryId) => csc.getStatesOfCountry(countryId)
        .map((state) => ({ label: state.name, value: state.name, ...state }));
  
    const updatedCities = (stateId) =>
      csc
        .getCitiesOfState(stateId)
        .map((city) => ({ label: city.name, value: city.name, ...city }));
       
       
        //for country city state
const [countryData, setcountryData] = useState([]);

useEffect(() => {
  loadCountryData();
}, []);

const loadCountryData = async () => {
  debugger
  const result = await axios.get(configData.ApiUrl+"/user/listOfCountries");
  setcountryData(result.data.data);
  console.log("country",result.data.data)
};

const handleCountryChange = (event) => {
  const [id, country] = event.target.value.split("-");
  setadditional((prevState) => ({
    ...prevState,
    countryCode: id,
    country: country,
  }));
  loadStateData(id);
};
//for state city state
const [stateData, setstateData] = useState([]);

useEffect(() => {
loadStateData();
}, []);

const loadStateData = async (countryCode) => {
debugger
const result = await axios.get(`${configData.ApiUrl}/user/listOfStates?countryId=${countryCode}`);
setstateData(result.data.data);
console.log("state",result.data.data)
};

const handleStateChange = (event) => {
debugger
const [id, state] = event.target.value.split("-");
setadditional((prevState) => ({
...prevState,
stateCode: id,
state: state,
}));
loadCityData(id);

};
//for state city state
const [cityData, setcityData] = useState([]);

useEffect(() => {
loadCityData();
}, []);

const loadCityData = async (stateCode) => {
debugger
const result = await axios.get(`${configData.ApiUrl}/user/listOfCities?stateId=${stateCode}`);
setcityData(result.data.data);
console.log("city",result.data.data);
};

const handleCityChange = (event) => {
const [id, city] = event.target.value.split("-");
setadditional((prevState) => ({
...prevState,
cityCode: id,
city: city,
}));
};
  
return(
    
<div className="container">
<form >
        <div className="row margintop">
          <div className="col-10" id="completeprofradio">
            <div className="row textLeft">
                <div className="col-lg-7 col-sm-12-col-md-12"> 
                     <span className="lbl popinsfont"style={{fontSize:'14px'}} >Address Details</span>
           </div>

</div>
         <div className="row margintop">
<div className="col-4 textright" style={{marginTop:"5px"}}>
  <span className="lbl popinsfont"style={{fontSize:'13px'}} >Address Line 1</span>
</div>
<div className="col-7 textleft">
<input type="text" class=" form-control" placeholder='Enter Address Line 1' onChange={handleChange} name='addressLine1' style={{fontSize:"13px"}} />
</div>
<div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && additional.addressLine1 == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Address Line1  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
         </div>
     <div className="row margintop">
<div className="col-4 textright" style={{marginTop:"5px"}}>
  <span className="lbl popinsfont"style={{fontSize:'13px'}} >Address Line 2</span>
</div>
<div className="col-7 textleft">
<input type="text" class=" form-control" placeholder='Enter Address Line 2' onChange={handleChange} name='addressLine2' style={{fontSize:"13px"}} />
</div>
<div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && additional.addressLine2 == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Address Line2  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
         </div>

<div className="row margintop">
<div className="col-4 textright" style={{marginTop:"5px"}}>
  <span className="lbl popinsfont"style={{fontSize:'13px'}}> Landmark</span>
</div>
<div className="col-7 textleft">
<input type="text" class=" form-control" placeholder='Enter Landmark' onChange={handleChange} name='landmark' style={{fontSize:"13px"}} />
</div>
     </div>
     
     <div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && additional.landmark == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Landmark  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
    
        
         <div className="row margintop">
<div className="col-4 textright" style={{marginTop:"7px"}}>
  <span className="lbl popinsfont" style={{fontSize:'13px'}}>Country</span>
</div>
<div className="col-7 textleft">
{/* <Select 
       id="country"
      name="country"
      label="country"
     className="countrydrp"
     rules={{ required: true }} 
    options={updatedCountries}
   
   onChange={(event) =>
    setadditional({...additional, country:event.name, countryId:event.id,countryCode:event.id})
   }
  />                  */}
  <select   id="countryy"  
                className=" form-control "
                name='country'
                value={additional?.country}
                onChange={handleCountryChange}>
                <option selected value="">Select</option>
                {
                  
                  countryData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.country}`}>{result.country}</option>
                  ) )

                }
                </select> 
</div>
         </div>
         <div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && additional.country == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Country  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
  <div className="row margintop">
<div className="col-4 textright" style={{marginTop:"7px"}}>
  <span className="lbl popinsfont" style={{fontSize:'13px'}}>State</span>
</div>
<div className="col-7 textleft">
{/* <Select 
      name="state"
      id="state"
      className="countrydrp"
      
       options={updatedStates(additional.countryId ? additional.countryId : null)}
      
       onChange={(event) =>
         setadditional({...additional, state:event.name,stateId:event.id,stateCode:event.id})
       }
    

      />         
                       */}

<select   id="state"  
                className=" form-control "
                name='state'
                value={additional?.state}
                onChange={handleStateChange}>
                <option selected value="">Select</option>
                {
                  
                  stateData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.state}`}>{result.state}</option>
                  ) )

                }
                </select> 
</div>
         </div>
         <div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && additional.state == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            State is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
         <div className="row margintop">
<div className="col-4 textright" style={{marginTop:"7px"}}>
  <span className="lbl popinsfont" style={{fontSize:'13px'}}>City</span>
</div>
<div className="col-7 textleft">
{/* <Select 
      //required
id="city"
className="countrydrp"
name="city"
options={updatedCities(additional.stateId ? additional.stateId : null)}

 onChange={(event) =>
   setadditional({...additional, city:event.name,cityId:event.id,cityCode:event.id})
 }

      />                           */}
      <select   id="city"  
                className=" form-control "
                name='city'
                value={additional?.city}
                onChange={handleCityChange}>
                <option selected value="">Select</option>
                {
                  
                  cityData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.city}`}>{result.city}</option>
                  ) )

                }
                </select>
</div>
         </div>
         <div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && additional.city == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            City is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
         <div className="row margintop">
<div className="col-4 textright" style={{marginTop:"5px"}}>
  <span className="lbl popinsfont"style={{fontSize:'13px'}}>PIN</span>
</div>
<div className="col-7 textleft">
<input type="text" class=" form-control" placeholder='Enter Zip Code' onChange={handleChange} name='pinCode' style={{fontSize:"13px"}} />
</div>
     </div>
     
  
         <div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && additional.pinCode == null ? (
                                        <span style={{ color: "red",fontSize:"13px" }}>
                                            Pin Code is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
         
     
       
          </div>  
          <div className="margintop row">
            <div className="col-3"></div>
            <div className="col-3 margintop3 textright">
            <button
               className="prevbtn sourcesansfont"
              type="submit"
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-2 margintop3">
          <button
            className="nextbtn1 sourcesansfont"
              variant="contained"
              color="primary"
              //onClick={handleNext}
              onClick={submitFormData}
            >
               Next
            </button>
            
            
          </div>
          <div className="margintop2  row"></div>

    
          </div>     
      </div>
</form>
    </div>
)
}
export default BusinessAddress