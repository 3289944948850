
import React from "react";
import './JoinNow.css';
import payment from '../../images/paymentsuc.svg';
import success from '../../images/sucses.svg';
import { useParams } from 'react-router-dom';

 
const PaymentSuccess = () => {

  const { paymentId, userId } = useParams();
  
  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
  console.log("authdataappjs", authlocalstoragedata)
  
  const [joinNowRes,setjoinNowRes]= useState()
  const [updatedData, setupdatedData] = useState(false);

   useEffect(() => {
    loadUpdatedData();
   }, []);

   const loadUpdatedData = async () => {
     debugger;
     const result = await axios.get(`${configData.ApiUrl}/getUpdatedData?userId=${authlocalstoragedata.userAuthenticationId}`);
     setupdatedData(result.data.data);
     if (result.data.data != null) {

      localStorage.setItem('JoinNowData',JSON.stringify(result.data.data));
      setjoinNowRes(result.data.data)
      debugger;
      localStorage.setItem('name',result.data.data.name);
      // history.push("/CompleteProfile")




    }
     
 console.log("updatedadata",updatedData)
   };
 
   return (
    <div className="container">
        <div className="margintop  row"></div>
    <div className="margintop  row ">
        <div className="col-2"></div>
          <div className="col-9 form-containerwhite">
            <div className="margintop textcenter">
            <img src={payment} class='margintop'/>
            <img src={success} class='succimg'/>
            </div>
            <div className="textcenter margintop">
              <span className="rslable">Rs. 399</span>
            </div>
            <div className="textcenter margintop">
              <span className="paidsucc-text">Paid successfully to Naidu Directory  Org</span>
            </div>
            <div className="textcenter margintop5">
              <span className="recivemail-text">You will receive the receipt on your registered email XYZ@gmail.com or you can also download it </span>
              <div ><span className="recivemail-text">from your profile section. In case of any queries you can drop an email to </span></div>
              <div ><span className="recivemail-text">  support@XYZcommunity.com</span></div>
            </div>
            <div className="row margintop">
              <div className="col-1"></div>
              <div className="col-3 textright">
                <span className="transactionid">Transaction Id:</span>
              </div>
              <div className="col-2 textleft">
              <span className="transactionid">{paymentId}</span>
                </div>
              <div className="col-3  textleft">
              <span className="transactionid">Payment Method:</span>

              </div>
              <div className="col-3  textleft" style={{marginLeft:"-40px"}}>
              <span className="transactionid">Card Payment</span>

              </div>

            </div>
            <div className="margintop textcenter">
              <button className="marginleftmins2 compl-propilebtn" onClick={loadUpdatedData}>Complete profile</button>
            </div>
          </div>
          </div>
          </div>
  );
};
 
export default PaymentSuccess;

