import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.css";
import { Avatar,Box,Paper } from "@material-ui/core";
import {AiOutlineLeft,AiOutlineRight} from "react-icons/ai"
import React, { useState, useEffect } from "react";
import axios from "axios";
import Carousel from 'react-grid-carousel'
import community1 from '../../images/community1.png';
import community2 from '../../images/community2.png';
import community3 from '../../images/community3.png';
import { useHistory } from "react-router-dom";
import {configData} from "../../Config"



const SponserBusiness = () => {

  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
  console.log("authdata11",authlocalstoragedata)
  
  const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
  
  const [sponserSlider, setsponserSlider] = useState([]);
  
        useEffect(() => {
          loadBloodReqSlider();
        }, []);
    
       const loadBloodReqSlider = async () => {
        
         const result = await axios.get(`${configData.ApiUrl}/CommunityPost/getListOfAds`);
         setsponserSlider(result.data.data);
         console.log("sponserSlider",result.data.data)
       };
  
       const [crntSlide, setcrntSlide] = useState(0);

  let history = useHistory();

  //for new session for userlogin
  
  return (
    <div className="sponserdiv">
        <Carousel cols={1} rows={1} gap={10} loop autoplay={2000} hideArrow={true} style={{height:"100%"}} >
        {
                  sponserSlider.map((getmenu, index) => (
             <Carousel.Item>
          <div className="row ">
          <img width="100%" src={getmenu.webPortalImagePath}  style={{ cursor: "pointer" ,height:"100px"}} />

        </div>   
       </Carousel.Item>
      
   
                    
    ))
  }
  {
       sponserSlider  && sponserSlider.length== 0 &&(
        <span className="slider-name textcenter">No Blood Requirements Currently</span>
       )
  }   
                  </Carousel>
    </div>
  );
};




export default SponserBusiness;