import './BloodRequirePopup.css';

import React, { useState, useEffect,useRef } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import validator from "validator";
import csc from "country-state-city";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import moment, { duration } from 'moment';
import OtpInput from 'react-otp-input';
import {configData} from "../../Config"


const BloodRequirePopup = props => {

  // //for session
  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  


    const [startDate, setStartDate] = useState(new Date());

    const handleChangedate= (e) => {
      debugger
     setStartDate(e)
     // write code for date parametars .. Like Moment formate 

     setFormData(preState => ({
      ...preState,
      "reqDate": (moment(e).format("DD MMM yyyy")),

    }))

    }

    const [showhide, setShowhide]= useState("");


 const handleshow= e=>{
   const getshow= e.target.value;
   setShowhide(getshow);
 }
 const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  
  const [isOpendiv, setIsOpendiv] = useState(true);
  const [isformdiv, setIsformdiv] = useState(false);
  const [isformdiv1, setIsformdiv1] = useState(false);
  const [isotpdiv, setisotpdiv] = useState(false);


  const onNext = (isback) => {
   
    if(isback){
      setIsOpendiv(true);
      setIsformdiv(false);
      setIsformdiv1(false)
      setisotpdiv(false)

    }
    else{
      setIsOpendiv(false);
      setIsformdiv(true)
      setIsformdiv1(false)
      setisotpdiv(false)


    }
       

  }
  const onNextOther = (isback1) => {
   
    if(isback1){
      setIsOpendiv(true);
      setIsformdiv(false);
      setIsformdiv1(false)
      setisotpdiv(false)

    }
    else{
      setIsOpendiv(false);
      setIsformdiv(false)
      setIsformdiv1(true)
      setisotpdiv(false)


    }
       

  }
  const onNextOtp = (isbackOtp) => {
   
    if(isbackOtp){
      
      setIsOpendiv(true);
      setIsformdiv(true)
      setIsformdiv1(true)
      setisotpdiv(false)
    }
    else{
      
        setIsOpendiv(false);
        setIsformdiv(false);
        setIsformdiv1(false);
        setisotpdiv(true)
    }
       

  }


  //for otp
  const [enablejoin, setenableResend] = useState(false);

const counterandbtn =()=>{
  setCounter(30);
  setenableResend(false);
  if(formData.reqFor=='myself'){
    resendOtp();      
   }
   else{
    resendOtpOther()

   }
}

  const [counter, setCounter]= useState(56);
  useEffect(() =>{
    if(counter === 0){
      setenableResend(true);
  
    }
    const timer =
    counter > 0 && setInterval(() =>setCounter(counter-1), 1000);
    return () => clearInterval(timer);
  },[counter]);

  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)

  const [formData, setFormData] = useState({
    reqFor:"",
    raisedById:authlocalstoragedata?.userAuthenticationId,
   raisedBy:authlocalstoragedata?.name2,
   raisedFor:"",
   contactNo:authlocalstoragedata?.mobileNumber,
   reqCountryId:"",
   reqCountry:"",
   reqStateId:"",
   reqState:"",
   reqCityId:"",
   reqCity:"",
   bloodGroup:"",
   reqDateType:"",
   reqDate:"",

    });
  
  const handlechange =(e)=>{
    const {name,value,type,checked}=e.currentTarget;
    debugger
    setFormData(preState =>({
      ...preState,
      [name]:type ==='checkbox'?checked: value
    }))
    
    if(type == 'radio' && value=='myselef'){
      setFormData(preState =>({
        ...preState,
        "raisedFor":formData.raisedBy
      }))}
      if(type == 'radio' && value=='othercommunity'){
        setFormData(preState =>({
          ...preState,
          "raisedFor":nameInfo
        }))}
        if(type == 'radio' && value=='othercommunity'){
          setFormData(preState =>({
            ...preState,
            "contactNo":mobilefoOtp
          }))}
    

  }


  const [otp,setOtp]= useState({
    otpNum1:"",
    otpNum2:"",
    otpNum3:"",
    otpNum4:""
  
   })

   const submitOtpFormData = (e) => {
    debugger
    e.preventDefault();
  
    // checking if value of first name and last name is empty show error else take to step 2
    if (otp.otpNum1==""||otp.otpNum1==null) {
        setError(true);
    }
    else if (otp.otpNum2==""||otp.otpNum2==null) {
        setError(true);
    }
    
    else if (otp.otpNum3==""||otp.otpNum3==null) {
      setError(true);
  
    }
    else if (otp.otpNum4==""||otp.otpNum4==null) {
      setError(true);
  
    }
    
  else {
      debugger
      handleotpVeryFyRedirect();
    }
  };
  
   const handlechangeOtp =(e)=>{
    const {name,value,type,checked}=e.currentTarget;
    setOtp(preState =>({
      ...preState,
      [name]:type ==='checkbox'?checked: value
    }))
  }

  const countries = csc.getAllCountries();
  //console.log(csc.getAllCountries())

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country
  }));
  
  const updatedStates = (countryId) => csc.getStatesOfCountry(countryId)
      .map((state) => ({ label: state.name, value: state.name, ...state }));

  const updatedCities = (stateId) =>
    csc
      .getCitiesOfState(stateId)
      .map((city) => ({ label: city.name, value: city.name, ...city }));


//for country city state
const [countryData, setcountryData] = useState([]);

useEffect(() => {
  loadCountryData();
}, []);

const loadCountryData = async () => {
  debugger
  const result = await axios.get(configData.ApiUrl+"/user/listOfCountries");
  setcountryData(result.data.data);
  console.log("country",result.data.data)
};

const handleCountryChange = (event) => {
  const [id, country] = event.target.value.split("-");
  setFormData((prevState) => ({
    ...prevState,
    reqCountryId: id,
    reqCountry: country,
  }));
  loadStateData(id);
};
//for state city state
const [stateData, setstateData] = useState([]);

useEffect(() => {
loadStateData();
}, []);

const loadStateData = async (countryCode) => {
debugger
const result = await axios.get(`${configData.ApiUrl}/user/listOfStates?countryId=${countryCode}`);
setstateData(result.data.data);
console.log("state",result.data.data)
};

const handleStateChange = (event) => {
debugger
const [id, state] = event.target.value.split("-");
setFormData((prevState) => ({
...prevState,
reqStateId: id,
reqState: state,
}));
loadCityData(id);

};
//for state city state
const [cityData, setcityData] = useState([]);

useEffect(() => {
loadCityData();
}, []);

const loadCityData = async (stateCode) => {
debugger
const result = await axios.get(`${configData.ApiUrl}/user/listOfCities?stateId=${stateCode}`);
setcityData(result.data.data);
console.log("city",result.data.data);
};

const handleCityChange = (event) => {
const [id, city] = event.target.value.split("-");
setFormData((prevState) => ({
...prevState,
reqCityId: id,
reqCity: city,
}));
};

      const [error, setError] = useState(false);
      const [error1, setError1] = useState(false);


      const submitFormData = (e) => {
        debugger
        e.preventDefault();
        if(formData.reqFor=='othercommunity'){
          if (validator.isEmpty(formData.reqFor)) {
            setError1(true);
        }
        else if (validator.isEmpty(formData.reqCountry)) {
            setError1(true);
        }
        else if (validator.isEmpty(formData.bloodGroup)) {
          setError1(true);
        }
        else if (validator.isEmpty(formData.reqState)) {
          setError1(true);
    
        }
        else if (validator.isEmpty(formData.reqCity)) {
            setError1(true);
    
        }
        
        else if (validator.isBoolean(formData.reqDateType)) {
        setError1(true);

       }
       else if (validator.isEmpty(formData.reqDate)&& formData.reqDateType=='specificdate') {
          
        
        setError1(true);

       }
       else if ((formData.raisedFor==''||formData.raisedFor==undefined)&& formData.reqFor=='othercommunity') {
        setError1(true);

       }
       
        
    else {
      if(formData.reqFor=='myself'){
        sendOtp();      
       }
      else{
        sendOtpOther();      

      }
      }
        }
        else{
          if (validator.isEmpty(formData.reqFor)) {
            setError(true);
        }
        else if (validator.isEmpty(formData.reqCountry)) {
            setError(true);
        }
        else if (validator.isEmpty(formData.bloodGroup)) {
          setError(true);
        }
        else if (validator.isEmpty(formData.reqState)) {
          setError(true);
    
        }
        else if (validator.isEmpty(formData.reqCity)) {
            setError(true);
    
        }
        
        else if (validator.isBoolean(formData.reqDateType)) {
        setError(true);

       }
       else if (validator.isEmpty(formData.reqDate)&& formData.reqDateType=='specificdate') {
          
        
        setError(true);

       }
       else if ((formData.raisedFor==''||formData.raisedFor==undefined)&& formData.reqFor=='othercommunity') {
        setError(true);

       }
       
        
    else {
      if(formData.reqFor=='myself'){
        sendOtp();      
       }
      else{
        sendOtpOther();      

      }
      }
        }
    
        // checking if value of first name and last name is empty show error else take to step 2
        
    };


    let history = useHistory();
//send otp
const sendOtp = async()=>{
 if(isformdiv){
   debugger
   const result = await axios.get(configData.ApiUrl+'/user/sendOTP?mobileNo='+
   authlocalstoragedata?.mobileNumber+ '&emailId='+authlocalstoragedata?.emailId+'&countryCode='+authlocalstoragedata?.countryCode);
   console.log("sendotp",result.data.data)
     if(result.data.code==200){
       
       toast.success("Otp sent!")
       setisotpdiv(true);
       setIsOpendiv(false);
         setIsformdiv(false);
         setIsformdiv1(false);
  
     }
     else{
       debugger
       if(isformdiv){
     setisotpdiv(false);
      setIsOpendiv(false);
      setIsformdiv(true);
      setIsformdiv1(false);
      toast.warn("Otp sent Fail!")

   }
      else{
       setisotpdiv(false);
       setIsOpendiv(false);
         setIsformdiv(false);
         setIsformdiv1(true);
  
   }
      
  
     }
   }
  
  }

  const sendOtpOther = async()=>{
    if(isformdiv1){
      debugger
      const result = await axios.get(configData.ApiUrl+'/user/sendOTP?mobileNo='+
      mobileNumberOtp+ '&emailId='+emailInfo+'&countryCode='+countrycodeInfo);
      console.log("sendotp",result.data.data)
        if(result.data.code==200){
          
          toast.success("Otp sent!")
          setisotpdiv(true);
          setIsOpendiv(false);
            setIsformdiv(false);
            setIsformdiv1(false);
     
        }
        else{
          debugger
          if(isformdiv1){
        setisotpdiv(false);
         setIsOpendiv(false);
         setIsformdiv(false);
         setIsformdiv1(true);
         toast.warn("Otp sent Fail!")
   
      }
         else{
          setisotpdiv(false);
          setIsOpendiv(false);
            setIsformdiv(true);
            setIsformdiv1(false);
     
      }
         
     
        }
      }
     
     }
    const handleotpVeryFyRedirect =()=>{
      if(formData.reqFor=='myself'){
        handleVerifyOptp()
      }
      else{
        handleVerifyOptpOther()
      }
      }

  const handleVerifyOptp =async()=>{
    debugger;
    if(authlocalstoragedata?.countryCode=="+91" ||authlocalstoragedata?.countryCode=="91"||authlocalstoragedata?.countryCode==" 91"){
  
    const result = await axios.get(configData.ApiUrl+'/user/validateMobileOtp?mobileNo='+
    authlocalstoragedata?.mobileNumber+ '&otp='+otpNew);
  console.log("sendotp",result.data.data)
    if(result.data.code==200){
      toast.success("Otp Verified!");
      addBloodReq();
    }
    else{
      toast.warn("Otp Verification fail!");
  
    }
  }
  else{
  
    const result = await axios.get(configData.ApiUrl+'/user/validateEmailOtp?emailId='+
    authlocalstoragedata?.emailId+ '&otp='+otpNew);
  console.log("sendotp",result.data.data)
    if(result.data.code==200){
      toast.success("Otp Verified!");
      addBloodReq();
      
    }
    else{
      toast.warn("Otp Verification fail!");
  
    }
  }
  
  }
  const handleVerifyOptpOther =async()=>{
    debugger;
    if(countrycodeInfo=="+91"||countrycodeInfo=="91"||countrycodeInfo.countryCode==" 91"){
  
    const result = await axios.get(configData.ApiUrl+'/user/validateMobileOtp?mobileNo='+
    mobileNumberOtp+ '&otp='+otpNew);
  console.log("sendotp",result.data.data)
    if(result.data.code==200){
      toast.success("Otp Verified!");
      addBloodReq();
    }
    else{
      toast.warn("Otp Verification fail!");
  
    }
  }
  else{
  
    const result = await axios.get(configData.ApiUrl+'/user/validateEmailOtp?emailId='+
    emailInfo+ '&otp='+otpNew);
  console.log("sendotp",result.data.data)
    if(result.data.code==200){
      toast.success("Otp Verified!");
      addBloodReq();
      
    }
    else{
      toast.warn("Otp Verification fail!");
  
    }
  }
  
  }
//add blood req
    const addBloodReq =()=>{
      debugger
    if(formData.reqFor=='myself'){
      setFormData(preState =>({
        ...preState,
        "raisedFor":formData.raisedBy
      }))

      formData.raisedFor = formData.raisedBy;
    }
        const response = axios.post(configData.ApiUrl+'/bloodReq/InsertBloodRequirement', formData)
        response.then(() => {
              toast.success("Blood Req Added Successfully!");
    
                
       })
           response.catch((err) => {
             alert("Getting error in featching data")
           });
           console.log("response", response?.data)
           props.handleClose()
          // history.push("/ViewProfile")
    console.log("bloodreq",formData)
          
    }

//resend otp
const resendOtp =async()=>{
  debugger;

  const result = await axios.get(configData.ApiUrl+'/user/resendUserOTP?mobileNo='+
  authlocalstoragedata?.mobileNumber+ '&emailId='+authlocalstoragedata?.emailId+'&countryCode='+authlocalstoragedata?.countryCode);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Sent!");

  }
  else{
    toast.warn("Otp Sent fail")

  }


}
const resendOtpOther =async()=>{
  debugger;

  const result = await axios.get(configData.ApiUrl+'/user/resendUserOTP?mobileNo='+
  mobileNumberOtp+ '&emailId='+emailInfo+'&countryCode='+countrycodeInfo);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Sent!");

  }
  else{
    toast.warn("Otp Sent fail")

  }


}

    const [bloodGroupData, setbloodGroup] = useState([]);

    useEffect(() => {
      loadBloodGroup();
    }, []);
  
    const loadBloodGroup = async () => {
      debugger
      const result = await axios.get(configData.ApiUrl+"/user/bloodGroups");
      setbloodGroup(result.data.data);
      console.log("bldgrp",result.data.data)
    };

    //for dynamic filter

    const [selected, setSelected] = useState([]);
    const [autofetch, setAutofetch] = useState([]);
    const [mobileNumberInfo, setmobileNumberInfo] = useState();
    const [emailInfo, setemailInfo] = useState();
    const [countrycodeInfo, setcountrycodeInfo] = useState();
    const [mobileNumberOtp, setmobileNumberOtp] = useState();
    const [nameInfo, setnameInfo] = useState();
    //for oyherotpemail
    const [emailInfoOtp, setemailInfoOtp] = useState();
    const [mobilefoOtp, setmobilefoOtp] = useState();






    useEffect(() => {
      PopulatUser();
    }, []);
  
    const PopulatUser = async () => {
      debugger
      const result = await axios.get(`${configData.ApiUrl}/bloodReq/getOtherCommunityMembers?id=${authlocalstoragedata?.userAuthenticationId}`);
      setAutofetch(result.data.data);
      console.log("autofetchuser",result.data.data)
    };
  

for(var i=0;i<autofetch.length;i++)
{
  autofetch[i].mobileNumberFormate = autofetch[i].mobileNumber.substr(0, 2) + 'xxxxxx' 
  + autofetch[i].mobileNumber.substr(8, 9);
}

const onChandeDDL =  (e) => {
  debugger
  setSelected(e)
//console.log( e.mobileNumber);
if(e.length>0){
setmobileNumberInfo(e[0].mobileNumberFormate)
setemailInfo(e[0].emailId)
setcountrycodeInfo(e[0].countryCode)
setmobileNumberOtp(e[0].mobileNumber)
setnameInfo(e[0].name)
//for emailotherotp
setemailInfoOtp(e[0].emailId)
setmobilefoOtp(e[0].mobileNumber)

setFormData(preState =>({
  ...preState,
  "raisedFor":e[0].name
}))
}
else{
  setmobileNumberInfo('')
  setemailInfo('')
  setcountrycodeInfo('')
setmobileNumberOtp('')
setnameInfo(' ')

}

  
}
 
const handleInputChange=(input, e) =>{
}

const [otpNew, setOtpNew] = useState('');

 const mobileNumberFormat = authlocalstoragedata?.mobileNumber?.substr(0, 2) + 'xxxxxx' 
    + authlocalstoragedata?.mobileNumber?.substr(8, 9);
    console.log(mobileNumberFormat)
  //  //for otherotp mobile
  const mobileNumberFormate1 = mobilefoOtp?.substr(0, 2) + 'xxxxxx' 
    + mobilefoOtp?.substr(8, 9);


   //for lastname emeil
    let email = authlocalstoragedata?.emailId
   let chunks = email.split("@");
    let Email = `${chunks[0].slice(0, 3)}xxxxxx${chunks[0].substr(chunks[0].length - 3)}@${chunks[1]}`

   //for subcast
  //  let emailsubcast = emailInfo
  //  let chunkssubcast = emailsubcast.split("@");
  //  let Emailsubcast = `${chunkssubcast[0].slice(0, 3)}xxxxxx${chunkssubcast[0].substr(chunkssubcast[0].length - 3)}@${chunkssubcast[1]}`
  
  //for close popup when click on outside
  const popupSearchRef = useRef(null);
  const handleClickOutsideSearchdiv = (event) => {
    if (popupSearchRef.current && !popupSearchRef.current.contains(event.target)) {
      props.handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSearchdiv);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSearchdiv);
    };
  }, []);

  return (
    <div className="popup-boxblood">
      <form onSubmit={submitFormData}>
      <div className="boxblood" ref={popupSearchRef}>
        <span className="close-iconblood" onClick={props.handleClose}>x</span>
        <div className="topnewsrow textcenter">
            <span className="blood-top-text">Submit Blood Requirements</span>
        </div>
        <br/>
        <div className={isOpendiv?"":"hide"} >
        <div style={{overflowY:"scroll",height:"240px"}}>
        <div className="textcenter margintop">
            <span className="blood-second-text">Who are you submitting the requirements for ?</span>
        </div>
        <div className="textcenter margintop1">
            <span className="blood-third-text">This name will be displayde on the  requorement post</span>
        </div>
        

        <div className="row margintop" id='bloodrdiodiv'>
                    <div className='col-3'></div>
                <div className="col-3  textleft">
                    {/* <input type="radio" id="radioblood" className='radiobtnnews' 
                    name="reqFor" value="myself" onChange={handlechange} onClick={handleSubmit(() => onNext(false))}
                    />  
                     <label for="radioblood" className='marginleft2 radiobtn-textblog'> Myself</label> */}
                     <label class="radiodivcust radiobtn-text1" >Myself
                     <input type="radio" id="radioblood" className='radiobtnnews' 
                    name="reqFor" value="myself" onChange={handlechange} onClick={handleSubmit(() => onNext(false))}
                    /> 
                      <span class="checkmark"></span>
           </label>
                    </div>
                    <div className="col-6  textleft">
                    {/* <input type="radio" id="radioblood" className='radiobtnnews' 
                            onClick={handleSubmit(() => onNextOther(false))}                  
                      name="reqFor" value="othercommunity" onChange={handlechange}
                    />  
                     <label for="radioblood" className='marginleft2 radiobtn-textblog'>Other Community Member</label> */}
                     <label class="radiodivcust radiobtn-text1" >Other Community Member
                     <input type="radio" id="radioblood" className='radiobtnnews' 
                            onClick={handleSubmit(() => onNextOther(false))}                  
                      name="reqFor" value="othercommunity" onChange={handlechange}
                    />  
                      <span class="checkmark"></span>
           </label>
                    </div>

                 </div>
                 <div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && formData.reqFor == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            This is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
        </div>
        
        <div className='popupbtn-div margintop'>

        <div className="row ">
            <div className="col-3"></div>
            <div className="col-3">
                <button className="cancelbtn" onClick={props.handleClose} style={{height:"42px",width:"140px"}}>
                    Cancel
                </button>
                </div>
            <div className="col-3"></div>

        </div>
        </div>
        </div>

        <div className={isformdiv?"":"hide"}>
        <div style={{overflowY:"scroll",height:"240px"}}>

   <div className='row'>
    <div className='col-5 textright'>
        <span className='blood-form-lable'>Community Member Name</span>
    </div>
    <div className='col-6 textleft'><span className='blood-form-text'>{authlocalstoragedata?.name2}</span>
    <div className='textleft'><span className='blood-third-text'>This name will be displayed on the requirement post</span></div>
    </div>

    <span></span>

    </div>    
    <div className='row margintop3'>
    <div className='col-5 textright'>
        <span className='blood-form-lable'>Member Contact Number</span>
    </div>
    <div className='col-6 textleft'>
        <span className='blood-form-text'>{authlocalstoragedata?.mobileNumber}</span>
    </div>

    </div>  
    <div className='row margintop3'>
    <div className='col-5 textright'>
        <span className='blood-form-lable'>Place of requirement</span>
    </div>
    <div className='col-6 form-group textleft'>
        <div>
        <span className='blood-form-lable'>Country</span>
        <select   id="countryy"  
                className=" form-control "
                name='country'
                onChange={handleCountryChange}>
                <option selected value="">Select</option>
                {
                  
                  countryData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.country}`}>{result.country}</option>
                  ) )

                }
                </select>   
        </div>
        <div className='row '>
    <div className=" col-12">
                                    {error && formData.reqCountry == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Country is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
        <div className='margintop1'>
        <span className='blood-form-lable'>State</span>
        {/* <Select 
      name="reqState"
      id="state"
      className="countrydrp"
      
       options={updatedStates(formData.countryId ? formData.countryId : null)}
      
       onChange={(event) =>
         setFormData({...formData, reqState:event.name,stateId:event.id,reqStateId:event.id})
       }
    

      /> */}
      <select   id="state"  
                className=" form-control "
                name='state'
                onChange={handleStateChange}>
                <option selected value="">Select</option>
                {
                  
                  stateData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.state}`}>{result.state}</option>
                  ) )

                }
                </select> 
        </div>
        <div className='row '>
    <div className=" col-12">
                                    {error && formData.reqState == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            State is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
        <div className='margintop1'>
        <span className='blood-form-lable'>City</span>

        {/* <Select 
      //required
id="city"
className="countrydrp"
name="reqCity"
options={updatedCities(formData.stateId ? formData.stateId : null)}

 onChange={(event) =>
   setFormData({...formData, reqCity:event.name,cityId:event.id,reqCityId:event.id})
 }

      />  */}
      <select   id="city"  
                className=" form-control "
                name='city'
                onChange={handleCityChange}>
                <option selected value="">Select</option>
                {
                  
                  cityData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.city}`}>{result.city}</option>
                  ) )

                }
                </select>
        </div>
        <div className='row '>
    <div className=" col-12">
                                    {error && formData.reqCity == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            City is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
    
          </div>

    </div>  
    <div className='row margintop3'>
    <div className='col-5 textright'>
        <span className='blood-form-lable'>Blood Group</span>
    </div>
    <div className='col-6 form-group textleft'>
            <select   id="inputState"  
                className=" form-control "
                name='bloodGroup'
                onChange={handlechange}>
                <option selected value="">Select</option>
                {
                  
                  bloodGroupData.map((result) =>(
                  <option value={result}>{result}</option>
                  ) )

                }
                </select> 
                <div className='row '>
    <div className=" col-10 ">
                                    {error && formData.bloodGroup == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Blood Group is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>          
          </div>
         

    </div> 
    <div className="row margintop1" id='bloodrdiodiv'>
                    <div className='col-5 textright'>      
                     <span className='blood-form-lable'>Required Date</span>
                  </div>
                <div className="col-3 margintop1 textleft">
                    {/* <input type="radio" id="radioblood" className='radiobtnnews' 
                    name="reqDateType" value="immediately" onClick={handleshow} onChange={handlechange}
                    />  
                     <label for="radioblood" className='marginleft2 blood-form-text'> Immediately</label> */}
                     <label class="radiodivcust radiobtn-text1" >Immediately
                     <input type="radio" id="radioblood" className='radiobtnnews' 
                    name="reqDateType" value="immediately" onClick={handleshow} onChange={handlechange}
                    />  
                      <span class="checkmark"></span>
           </label>
                    </div>
                    <div className="col-3 margintop1 textleft" style={{whiteSpace:"nowrap"}}>
                    {/* <input type="radio" id="radioblood" className='radiobtnnews'  
                            onClick={handleshow}                  
                      name="reqDateType" value="specificdate" onChange={handlechange}
                    />  
                     <label for="radioblood" className='marginleft2 blood-form-text'>Specific Date</label> */}
                     <label class="radiodivcust radiobtn-text1" >Specific Date
                     <input type="radio" id="radioblood" className='radiobtnnews'  
                            onClick={handleshow}                  
                      name="reqDateType" value="specificdate" onChange={handlechange}
                    />   
                      <span class="checkmark"></span>
           </label>
                    </div>

                 </div> 
                 <div className='row '>
    <div className="col-12">
                                    {error && formData.reqDateType == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Date Type is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
                 {
                    showhide==='specificdate' && (
                    <div className='row'>
                      <div className='col-5'></div>
                    
                     <div className='col-5 '>
                     <DatePicker
                    className="adreservdate  "
                    name='reqDate'
                    selected={startDate}
                    onChange={date => handleChangedate(date)}
                    selectsStart // tells this DatePicker that it is part of a range*
                    startDate={startDate}
                    minDate={moment().toDate()}

                    dateFormat="dd  MMM yyyy"
                />        
                <div className='row '>
    <div className=" col-10 ">
                                    {error && formData.reqDate == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Date is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 
                     </div>
                     
                    </div>
                    

                    )

                    }  
</div>
<div className='popupbtn-div margintop'>
                 <div className="row ">
            <div className="col-3"></div>
           
            <div className="col-3">
                <button className="cancelbtn" onClick={() => onNext(true)} style={{height:"42px",width:"140px"}}>
                    Previous
                </button>
                </div>
                
                {
                    showhide==='immediately' && (
            <div className="col-3">
            <button className="createeventbtn" 
              type="submit"
          //  onClick={() => onNextOtp(false)} 
          style={{height:"42px",width:"140px",marginLeft:"15px"}} 
             >
                    Next
                </button>
            </div>
             )

          }   

{
                    showhide==='specificdate' && (
            <div className="col-3">
            <button className="createeventbtn"
            type='submit' 
            style={{height:"42px",width:"140px",marginLeft:"15px"}}
            //  onClick={() => onNextOtp(false)}    
               >
                    Next
                </button>
            </div>
             )

          }   
        </div>
           </div>
            </div>

            <div className={isformdiv1?"":"hide"}>
            <div style={{overflowY:"scroll",height:"240px"}}>

                
   <div className='row'>
    <div className='col-5 textright'>
        <span className='blood-form-lable'>Community Member Name</span>
    </div>
    <div className='col-6 textleft form-group'>
    
    {/* <input type="text" className=" form-control" id="fst" name="raisedFor"
              placeholder="Type Name and Mobile number of Member" style={{fontSize:"12px"}}/>     */}
     
     <Typeahead
      id="basic-example"
      name="raisedFor"
      //labelKey="name"
      labelKey={option => `${option.name} ${option.mobileNumberFormate}`}

      filterBy={['name', 'mobileNumberFormate']}
      // onInputChange={handleInputChange}
      onChange={onChandeDDL}
      options={autofetch}
      placeholder="Type Name or Mobile No "
      selected={selected}
      style={{fontSize:"12px"}}
    />
     <div className='textleft'><span className='blood-third-text'>This name will be displayed on the requirement post</span></div>
     <div className='row '>
    <div className=" col-12 ">
                                    {error1 && formData.raisedFor == ""|| error1 && formData.raisedFor == undefined? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            This is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
    </div>
   

    <span></span>

    </div> 
        
    <div className='row margintop3'>
    <div className='col-5 textright'>
        <span className='blood-form-lable'>Member Contact Number</span>
    </div>
    <div className='col-6 textleft'>
        <span className='blood-form-text'>{mobileNumberInfo}</span>
    </div>

    </div>  
    <div className='row margintop3'>
    <div className='col-5 textright'>
        <span className='blood-form-lable'>Place of requirement</span>
    </div>
    <div className='col-6 form-group textleft'>
        <div>
        <span className='blood-form-lable'>Country</span>
     <Select 
       id="country"
      name="reqCountry"
      label="country"
     className="countrydrp"
     rules={{ required: true }} 
    options={updatedCountries}
   
   onChange={(event) =>
    setFormData({...formData, reqCountry:event.name,countryId:event.id,reqCountryId:event.id})
   }
  />        
        </div>
        <div className='row '>
    <div className=" col-12 ">
                                    {error1 && formData.reqCountry == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Country is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
        <div className='margintop1'>
        <span className='blood-form-lable'>State</span>
        <Select 
      name="reqState"
      id="state"
      className="countrydrp"
      
       options={updatedStates(formData.countryId ? formData.countryId : null)}
      
       onChange={(event) =>
         setFormData({...formData, reqState:event.name,stateId:event.id,reqStateId:event.id})
       }
    

      />
        </div>
        <div className='row '>
    <div className=" col-12">
                                    {error1 && formData.reqState == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            State is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
        <div className='margintop1'>
        <span className='blood-form-lable'>City</span>

        <Select 
      //required
id="city"
className="countrydrp"
name="reqCity"
options={updatedCities(formData.stateId ? formData.stateId : null)}

 onChange={(event) =>
   setFormData({...formData, reqCity:event.name,cityId:event.id,reqCityId:event.id})
 }

      /> 
        </div>
        <div className='row '>
    <div className=" col-12">
                                    {error1 && formData.reqCity == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            City is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
    
          </div>

    </div> 
    <div className='row margintop3'>
    <div className='col-5 textright'>
        <span className='blood-form-lable'>Blood Group</span>
    </div>
    <div className='col-6 form-group textleft'>
            <select   id="inputState"  
                className=" form-control "
                name='bloodGroup'
                onChange={handlechange}>
                <option selected value="">Select</option>
                {
                  
                  bloodGroupData.map((result) =>(
                  <option value={result}>{result}</option>
                  ) )

                }
                </select> 
                <div className='row '>
    <div className=" col-12">
                                    {error1 && formData.bloodGroup == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Blood Group is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>          
          </div>
        

    </div> 
    
    <div className="row margintop1" id='bloodrdiodiv'>
                    <div className='col-5 textright'>      
                     <span className='blood-form-lable'>Required Date</span>
                  </div>
                <div className="col-3 margintop1 textleft">
                    {/* <input type="radio" id="radioblood" className='radiobtnnews' 
                    name="reqDateType" value="immediately" onClick={handleshow} onChange={handlechange}
                    />  
                     <label for="radioblood" className='marginleft2 blood-form-text'> Immediately</label> */}
                     <label class="radiodivcust radiobtn-text1" >Immediately
                     <input type="radio" id="radioblood" className='radiobtnnews' 
                    name="reqDateType" value="immediately" onClick={handleshow} onChange={handlechange}
                    />  
                      <span class="checkmark"></span>
           </label>
                    </div>
                    <div className="col-4 margintop1 textleft">
                    {/* <input type="radio" id="radioblood" className='radiobtnnews' 
                            onClick={handleshow}                  
                      name="reqDateType" value="specificdate" onChange={handlechange}
                    />  
                     <label for="radioblood" className='marginleft2 blood-form-text'>Specific Date</label> */}
                     <label class="radiodivcust radiobtn-text1" >Specific Date
                     <input type="radio" id="radioblood" className='radiobtnnews' 
                            onClick={handleshow}                  
                      name="reqDateType" value="specificdate" onChange={handlechange}
                    />  
                      <span class="checkmark"></span>
           </label>
                    </div>

                 </div> 
                 <div className='row '>
    <div className=" col-12 ">
                                    {error1 && formData.reqDateType == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Date Type is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
                 {
                    showhide==='specificdate' && (
                    <div className='row'>
                      <div className='col-5'></div>
                    
                     <div className='col-5 textleft'>
                     <DatePicker
                    className="adreservdate"
                    name='reqDate'
                    selected={startDate}
                    onChange={date => handleChangedate(date)}
                    selectsStart // tells this DatePicker that it is part of a range*
                    startDate={startDate}
                    minDate={moment().toDate()}

                    dateFormat="dd  MMM yyyy"
                />         
                <div className='row '>
    <div className=" col-12">
                                    {error1 && formData.reqDate == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Date is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 
                     </div>
                     
                    </div>

                    )

                    }  
                    </div>

                            <div className='popupbtn-div margintop'>
            
                 <div className="row ">
            <div className="col-3"></div>
           
            <div className="col-3">
                <button className="cancelbtn" onClick={() => onNextOther(true)}            
                 style={{height:"42px",width:"140px",marginLeft:""}}
>
                    Previous
                </button>
                </div>
                
                {
                    showhide==='immediately' && (
            <div className="col-3">
            <button className="createeventbtn" 
            type='submit'
            style={{height:"42px",width:"140px",marginLeft:"15px"}}

            //  onClick={() => onNextOtp(false)}  
               >
                    Next
                </button>
            </div>
             )

          }   

{
                    showhide==='specificdate' && (
            <div className="col-4">
            <button className="createeventbtn"  
            type='submit'
            style={{height:"42px",width:"140px",marginLeft:"15px"}}

            //  onClick={() => onNextOtp(false)} 
              >
                    Next
                </button>
            </div>
             )

          }   
        </div>
        </div>
            </div>

            <div className={isotpdiv?"":"hide"}>
            <div style={{overflowY:"",height:"240px"}}>

                <div className="row margintop1">
          <div className="col-1"> </div>
          <div className="margintop col-10">
          
            {
              formData.reqFor=='othercommunity'?(
                <div>
                <span className="enterotptxt"style={{fontSize:"13px"}}>Enter the OTP send to your mobile number {mobileNumberFormate1}</span>
                  <div className='margintop3' style={{whiteSpace:'nowrap'}}>
                    <span className='nri-text' style={{fontSize:"13px"}}>
                      NRIs check the OTP sent on your registered email id {emailInfoOtp}
                    </span>
                  </div>
              </div>
              ):(
                <div>
                <span className="enterotptxt"style={{fontSize:"13px"}}>Enter the OTP send to your mobile number {mobileNumberFormat}</span>
                  <div className='margintop3' style={{whiteSpace:'nowrap'}}>
                    <span className='nri-text' style={{fontSize:"13px"}}>
                      NRIs check the OTP sent on your registered email id {Email}
                    </span>
                  </div>
              </div>
              )
            }
           
            

            {/* <div className='row margintop'>
              <div className='col-1'></div>
              <div className='col-2'>
              <input type="number" className="otpbox form-control" id="fst"
               name="otpNum1" onChange={handlechangeOtp}/>
               <div className='row '>
                       <div className="  ">

                                    {error && otp.otpNum1 == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                        
                    </div>
              </div>
              <div className='col-2'>
              <input type="number" className="otpbox1  form-control" id="fst"
               name="otpNum2" style={{marginLeft:2}} onChange={handlechangeOtp}/>
               <div className='row '>
                       <div className="  ">

                                    {error && otp.otpNum2 == "" ? (
                                        <span style={{ color: "red",fontSize:"12px",marginLeft:"-5px" }}>
                                             required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                        
                    </div>
              </div>
              <div className='col-2'>
              <input type="number" className="otpbox3  form-control" id="fst"
               name="otpNum3" style={{marginLeft:4}} onChange={handlechangeOtp}/>
               <div className='row '>
                       <div className="  ">

                                    {error && otp.otpNum3 == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" ,marginLeft:"-0px" }}>
                                             required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                        
                    </div>
              </div>
              <div className='col-2'>
              <input type="number" className="otpbox4  form-control" id="fst"
               name="otpNum4" style={{marginLeft:6}} onChange={handlechangeOtp}/>
               <div className='row '>
                       <div className="  ">

                                    {error && otp.otpNum4 == "" ? (
                                        <span style={{ color: "red",fontSize:"12px",marginLeft:"0px"  }}>
                                             required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                        
                    </div>
              </div>
            
            </div> */}
           <div className='row margintop3'>
  <div className='col-2'></div>
  <div className='col-8'>
  <OtpInput
      value={otpNew}
      onChange={setOtpNew}
      numInputs={4}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} style={{width:"3rem",height:"3rem",textAlign:"center",borderColor:"",borderTopColor:"",borderLeftColor:""}} />}
    />
  </div>


</div>
            <div className=' row margintop '>
              <div className='col-1'></div>
              <div className=' col-10'>
              <span className='dindopt-txt'style={{fontSize:"12px",marginLeft:"20px"}}>Didn't receive OTP? <span className={enablejoin ?"resend-text resendtxt":'dindopt-txt dindopt-txt1 cursorNone'} onClick={()=>counterandbtn()}>Resend</span>  in <span className='resend-text'>{counter} seconds</span></span>

              </div>
            </div>
            <br/>
            
          </div>
        </div>
                </div>
                <div className='popupbtn-div margintop'>

<div className="row ">
<div className='col-3'></div>

<div className="col-3">
    <button className="cancelbtn" onClick={() => onNextOther(true)} 
      style={{height:"42px",width:"140px",marginLeft:"px"}}
>
        Previous
    </button>
    </div>
    
    
<div className="col-3">
<button className="createeventbtn"
style={{height:"42px",width:"140px",marginLeft:"15px"}}
onClick={handleotpVeryFyRedirect}
// onClick={() => onNextOtp(false)}  
  >
        Submit
    </button>
</div>


</div>
</div>
                </div>



</div>
</form>
    </div>
  );
};
 
export default BloodRequirePopup;