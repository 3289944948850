
import React, { useState, useEffect} from "react";
import '../UserLandingPages/UserLanding.css'
import clogo from '../../images/Naidus directory_Symbol.svg';
import profile from '../../images/sagarsir.svg';
import {MdOutlineArrowDropDown,MdLocationOn,MdNotifications,MdWaterDrop,MdEmail} from 'react-icons/md';
import { Link } from "react-router-dom";
import {CgProfile} from 'react-icons/cg'
import {Box} from '@material-ui/core';
import {Slider} from '@material-ui/core';
import eventlogo from '../../images/eventlogo.svg';
import eventimg1 from '../../images/event1.png';
import eventimg2 from '../../images/event2.png';
import eventimg3 from '../../images/event3.png';
import eventimg4 from '../../images/eventimg4jpg.jpg';
import {BiLike,} from 'react-icons/bi';
import {FaRegComments,FaUserClock,FaFilter,FaInfoCircle,FaRegHandshake,FaEdit,FaWhatsapp,FaPhoneSquareAlt,} from 'react-icons/fa';

import {AiOutlineDislike,AiFillLike} from 'react-icons/ai'
import {HiOutlineLogout} from 'react-icons/hi'
import{BsBookmark} from 'react-icons/bs'
import {RiCupFill,RiSendPlaneFill} from 'react-icons/ri';
import imgupload from '../../images/imageupload.svg'
import cups from '../../images/cups.svg';
import from from '../../images/from.svg';
import radius from '../../images/radius.svg';
import conprof from '../../images/coneect.svg';

import contactinfo from '../../images/contactinfo.png';
import connect from '../../images/connect.png';
import {MdAddBox} from 'react-icons/md';
import NotificationPopup from "../UserLandingPages/NotificationPopup";
import {ImHome3} from 'react-icons/im'
import './ViewProfile.css'
import {Tab,Tabs,Row,Col,Nav,NavItem,Card} from 'react-bootstrap'
import ReactTooltip from "react-tooltip";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns ,data, heading, Rows } from "./Data1";
import Payment from "./Payment";
import Contact from "./Contact";
import Refferal from "./Refferal";
import Post from "./Post";
import CoffeeMeets from "./CoffeeMeets";
import BloodRequirement from "./BloodRequirement";
import AccountSetting from "./AccountSetting";
import editviewpic from '../../images/u842.png';
import UpdateProfilePop from "./UpdateProfilePop";
import axios from "axios";
import { toast } from "react-toastify";
import Avatar from '@mui/material/Avatar';
import { useHistory } from "react-router-dom";
import {MdArrowDropDown} from 'react-icons/md'
import {configData} from "../../Config"
import CoffeemeetPopup from "./CoffeemeetPopup";
import Subscription from "./Subscription";
import EditMyprofBasicDetail from "./EditMyprofBasicDetail";
import EditMyprofAddress from "./EditMyprofAddress";




const ViewProfile =()=>{

  // //for session
  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  


  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)

const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);

  const [isOpenMessagePop, setIsOpenMessagePop] = useState(false);
      
     const togglePopupMessage = () => {
        setIsOpenMessagePop(!isOpenMessagePop);
      }

  const tableData = {
    columns,
    data,
    
  };

    const [isOpenNotification, setIsOpenNotification] = useState(false);
    const togglePopupNotify = () => {
      setIsOpenNotification(!isOpenNotification);
    }




    //for view profile
    const [subscriber ,setSubscriber] = useState({
      firstName: "",
      middleName: "",
      lastName: "",
      religion: "",
      subCaste: "",
      emailId: "",
      countryCode: "",
      mobileNumber: "",
      password: "",
      joinDate: "",
      verification: "",
      gender: "",
      birthDate: "",
      bloodGroup: "",
      maritalStatus: "",
      familyCount: "",
      profession: "",
      natureOfBuisness: "",
      mobileVisibility: "",
      currentCountry: "",
      currentState: "",
      currentCity: "",
      currentAddress1: " ",
      currentAddress2: "",
      currentLandmark: "",
      homeCountry: "",
      homeState: "",
      homeCity: "",
      homeAddress1: "",
      homeAddress2: "",
      homeLandmark: "",
      referalcount: "",
      directory: "",
      matrimony: "",
      buisnessDirectory: "",
      status: "",
      documentType: "",
      verificationDate: "",
      createdDate: ""
  });

  useEffect(()=>{
      loadsubScriber()
  },[])

const loadsubScriber = async () =>{
  const res = await axios.get(`${configData.ApiUrl}/subscriber/getSubscribersById?id=${uerAuthId}`);
  debugger;
  setSubscriber(res.data.data);
  console.log("getSubscribersById",res.data.data)
}

let history = useHistory();

const logoutclick = async() => {
  localStorage.clear()
  const result = await axios.get(configData.ApiUrl+'/logout');

  history.push("/Login");
}

const redirecttoViewProf = () => {
history.push("/ViewProfile");
}

    const [attachment, setAttachment] = useState([])

    const profilepicChange = event=>{
      debugger

      if(event.currentTarget.files != null)
         {
           setAttachment(event.target.files[0]);

           const formData = new FormData();

      formData.append("id",uerAuthId)
debugger;

      formData.append('profilePicture', event.target.files[0]);

      const response =  axios.post(configData.ApiUrl+'/user/updateProfilePicture', formData)
      
      response.then(() => {
        toast.success("Profile Added Successfully!");
        loadProfilePic();
        console.log('profilePicture',attachment)
      
      })
      response.catch((err) => {
        toast.error("Something Went Wrong");
      });
      
      }
         }

         
         //addProfilepic();
   //}

    const addProfilepic = async ()=>{
      
      const formData = new FormData();

      formData.append("id",uerAuthId)
debugger;

      formData.append('profilePicture', attachment);

      const response = await axios.post(configData.ApiUrl+'/user/updateProfilePicture', formData)
      if(response && response.code == 200)
      {
        toast.success("Profile Added Successfully!");
        loadProfilePic();
        console.log('profilePicture',attachment)
      }
      else{
        toast.error("Something Went Wrong");
      }
         console.log("response", response?.data)
     }

     function handleContextMenuimgdisable(e) {
      debugger
      e.preventDefault(); // prevents the default right-click menu from appearing
    }
  

     const [loadProfile, setloadProfile] = useState([]);

     useEffect(() => {
      loadProfilePic();
     }, []);
  
    const loadProfilePic = async () => {
      
      debugger;
      const result = await axios.get(`${configData.ApiUrl}/user/getProfilePicture?id=${uerAuthId}`);
      setloadProfile(result.data.data);
      console.log("profpic",result.data.data);
      const rootElement1 = document.getElementById('profpicdisablesave1');

    if(rootElement1){
      rootElement1.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
    if(rootElement1){
      return () => {
        rootElement1.removeEventListener('contextmenu', handleContextMenuimgdisable);
      }
    }
    const rootElement4 = document.getElementById('profpicdisablesave4');

    if(rootElement4){
      rootElement4.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
    if(rootElement4){
      return () => {
        rootElement4.removeEventListener('contextmenu', handleContextMenuimgdisable);
      }
    }
    const rootElementmyprof = document.getElementById('profpicviewprofmyprof');

    if(rootElementmyprof){
      rootElementmyprof.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
    if(rootElementmyprof){
      return () => {
        rootElementmyprof.removeEventListener('contextmenu', handleContextMenuimgdisable);
      }
    }
    
    };

    const avtarProf = subscriber.firstName.substr(0, 1) 

    const redirectToReferal = () => {
      history.push("/RefferalMain");
  }
  const redirectToContact = () => {
    history.push("/ContactMain");
    }
    const redirectToContactRecive = () => {
      history.push("/ViewContactRecive");
     }
 const redirectToCoffeemeetInvite = () => {
     history.push("/ViewCoffeeMeetinvitatin");
        }
        const redirectToAccSetting = () => {
          history.push("/AccountMain");
              }
      const redirectToBloodReq = () => {
          history.push("/BloodReqMain");
              }

              //for new session for userlogin
 //disable download and rightclick on image
useEffect(() => {
  function handleContextMenu(e) {
    e.preventDefault(); // prevents the default right-click menu from appearing
  }
  // add the event listener to the component's root element
  const rootElement = document.getElementById('profpicdisablesave');
  rootElement.addEventListener('contextmenu', handleContextMenu);
  // remove the event listener when the component is unmounted

  return () => {
    rootElement.removeEventListener('contextmenu', handleContextMenu);
  };
}, []);


////recieved invitaion coffeemeet list
const [otherscoffeemeet, setotherscoffeemeet] = useState([]);

useEffect(() => {
  loadOthersCoffeeMeet();
}, []);

const loadOthersCoffeeMeet = async () => {

  const result = await axios.get(`${configData.ApiUrl}/coffeeMeet/getListOfInvitedCoffeeMeets?hostId=${uerAuthId}`);
  setotherscoffeemeet(result.data.data);
  console.log("othercoffeemeet", result.data.data);
  
};

//my coffeemeet list
const [mycoffeemeet, setmycoffeemeet] = useState([]);

useEffect(() => {
  loadMyCoffeeMeet();
}, []);

const loadMyCoffeeMeet = async () => {

  const result = await axios.get(`${configData.ApiUrl}/coffeeMeet/getListOfMyCoffeeMeets?hostId=${uerAuthId}`);
  setmycoffeemeet(result.data.data);
  console.log("mycoffeemeet", result.data.data);
  
};

 //for businessdirectory
 const [isOpenCoffeePopup, setisOpenCoffeePopup] = useState(false);
  
 const togglePopupCoffee = () => {
  setisOpenCoffeePopup(!isOpenCoffeePopup);
 }
 const [editmyprofbasic, seteditmyprofbasic] = useState(false);
 const togglePopupeditbasic = () => {
  seteditmyprofbasic(!editmyprofbasic);
 }
 const [editmyprofAddress, seteditmyprofAddress] = useState(false);
 const togglePopupeditAddress = () => {
  seteditmyprofAddress(!editmyprofAddress);
 }

    return(
        <div className="container-fluid containerfluidpadding0">
        <div className="container-fluid  userlandtop-div">
        <div className="row">
          <div className="col-lg-1 col-sm-12 col-md-3">
            <img src={clogo} className='clogo' id='profpicdisablesave'/>

          </div>
          <div className="col-lg-2 col-sm-12 col-md-6 textleft">
            <span className="com-name">Naidu Directory</span>

          </div>
          <div className="col-lg-6 col-sm-12 col-md-12 "></div>
          <div className="col-1 displyinline  ">
            <MdNotifications size='1.8em' className="notifiicon" onClick={togglePopupNotify} />
            {isOpenNotification && <NotificationPopup
              handleClose={togglePopupNotify}
            />
            }
            <p className="notifi-text">12</p>
            {/* <RiCupFill className="cupicon-top" size='1.7em' />
            <p className="notifi-text1">2</p> */}

          </div>
          <div className="col-lg-2 col-sm-12 col-md-12 textcenter">

            <div className=" margintop1 textright">
              <div class="dropdown drpdiv">
                <button type="button" class="drpbtn-menu dropdown-toggle" data-toggle="dropdown">
                  <div className="row proftop-div textcenter">
                    <div className="col-lg-1 col-md-2 col-sm-12 margintop1">
                      {loadProfile == "" || loadProfile == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "20px", height: "20px" }}>{avtarProf}</Avatar>)
                        : (<img src={loadProfile} id='profpicdisablesave1' className='prof-img-right topprofsmimg' />)
                      }

                    </div>
                    <div className="col-lg-8 col-sm-12 col-md-9 margintop1 textcenter" style={{paddingTop:"4px"}}>
                      <span className="proftop-name textShowondottopprofname capitalizetxxxt">{authlocalstoragedata?.name2}</span>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-12 margintop1">
                      {/* <i class="fa fa-caret-down" aria-hidden="true"></i> */}
                      <MdArrowDropDown size='1.9em' style={{marginTop:"6px"}}/>
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu drpdn-meni-item" style={{ cursor: "pointer" }}>
                  <a class="dropdown-item drp-item" onClick={redirecttoViewProf} style={{ cursor: "pointer" }}>View Profile</a>
                  <a class="dropdown-item drp-item" onClick={redirectToContact}>View Contacts</a>
                  <a class="dropdown-item drp-item" onClick={redirectToBloodReq}>Blood Requirement Request</a>
                  <a class="dropdown-item drp-item" >View Subscriptions</a>
                  <a class="dropdown-item drp-item" onClick={redirectToContactRecive}>View Connect requests</a>
                  <a class="dropdown-item drp-item" onClick={redirectToReferal}>Referrals</a>
                  <a class="dropdown-item drp-item" onClick={redirectToAccSetting}>Change Password</a>
                  <a class="dropdown-item drp-item" onClick={redirectToAccSetting}>My Account</a>
                  <a class="dropdown-item drp-item" onClick={logoutclick}>Sign Out <HiOutlineLogout size='1.4em' className="marginleft2" /></a>
                </div>
              </div>
            </div>


          </div>


        </div>
      </div>
        <br/>
{/* <div className=" margintop1">
<div className=" row ">
<div className="col-lg-1 "></div>
<div className="col-lg-10 col-sm-12 col-md-12 viewprofile-top-div">
<Tabs defaultActiveKey={2} id="uncontrolled-tab-example">
<Tab eventKey={1} title="Sagar Suresh Naidu">
    Tab 1 content
  </Tab>
  <Tab eventKey={2} title="Home" >
    
     <div className="panel-body tabs-menu-body">
     <Tab.Container id="left-tabs-example" defaultActiveKey="first">
  <Row className="clearfix">
    <Col  className='col-4'>
      <Nav bsStyle="pills" stacked>
        <NavItem eventKey="first">Tab 1</NavItem>
        <NavItem eventKey="second">Tab 2</NavItem>
      </Nav>
    </Col>
    <Col  className='col-8'>
      <Tab.Content animation>
        <Tab.Pane eventKey="first">Tab 1 content</Tab.Pane>
        <Tab.Pane eventKey="second">Tab 2 content</Tab.Pane>
      </Tab.Content>
    </Col>
  </Row>
</Tab.Container> 
    </div> 

</Tab>
  
  <Tab eventKey={3} title="Free Directory">
    Tab 3 content
  </Tab>
  <Tab eventKey={4} title="Paid Directory" >
    Tab 4 content
  </Tab>
  <Tab eventKey={5} title="Matrimony">
    Tab 5 content
  </Tab>
  <Tab eventKey={6} title="Business Directory">
    Tab 6 content
  </Tab>
</Tabs>;
</div>
  
<div className="col-lg-1"></div>

        </div>
</div> */}
       
       <Row>
        <Col md={12} xl={12} xs={12} sm={12} >
          
            
              <div>
              <div className="text-wrap">
                  <div className="border">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="link-29">
                      <div className=" bg-gray-100 nav-bg horizontal-tabmenu ">

                        <Nav className="nav nav-tabs  ">
                        <Nav.Item>
                            <Nav.Link className="navlinkforverticaltab">
                            {authlocalstoragedata?.name1}
                        <span className='viewsub-activ-spn'>Active</span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="link-29" className="navlinkforverticaltab">
                              Home
                            </Nav.Link>
                          </Nav.Item>
                         
                          <Nav.Item>
                            <Nav.Link eventKey="link-30" className="navlinkforverticaltab">
                              Community Directory
                            </Nav.Link>
                          </Nav.Item>
                          
                          <Nav.Item>
                            <Nav.Link eventKey="link-31" className="navlinkforverticaltab">
                               Matrimony
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="link-32" className="navlinkforverticaltab">
                               Business Directory
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>

                      </div>

                      <Tab.Content className="card-body ">
                        <Tab.Pane eventKey="link-29">
                  <div className="">
                    
                    <Tab.Container id="left-tabs-example" defaultActiveKey="link-29">
                    <div className='row'>
                      {/* for left side div */}
                        <div className='col-3 scrolltoviewsub-left-div'>
                        <div className=" ">
                        <label className=''style={{paddingLeft:"20px",paddingTop:"3px",cursor:"pointer"}} for="selectFile">
                        {
  loadProfile==""?(
<Avatar className="avtarimg">{avtarProf}</Avatar> 

  ):(
    <img
    src={loadProfile}
    id="profpicviewprofmyprof"
    className=""
    alt=""
    style={{width:156,height:150,borderRadius:"50%",border:"1px",borderStyle:"solid",borderColor:"rgb(237, 237, 237)"}}
    />
  )
}
                         
                        


{
  loadProfile==""?(
<img src={editviewpic}  className="imgforeditviewpic"/>
  ):(
    <img src={editviewpic}  className="imgforeditviewpic1"/>

  )
}

{
  loadProfile==""?(
    <div className="editpic-viewprof">
  Upload Profile Pic 
  </div>
  ):(
<div className="editpic-viewprof1">
  Edit Profile Pic
  </div>
  )
}

  </label>
    <input id="selectFile" type="file"  className="fileupload hide" 
           name="profilePicture"  onChange={profilepicChange}
          />

<Nav className="nav nav-tabs flex-column " style={{marginTop:"15px"}}>

  <Nav.Item className="" style={{marginLeft:"25px"}}>
    <Nav.Link eventKey="link-29"  >
     My Profile
    </Nav.Link>
  </Nav.Item>
 
  <Nav.Item>
    <Nav.Link eventKey="link-30" style={{marginLeft:"25px"}}>
       Subscriptions
    </Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link eventKey="link-31" style={{marginLeft:"25px"}}>
      Payments
    </Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link eventKey="link-32" style={{marginLeft:"25px"}}>
       Contacts 
    </Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link eventKey="link-33" style={{marginLeft:"25px"}}>
        Referrals
    </Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link eventKey="link-34" style={{marginLeft:"25px"}}>
        Posts
    </Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link eventKey="link-35" style={{marginLeft:"25px"}} onClick={togglePopupCoffee}>
        Coffee Meets
    </Nav.Link>
    {isOpenCoffeePopup && <CoffeemeetPopup
                    handleClose={togglePopupCoffee}
                  />
                  }
                  
  </Nav.Item>
  <Nav.Item>
    <Nav.Link eventKey="link-36" style={{marginLeft:"25px"}}>
        Community Meets
    </Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link eventKey="link-37" style={{marginLeft:"25px"}} >
        Blood Requirements
    </Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link eventKey="link-38" style={{marginLeft:"25px"}}>
         Matrimony
    </Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link eventKey="link-39" style={{marginLeft:"25px"}}>
        Business Directory
    </Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link eventKey="link-40" style={{marginLeft:"25px"}}>
        Account Settings 
    </Nav.Link>
  </Nav.Item>
</Nav>

</div>
                        </div>
                        {/* for right hand div */}
                        <div className='col-9 viewsub-rightdiv'>
                          {/* <div className={isOpenMessagePop?"":"hide"}>
                         <UpdateProfilePop  handleClose={togglePopupMessage}  />
                         </div> */}
                         
                        <Tab.Content className="card-body ">
                        <Tab.Pane eventKey="link-29">
                         <div className='row'>
                            <div className='col-6'style={{marginTop:"-7px"}}>
                              <span className="profile-hdtext">My Profile</span>
                              <FaEdit size='1.2em' style={{marginLeft:'10px',cursor:"pointer"}} onClick={togglePopupeditbasic}/>
                              <div className="border1px margintop3">
                              <div className="row margintop3">
                                <div className="col-lg-6 col-sm-12 col-md-12 textright">
                                  <span className="event-sub-detail textright">Name:</span>

                                </div>
                                <div className="col-lg-6 col-sm-12 col-md-12 ">
                                <span className="event-sub-detail1" style={{textTransform:"capitalize"}}>{subscriber.firstName} {subscriber.middleName} {subscriber.lastName}</span>

                                </div>

                              </div>
                              <div className="row margintop3">
                                <div className="col-lg-6 col-sm-12 col-md-12 textright">
                                  <span className="event-sub-detail textright">CID:</span>

                                </div>
                                <div className="col-lg-6 col-sm-12 col-md-12 ">
                                <span className="event-sub-detail1"style={{textTransform:"capitalize"}}>{subscriber.id}</span>

                                </div>

                              </div>
                              <div className="row margintop3">
                                <div className="col-lg-6 col-sm-12 col-md-12 textright">
                                  <span className="event-sub-detail textright">DOA</span>
                                  <FaInfoCircle size='0.8em' className="margintpmins1" data-tip data-for="registerTip"/>:
                                  <ReactTooltip id="registerTip" place="right"  class='tooltip' effect="solid">
                    Date of Activation
                 </ReactTooltip>
                                </div>
                                <div className="col-lg-6 col-sm-12 col-md-12 ">
                                <span className="event-sub-detail1"style={{textTransform:"capitalize"}}>{subscriber.joinDate}</span>
                                </div>

                              </div>
                              <div className="row margintop3">
                                <div className="col-lg-6 col-sm-12 col-md-12 textright">
                                  <span className="event-sub-detail textright">Subcast:</span>

                                </div>
                                <div className="col-lg-6 col-sm-12 col-md-12 ">
                                <span className="event-sub-detail1"style={{textTransform:"capitalize"}}>{subscriber.subCaste}</span>
                                </div>

                              </div>
                              <div className="row margintop3">
                                <div className="col-lg-6 col-sm-12 col-md-12 textright">
                                  <span className="event-sub-detail textright">DOB:</span>

                                </div>
                                <div className="col-lg-6 col-sm-12 col-md-12 ">
                                <span className="event-sub-detail1"style={{textTransform:"capitalize"}}>{subscriber.birthDate}</span>
                                </div>

                              </div>
                              <div className="row margintop3">
                                <div className="col-lg-6 col-sm-12 col-md-12 textright">
                                  <span className="event-sub-detail textright">Gender:</span>

                                </div>
                                <div className="col-lg-6 col-sm-12 col-md-12 ">
                                <span className="event-sub-detail1"style={{textTransform:"capitalize"}}>{subscriber.gender}</span>
                                </div>

                              </div>
                              <div className="row margintop3">
                                <div className="col-lg-6 col-sm-12 col-md-12 textright">
                                  <span className="event-sub-detail textright">Blood Group:</span>

                                </div>
                                <div className="col-lg-6 col-sm-12 col-md-12 ">
                                <span className="event-sub-detail1"style={{textTransform:"capitalize"}}>{subscriber.bloodGroup}</span>
                                </div>

                              </div>
                              <div className="row margintop3">
                                <div className="col-lg-6 col-sm-12 col-md-12 textright">
                                  <span className="event-sub-detail textright">Family Members:</span>

                                </div>
                                <div className="col-lg-6 col-sm-12 col-md-12 ">
                                <span className="event-sub-detail1"style={{textTransform:"capitalize"}}>{subscriber.familyCount}</span>
                                </div>

                              </div>
                              <div className="row margintop3">
                                <div className="col-lg-6 col-sm-12 col-md-12 textright">
                                  <span className="event-sub-detail textright">Marital Status:</span>

                                </div>
                                <div className="col-lg-6 col-sm-12 col-md-12 ">
                                <span className="event-sub-detail1"style={{textTransform:"capitalize"}}>{subscriber.maritalStatus}</span>
                                </div>

                              </div>
                              <div className="row margintop3 ">
                                <div className="col-lg-6 col-sm-12 col-md-12 textright">
                                  <span className="event-sub-detail textright">Profession:</span>

                                </div>
                                <div className="col-lg-6 col-sm-12 col-md-12 ">
                                <span className="event-sub-detail1"style={{textTransform:"capitalize"}}>{subscriber.profession}</span>
                                </div>

                              </div>
                              <div className="row margintop3">
                                <div className="col-lg-6 col-sm-12 col-md-12 textright ">
                                  <span className="event-sub-detail ">Nature of Profession:</span>

                                </div>
                                <div className="col-lg-6 col-sm-12 col-md-12 ">
                                <span className="event-sub-detail1"style={{textTransform:"capitalize"}}>{subscriber.natureOfBuisness}</span>
                                </div>

                              </div>
                              <div className="row margintop3"></div>


                              </div>

                              
                            </div>
                            
                            <div className='col-6' style={{marginTop:"-7px"}}>
                            <span className="profile-hdtext">Address Details</span>
                              <FaEdit size='1.2em' style={{marginLeft:'10px',cursor:"pointer"}} onClick={togglePopupeditAddress}/>

                              <div className="border1px margintop3">
                              <div className="row ">
                                <div className="col-lg-1"></div>
                                <div className="col-lg-5 col-sm-12 col-md-12 "style={{textTransform:"capitalize"}}>
                                  <div className="margintop3"><span className="event-sub-detail ">From</span>  <span className="event-sub-detail1">Latur</span></div>
                                  <div className="margintop3"><span className="event-sub-detail1 ">{subscriber.homeAddress1}</span></div>
                                  <div className="margintop3"><span className="event-sub-detail1 "> {subscriber.homeAddress2}</span></div>
                                  <div className="margintop3"><span className="event-sub-detail1 ">{subscriber.homeLandmark} </span></div>
                                  <div className="margintop3"><span className="event-sub-detail1 "> {subscriber.homeCity}</span></div>
                                  <div className="margintop3"><span className="event-sub-detail1 ">{subscriber.homeState}</span></div>
                                  <div className="margintop3"><span className="event-sub-detail1 ">{subscriber.homeCountry}</span></div>




                                </div>
                                <div className="col-lg-6 col-sm-12 col-md-12 "style={{textTransform:"capitalize"}}>
                                <div className="margintop3"><span className="event-sub-detail ">Lives In</span>  <span className="event-sub-detail1">Pune</span></div>
                                  <div className="margintop3"><span className="event-sub-detail1 ">{subscriber.currentAddress1}</span></div>
                                  <div className="margintop3"><span className="event-sub-detail1 ">{subscriber.currentAddress2}</span></div>
                                  <div className="margintop3"><span className="event-sub-detail1 ">{subscriber.currentLandmark}</span></div>
                                  <div className="margintop3"><span className="event-sub-detail1 ">{subscriber.currentCity}</span></div>
                                  <div className="margintop3"><span className="event-sub-detail1 ">{subscriber.currentState}</span></div>
                                  <div className="margintop3"><span className="event-sub-detail1 ">{subscriber.currentCountry}</span></div>
                                </div>

                              
                              
                              </div>
                              
                              
                              <div className="row "></div>


                              </div>
                              
                              <div style={{marginTop:'10px'}}><span className="profile-hdtext ">My Contact Details</span><FaEdit size='1.2em' style={{marginLeft:'10px'}}/></div>
                              
                              <div className="border1px margintop1">
                              
                              <div className="row">
                                <div className="col-1"></div>
                                <div className="col-lg-11 col-sm-12 col-md-12 ">
                                <div className="margintop3"><MdEmail/><span className="event-sub-detail1 marginleft5 ">{subscriber.emailId}</span> </div>
                                  <div className="margintop3"><FaPhoneSquareAlt/><span className="event-sub-detail1 marginleft5" style={{textTransform:"capitalize"}}>{subscriber.countryCode}/{subscriber.mobileNumber}</span></div>
                                  <div className="margintop3"><FaWhatsapp/><span className="event-sub-detail1 marginleft5" style={{textTransform:"capitalize"}}>{subscriber.countryCode}/{subscriber.mobileNumber}</span></div>
                              <div className="row margintop3"></div>
                                </div>

                              </div>
                              


                              </div>

                            </div>
                            

                         </div>
                         {editmyprofbasic && <EditMyprofBasicDetail
      
      handleClose={togglePopupeditbasic} subscriber={subscriber} setSubscriber={setSubscriber}
    />}  
    {editmyprofAddress && <EditMyprofAddress
      
      handleClose={togglePopupeditAddress} subscriber={subscriber} setSubscriber={setSubscriber}
    />}  
                         {/* /* for right verificatopn detals */ }
                         <Row>
                          <Col className='col-3'></Col>
                         <Col className='col-4'>                  
                           </Col>
                         </Row>
                         <div className='row'>
                         
                          <div className='col-6'>
                          

                            {/* <Card.Body>
              <h5 className="mb-3">Member details </h5>
              <div className="">
                <Row className="">
                  <Col xl={12}>
                    <div className="table-responsive">
                      <Table className="table mb-0 border-top  text-nowrap">
                        <tbody>
                          <tr className='bordernon'>
                            <th scope="row">Name</th>
                            <td> {subscriber.firstName} {subscriber.middleName} {subscriber.lastName}</td>
                          </tr>
                          <tr className='bordernon'>
                            <th scope="row">CID</th>
                            <td>{subscriber.id}</td>
                          </tr>
                          <tr className='bordernon'>
                            <th scope="row">DOA</th>
                            <td>{subscriber.joinDate}</td>
                          </tr>
                          <tr className='bordernon'>
                            <th scope="row">Subcast</th>
                            <td>{subscriber.subCaste}</td>
                          </tr>
                          <tr className='bordernon'>
                            <th scope="row">DOB</th>
                            <td>{subscriber.birthDate}</td>
                          </tr>
                          <tr className='bordernon'>
                            <th scope="row">Gender</th>
                            <td>{subscriber.gender}</td>
                          </tr>
                          <tr className='bordernon'>
                            <th scope="row">Blood Group</th>
                            <td>{subscriber.bloodGroup}</td>
                          </tr>
                          <tr className='bordernon'>
                            <th scope="row">Family Members</th>
                            <td>{subscriber.familyCount}</td>
                          </tr>
                          <tr className='bordernon'>
                            <th scope="row">Marital Status</th>
                            <td>{subscriber.maritalStatus}</td>
                          </tr>
                          <tr className='bordernon'>
                            <th scope="row">profession</th>
                            <td>{subscriber.profession}</td>
                          </tr>
                          <tr className='bordernon'>
                            <th scope="row">Nature of Profession</th>
                            <td>{subscriber.natureOfBuisness}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card.Body> */}
                          </div>
                          <div className='col-6'>
                          
                            {/* <Card.Body>
              <h5 className="mb-3">Address details <span className='viewsub1edit'>1 Edit</span></h5>
              <div className='row border1px backffffcolor'>
                          <div className='col-6'>
                <ListGroupItem className="list-group-item bordernon backffffcolornon"> <span className='fontweightbold fontsize16'>From</span> Latur</ListGroupItem>
                <ListGroupItem className="list-group-item bordernon backffffcolornon">
                  {subscriber.homeAddress1}               
                </ListGroupItem>
                <ListGroupItem className="list-group-item bordernon backffffcolornon">
                {subscriber.homeAddress2}               
                </ListGroupItem>
                <ListGroupItem className="list-group-item bordernon backffffcolornon">
              {subscriber.homeLandmark}                
           </ListGroupItem>
                <ListGroupItem className="list-group-item bordernon backffffcolornon">
                {subscriber.homeCity}
                </ListGroupItem>
                <ListGroupItem className="list-group-item bordernon backffffcolornon">
                {subscriber.homeState}
                </ListGroupItem><ListGroupItem className="list-group-item bordernon backffffcolornon">
                {subscriber.homeCountry}

                </ListGroupItem>
                          </div>
                          <div className='col-6'>
                          <ListGroupItem className="list-group-item bordernon backffffcolornon"> <span className='fontweightbold fontsize16'>Lives in</span>  Pune</ListGroupItem>
                <ListGroupItem className="list-group-item bordernon backffffcolornon">
                {subscriber.currentAddress1}
                </ListGroupItem>
                <ListGroupItem className="list-group-item bordernon backffffcolornon">
                {subscriber.currentAddress2}
                </ListGroupItem>
                <ListGroupItem className="list-group-item bordernon backffffcolornon">
                {subscriber.currentLandmark}
                </ListGroupItem>
                <ListGroupItem className="list-group-item bordernon backffffcolornon">
                {subscriber.currentCity}
                </ListGroupItem>
                <ListGroupItem className="list-group-item bordernon backffffcolornon">
                {subscriber.currentState}
                </ListGroupItem><ListGroupItem className="list-group-item bordernon backffffcolornon">
                {subscriber.currentCountry}
                </ListGroupItem>
                          </div>

                          </div>
              
            </Card.Body> */}
            <div className='row'>
             
            </div>
             
                          </div>

                         </div>



                        </Tab.Pane>
                        <Tab.Pane eventKey="link-30">
                        <Row className=" row-sm">
             <Col lg={12}>
            
              
              <div className="table-responsive  deleted-table">
              <h6>Subscriptions </h6>

                {/* <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noDataComponent={false} //or your component
          striped
          noHeader
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          highlightOnHover
          print={false}
          export={true}
        
        />
      </DataTableExtensions> */}
      <Subscription/>
                
              </div>
           
        </Col>
      </Row>
      
                        </Tab.Pane>
                        <Tab.Pane eventKey="link-31">
                        <Row>
      <Col sm={12} md={12} lg={12}>
        <h6>Payments</h6>
        <div className="table-responsive fileexport pos-relative">
                <Payment/>
</div>
            
      </Col>
    </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="link-32">
                          
                        <Row>
      <Col sm={12} md={12} lg={12}>
        <h6>Contacts</h6>
        <div className="mt-2">
                <Contact/>
</div>
            
      </Col>
    </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="link-33">
                        <Row className=" row-sm">
             <Col lg={12}>
             <h6>Referrals</h6>

              <div className="table-responsive mt-2 deleted-table">
                  <Refferal/>
                
              </div>
           
        </Col>
                   </Row>
                        </Tab.Pane><Tab.Pane eventKey="link-34">
                        <Row className=" row-sm">
             <Col lg={12}>
             <h6>Posts</h6>

              <div className="table-responsive mt-2 deleted-table">
                  <Post/>
                
              </div>
           
        </Col>
                   </Row>                       
                    </Tab.Pane>
                    <Tab.Pane eventKey="link-35">
                    <Row className=" row-sm">
             <Col lg={12}>

              <div className="table-responsive mt-2 deleted-table">
                  <CoffeeMeets  otherscoffeemeet={otherscoffeemeet} setotherscoffeemeet={setotherscoffeemeet} mycoffeemeet={mycoffeemeet} setmycoffeemeet={setmycoffeemeet} />
                
              </div>
           
        </Col>
                   </Row>      
                        </Tab.Pane>
                        <Tab.Pane eventKey="link-36">
                           voluptas assumenda est, omnis dolor
                          repellendus.
                        </Tab.Pane>
                        <Tab.Pane eventKey="link-37">
                        <Row className=" row-sm">
             <Col lg={12}>

              <div className="table-responsive mt-2 deleted-table">
                  <BloodRequirement/>
                
              </div>
           
        </Col>
                   </Row> 
                          
                        </Tab.Pane>
                        <Tab.Pane eventKey="link-38">
                          Nam libero tempore, cum soluta nobis est eligendi optio
                          cumque nihil impedit 
                          repellendus.
                        </Tab.Pane>
                        <Tab.Pane eventKey="link-39">
                          
                        <Row className=" row-sm">
             <Col lg={12}>
            
              <div className="table-responsive mt-5 deleted-table">

                
              </div>
           
        </Col>
      </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="link-40">
                        <Row className=" row-sm">
             <Col lg={12}>

              <div className="table-responsive mt-2 deleted-table">
            <AccountSetting/>
                
              </div>
           
        </Col>
      </Row>
                        </Tab.Pane>
                      </Tab.Content>
                        </div>

                    </div>
                      
                      
                    </Tab.Container>
                  </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="link-30">
                          At vero eos et accusamus et iusto odio dignissimos ducimus
                          qui blanditiis praesentium voluptatum deleniti atque
                          corrupti quos dolores et quas molestias excepturi sint
                          occaecati cupiditate non provident, similique sunt in
                          culpa qui officia deserunt mollitia animi, id est laborum
                          et dolorum fuga.
                        </Tab.Pane>
                        <Tab.Pane eventKey="link-31">
                          At vero eos et accusamus et iusto odio dignissimos ducimus
                          qui blanditiis praesentium voluptatum deleniti atque
                          corrupti quos dolores et quas molestias excepturi sint
                          occaecati cupiditate non provident, similique sunt in
                          culpa qui officia deserunt mollitia animi, id est laborum
                          et dolorum fuga.
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
              </div>
              
              </div>
            
         
        </Col>
      </Row>
        
        

      


      
    </div>
    );
}


export default ViewProfile